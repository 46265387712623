import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

import {GlobalServices} from "../services/global-services";
import {Utilizador} from "../models/Utilizador";

@autoinject()
export class RecuperaPassDialog {
  //static inject = [DialogController, ValidationControllerFactory];
  protected controller: DialogController;
            app: GlobalServices;

  protected isBusy: boolean  = false;
  protected enviado: boolean = false;
  private multifield: string;

  // // predicado da validade do formulário.
  // public get isValid(): boolean {
  //   return this.validationController.errors.length == 0;
  // }

  constructor(controller: DialogController, app: GlobalServices) {
    this.controller = controller;
    this.app        = app;

    //console.log("lodash test", _s.camelCase("uma-string-que-vai passar a camel case"));
  }

  activate() {}

  attached() {}

  cleanup() {}

  submit() {
    this.isBusy = true;
    this.app.auth
      .recover(this.multifield)
      .then(_ => this.enviado = true)
      .catch(err => {
        this.app.notificationErrorCompact(err);
        this.isBusy = false;
        this.enviado = true;
      });
  }
}
