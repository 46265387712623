// Auto-gerado com typewriter

import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {Artigo} from './Artigo';
import {Familia} from './Familia';

export class ArtigoFamilia extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public nvcArtigo: string             = null;
  public nvcFamilia: string            = null;
  public nvcArtigoNavigation: Artigo   = null;
  public nvcFamiliaNavigation: Familia = null;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ArtigoFamilia>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): ArtigoFamilia {
    let model = new ArtigoFamilia();
    model.setState(obj);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): ArtigoFamilia[] {
    if (objs && Array.isArray(objs)) return objs.map(ArtigoFamilia.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ArtigoFamilia>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ArtigoFamilia { return ArtigoFamilia.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `ArtigoFamilia`;
  }
}

/*
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: ArtigoFamilia) => m.nvcArtigo).displayName("nvcArtigo").required()
    .ensure((m: ArtigoFamilia) => m.nvcFamilia).displayName("nvcFamilia").required()
    .ensure((m: ArtigoFamilia) => m.nvcArtigoNavigation).displayName("nvcArtigoNavigation").required()
    .ensure((m: ArtigoFamilia) => m.nvcFamiliaNavigation).displayName("nvcFamiliaNavigation").required()
  .on(ArtigoFamilia);
*/
