/**
 * Created by hernani on 2017-06-20.
 */

export class NumberFormatterValueConverter {
  public toView(value: any) {
    if (value) {
      return value;
    } else {
      return value;
    }
  }

  public fromView(value: any) {
    if (value) {
      let check = value * 1;
      if (isNaN(check)) {
        return value;
      } else {
        return value * 1;
      }

    } else {
      return value;
    }
  }
}
