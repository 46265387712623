// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {PlanoExpedicao} from './PlanoExpedicao';
import {Utilizador} from './Utilizador';
import environment from "../environment";

//export classe
export class ArmazemEtiqueta extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idArmazemEtiqueta: number                  = 0;
  public idPlanoExpedicao: number                   = 0;
  public intQuantidade: number                      = 0;
  public dtmData: string                            = null;
  //public _dtmData:Date = new Date();
  public nvcClienteArmazemSobreposicao: string      = null;
  public nvcArtigoSobreposicao: string              = null;
  public nvcArtigoDescricaoSobreposicao: string     = null;
  public nvcCodigoEncomendaSobreposicao: string     = null;
  public nvcGravacaoSobreposicao: string            = null;
  public nvcCodigoInterno: string                   = "00000000-0000-0000-0000-000000000000";
  public nvcObservacoes: string                     = null;
  public bitConferido: boolean                      = null;
  public dtmDataConferencia: string                 = null;
  public _dtmDataConferencia: Date                  = new Date();
  public idUtilizadorI: number                      = 0;
  public idUtilizadorA: number                      = 0;
  public dtmDataI: string                           = null;
  public dtmDataA: string                           = null;
  public timestamp: number[]                        = [];
  public idPlanoExpedicaoNavigation: PlanoExpedicao = null;

  // public idUtilizadorANavigation : Utilizador = null;
  // public idUtilizadorINavigation : Utilizador = null;

  //extra
  public intNumeroEmbalagens: number    = 1;
  public intCapacidadeEmbalagem: number = 100;
  public intReservadoCapacidade: number = 0;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ArmazemEtiqueta>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): ArmazemEtiqueta {
    let model = new ArmazemEtiqueta();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): ArmazemEtiqueta[] {
    if (objs && Array.isArray(objs)) return objs.map(ArmazemEtiqueta.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ArmazemEtiqueta>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ArmazemEtiqueta { return ArmazemEtiqueta.fromPOJSO(this.stateToPOJSO());}

  //endregion

  //region próprios

  public recalcula () {
    if(environment.debug) console.log("[armazemetiqueta]","recalcula");
    this.recalculaEmbalagens(false);
    this.intCapacidadeEmbalagem = this.intReservadoCapacidade || this.intCapacidadeEmbalagem;
    if(this.intCapacidadeEmbalagem * this.intNumeroEmbalagens != this.intQuantidade) this.recalculaCapacidade();
  }

  public recalculaEmbalagens(limpaReserva: boolean = true) {
    //qd a função é executada pelo evento de change do numero de páginas a reserva fuuushhh!
    if (limpaReserva) this.intReservadoCapacidade = 0;
    if (this.intQuantidade) {
      this.intCapacidadeEmbalagem = Math.ceil(this.intQuantidade / this.intNumeroEmbalagens);
    }
  }

  public recalculaCapacidade() {
    this.intReservadoCapacidade = this.intCapacidadeEmbalagem;
    if (this.intQuantidade) {
      this.intNumeroEmbalagens = Math.ceil(this.intQuantidade / this.intCapacidadeEmbalagem);
    }
  }
  //endregion próprios

  public toString() {
    return `ArmazemEtiqueta`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  // .ensure((m: ArmazemEtiqueta) => m.idArmazemEtiqueta).displayName("idArmazemEtiqueta").required()
  // .ensure((m: ArmazemEtiqueta) => m.idPlanoExpedicao).displayName("idPlanoExpedicao").required()
  .ensure((m: ArmazemEtiqueta) => m.intQuantidade).displayName("intQuantidade").required()
  .ensure((m: ArmazemEtiqueta) => m.dtmData).displayName("dtmData").required()
  .on(ArmazemEtiqueta);
