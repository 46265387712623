import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from "../../../models/BaseViewModel";
import {VmWrapper} from "../../../models/VmWrapper";

export class ZzRemoteGridColumn extends BaseViewModel {
  public idZzRemoteGridColumn: number = 0;
  // public idZzRemoteGrid: number       = 0;
  public nomeRemoteGrid: number       = 0;
  public sqlField: string             = '';
  public virtual: boolean             = false;
  public printable: boolean           = false;
  public printHeaderName: string;
  public condition: string            = '';
  public formatFn: string;
  public sqlSearchFn: string;
  public aggregationType: string;
  public aggregatorFn: string;
  public key: string;
  public colField: string             = '';
  public colWidth: number             = 0;
  public colRowTemplate: string;
  public colHeaderTemplate: string;
  public colPinLeft: boolean;
  public colPinRight: boolean;
  public colHeaderName: string;
  public colHeaderClass: string;
  public colAddLabelAttributes: string;
  public colAddFilterAttributes: string;
  public colAddRowAttributes: string;
  public colFilterMenu: string;
  public colLabelMenu: string;
  public colRowMenu: string;
  public colHidden: boolean           = false;
  public colDragDrop: string;
  public colResizeable: string;
  public colSort: string;
  public colDisplayEdit: string;
  public colFilter: string;
  public colFilterTop: string;
  public colCss: string;
  public colType: string;
  public colCssClass: string;
  public colAggrClass: string         = '';
  public colProxy: boolean            = false;
  public colProxyFilterType: string;
  public colProxyAlign: string        = '';
  public colProxyOperator: string     = '';
  public colAggRowTemplate: string;
  public bundles: string;
  public ordinalPosition: number      = 0;
  public colUseInList: boolean        = true;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ZzRemoteGridColumn>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): ZzRemoteGridColumn {
    let model = new ZzRemoteGridColumn();

    //PORQUE É QUE? não se usa o setStade do BaseViewModel? porque o nome das propriedades nesta classe não obedece a nenhum principio de prefixo
    model.idZzRemoteGridColumn   = +obj.idZzRemoteGridColumn;
    model.nomeRemoteGrid         = obj.nomeRemoteGrid;
    model.sqlField               = obj.sqlField;
    model.virtual                = obj.virtual;
    model.printable              = obj.printable;
    model.printHeaderName        = obj.printHeaderName;
    model.condition              = obj.condition;
    model.formatFn               = obj.formatFn;
    model.sqlSearchFn            = obj.sqlSearchFn;
    model.aggregationType        = obj.aggregationType;
    model.aggregatorFn           = obj.aggregatorFn;
    model.key                    = obj.key;
    model.colField               = obj.colField;
    model.colWidth               = obj.colWidth;
    model.colRowTemplate         = obj.colRowTemplate;
    model.colHeaderTemplate      = obj.colHeaderTemplate;
    model.colPinLeft             = obj.colPinLeft;
    model.colPinRight            = obj.colPinRight;
    model.colHeaderName          = obj.colHeaderName;
    model.colHeaderClass         = obj.colHeaderClass;
    model.colAddLabelAttributes  = obj.colAddLabelAttributes;
    model.colAddFilterAttributes = obj.colAddFilterAttributes;
    model.colAddRowAttributes    = obj.colAddRowAttributes;
    model.colFilterMenu          = obj.colFilterMenu;
    model.colLabelMenu           = obj.colLabelMenu;
    model.colRowMenu             = obj.colRowMenu;
    model.colHidden              = obj.colHidden;
    model.colDragDrop            = obj.colDragDrop;
    model.colResizeable          = obj.colResizeable;
    model.colSort                = obj.colSort;
    model.colDisplayEdit         = obj.colDisplayEdit;
    model.colFilter              = obj.colFilter;
    model.colFilterTop           = obj.colFilterTop;
    model.colCss                 = obj.colCss;
    model.colType                = obj.colType;
    model.colCssClass            = obj.colCssClass;
    model.colAggrClass           = obj.colAggrClass;
    model.colProxy               = obj.colProxy;
    model.colProxyFilterType     = obj.colProxyFilterType;
    model.colProxyAlign          = obj.colProxyAlign;
    model.colProxyOperator       = obj.colProxyOperator;
    model.colAggRowTemplate      = obj.colAggRowTemplate;
    model.bundles                = obj.bundles;
    model.ordinalPosition        = obj.ordinalPosition;
    model.colUseInList           = obj.colUseInList;

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): ZzRemoteGridColumn[] {
    if (objs && Array.isArray(objs)) return objs.map(ZzRemoteGridColumn.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ZzRemoteGridColumn>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ZzRemoteGridColumn { return ZzRemoteGridColumn.fromPOJSO(this.stateToPOJSO());}

  // transforma o estado interno num adequado ao transporte num ficheiro
  public stateToPOJSOSafeId(): any {
    let state = this.getState();
    //no fundo apagam-se os ids
    if (state.idZzRemoteGridColumn) state.idZzRemoteGridColumn = 0;
    return state;
    //
  }

  //endregion estado

  //todo:
  // public adicionaColuna(){
  //
  // }

  public toString() {
    return `ZzRemoteGridColumn`;
  }

  public toFgString() {
    if (this.bundles.startsWith("Accoes")) {
      return `
<!-- accoes -->
      `;
    }
    if (this.bundles.startsWith("Bool")) {
      return `
<f-g class="col-md-1" label="${this.colHeaderName || this.colField}" title="${this.colHeaderName || this.colField}">
  <switch checked.bind="modelo.${this.colField}"></switch>
</f-g>`;
    }
    return `
<f-g class="col-md-12" label="${this.colHeaderName || this.colField}" title="${this.colHeaderName || this.colField}">
  <input class="form-control" value.bind="modelo.${this.colField} & validate" placeholder="${this.colHeaderName || this.colField}">
</f-g>`
  }
}

//region aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: ZzRemoteGridColumn) => m.idZzRemoteGridColumn).displayName('idZzRemoteGridColumn').required()
  .ensure((m: ZzRemoteGridColumn) => m.nomeRemoteGrid).displayName('nomeRemoteGrid').required()
  .ensure((m: ZzRemoteGridColumn) => m.sqlField).displayName('sqlField').required()
  .ensure((m: ZzRemoteGridColumn) => m.virtual).displayName('virtual').required()
  .ensure((m: ZzRemoteGridColumn) => m.printable).displayName('printable').required()
  .ensure((m: ZzRemoteGridColumn) => m.printHeaderName).displayName('printHeaderName').required()
  .ensure((m: ZzRemoteGridColumn) => m.condition).displayName('condition').required()
  // .ensure((m: ZzRemoteGridColumn) => m.formatFn).displayName('formatFn').required()
  // .ensure((m: ZzRemoteGridColumn) => m.sqlSearchFn).displayName('sqlSearchFn').required()
  // .ensure((m: ZzRemoteGridColumn) => m.aggregationType).displayName('aggregationType').required()
  // .ensure((m: ZzRemoteGridColumn) => m.aggregatorFn).displayName('aggregatorFn').required()
  // .ensure((m: ZzRemoteGridColumn) => m.key).displayName('key').required()
  .ensure((m: ZzRemoteGridColumn) => m.colField).displayName('colField').required()
  .ensure((m: ZzRemoteGridColumn) => m.colWidth).displayName('colWidth').required()
  .ensure((m: ZzRemoteGridColumn) => m.colRowTemplate).displayName('colRowTemplate').required()
  .ensure((m: ZzRemoteGridColumn) => m.colHeaderTemplate).displayName('colHeaderTemplate').required()
  .ensure((m: ZzRemoteGridColumn) => m.colPinLeft).displayName('colPinLeft').required()
  .ensure((m: ZzRemoteGridColumn) => m.colPinRight).displayName('colPinRight').required()
  // .ensure((m: ZzRemoteGridColumn) => m.colHeaderName).displayName('colHeaderName').required()
  // .ensure((m: ZzRemoteGridColumn) => m.colHeaderClass).displayName('colHeaderClass').required()
  .ensure((m: ZzRemoteGridColumn) => m.colAddLabelAttributes).displayName('colAddLabelAttributes').required()
  .ensure((m: ZzRemoteGridColumn) => m.colAddFilterAttributes).displayName('colAddFilterAttributes').required()
  .ensure((m: ZzRemoteGridColumn) => m.colAddRowAttributes).displayName('colAddRowAttributes').required()
  .ensure((m: ZzRemoteGridColumn) => m.colFilterMenu).displayName('colFilterMenu').required()
  .ensure((m: ZzRemoteGridColumn) => m.colLabelMenu).displayName('colLabelMenu').required()
  .ensure((m: ZzRemoteGridColumn) => m.colRowMenu).displayName('colRowMenu').required()
  .ensure((m: ZzRemoteGridColumn) => m.colHidden).displayName('colHidden').required()
  .ensure((m: ZzRemoteGridColumn) => m.colDragDrop).displayName('colDragDrop').required()
  .ensure((m: ZzRemoteGridColumn) => m.colResizeable).displayName('colResizeable').required()
  .ensure((m: ZzRemoteGridColumn) => m.colSort).displayName('colSort').required()
  .ensure((m: ZzRemoteGridColumn) => m.colDisplayEdit).displayName('colDisplayEdit').required()
  .ensure((m: ZzRemoteGridColumn) => m.colFilter).displayName('colFilter').required()
  .ensure((m: ZzRemoteGridColumn) => m.colFilterTop).displayName('colFilterTop').required()
  .ensure((m: ZzRemoteGridColumn) => m.colCss).displayName('colCss').required()
  .ensure((m: ZzRemoteGridColumn) => m.colType).displayName('colType').required()
  .ensure((m: ZzRemoteGridColumn) => m.colCssClass).displayName('colCssClass').required()
  .ensure((m: ZzRemoteGridColumn) => m.colAggrClass).displayName('colAggrClass').required()
  .ensure((m: ZzRemoteGridColumn) => m.colProxy).displayName('colProxy').required()
  .ensure((m: ZzRemoteGridColumn) => m.colProxyFilterType).displayName('colProxyFilterType').required()
  .ensure((m: ZzRemoteGridColumn) => m.colProxyAlign).displayName('colProxyAlign').required()
  .ensure((m: ZzRemoteGridColumn) => m.colProxyOperator).displayName('colProxyOperator').required()
  .ensure((m: ZzRemoteGridColumn) => m.colAggRowTemplate).displayName('colAggRowTemplate').required()
  // .ensure((m: ZzRemoteGridColumn) => m.bundles).displayName('bundles').required()
  .ensure((m: ZzRemoteGridColumn) => m.ordinalPosition).displayName('ordinalPosition').required()
  .on(ZzRemoteGridColumn);
//endregion
