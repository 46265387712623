import {bindable} from 'aurelia-framework';

export class EjDatePicker {
  @bindable value;
  @bindable placeholder              = "Escolha uma data";
  @bindable debug: boolean           = false;
  @bindable showPopupButton: boolean = true;
  @bindable pixel:number =24;
  public domElement: HTMLSelectElement;
  private parentElement: HTMLDivElement;

  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("EjDatePicker", "valueChanged", this, newValue, oldValue);
    if (newValue && newValue != oldValue && this.value != newValue) {
      this.value = newValue;
    }

    if (this.debug) console.log("EjDatePicker", "valueChanged", $(this.domElement), $(this.domElement).val());
    if (this.domElement && $(this.domElement).val() != newValue) {
      //Setting select2
      $(this.domElement).val(newValue).trigger("change");
    }
  }

  attached() {
    if (this.debug) console.log("EjDatePicker", "attached", this);

    $(this.domElement).ejDatePicker({
      locale         : "pt-PT",
      value          : this.value, // sets the current date
      dateFormat     : "yyyy-MM-dd", // sets the date format
      showPopupButton: this.showPopupButton,
      width          : "100%",
      buttonText     : "Hoje",
      watermarkText  : this.placeholder,
      allowEdit      : false,
      //height         : "41px",
      showFooter     : false,
      change         : (v) => {
        if (this.debug) console.log("EjDatePicker", "date changed", v);
        if (v && v.value) {
          if(v.value != this.value) this.changeEvent();
          this.value = v.value;
        } else {
          if(this.value) this.changeEvent();
          this.value = "";
        }
      }
    });
    // if (this.value) {
    //   if (this.debug) console.log("EjDatePicker", "attached", this.select2);
    //   this.select2.val("" + this.value).trigger("change");
    // }
    if (this.debug) console.log("EjDatePicker", "attached", this.parentElement);
  }

  private changeEvent() {
    if (this.debug) console.log("EjDatePicker", "changeEvent");
    // https://stackoverflow.com/a/2856602
    if (this.parentElement.onchange) {
      if (this.debug) console.log("EjDatePicker", "direct call to onchange");
      this.parentElement.onchange(new Event("change"));
      return;
    }
    if ("createEvent" in document) {
      if (this.debug) console.log("EjDatePicker", "direct call to onchange");
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", true, true);
      this.parentElement.dispatchEvent(evt);
    }
    else
      (this.parentElement as any).fireEvent("onchange");
  }
}
