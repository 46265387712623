// import {ColConfigInterface, DataSource, EntityInterface, GridConnector, Selection, SortObjectInterface} from "aurelia-v-grid";
import {GlobalServices} from "../../services/global-services";
import {autoinject} from "aurelia-framework";
import * as AwesompleteJs from "awesomplete";
import {validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {BootstrapFormRenderer} from "../../services/bootstrap-form-renderer";
import environment from "../../environment";

//import * from "choices.js/assets/styles/css";
@autoinject()
export class Anim {
  message: string;
  private toggle:boolean = false;

  constructor(public app: GlobalServices) {
  }

  //esta route apenas ativa em debug
  canActivate(p) {
    return environment.debug;
  }
}
