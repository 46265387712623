import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {App} from "../../app";
import {dateISOString} from "../../utils/ItNumeric";
import {ColConfigInterface, DataSource, GridConnector, Selection} from "../../it-v-grid/interfaces";
import {RegistoPlanoPrgOriginal} from "../../models/RegistoPlanoPrgOriginal";
import {RegistoEtiqueta} from "../../models/RegistoEtiqueta";
import {ComposeDialog, ComposeDialogOptions} from "../../dialogs/compose-dialog";
import {EntradaEtiquetasDialog} from "./ce/entrada-etiquetas-dialog";
import {confirmaActionTyped} from "../../services/api-envelopes";
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {activationStrategy} from "aurelia-router";
import {range} from "../../utils/ItMultiPurpose";

@autoinject()
export class NovaExpedicao {
  public app: GlobalServices;

  //itsy bitsy spiders
  public isBusy: boolean = false;

  private codBarras: HTMLInputElement;

  private buffer: string = "";

  //V-Grid
  private ds: DataSource;
  private gridConnector: GridConnector;
  private columns: ColConfigInterface[]     = [];
  private linhas: RegistoPlanoPrgOriginal[] = [];

  private diaIni: string;

  //cliente armazém
  private planosUtilizador: string[] = [];
  private planos: ClienteArmazem[]   = [];
  private listaClientes: ClienteArmazem[];

  constructor(app: GlobalServices, public rootApp: App) {
    this.app           = app;
    this.ds            = new DataSource(new Selection("single"), {key: 'idRegistoPlano'});
    this.gridConnector = new GridConnector(this.ds);
    window["curr"]     = this;
  }

  //region Aurelia
  canActivate(p) {
    if (environment.debug) console.log("[nova-expedicao]", "canActivate");
    let oneOf = this.app.auth.oneOf(["Controlo.ExpedicaoVer", "App.FolhaExpedicaoVer"]);

    if (!oneOf) return this.app.notificationErrorCompact("O utilizador não dispõe de permissões para aceder ao módulo de controlo.");

    this.planosUtilizador = this.app.auth.getPlanos();//.join(",");//this.parent.activeSubRoute = "expedicao";
    if (environment.debug) console.log("[nova-expedicao]", "canActivate", this.planosUtilizador);

    if (p && p.diaIni) { this.diaIni = p.diaIni;} else { this.diaIni = dateISOString(new Date()); }

    return ClienteArmazem.memoizeMultiple(this.app.api)
      .then(r => this.planos = r)
      .then(_ => true)
      .catch(err => this.app.notificationErrorCompact(err));
  }

  activate(p) {
    if (environment.debug) console.log("[expedicao-doc]", "activate", p);
    this.configuraColunas();
    //reduz a seleção dos planos
    this.listaClientes     = this.planos.filter(el => this.planosUtilizador.includes(el.nvcClienteArmazem));
    if (environment.debug) console.log("[expedicao-doc]", "activate end", this.columns, this.listaClientes);
  }

  attached() {
    if (environment.debug) console.log("[nova-expedicao]", "attached");

    /*
        this.linhas = [];
        this.ds.setArray(this.linhas);
        this.ds.orderBy("nvcClienteArmazem");
        this.ds.orderBy("dtmMovimento", true);
        this.ds.orderBy("idRegistoPlano", true);
        this.registerListeners();
    */
    this.doAction("INIT");
  }

  unbind() {
    if (environment.debug) console.log("[nova-expedicao]", "unbind");
    document.onkeyup = null;
    //fechar todos os popups
    this.app.ds.closeAll();
  }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  /**
   * Pede uma lista de linhas de programações e as etiquetas associadas
   * @param {string[] | number[]} rps
   * @return {Promise<RegistoPlanoPrgOriginal[]>}
   */
  getLinhas(rps: string[] | number[] = []) {
    let payload: any = {};
    payload.diaIni   = this.diaIni;
    payload.planos   = this.planosUtilizador;
    if (rps && rps.length > 0) payload.rps = rps;

    return this.app.api.getProcessed('api/expedicao/folha', payload)
      .then(r => {
        //console.log(r);
        let linhas = RegistoPlanoPrgOriginal.multipleFromPOJSO(r.linhas);
        if (r.etiquetas && Array.isArray(r.etiquetas)) {
          r.etiquetas.forEach(e => {
            let etiqueta = RegistoEtiqueta.fromPOJSO(e);
            let linha    = linhas.find(el => el.idRegistoPlano == etiqueta.idRegistoPlano);
            if (linha) {
              linha.RegistoEtiqueta.push(etiqueta);
            }
          })
        }
        //console.log(this.linhas);
        return linhas;
      })
  }

  /**
   * Atualiza algumas linhas com novasLinhas
   * Usado para publicar dados na grid
   *
   * @param {RegistoPlanoPrgOriginal[]} novasLinhas
   * @return {Promise<boolean>}
   */
  mergeLinhas(novasLinhas: RegistoPlanoPrgOriginal[]) {
    if (!novasLinhas || novasLinhas.length == 0) {
      console.warn("não há novas linhas", novasLinhas);
      return Promise.resolve(true);
    }
    novasLinhas.forEach(nl => {
      let existente = this.linhas.findIndex(l => l.idRegistoPlano == nl.idRegistoPlano);
      if (existente >= 0) {
        //atualizar a referência
        this.linhas[existente] = nl;
      } else {
        if(environment.debug) console.warn("[nova-expedicao]", `A linha de código ${nl.idRegistoPlano} não foi encontrada.`);
        this.linhas.push(nl);
      }
    });
    this.ds && this.ds.refresh(this.linhas);
    return Promise.resolve(true);
  }

  //endregion

  //region diversos
  private registerListeners() {
    document.onkeyup = (e) => {
      if (environment.debug)
      console.log("[nova-expedicao]", "onkeyup", e, "buffer", this.buffer);

      //ignora-se o input oriundo de inputs e botões
      if (!["button", "input"].includes(e.srcElement.localName)) {

        const regex = /^[a-z0-9]$/i;
        if (e.key == "Enter") {
          // console.log("[nova-expedicao]", "onkeyup ENTER", e);
          if(environment.debug)
            console.log("[nova-expedicao]", "onkeyup ENTER", this.buffer);
          this.buffer && this.doAction("CONSULTA-REFERENCIA", this.buffer);
          this.buffer = "";
        }

        if (regex.test(e.key))
          this.buffer = this.buffer + e.key;
      } else {
        if (e.key == "Enter") {
          let ae = document.activeElement;
          (ae as any).blur();
          this.buffer = "";
        }
      }
    };
    // this.codBarras.onpaste = (e) => {
    //   if(environment.debug) console.log("[nova-expedicao]","onpaste", e);
    // }
  }

  private lefties: ColConfigInterface[] = [
    {
      colHeaderName : "#", colType: "static", colWidth: 22, colField: "idRegistoPlano", colSort: "field:idRegistoPlano", colFilter: "field:idRegistoPlano; operator:=",
      colRowTemplate: '<button class="btn btn-xs btn-info" click.delegate=\'doAction("REFRESH-LINHAS", [rowRef.idRegistoPlano])\' title="Refrescar a linha ${rowRef.idRegistoPlano}"><i class="fa fa-refresh"></i></button>',
      colCss        : 'text-align:center; line-height: 40px; background: ${rowRef._backgroundColor}',
      colPinLeft    : true,
    },
    {colHeaderName: "Cliente", colType: "text", colWidth: 48, colField: "nvcClienteArmazem", colPinLeft: true, colSort: "field:nvcClienteArmazem", colFilter: "field:nvcClienteArmazem; operator:*", colCss: 'background: ${rowRef._backgroundColor}',},
    {colHeaderName: "Código Produto", colType: "text", colWidth: 100, colField: "nvcArtigo", colPinLeft: true, colSort: "field:nvcArtigo", colFilter: "field:nvcArtigo; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "Designação Produto", colType: "text", colWidth: 300, colField: "nvcDescricaoArtigo", colPinLeft: true, colSort: "field:nvcDescricaoArtigo", colFilter: "field:nvcDescricaoArtigo; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "CDE", colType: "text", colWidth: 80, colField: "nvcCodigoEncomenda", colPinLeft: true, colSort: "field:nvcCodigoEncomenda", colFilter: "field:nvcCodigoEncomenda; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "A Enviar", colType: "text", colWidth: 80, colField: "intDelta", colPinLeft: true, colSort: "field:intDelta", colFilter: "field:intDelta; operator:=", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "Data", colType: "text", colWidth: 100, colField: "dtmMovimento", colPinLeft: true, colSort: "field:dtmMovimento", colFilter: "field:dtmData; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "Gravação", colType: "text", colWidth: 80, colField: "nvcGravacao", colPinLeft: true, colSort: "field:nvcGravacao", colFilter: "field:nvcGravacao; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
  ];

  //colunas coladas à direita
  private righties: ColConfigInterface[] = [
    {colHeaderName: "Gravação", colType: "text", colWidth: 80, colField: "nvcGravacao", colPinRight: true, colSort: "field:nvcGravacao", colFilter: "field:nvcGravacao; operator:*",},
  ];

  //injeta a definição das colunas de acordo com os parâmetros de navegação ao viewModel
  public configuraColunas(): void {
    // this.columns = [...this.lefties, ...this.colunasDinamicas(), ...this.righties];
    this.columns = [...this.lefties, ...this.colunasDinamicas()];
    //if (environment.debug) console.log("[expedicao-doc]", "configuraColunas", this.columns);
  }

  //gerador das colunas (parte central, variável consoante o periodo visualizado)
  private colunasDinamicas(): ColConfigInterface[] {
    //if (environment.debug) console.log("[expedicao-doc]", "colunasDinamicas", this.tipo);
    let slots = range(0, 50);
    return [
      ...slots.map(el => ({
        colWidth         : 64,
        colField         : `RegistoEtiqueta[${el}]`,
        colHeaderTemplate: `<div class="text-center">${el}</div>`,
        colCss           : 'background: ${rowRef._backgroundColor}',
        //colHidden        : el > 10,
        colRowTemplate   :
          `<registo-etiqueta-cell if.bind="rowRef.RegistoEtiqueta[${el}]" etiqueta.bind="rowRef.RegistoEtiqueta[${el}]" class.bind="rowRef.RegistoEtiqueta[${el}].estado" click.delegate="doAction('DETALHES-ETIQUETA', rowRef.RegistoEtiqueta[${el}].idRegistoEtiqueta)"></registo-etiqueta-cell>`
      }))
    ]
  }

  transfere(tipo: string = "pdf", re: RegistoEtiqueta) {
    if (tipo != "xls") tipo = "pdf";
    let payload: any = {idRegistoEtiqueta: re.idRegistoEtiqueta};

    let mapa = "EtiquetaControloA?tipo=" + tipo;
    // route += "&definicao=1";
    return this.app.report(mapa, payload, `Etiqueta-${re.idRegistoEtiqueta}`, true);
  }

  //endregion
  //region acções
  /**
   * doAction Componente
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[nova-expedicao]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          this.linhas = [];
          this.ds.setArray(this.linhas);
          this.ds.orderBy("nvcClienteArmazem");
          this.ds.orderBy("dtmMovimento", true);
          this.ds.orderBy("idRegistoPlano", true);
          this.registerListeners();
          this.doAction("REFRESH-LINHAS");
          break;
        }

        case "REFRESH-LINHAS": {
          this.app.ds.closeAll();
          this.app.smoked = true;
          return this.getLinhas(payload)
            .then(novasLinhas => this.mergeLinhas(novasLinhas))
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.app.smoked = this.isBusy = false);
        }

        case "PERIODO": {
          return this.app.router.navigate("#/expedicao?diaIni=" + this.diaIni, {replace: true});
        }

        //region detalhes de etiquetas
        case "DETALHES-ETIQUETA": {
          let id = +payload;
          if (environment.debug) console.log("[nova-expedicao]", "DETALHES-ETIQUETA", id);
          return this.app.api.getProcessed('api/expedicao/etiqueta', {id})
            .then(r => RegistoEtiqueta.fromPOJSO(r))
            .then(etiqueta => {
              this.isBusy = false;
              return this.app.ds.open({
                viewModel: ComposeDialog,
                model    : {
                  modelo : etiqueta,
                  invoker: this,
                  options: new ComposeDialogOptions({
                    withDefaultFooter: false,
                    trackChanges     : false,
                    okLabel          : "",
                    mainView         : '../routes/expedicao/ce/estado-registo-etiqueta-dialog.html',
                    //postUri          : 'api/controlo/etiqueta'
                  }),
                }
              })
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err));
        }

        case "ABATE-ETIQUETA": {
          let etiqueta = payload as RegistoEtiqueta;
          if (environment.debug) console.log("[expedicao-doc]", "IMPRIME-ETIQUETA", etiqueta);

          this.app.smoked = true;
          return confirmaActionTyped(this, etiqueta, 'api/controlo/abate-etiqueta')
            .then(r => {
              if (r) {
                this.app.ds.closeAll();
                this.app.notificationSuccess("Etiqueta abatida")
              }
            })
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.app.smoked = this.isBusy = false);

          // return this.transfere("pdf", etiqueta)
          //   .then(r => this.app.api.postProcessed("api/controlo/impressao-etiqueta", etiqueta.stateToPOJSO()))
          //   .catch(err => this.app.notificationErrorCompact(err))
          //   .then(_ => this.app.smoked = this.isBusy = false);
        }

        case "CONSULTA-REFERENCIA": {
          let ref: string = payload;
          let regex = /^re/i;
          if (!ref || !regex.test(ref)) {
            return this.isBusy = this.app.notificationErrorCompact("ERRO NA LEITURA DE CÓDIGO DE BARRAS<br> Apenas devem ser lidas etiquetas de controlo / gravação nesta página.");
          }
          //console.log("id da etiqueta", ref, ref.match(numRegEx), ref.replace(/\D/g, ''));
          this.doAction("ADICIONA-REFERENCIA", ref.replace(/\D/g, ''));
          break;
        }

        case "ADICIONA-REFERENCIA": {
          let id = Number.parseInt(payload);
          return this.app.ds.open({viewModel: EntradaEtiquetasDialog, model: {app: this.app, refInicial: id}})
            .whenClosed(r => {
                if (environment.debug) console.log("[nova-expedicao]", "reposta dialogs", r);
                this.isBusy = false;
                this.registerListeners();
                if (!r.wasCancelled) {
                  if (r.output && r.output.linhas && r.output.linhas.length > 0) return this.doAction("REFRESH-LINHAS", r.output.linhas);
                }
              }
            );
        }

        case 'IMPRIME-ETIQUETA': {
          let etiqueta = payload as RegistoEtiqueta;
          if (environment.debug) console.log("[expedicao-doc]", "IMPRIME-ETIQUETA", etiqueta);

          this.app.smoked = true;
          return this.transfere("pdf", etiqueta)
            .then(r => this.app.api.postProcessed("api/controlo/impressao-etiqueta", etiqueta.stateToPOJSO()))
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.app.smoked = this.isBusy = false);
        }

        //reversão para prior a entrada
        case "REVERTE-ENTRADA": {
          return this.doAction("REVERTE", {etiqueta: payload, tipo: "entrada"});
        }

        //reversão para entrada
        case "REVERTE-SAIDA": {
          return this.doAction("REVERTE", {etiqueta: payload, tipo: "saida"});
        }

        case "REVERTE": {
          let etiqueta = payload.etiqueta as RegistoEtiqueta;
          if (environment.debug) console.log("[expedicao-doc]", "REVERTE", etiqueta);

          this.app.smoked = true;
          return confirmaActionTyped(this, etiqueta, 'api/expedicao/reverte?tipo=' + payload.tipo)
            .then(r => {
              this.app.smoked = false;
              if (r !== false) {
                if (etiqueta.idRegistoPlano) this.doAction("REFRESH-LINHAS", [etiqueta.idRegistoPlano]);
              }
              return Promise.resolve(false);
            })
        }

        //endregion detalhes de etiquetas
        default: {
          if (environment.debug) console.log("[nova-expedicao]", "Acção DESCONHECIDA [artigo-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }

  //endregion acções
}
