// import {ColConfigInterface, DataSource, EntityInterface, GridConnector, Selection, SortObjectInterface} from "aurelia-v-grid";
import {GlobalServices} from "../../services/global-services";
import {autoinject} from "aurelia-framework";
import environment from "../../environment";

//import * from "choices.js/assets/styles/css";
@autoinject()
export class Numeric {

  private modelo:{numero:number, inteiro:number};

  constructor(public app: GlobalServices) {
    if(environment.debug) console.log("[numeric]","constructor");
    this.modelo = {inteiro: 0, numero:0};
  }

  canActivate(p){
    if(environment.debug) console.log("[numeric]","canActivate");
    return true;
  }

  attached() {

  }

  log(n) {
  }

  doAction(action, payload){
    console.log(action, payload, this);
  }
}

