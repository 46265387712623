import {autoinject, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';
import {Redirect, Router, RouterConfiguration} from "aurelia-router";
import {mainRoutes} from "./app-routes";
import {ActionInterface, GlobalServices} from "./services/global-services";
import {jsRedirect} from "./utils/ItMultiPurpose";
import {setupValidationRules} from "./validation/custom-rules";
import environment from "./environment";
import {GuiaTransportePlanoExpedicaoVm} from "./models/GuiaTransportePlanoExpedicaoVm";
import {GuiaTransporteLinha} from "./models/GuiaTransporteLinha";
import {ComposeDialog, ComposeDialogOptions} from "./dialogs/compose-dialog";
import {GuiaTransporte} from 'models/GuiaTransporte';
import {doActionGlobal} from "./do-action";

export const versao = "1.0.000";

@autoinject()
export class App {
  // protected authService: AuthService;
  // protected api: Api;
  // protected router: Router;
  protected logger: Logger;
  private app: GlobalServices;
  public versao: string = versao;
  public router: Router;

  public isMobile: boolean = false;

  /**
   * CONSTRUCTOR
   */
  constructor(global: GlobalServices) {
    this.logger = LogManager.getLogger('App');
    this.app    = global;
    setupValidationRules(this.app.api);
    //registar a raiz de tudo
    this.app.aureliaMain = this;

    let versaoConhecida = window.localStorage.getItem("versaoApp");
    if (environment.debug) console.log("[App]", "verificação de versão");
    if (this.versao != versaoConhecida) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.localStorage.setItem("versaoApp", this.versao);
      jsRedirect('/');
    }

    if (window) {
      //todo: arranjar um meio mais eficaz de determinar isto.
      if (window.innerWidth > window.innerHeight) {
        //landscape
        this.isMobile = window.innerWidth < 1280;
      } else {
        //portrait
        this.isMobile = window.innerWidth < 600;
      }
    }

    //region TESTE - para apagar
    let resp = {
      idGuiaTransporteLinha                        : 1,
      idGuiaTransporte                             : 2,
      idRpOrigem                                   : 3,
      "idRpOrigemNavigation.idRpOrigem"            : 4,
      "idGuiaTransporteNavigation.idGuiaTransporte": 5,
    };
    let test = JSON.parse(JSON.stringify(resp));

    let gtl = GuiaTransporteLinha.fromPOJSO(test);
    console.log("teste dotnotation", resp, test, gtl);
    console.log("teste dotnotation", resp, test, gtl);
    console.log("teste dotnotation", resp, test, gtl);
    //region TESTE - para apagar
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    if (environment.debug) console.log("[app]", "configureRouter", config, router);
    let step     = {
      run: (navigationInstruction, next) => {

        if (environment.debug) console.log("[app]", "intercepted navigation", this);
        if (navigationInstruction.getAllInstructions().some(i => i.config.auth)) {
          let isLoggedIn = this.authenticated;
          if (!isLoggedIn) {
            return next.cancel(new Redirect('login'));
          }
        }
        return next();
      }
    };
    config.title = '-';
    config.addPipelineStep('authorize', step);
    config.map(mainRoutes);
    this.router = router;
  }

  get authenticated(): boolean {
    return this.app.auth.authenticated;
  }

  logout() {
    //this.logger.info(`Utilizador ${this.authService.userName} terminou a sessão.`);
    this.app.auth.logout();
    this.router.navigateToRoute("login");
  }

  /**
   * doActionGlobal
   * Quando as acções invocadas não são encontradas na route do componente "transbordam" para esta função.
   *
   * A acção `Q` ou  `QUEUE` processa uma lista de acções descritas pela `ActionInterface`
   *
   * NOTAS:
   * Cuidado com os composes e os respectivos `invokers`, deverão ser na forma: `invoker: context || this,`
   *
   * @param {string} action
   * @param payload
   * @param context
   * @return {Promise<boolean>}
   */
  public doActionGlobal = doActionGlobal.bind(this);
}
