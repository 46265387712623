import {BaseViewModel} from "./BaseViewModel";
import {ValidationRules} from "aurelia-validation";
import {tiposRegisto, tiposRegistoCor, tiposRegistoCorVirtual} from "./TipoRegistoPlano";
import {GlobalServices} from "../services/global-services";
import {ConfirmacaoDialog} from "../dialogs/confirmacao-dialog";
import {VmWrapper} from "./VmWrapper";
import {GuiaTransporteLinha} from "./GuiaTransporteLinha";
import {PlanoExpedicao} from "./PlanoExpedicao";
import {Utilizador} from "./Utilizador";
import environment from "../environment";

/**
 * Created by herna on 5/2/2017.
 * DomainModel para registo de um plano de expedição.
 */
export class RegistoPlano extends BaseViewModel {
  public idRegistoPlano: number           = 0;
  public idPlanoExpedicao: number         = 0;
  public intDelta: number                 = 0;
  public nvcTipo: string                  = "";
  public dtmMovimento: string             = "";
  public nvcObservacoes: string           = "";
  public idPlanoExpedicaoDerivado: number = 0;
  public dtmDatains: string;
  public timestamp: string                = "";
  public _dtmMovimento: Date              = null;

  //relações:
  GuiaTransporteLinhaIdRpOrigemNavigation: GuiaTransporteLinha[] = [];

  // para a navegação inversa da Parcela e Destino força-se uma relação 1:1
  GuiaTransporteLinhaIdRpParcelaNavigation: GuiaTransporteLinha = null;
  GuiaTransporteLinhaIdRpDestinoNavigation: GuiaTransporteLinha = null;

  IdPlanoExpedicaoNavigation: PlanoExpedicao = null;
  IdUtilizadorNavigation: Utilizador = null;

  // campos calculados
  _dependentes: RegistoPlano[]        = [];
  _dependentesParcela: RegistoPlano[] = [];
  //public _indicePlano: number         = -1;

  public setIdPlanoExpedicao(rowRef:any) {
    if(environment.debug) console.log("[registoplano]", "setIdPlanoExpedicao", rowRef);
    this.IdPlanoExpedicaoNavigation = PlanoExpedicao.fromPOJSO(rowRef);
    this.idPlanoExpedicao = this.IdPlanoExpedicaoNavigation.idPlanoExpedicao;
  }

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<RegistoPlano>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): RegistoPlano {
    // console.log("RegistoPlano", "fromPOJSO", obj);
    let model = new RegistoPlano();
    model.setState(obj);
    if (model.dtmMovimento) model._dtmMovimento = new Date(model.dtmMovimento);

    if (obj.guiaTransporteLinhaIdRpOrigemNavigation && Array.isArray(obj.guiaTransporteLinhaIdRpOrigemNavigation))
      model.GuiaTransporteLinhaIdRpOrigemNavigation = GuiaTransporteLinha.multipleFromPOJSO(obj.guiaTransporteLinhaIdRpOrigemNavigation);

    if (obj.guiaTransporteLinhaIdRpParcelaNavigation)
      model.GuiaTransporteLinhaIdRpParcelaNavigation = GuiaTransporteLinha.fromPOJSO(obj.guiaTransporteLinhaIdRpParcelaNavigation);

    if (obj.guiaTransporteLinhaIdRpDestinoNavigation)
      model.GuiaTransporteLinhaIdRpDestinoNavigation = GuiaTransporteLinha.fromPOJSO(obj.guiaTransporteLinhaIdRpDestinoNavigation);

    if (obj.idPlanoExpedicaoNavigation)
      model.IdPlanoExpedicaoNavigation = PlanoExpedicao.fromPOJSO(obj.idPlanoExpedicaoNavigation);
    // if (obj.controloDiario) model.ControloDiario = ControloDiario.multipleFromPOJSO(obj.controloDiario);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): RegistoPlano[] {
    // console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(RegistoPlano.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  /** McDonalds wraps */
  public wrapIt(cl?: number) {
    return new VmWrapper<RegistoPlano>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): RegistoPlano { return RegistoPlano.fromPOJSO(this.stateToPOJSO());}

  /**
   * Gera um representante com a soma das quantidades de todos os registos de um conjunto passado por parâmetro
   * Usa o tipo, idPlanoExpedição e dtmMovimento do primeiro.
   * @param registos
   * @constructor
   */
  public static FactoryRepresentante(registos:RegistoPlano[] = []) {
    if(registos.length == 0) return null;
    let primeiro = registos[0];

    let rp = new RegistoPlano({nvcTipo: primeiro.nvcTipo, idPlanoExpedicao: primeiro.idPlanoExpedicao, dtmMovimento: primeiro.dtmMovimento});
    rp.intDelta = registos.reduce((acc, el) => acc + el.intDelta, 0);

    //Quando exisir apenas um no conjunto, o representante espelha-o
    if(registos.length == 1) {
      rp.idRegistoPlano = primeiro.idRegistoPlano;
      rp.nvcObservacoes = primeiro.nvcObservacoes;
    }
    return rp;
  }

  /**
   *
   * @param app
   * @param vmw
   * @return {Promise<RegistoPlano[]>}
   */
  public static saveWithConfirm(app: GlobalServices, vmw: VmWrapper<RegistoPlano>) {
    return app.api
      .postProcessed("api/plano-expedicao/add-registo-pe", vmw.stateToPOJSO())
      // .then(r => app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return RegistoPlano.saveWithConfirm(app, vmw.nextLevel());
                } else {
                  throw new Error("A ação foi cancelada.");
                }
              });
          }
          if (obj.tipo == "warning") {
            app.notificationWarning(["Erro de sincronização com o Primavera:", ...obj.mensagens]);
            return obj.payload;
          }

          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        //console.log("saveWithConfirm", obj);
        return RegistoPlano.multipleFromPOJSO(obj);
      })
      //.then(RegistoPlano.multipleFromPOJSO)
      ;
  }

//region visualização

  public htmlPill2ColorTotais(usado: number = 0, topColor = "green", tipo = "expedicao") {
    //console.log("htmlPill2ColorTotais", usado, topColor, tipo);
    let hasObs    = (this.nvcObservacoes) ? 'OBS' : '';
    let baseColor = tiposRegistoCor[this.nvcTipo];
    let percent   = 0;
    let total     = this.intDelta || 0;
    if (usado < 0) usado = 0;
    if (usado > total) usado = total;
    if (total > 0) percent = (usado / total) * 100;
    let titulo = `Programação(${total}) com (${usado}) lançadas na linha.`;
    if (!!this.nvcObservacoes) {
      titulo += "&#13;" + this.nvcObservacoes;
    }
    let hasControlo = '';
    // if (this.ControloDiario.length > 0) {
    //   hasControlo = 'CNTRL';
    //   titulo += "&#13;Controlo:&#13; " + this.ControloDiario.reduce((acc, el) => acc + el.representacaoPlano() + "&#13;", "");
    // }
    switch (tipo) {
      case "expedicao":
        return `<div class="movimento pointer ${hasObs} ${hasControlo} PRG" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" style="background-image: linear-gradient(to left, ${baseColor} 0%, ${baseColor} ${100 - percent}%, ${topColor} ${100 - percent}%, ${topColor} 100%);
        background-size: 100% 6px; background-position: 0 0, 100% 0;background-repeat: no-repeat;" title="${titulo}"><input onchange="prgChange(event, ${this.idRegistoPlano})" value="${this.intDelta || 0}" onclick="this.select()"></div>`;
      case "calculo":
        return `<div class="movimento pointer ${hasObs} ${hasControlo} PRG" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" style="background-image: linear-gradient(to left, ${baseColor} 0%, ${baseColor} ${100 - percent}%, ${topColor} ${100 - percent}%, ${topColor} 100%);
        background-size: 100% 6px; background-position: 0 0, 100% 0;background-repeat: no-repeat;" title="${titulo}">${this.intDelta}</div>`;
      default:
        return `<div class="movimento pointer ${hasObs} ${hasControlo} PRG" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" 
  style="background: linear-gradient(to bottom, ${baseColor} 0%, ${baseColor} ${100 - percent}%, ${topColor} ${100 - percent}%, ${topColor} 100%); border-left: solid px ${baseColor}; border-right: solid 4px ${baseColor}" 
  title="${titulo}">${this.intDelta || 0}</div>`;

    }
  }

  public htmlPill2ColorVisualizacao(usado: number = 0, topColor = "green", tipo = "expedicao") {
    let hasObs    = (this.nvcObservacoes) ? 'OBS' : '';
    let baseColor = tiposRegistoCor[this.nvcTipo];
    let percent   = 0;
    let total     = this.intDelta || 0;
    if (usado < 0) usado = 0;
    if (usado > total) usado = total;
    if (total > 0) percent = (usado / total) * 100;
    let titulo = `Programação(${total}) com (${usado}) lançadas na linha.`;
    if (this.nvcObservacoes)
      titulo += "&#13;" + this.nvcObservacoes;
    return `<div class="movimento pointer ${hasObs} ${this.nvcTipo}" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" 
style="background-image: linear-gradient(to left, ${baseColor} 0%, ${baseColor} ${100 - percent}%, ${topColor} ${100 - percent}%, ${topColor} 100%);
  background-size: 100% 6px; background-position: 0 0, 100% 0;background-repeat: no-repeat;" title="${titulo}">${this.intDelta || 0}</div>`;
  }

  public htmlPill2ColorV(percent: number = 0, topColor = "green") {
    let hasObs    = (this.nvcObservacoes) ? 'OBS' : '';
    let baseColor = tiposRegistoCor[this.nvcTipo];
    return `<div class="movimento pointer ${hasObs} ${this.nvcTipo}" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" style="background: linear-gradient(to bottom, ${baseColor} 0%, ${baseColor} ${100 - percent}%, ${topColor} ${100 - percent}%, ${topColor} 100%); border-left: solid 4px ${baseColor}; border-right: solid 4px ${baseColor}" title="Programação com ${percent}% de peças lançadas na linha.">${this.intDelta || 0}</div>`
  }

  public htmlPill2Color(percent: number = 0, topColor = "green") {
    let hasObs    = (this.nvcObservacoes) ? 'OBS' : '';
    let baseColor = tiposRegistoCor[this.nvcTipo];
    return `<div class="movimento pointer ${this.nvcTipo} ${hasObs}" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" 
style="background: ${baseColor}; border-left: solid 4px ${baseColor}; border-top-width: 10px; border-top-style: solid; 
border-image: linear-gradient(to left, ${baseColor} 0%, ${baseColor} ${100 - percent}%, ${topColor} ${100 - percent}%, ${topColor} 100%);
border-top-color: linear-gradient(to left, ${baseColor} 0%, ${baseColor} ${100 - percent}%, ${topColor} ${100 - percent}%, ${topColor} 100%);
border-right: solid 4px ${baseColor}" title="Programação com ${percent}% de peças lançadas na linha.">${this.intDelta || 0}</div>`
  }

  public htmlPill(cssClass: string = "") {
    let hasObs    = (this.nvcObservacoes) ? 'OBS' : '';
    let baseColor = tiposRegistoCor[this.nvcTipo];
    // if (this.nvcTipo === tiposRegisto.LCM)
    //   return `<div class="movimento pointer ${cssClass} ${hasObs} LCM" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" style="background: ${baseColor}" title="${this.nvcObservacoes}">${this.intDelta || 0}</div>`;
    //
    // if (this.nvcTipo === tiposRegisto.FAL) {
    //   let hasControlo = '';
    //   let titulo = this.nvcObservacoes;
    //   if (this.ControloDiario.length > 0) {
    //     hasControlo = 'CNTRL';
    //     titulo += "&#13;Controlo:&#13; " + this.ControloDiario.reduce((acc, el) => acc + el.representacaoPlano() + "&#13;", "");
    //   }
    //
    //   return `<div class="movimento pointer ${cssClass} ${hasObs} ${hasControlo} FAL" style="background: ${baseColor}" draggable="true"  title="${titulo}">${this.intDelta || 0}</div>`;
    // }
    if (this.nvcTipo === tiposRegisto.PRG)
      return `<div class="movimento pointer ${cssClass} ${hasObs} PRG" style="background: ${tiposRegistoCor.PRG}" draggable="true" title="${this.nvcObservacoes}">${this.intDelta || 0}</div>`;
    return `<div class="movimento pointer ${cssClass} ${hasObs} ${this.nvcTipo}" data-id-registo="${this.idRegistoPlano || 0}" style="background: ${baseColor}"  title="${this.nvcObservacoes}">${this.intDelta || 0}</div>`
  }

  /**
   * sobreposição para esbater cores em eventos de linhas "virtuais" ou fantasma
   * @returns {string}
   */
  public htmlPillVirtual() {
    let hasObs    = (this.nvcObservacoes) ? 'OBS' : '';
    let baseColor = tiposRegistoCorVirtual[this.nvcTipo];
    // if (this.nvcTipo === tiposRegisto.LCM)
    //   return `<div class="movimento pointer ${hasObs} LCM" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" style="background: ${baseColor}" title="${this.nvcObservacoes}">${this.intDelta || 0}</div>`;
    // if (this.nvcTipo === tiposRegisto.FAL)
    //   return `<div class="movimento pointer ${hasObs} FAL" style="background: ${baseColor}" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" title="${this.nvcObservacoes}">${this.intDelta || 0}</div>`;
    if (this.nvcTipo === tiposRegisto.PRG)
      return `<div class="movimento pointer ${hasObs} PRG" style="background: ${baseColor}" data-id-registo="${this.idRegistoPlano || 0}" draggable="true" title="${this.nvcObservacoes}"><input onchange="prgChange(event, ${this.idRegistoPlano})" value="${this.intDelta || 0}" onclick="this.select()"></div>`;
    return `<div class="movimento pointer ${hasObs} ${this.nvcTipo}" data-id-registo="${this.idRegistoPlano || 0}" style="background: ${baseColor}"  title="${this.nvcObservacoes}">${this.intDelta || 0}</div>`
  }

  public usedHtmlPill() {
    let baseColor = tiposRegistoCor[this.nvcTipo];
    return `<div class="movimento" data-id-registo="${this.idRegistoPlano || 0}" style="background: lightgrey;">--</div>`
  }

//endregion

  //region dependências (mas não é da droga!)

  public totalInicial() {
    return this.intDelta + this._dependentesParcela.reduce((acc, el) => acc + (el.intDelta || 0), 0);
  }

  //total utilizado nominal (pode ser maior do que o inicial)
  public totalUtilizado() {
    return this._dependentes.reduce((acc, el) => acc + (el.intDelta || 0), 0);
  }

  /**
   * obtém a contagem de dependentes (para colspan)
   * @return {number}
   */
  public totalDependentes() {
    return this._dependentes.length || 1;
  }

  /**
   * obtém o conjunto de dependentes com, pelo menos, um objeto
   * @return RegistoPlano[]
   */
  public conjuntoDependentes() {

    //todo: passar isto para cima, onde o _dependentes é calculado
    /*
    let etiquetasExistentes: RegistoEtiqueta[] = [];
    if (this.ControloDiario.length > 0) {
      etiquetasExistentes = this.ControloDiario[0].registoEtiqueta;
    }
    let etiquetasComSaida: number[] = this._dependentes.map(el => {
      if (el && el.GuiaTransporteLinhaIdRpDestinoNavigation && el.GuiaTransporteLinhaIdRpDestinoNavigation.RegistoEtiqueta.length > 0) {
        return el.GuiaTransporteLinhaIdRpDestinoNavigation.RegistoEtiqueta[0].idRegistoEtiqueta;
      }
      //id inválido, nunca irá fazer match
      return -10000;
    });

    let etiquetasNaoExistentes = etiquetasExistentes.filter(el => !etiquetasComSaida.includes(el.idRegistoEtiqueta));

    console.log("Etiquetas", "Existentes", etiquetasExistentes, "EtiquetasComSaida", etiquetasComSaida, "EtiquetasNaoExistentes", etiquetasNaoExistentes);

    let saidas:RegistoPlano[] = etiquetasNaoExistentes.map(etiq => new RegistoPlano({
      nvcTipo: 'SAI',
      GuiaTransporteLinhaIdRpDestinoNavigation: new GuiaTransporteLinha({RegistoEtiqueta: [etiq]})
    }));

    console.log(saidas);

    if (this._dependentes.length > 0) {
      //nos dependentes faz falta as referências de SAI para etiquetas não anuladas por integrar
      let newVar = [this._dependentes, ...saidas];
      console.log(newVar);
      return newVar;
    } else
      return [new RegistoPlano()];
     */
    if (this._dependentes.length > 0)
      return this._dependentes;
    else
      return [new RegistoPlano()];
  }

  //endregion

  // getGuiaTransporteUri() {
  //   // if(this.GuiaTransporteLinhaIdRpDestinoNavigation.InverseIdGuiaTransporteLinhaOrigemNavigation[0].IdGuiaTransporteNavigation)
  //   if (this.GuiaTransporteLinhaIdRpDestinoNavigation && this.GuiaTransporteLinhaIdRpDestinoNavigation.IdGuiaTransporteNavigation) {
  //     let gt = this.GuiaTransporteLinhaIdRpDestinoNavigation.IdGuiaTransporteNavigation;
  //     return `/#/plano/guia-transporte/${gt.dtmDataDocumento}/${gt.nvcClienteArmazem}?numero=${gt.intNumero}`
  //   }
  //   return "";
  // }

  toString() {
    return `#${this.idRegistoPlano} (${this.nvcTipo}), ${this.intDelta} ${this.dtmMovimento}`
  }
}

ValidationRules
  .ensure((m: RegistoPlano) => m.idPlanoExpedicao).displayName("Associação a encomenda").required().satisfiesRule("validId")
  .ensure((m: RegistoPlano) => m.dtmMovimento).displayName("Data Movimento").required().withMessage(`\${$displayName} é obrigatório`)
  .ensure((m: RegistoPlano) => m.nvcTipo).displayName("Tipo Movimento").maxLength(4).required().withMessage(`\${$displayName} é obrigatório`)
  .ensure((m: RegistoPlano) => m.intDelta).displayName("Quantidade").required().matches(/-?\s*\d{1,8}/).withMessage(`\${$displayName} deve ser um número`)
  //.ensure((m: RegistoPlano) => m.nvcObservacoes).displayName("Observações").required().withMessage(`\${$displayName} é obrigatório`)
  .on(RegistoPlano);
