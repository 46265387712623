import {autoinject} from 'aurelia-framework';

@autoinject()
export class AutoselCustomAttribute {

  constructor(private element: Element) {
    // console.log("AutoselCustomAttribute", this.element);
  }

  attached(){
    // console.log("AutoselCustomAttribute", "attached");
    this.element.addEventListener('click', AutoselCustomAttribute.focusSelect);
    this.element.addEventListener('focus', AutoselCustomAttribute.select);
  }

/*
  bind(){
    // console.log("AutoselCustomAttribute", "bind");
  }

  unbind(){
    // console.log("AutoselCustomAttribute", "unbind");
  }
*/
  detached(){
    // console.log("AutoselCustomAttribute", "detached");
    this.element.removeEventListener('click', AutoselCustomAttribute.focusSelect);
    this.element.removeEventListener('focus', AutoselCustomAttribute.select);
  }

  static focusSelect(e:MouseEvent) {
      let target = (e.target as HTMLInputElement);
      // console.log("focusSelect", target);
      target.focus();
      //target.select();
  }

  static select(e:MouseEvent) {
      let target = (e.target as HTMLInputElement);
      // console.log("focusSelect", target);
      target.select();
  }
}

