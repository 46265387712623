//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import {ComposeDialog, ComposeDialogOptions} from "../../dialogs/compose-dialog";
import {planoComponent} from "../plano-component";
import {EtiquetaAnexoArtigo} from "../../models/EtiquetaAnexoArtigo";
import {Artigo} from "../../models/Artigo";
import {confirmaActionTyped, confirmaDeletionTyped} from "../../services/api-envelopes";

@autoinject()
export class AnexosListagem {
  private isBusy: boolean = false;
  private rg: RemoteGrid;

  constructor(public app: GlobalServices, private parent: planoComponent) { }

  canActivate() {
    if (!this.app.auth.can("App.AnexosVer")) {
      this.app.notificationError("Não dispõe da permissao para visualizar os anexos associados a referências/CDE");
      return false;
    }
    //this.parent.activeSubRoute = "expedicao_listagem";
    //this.app.auth.
    return true
  }

  // canDeactivate() {
  //   this.cleanup();
  //   return true;
  // }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  //region EXPERIMENTAL

  public consultaTemplate(view: ComposeDialog) {
    if(environment.debug) console.log("[anexos-listagem]","EtiquetaAnexoArtigo", view.modelo, "view", view);
    let modelo = view.modelo as EtiquetaAnexoArtigo;
    view.isBusy = true;
    return this.app.api.getProcessed("api/artigo/get", {artigo: modelo.nvcArtigo})
      .then(r => Artigo.fromPOJSO(r))
      .then(art => {
        if(environment.debug) console.log("[anexos-listagem]","Seleção de Artigo", art);
        if (art.nvcTemplateAnexo && art.nvcTemplateAnexo != modelo.nvcDescricaoEtiqueta) {
          return this.app.confirmaPopup(["O artigo contém um modelo para a descrição.", art.nvcTemplateAnexo])
            .then(pr => {
              if(pr) {
                modelo.nvcDescricaoEtiqueta = art.nvcTemplateAnexo;
              }
            })
        }
        //console.log(art);
      })
      .catch(err => this.app.notificationErrorCompact(err))
      .then(_ => view.isBusy = false)
  }

  //endregion EXPERIMENTAL

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[anexos-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          break;
        }
        case "ADICIONA-ANEXO": {
          let etiqueta = new EtiquetaAnexoArtigo({bitActivo: true});
          this.doAction("EDITA-ANEXO", etiqueta.stateToPOJSO());
          break;
        }

        case "APAGA-ANEXO": {
          let etiqueta = EtiquetaAnexoArtigo.fromPOJSO(payload);
          return confirmaDeletionTyped(this, etiqueta, 'api/artigo/anexo')
            .then(_ => this.rg.refreshPage());
        }

        case "DUPLICA-ANEXO": {
          let etiqueta = EtiquetaAnexoArtigo.fromPOJSO(payload);
          etiqueta.idEtiquetaAnexoArtigo = 0;
          this.doAction("EDITA-ANEXO", etiqueta.stateToPOJSO());
          break;
        }

        case 'IMPRIME-ANEXO': {
          let pl = {idRegistoEtiqueta: payload.idEtiquetaAnexoArtigo};
          return this.app.report("EtiquetaAnexoArtigo?tipo=pdf", pl, "Etiqueta", true)
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false);
        }

        case "EDITA-ANEXO": {
          let etiqueta = EtiquetaAnexoArtigo.fromPOJSO(payload);
          this.isBusy = false;
          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : {
                modelo : etiqueta,
                invoker: this,
                options: new ComposeDialogOptions({
                  withDefaultFooter: false,
                  mainView         : '../routes/plano/ce/etiqueta-anexo-artigo-dialog.html',
                  postUri          : 'api/artigo/anexo'
                }),
              }
            })
            .whenClosed(r => {
              //if (environment.debug) console.log("[expedicao-tabela-virtual]", "ADICIONA-ETIQUETA", r);
              if (!r.wasCancelled) {
                this.app.notificationSuccess("Anexo actualizado");
                this.rg.refreshPage(true);
              }
            });
        }

        // case "APAGA-ETIQUETA-ARMAZEM": {
        //   let etiqueta = ArmazemEtiqueta.fromPOJSO(payload);
        //   return this.app.confirmaDeletionTyped(etiqueta.wrapIt(), "api/plano-expedicao/armazem-etiqueta")
        //     .then(r => {
        //       if (this.app.processConfirmation(r)) {
        //         this.app.notificationSuccess("Etiqueta anulada");
        //         this.rg.refreshPage(true);
        //       }
        //     })
        //     .catch(err => this.app.notificationErrorCompact(err));
        // }
        //
        // case 'IMPRIME-ETIQUETA-ARMAZEM': {
        //   let pl = {idArmazemEtiqueta: payload.idArmazemEtiqueta};
        //   return this.app.report("EtiquetaArmazemA?tipo=pdf", pl, "EntradaArmazem" + payload.idArmazemEtiqueta, true)
        //     .catch(err => this.app.notificationErrorCompact(err))
        //     .then(_ => this.isBusy = false);
        // }
        //
        // case 'TRANSFERE-ETIQUETA-ARMAZEM': {
        //   let pl = {idArmazemEtiqueta: payload.idArmazemEtiqueta};
        //   return this.app.report("EtiquetaArmazemA?tipo=pdf", pl, "EntradaArmazem" + payload.idArmazemEtiqueta, false)
        //     .catch(err => this.app.notificationErrorCompact(err))
        //     .then(_ => this.isBusy = false);
        // }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[anexos-listagem]", "RG_CELL_CHANGE", payload);
          let anexo = EtiquetaAnexoArtigo.fromPOJSO(payload.rowRef);
          return confirmaActionTyped(this, anexo, "api/artigo/toggle-anexo")
            .then(_ => this.rg.refreshPage());
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[anexos-listagem]", "RG_ROW_CLICK", payload);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // let registoEtiqueta = ArmazemEtiqueta.fromPOJSO(selecaoPriArtigo);

          // let artigo           = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          //this.doAction("ETIQUETA", registoEtiqueta);
          this.isBusy = false;
          break;
        }
        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[anexos-listagem]", "Acção DESCONHECIDA [anexos-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
