import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

import {GlobalServices} from "../services/global-services";
import {Utilizador} from "../models/Utilizador";
import environment from "../environment";

@autoinject()
export class UtilizadorDialog {
  //static inject = [DialogController, ValidationControllerFactory];
  protected validationController: ValidationController;
  protected controller: DialogController;
            app: GlobalServices;

  protected utilizador: Utilizador;
  protected isBusy:boolean = false;

  // // predicado da validade do formulário.
  // public get isValid(): boolean {
  //   return this.validationController.errors.length == 0;
  // }

  constructor(controller: DialogController, app: GlobalServices, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    this.app                  = app;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    //console.log("lodash test", _s.camelCase("uma-string-que-vai passar a camel case"));
  }

  canActivate(payload: { id: number }) {
    if(environment.debug) console.log("[utilizador-dialog]", "UtilizadorDialog", payload);
    let id = +payload.id;
    if (id <= 0) {
      this.utilizador = new Utilizador();
      return true;
    } else {
      this.isBusy = true;
      return this.app.api.get("api/auth/detalhes-utilizador", {idUtilizador: id})
        .then(r => this.app.api.processResponse(r))
        .then(o => Utilizador.fromPOJSO(o))
        .then(ut => {this.utilizador = ut; this.isBusy = false;})
        .then(ut => true)
        .catch(e => this.app.notificationErrorCompact(e))
    }
  }

  attached() {}

  cleanup() {
  }

  gravaUtilizador() {
    return this.app.confirmaActionTyped(this.utilizador.wrapIt(), "api/auth/cria-utilizador")
      .then(r =>  {
        if(this.app.processConfirmation(r)) {
          return true;
        }
        return false;
      })
      .catch(e => this.app.notificationErrorCompact(e));
    // return this.app.api.post("api/auth/cria-utilizador", this.utilizador.stateToPOJSO())
    //   .then(r => this.app.api.processResponse(r))
    //   .then(r => {
    //     console.log("resposta gravaUtilizador", r);
    //     this.app.notificationSuccess("O utilizador foi gravado na base de dados.");
    //     this.cleanup();
    //     return true;
    //   })
  }

  submit() {
    // console.log("Submit", planoExpedicao, this.planoExpedicao);
    // let o = this.planoExpedicao.dto();
    // console.log(o);
    this.validationController.validate()
      .then(r => {
        if (r.valid) {
          this.gravaUtilizador().then(_ =>{
            this.controller.ok({action: "REFRESH", payload: {}});
          });
        } else {
          this.app.notificationErrorCompact("Verifique os erros.")
        }
      })
      .catch(e => this.app.notificationErrorCompact(e));
    // this.validationController.validate()
    //   .then(r => {
    //     // console.log("Validation Results", r);
    //     if (r.valid) {
    //       this.app.api
    //       .post("api/plano-expedicao/add-linha", this.planoExpedicao.stateToPOJSO())
    //       .then(r => {
    //         // console.log("Post response", r);
    //         if (r.status == 400) {
    //           r.json()
    //             .then(r => {
    //               //analisar a resposta
    //               for (let prop in r) {
    //                 if (r.hasOwnProperty(prop)) {
    //                   let errors = r[prop].reduce((acc, el) => acc + el, "");
    //                   r[prop].forEach(el => this.validationController.addError(el, this.planoExpedicao, _s.camelCase(prop)));
    //                   this.app.notificationError(r[prop]);
    //                 }
    //               }
    //               //throw "erro";
    //             });
    //         }
    //         if (r.status >= 200 && r.status <= 299) {
    //           //a resposta
    //           return r.json();
    //         }
    //         // se a execução chegou aqui aconteceu um erro
    //         console.log("err");
    //         throw r;
    //       })
    //       .then(suc => {
    //         // console.log("success", suc);
    //         this.cleanup();
    //         return this.controller.ok({action: "ATUALIZA-LINHA", payload: {model: suc, rowIndex:this.rowIndex, cellIndex:this.cellIndex}});
    //         //this.controller.close(true, suc);
    //       })
    //       .catch(err => {console.error(err)});
    //   }
    //   })
  }
}
