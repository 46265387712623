import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

import {GlobalServices} from "../services/global-services";
import {ClienteDestinatario} from "../models/ClienteDestinatario";
import {EmailGuiaTransporteVm} from "../models/EmailGuiaTransporteVm";

@autoinject()
export class EmailGuiaTransporte {
  protected validationController: ValidationController;
  protected controller: DialogController;
            app: GlobalServices;

  protected modelo: EmailGuiaTransporteVm;
  protected isBusy: boolean = false;

  constructor(controller: DialogController, app: GlobalServices, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    this.app                  = app;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  /**
   * Aqui dá muito mais jeito a transmissão de uma instância ao invés do simples id.
   * @param payload
   */
  activate(payload: EmailGuiaTransporteVm) {
    console.log("EmailGuiaTransporte", payload);
    this.modelo = payload;
  }

  attached() {}

  cleanup() {
  }

  // gravaMorada() {
  //   return this.app.api.post("api/cliente/save-morada", this.modelo.stateToPOJSO())
  //     .then(r => this.app.api.processResponse(r))
  //     .then(r => {
  //       console.log("resposta gravaMorada", r);
  //       this.app.notificationSuccess("O utilizador foi gravado na base de dados.");
  //       this.cleanup();
  //       return true;
  //     });
  //     // .catch(e => {this.app.notificationErrorCompact(e); throw e;});
  // }
  //

  submit() {
    this.validationController.validate()
      .then(r => {
        if (r.valid) {
          console.log("model is valid!");
          // this.gravaMorada().then(_ => {
          this.controller.ok({action: "ENVIA-EMAIL", payload: this.modelo});
          // })
          //   .catch(e => this.app.notificationErrorCompact(e));
        } else {
          this.app.notificationErrorCompact("Verifique os erros assinalados no formulário.")
        }
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }
}
