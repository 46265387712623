import {autoinject} from "aurelia-framework";
import {DialogController, DialogService} from "aurelia-dialog";
import {ValidationController} from "aurelia-validation";
import {AuthService} from "../services/auth-service";
import {GlobalServices} from "../services/global-services";
import environment from '../environment';

@autoinject()
export class PickerArtigo {
  protected controller: DialogController;
  private app: GlobalServices;

  constructor(gs: GlobalServices, controller: DialogController) {
    this.app        = gs;
    this.controller = controller;
    // this.as         = auth;
  }

  // activate(planoExpedicao) {
  //   //obter a lista de clientes.
  //   console.log("activate!");
  // }

  attached() {
    //console.log("activate!");
    this.app.auth.promisedActiveToken()
      .then(token => {
        let g = $("#Grid").ejGrid({
          locale          : "pt-PT",
          dataSource    : ej.DataManager({
            //done: injetar a localização do serviço
            url        : environment.endpoint + "api/ejg/Artigo",
            crossDomain: true,
            //requestType : "post",
            adaptor    : new ej.WebMethodAdaptor(),
            headers    : [{Authorization: `Bearer ${token}`}]
          }),
          allowPaging   : true,
          pageSettings  : {pageSize: 10},
          allowSorting  : true,
          allowSearching: true,
          allowFiltering: true,
          sortSettings    : {sortedColumns: [{field: "nvc_artigo", direction: "ascending"}]},
          recordClick: (args) => {
            console.log("Click", args);
            $("#Grid").ejGrid("destroy");
            this.controller.ok(args.data);
          },
          columns    : [
            //@formatter:off
            //{field: "bit_ativo"      , headerText:"Ativo?"         , width:20}  ,
            {field: "nvc_artigo"      , headerText:"Referência"    , width:80}  ,
            {field: "nvc_descricao"  , headerText:"Descrição"      , width:200} ,
            {field: "nvc_codigo_pri" , headerText:"Cód. Primavera" , width:80}
            //@formatter:on
          ]
        });
      });
  }
}
