import {computedFrom} from "aurelia-framework";
import {BaseViewModel} from "./BaseViewModel";
import {FolhaConferencia} from "./FolhaConferencia";
import {VmWrapper} from "./VmWrapper";
import {PlanoExpedicao} from "./PlanoExpedicao";
import {FolhaExpedicao} from "./FolhaExpedicao";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class FolhaConferenciaLinha extends BaseViewModel {
  public idFolhaConferenciaLinha: number   = 0;
  public idFolhaConferencia: number        = 0;
  public idPlanoExpedicao: number          = 0;
  public intQuantidadeEnviar: number       = 0;
  public intQuantidadeExpedida: number     = 0;
  public intQuantidadeDespachada: number   = 0;
  public intControlo: number               = 0;
  public intOrdem: number                  = 0;
  public nvcPal: string                    = "";
  public nvcRal: string                    = "";
  public nvcQuantidadesControladas: string = "";
  public nvcObservacoes: string            = "";
  public timestamp: string = "";

  //extra
  public _controloConferencia: number = 0;

  //relações
  public IdFolhaConferenciaNavigation: FolhaConferencia = null;
  public IdPlanoExpedicaoNavigation: PlanoExpedicao     = null;

  @computedFrom("intQuantidadeDespachada", "intQuantidadeExpedida")
  public get controloConferencia(){
    return +this.intQuantidadeDespachada - +this.intQuantidadeExpedida;
  }

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<FolhaConferenciaLinha>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region calculated fields
  // public getControlo(){
  //   return ((+this.intQuantidadeExpedida > +this.intQuantidadeDespachada)? +this.intQuantidadeExpedida:+this.intQuantidadeDespachada) - +this.IntQuantidadeEnviar;
  // }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): FolhaConferenciaLinha {
    let model = new FolhaConferenciaLinha();
    model.setState(obj);
    if (obj.idPlanoExpedicaoNavigation) {
      model.IdPlanoExpedicaoNavigation = PlanoExpedicao.fromPOJSO(obj.idPlanoExpedicaoNavigation);
    }
    // model.IntControlo = ((+model.intQuantidadeExpedida > +model.intQuantidadeDespachada)? +model.intQuantidadeExpedida:+model.intQuantidadeDespachada) - +model.IntQuantidadeEnviar
    model._controloConferencia = (+model.intQuantidadeDespachada - +model.intQuantidadeExpedida);
    //assume o menor
    //if(model.IntControlo < model._controloConferencia) model._controloConferencia = model.IntControlo + model.intQuantidadeDespachada;
    model.__index = index;
    return model;
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    // if (this.RegistoPlanoIdPlanoExpedicaoNavigation && Array.isArray(this.RegistoPlanoIdPlanoExpedicaoNavigation) && this.RegistoPlanoIdPlanoExpedicaoNavigation.length > 0) {
    //   ret.RegistoPlanoIdPlanoExpedicaoNavigation = this.RegistoPlanoIdPlanoExpedicaoNavigation.map(e => e.stateToPOJSO());
    // }
    return ret;
  }

  public static multipleFromPOJSO(objs: any[]): FolhaConferenciaLinha[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(FolhaConferenciaLinha.fromPOJSO);
    return [];
  }

  public cloneInstance(): FolhaExpedicao {
    return FolhaExpedicao.fromPOJSO(this.stateToPOJSO());
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<FolhaConferenciaLinha>({payload: this, confirmLevel: (+cl || 0)});
  }
  //endregion

  public recalcula() {
    this._controloConferencia = +this.intQuantidadeDespachada - +this.intQuantidadeExpedida;
  }
}
