import {BaseViewModel} from "./BaseViewModel";
/**
 * Created by herna on 5/2/2017.
 * DomainModel (virtual) que descreve o tipo de um registo do plano de expedição.
 */
export class TipoRegistoPlano extends BaseViewModel {
  public nvcTipo: string      = "";
  public nvcDescricao: string = "";

  //relações:

  public static fromPOJSO(obj: any): TipoRegistoPlano {
    let model = new TipoRegistoPlano();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): TipoRegistoPlano[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(TipoRegistoPlano.fromPOJSO);
    return [];
  }

  //todo: stateToPOJSO
  //todo: check ao timestamp

  /**
   * mapa com os tipos admitidos pela aplicação.
   * edit e-t: adicionou-se a propriedade bitManual para ajudar a determinar se o valor pode ser usado na criação manual dos registos.
   */
  public static tipos() {
    return [
      {nvcTipo: tiposRegisto.PRG  , nvcDescricao: 'Agendamento Para Entrega'        , bitManual: true}  ,
      {nvcTipo: tiposRegisto.PROD , nvcDescricao: 'Registo de produção'             , bitManual: true}  ,
      {nvcTipo: tiposRegisto.CNF  , nvcDescricao: 'Confirmação de produção cliente' , bitManual: false} ,
      {nvcTipo: tiposRegisto.SAI  , nvcDescricao: 'Peças Entregues'                 , bitManual: false} ,
      {nvcTipo: tiposRegisto.QTY  , nvcDescricao: 'Transferência'                   , bitManual: false} ,
    ]
  }
}

export const tiposRegisto = {
  'QTY': 'QTY',
  'PRG': 'PRG',
  'SAI': 'SAI',
  'PROD': 'PROD',
  'CNF': 'CNF',
  // 'LCM': 'LCM',
  // 'REM': 'REM',
};

export const tiposRegistoCor = {
  'QTY': '#f0f0f0',
  'PRG': '#a01414',
  'SAI': '#4786d0',
  // 'SAI': '#d2dcfa',
  'PROD': '#ffc727',
  'CNF': '#a6cc9b',
  // 'EXT': '#cc3d5f',
  // 'REM': '#0073be', //todo: rever esta cor
};

export const tiposRegistoCorVirtual = {
  'QTY': '#f0f0f0',
  'PRG': '#eb737b',
  'SAI': '#4786d0',
  // 'SAI': '#d2dcfa',
  'FAL': '#ffec9e',
};

export const tiposRegistoCorProgress = {
  'QTY': '#f0f0f0',
  'PRG': '#a01414',
  'SAI': '#4786d0',
  'PROD': '#ffd764',
  'EXT': '#cc3d5f',
  'CNF': '#258019',
  // 'REM': '#0073be', //todo: rever esta cor
};
