
// Auto-gerado com typewriter


import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {Utilizador} from './Utilizador';
import {ClienteArmazem} from './ClienteArmazem';
export class UtilizadorClienteArmazem extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public nvcClienteArmazem : string = null;
    public idUtilizador : number = 0;
    public bitRead : boolean = false;
    public bitWrite : boolean = false;
    public bitDefeito : boolean = false;
    public idUtilizadorI : number = 0;
    public idUtilizadorA : number = 0;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public dtmDataA:string = null;
    public _dtmDataA:Date = new Date();
    public timestamp : number[] = [];
    public idUtilizadorNavigation : Utilizador = null;
    public idUtilizadorANavigation : Utilizador = null;
    public idUtilizadorINavigation : Utilizador = null;
    public nvcClienteArmazemNavigation : ClienteArmazem = null;
    
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<UtilizadorClienteArmazem>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): UtilizadorClienteArmazem {
        let model = new UtilizadorClienteArmazem();
        model.setState(obj);

        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): UtilizadorClienteArmazem[] {
        if (objs && Array.isArray(objs)) return objs.map(UtilizadorClienteArmazem.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<UtilizadorClienteArmazem>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): UtilizadorClienteArmazem { return UtilizadorClienteArmazem.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `UtilizadorClienteArmazem`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: UtilizadorClienteArmazem) => m.nvcClienteArmazem).displayName("nvcClienteArmazem").required()
    .ensure((m: UtilizadorClienteArmazem) => m.idUtilizador).displayName("idUtilizador").required()
    .ensure((m: UtilizadorClienteArmazem) => m.bitRead).displayName("bitRead").required()
    .ensure((m: UtilizadorClienteArmazem) => m.bitWrite).displayName("bitWrite").required()
    .ensure((m: UtilizadorClienteArmazem) => m.bitDefeito).displayName("bitDefeito").required()
    .ensure((m: UtilizadorClienteArmazem) => m.idUtilizadorI).displayName("idUtilizadorI").required()
    .ensure((m: UtilizadorClienteArmazem) => m.idUtilizadorA).displayName("idUtilizadorA").required()
    .ensure((m: UtilizadorClienteArmazem) => m.dtmDataI).displayName("dtmDataI").required()
    .ensure((m: UtilizadorClienteArmazem) => m.dtmDataA).displayName("dtmDataA").required()
    .ensure((m: UtilizadorClienteArmazem) => m.timestamp).displayName("timestamp").required()
    .ensure((m: UtilizadorClienteArmazem) => m.idUtilizadorNavigation).displayName("idUtilizadorNavigation").required()
    .ensure((m: UtilizadorClienteArmazem) => m.idUtilizadorANavigation).displayName("idUtilizadorANavigation").required()
    .ensure((m: UtilizadorClienteArmazem) => m.idUtilizadorINavigation).displayName("idUtilizadorINavigation").required()
    .ensure((m: UtilizadorClienteArmazem) => m.nvcClienteArmazemNavigation).displayName("nvcClienteArmazemNavigation").required()
  .on(UtilizadorClienteArmazem);

