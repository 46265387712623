import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";
import {DateFilterTemplateGreaterEqual, identityNumericFilter, IdentityStringFilter} from "../utils/EjHelper";

@autoinject()
export class PickerProximoPrg {
  protected controller: DialogController;
  private app: GlobalServices;
  private initObj: PickerProximoPrgPayload;
  private filteredColumns: any[];
  private dataSource: any;

  constructor(gs: GlobalServices, controller: DialogController) {
    this.app        = gs;
    this.controller = controller;
    // this.as         = auth;
  }

  activate(payload: PickerProximoPrgPayload) {
    // console.log("activate!");
    this.initObj = payload;
  }

  attached() {
    //console.log("activate!");
    this.app.auth.promisedActiveToken()
      .then(token => {
        this.dataSource = ej.DataManager({
          //done: injetar a localização do serviço
          url        : environment.endpoint + "api/ejg/ProximosPrg",
          crossDomain: true,
          //requestType : "post",
          adaptor    : new ej.WebMethodAdaptor(),
          headers    : [{Authorization: `Bearer ${token}`}]
        });

        this.filteredColumns = [];
        if (this.initObj.nvc_cliente_armazem && this.initObj.nvc_cliente_armazem != 'RAOUL') { this.filteredColumns.push({field: "nvc_cliente_armazem", operator: "equal", value: this.initObj.nvc_cliente_armazem, matchcase: false})}
        if (this.initObj.nvc_artigo_terminacao) { this.filteredColumns.push({field: "nvc_artigo_terminacao", operator: "equal", value: this.initObj.nvc_artigo_terminacao, matchcase: false})}
        if (this.initObj.disponiveis) { this.filteredColumns.push({field: "int_qty_a_expedir", operator: "greaterthan", value: +this.initObj.disponiveis, matchcase: false})}
        if (this.initObj.int_delta === 0) { this.filteredColumns.push({field: "int_delta", operator: "greaterthan", value: +this.initObj.disponiveis, matchcase: false})}
        this.filteredColumns.push({field: "nvc_estado", operator: "equal", value: "aberto", matchcase: false});

        this.initEjGrid();
      });
  }

  canDeactivate() {
    this.cleanup();
  }

  cleanup() {
    let $Grid = $("#Grid");
    if ($Grid.data("ejGrid")) {
      $Grid.ejGrid("destroy");
    }
  }

  initEjGrid() {
    //@formatter:off
    let columns = [
    {field: "id_registo_plano"       , headerText: "Cód Registo"   , headerTooltip:"Identificador do registo do plano", width:20 , type:"number"  , filterBarTemplate: identityNumericFilter, textAlign: "right"  }    ,
    {field: "int_delta"              , headerText: "Quantidade"    , headerTooltip:"Quantidade"                       , width:40 , type:"number"  , filterBarTemplate: identityNumericFilter, textAlign: "right"  }    ,
    {field: "dtm_movimento"          , headerText: "Data Movimento", headerTooltip:"Data do Movimento"                , width:60 , type: "date"   , textAlign: "center"                     , format: "{0:yyyy-MM-dd}" , filterBarTemplate: DateFilterTemplateGreaterEqual},
    {field: "nvc_artigo_terminacao"  , headerText:"Referência"     , headerTooltip:"Referência do artigo"             , width:80 , type: 'string' , filterBarTemplate: IdentityStringFilter},
    {field: "nvc_descricao_artigo"   , headerText:"Descrição"      , headerTooltip:"Descrição do artigo"              , width:200, type: 'string' , filterBarTemplate: IdentityStringFilter},
    {field: "nvc_tipo_gravacao"      , headerText:"Gravação"       , headerTooltip:"Gravação associada"               , width:40 , type: 'string' , filterBarTemplate: IdentityStringFilter},
    // {field: "nvc_acabamento"      , headerText:"Acabamento"     , headerTooltip:"Acabamento associado"             , width:40 , type: 'string'},
    {field: "int_qty_a_expedir"      , headerText:"Encomendadas"   , headerTooltip:"Unidades Encomendadas por Expedir", width:40 , type: 'number' , filterBarTemplate: identityNumericFilter, textAlign: 'right'       , cssClass: "success"}                              ,
    {field: "total_prg"              , headerText:"Programadas"    , headerTooltip:"Unidades Programadas"             , width:30 , type: 'number' , filterBarTemplate: identityNumericFilter, textAlign: 'right'}      ,
    {field: "total_lcm"              , headerText:"Lançadas"       , headerTooltip:"Unidades Lançadas"                , width:30 , type: 'number' , filterBarTemplate: identityNumericFilter, textAlign: 'right'}      ,
    {field: "total_sai"              , headerText:"Saída"          , headerTooltip:"Unidades Expedidas"               , width:30 , type: 'number' , filterBarTemplate: identityNumericFilter, textAlign: 'right'}      ,
    {field: "int_qty_total_encomenda", headerText:"Encomenda"      , headerTooltip:"Unidades Encomendadas"            , width:40 , type: 'number' , filterBarTemplate: identityNumericFilter, textAlign: 'right'       , cssClass: "success"}                              ,
    {field: "nvc_codigo_encomenda"   , headerText:"CDE"            , headerTooltip:"Código de Encomenda"              , width:60 , type: 'string' , filterBarTemplate: IdentityStringFilter},
    // {field: "dtm_pedido"          , headerText:"Data Pedido"    , headerTooltip:"Data Pedido"                      , width:60 , type: 'date'   , textAlign: "center"                     , format: "{0:yyyy-MM-dd}" , filterBarTemplate: DateFilterTemplateGreaterEqual},
    {field: "stock_entrada"          , headerText:"ENT"            , headerTooltip:"Stock Armazém de entrada"         , width:30 , type: 'number' , filterBarTemplate: identityNumericFilter, cssClass: "warning"}     ,
    {field: "stock_producao"         , headerText:"PROD"           , headerTooltip:"Stock Armazém de Saída"           , width:30 , type: 'number' , filterBarTemplate: identityNumericFilter, cssClass: "warning"}     ,
    {field: "nvc_estado"             , headerText:"Estado"         , headerTooltip:"Estado da Linha do Plano"         , width:30 , type: 'string' , filterBarTemplate: identityNumericFilter, visible: false}          ,
    {field: "nvc_cliente_armazem"    , headerText: "Plano"         , headerTooltip:"Plano de origem das programações" , width:80 , type: "string" , filterBarTemplate: IdentityStringFilter , visible: (this.initObj.nvc_cliente_armazem === 'RAOUL') || environment.debug },
    ];
    //@formatter:on

    $("#Grid").ejGrid({
      locale          : "pt-PT",
      dataSource      : this.dataSource,
      // actionBegin: function (args) {
      //   console.log("actionBegin", args, this, $(this));
      //   // this.model.query.addParams('$filter', 'id_registo_plano eq 1');
      //   // this.model.filterColumn('id_registo_plano', 'equal', '1', false)
      // },
      allowPaging     : true,
      pageSettings    : {pageSize: 15},
      allowSorting    : true,
      allowSearching  : true,
      allowFiltering  : true,
      cssClass        : "grid-picker",
      filterSettings  : {
        filteredColumns    : this.filteredColumns,
        //showFilterBarStatus: false,
        filterType         : "filterbar"
        // filterType         : "menu"
      },
      sortSettings    : {sortedColumns: [{field: "dtm_movimento", direction: "ascending"}]},
      recordClick     : (args) => {
        //console.log("Click", args);
        // this.cleanup();
        // ATENÇÃO: a acção aqui definida pode ser sobreposta na callback de tratamento da seleção
        this.controller.ok({action: "PICK-LINHA", registo: args.data});
      },
      columns       : columns
    });

  }

  resetSearch() {
    $("#Grid").ejGrid("destroy");
    this.initEjGrid();
    // let instance = $("#Grid").ejGrid("instance");
    // instance.clearFiltering("id_registo_plano");
    // instance.clearFiltering("int_delta");
    // instance.clearFiltering("dtm_movimento");
    // instance.clearFiltering("nvc_artigo_terminacao");
    // instance.clearFiltering("nvc_descricao_artigo");
    // instance.clearFiltering("nvc_tipo_gravacao");
    // // instance.clearFiltering("nvc_acabamento"         );
    // instance.clearFiltering("int_qty_a_expedir");
    // instance.clearFiltering("total_prg");
    // instance.clearFiltering("total_lcm");
    // instance.clearFiltering("total_sai");
    // instance.clearFiltering("int_qty_total_encomenda");
    // instance.clearFiltering("nvc_codigo_encomenda");
    // // instance.clearFiltering("dtm_pedido");
    // instance.clearFiltering("stock_entrada");
    // instance.clearFiltering("stock_producao");
    // // if (this.initObj.nvc_cliente_armazem === 'RAOUL')
    // //   instance.clearFiltering("nvc_cliente_armazem");
  }

  todosPlanos() {
    let instance = $("#Grid").ejGrid("instance");
    if (this.initObj.nvc_cliente_armazem === 'RAOUL')
      instance.clearFiltering("nvc_cliente_armazem");
  }
}

export interface PickerProximoPrgPayload {
  nvc_cliente_armazem: string;
  int_delta: number;
  nvc_artigo_terminacao?: string;
  disponiveis?: string;
}

