import {LogManager, bindable, bindingMode, inject, autoinject} from "aurelia-framework";
import {Logger} from "aurelia-logging";
import {AuthService} from "../services/auth-service";

// @inject(Logger)
@autoinject()
export class Navigation {
  @bindable({defaultBindingMode: bindingMode.twoWay}) router;
  @bindable logoutCallback;
  protected logger: Logger;
  protected authService: AuthService;

  protected isSuper:boolean = false;

  constructor(authService : AuthService) {
    this.logger = LogManager.getLogger("Navigation");
    this.authService = authService;
    this.isSuper = this.authService.can("Controlo.ExpedicaoSemLimite");
  }
}
