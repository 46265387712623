//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy, Router} from 'aurelia-router';
//App Imports
import {GlobalServices} from "../../services/global-services";
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {GuiaTransporte} from "../../models/GuiaTransporte";
import {ConfirmacaoDialog} from "../../dialogs/confirmacao-dialog";
import {GuiaTransporteLinha} from "../../models/GuiaTransporteLinha";
import {PickerProximoPrg, PickerProximoPrgPayload} from "../../dialogs/picker-proximo-prg";
import {VmWrapper} from "../../models/VmWrapper";
import {PickerData} from "../../dialogs/picker-data";
import {dateISOString} from "../../utils/ItNumeric";
import {PickerMorada} from "../../dialogs/picker-morada";
import {PickerLinhaEncomenda, PickerLinhaEncomendaPayload} from "../../dialogs/picker-linha-encomenda";
import {PickerClienteVirtual} from "../../dialogs/picker-cliente-virtual";
import {TipoGuiaTransporte} from "../../models/TipoGuiaTransporte";
import {tiposGravacao} from "../../utils/ItMultiPurpose";
import environment from "../../environment";
import {PickerRemoteGrid} from "../../it-features/remote-grid/picker-remote-grid";
import {RegistoEtiqueta} from "../../models/RegistoEtiqueta";
import {confirmaActionTyped} from "../../services/api-envelopes";
import {ConfirmacaoPromptDialog} from "../../dialogs/confirmacao-prompt-dialog";

@autoinject()
export class GuiaTransporteRoute {
  buffer: string = "";
  tipos: { nvcTipo: string; nvcDescricao: string; }[];
  private app: GlobalServices;

  //parametros GET
  private dia: string;
  private id: number = 0;
  private debug: string;
  // private logger: Logger;
  private clienteArmazem: ClienteArmazem;
  private _detalhesVisiveis: boolean          = true;
  private guiaEmEdicao: GuiaTransporte        = null;
  private _indexEmEdicao: number              = -1;
  private _numeroEmEdicao: number             = -1;
  private _linhaEmEdicao: GuiaTransporteLinha = null;
  private _existeGuiaNova: boolean            = false;
  private confirmaSaida: boolean              = false;
  private isBusy: boolean                     = true;

  //region getters & setters
  @computedFrom("_indexEmEdicao")
  get indexEmEdicao(): number {
    return this._indexEmEdicao;
  }

  @computedFrom("_existeGuiaNova")
  get existeGuiaNova(): boolean {
    return this._existeGuiaNova;
  }

  @computedFrom("_detalhesVisiveis")
  get detalhesVisiveis(): boolean {
    return this._detalhesVisiveis;
  }

  set detalhesVisiveis(value: boolean) {
    this._detalhesVisiveis = value;
    localStorage.setItem("gt-detalhesVisiveis", JSON.stringify(value));
  }
  //endregion

  constructor(g: GlobalServices, protected router: Router) {
    this.app   = g;
    this.tipos = TipoGuiaTransporte.tipos();
  }

  //region Aurelia lifecycle
  canActivate(p, rc) {
    if (environment.debug) console.log("[guia-transporte]", "canActivate", p, rc);
    this.dia            = p.dia;
    this.id             = p.id || 0;

    this.debug          = p.debug;
    this._indexEmEdicao = p.linha ? +p.linha : -1;
    if (p.numero)
      this._numeroEmEdicao = p.numero;
    if (localStorage.hasOwnProperty("gt-detalhesVisiveis")) {
      this._detalhesVisiveis = JSON.parse(localStorage.getItem("gt-detalhesVisiveis"));
    }
    return true;
  }

  determineActivationStrategy() {
    if (environment.debug) console.log("[guia-transporte]", "determineActivationStrategy");
    return activationStrategy.replace;
  }

  cleanup() {
    //chamam-se os destrutores dos controlos criados no popup antes de invocar o seu fecho.
    $(".js-observacoes").ejAutocomplete('destroy');
  }

  canDeactivate(param) {
    if (environment.debug) console.log("[guia-transporte]", "canDeactivate");
    this.cleanup();
    return true;
  }

  initJqueryControls() {
    $('.js-gravacao').ejAutocomplete({
      locale         : "pt-PT",
      width          : "100%",
      watermarkText  : "Gravação - A, O, P, S",
      emptyResultText: "Outros",
      showPopupButton: true,
      animateType    : "none",
      dataSource     : tiposGravacao,
      focusIn        : function (argument: any) {
        console.log(argument);
        $(argument.event.target).ejAutocomplete("open");
      },
      change         : (v) => {
        console.log("change js-gravacao", v);
        if (v && v.event && v.event.originalEvent && v.event.originalEvent.type == "blur") {
          let idsLinha = [];
          try {
            idsLinha = v.event.target.closest("tr").dataset.id.split(",");

            if (Array.isArray(idsLinha) && idsLinha.length == 1) {
              let linhaBase     = idsLinha[0];
              let linha         = this.guiaEmEdicao.GuiaTransporteLinha.find(el => el.idGuiaTransporteLinha == linhaBase);
              linha.nvcGravacao = v.value;
              if (linha) this.updateDetalhesLinha(linha)
            }
            if (Array.isArray(idsLinha) && idsLinha.length == 2) {
              let linhaBase       = idsLinha[0];
              let linhaDependente = idsLinha[1];
              let linha           = this.guiaEmEdicao.GuiaTransporteLinha[this.guiaEmEdicao.GuiaTransporteLinha.findIndex(el => el.idGuiaTransporteLinha == linhaBase)].InverseIdGuiaTransporteLinhaOrigemNavigation.find(el => el.idGuiaTransporteLinha == linhaDependente);
              linha.nvcGravacao   = v.value;
              if (linha) this.updateDetalhesLinha(linha)
            }
          } catch (err) {
            this.app.notificationErrorCompact(err);
            console.log("erro", "decididamente um erro [wrtyuio]", err);
          }
        }
      }
    });
    $('.js-observacoes').ejAutocomplete({
      locale         : "pt-PT",
      width          : "100%",
      //watermarkText: "Gravação - A, O, P, S",
      emptyResultText: "Outros",
      showPopupButton: true,
      animateType    : "none",
      dataSource     : ["K", "K/cde soldé", "F", "F/cde soldé", "cde soldé", "Pré-Série", "Pré-Série/cde soldé"],
      focusIn        : function (argument: any) {
        console.log(argument);
        $(argument.event.target).ejAutocomplete("open");
      },
      change         : (v) => {
        if (v && v.event && v.event.originalEvent && v.event.originalEvent.type == "blur") {
          let idsLinha = [];
          try {
            idsLinha = v.event.target.closest("tr").dataset.id.split(",");

            if (Array.isArray(idsLinha) && idsLinha.length == 1) {
              let linhaBase        = idsLinha[0];
              let linha            = this.guiaEmEdicao.GuiaTransporteLinha.find(el => el.idGuiaTransporteLinha == linhaBase);
              linha.nvcObservacoes = v.value;
              if (linha) this.updateDetalhesLinha(linha)
            }
            if (Array.isArray(idsLinha) && idsLinha.length == 2) {
              let linhaBase        = idsLinha[0];
              let linhaDependente  = idsLinha[1];
              let linha            = this.guiaEmEdicao.GuiaTransporteLinha[this.guiaEmEdicao.GuiaTransporteLinha.findIndex(el => el.idGuiaTransporteLinha == linhaBase)].InverseIdGuiaTransporteLinhaOrigemNavigation.find(el => el.idGuiaTransporteLinha == linhaDependente);
              linha.nvcObservacoes = v.value;
              if (linha) this.updateDetalhesLinha(linha)
            }
          } catch (err) {
            this.app.notificationErrorCompact(err);
            console.log("erro", "decididamente um erro [wrtyuio]", err);
          }
        }
      }
    });
  }

  attached() {
    this.doAction("LOAD-GUIA",this.id);
  }

  //endregion

  private registerListeners() {
    document.onkeyup = (e) => {
      //if (environment.debug) console.log("[nova-expedicao]", "onkeyup", e);

      //ignora-se o input oriundo de inputs e botões
      if (!["button", "input"].includes(e.srcElement.localName)) {

        const regex = /^[a-z0-9]$/i;
        if (e.key == "Enter") {
          // console.log("[nova-expedicao]", "onkeyup ENTER", e);
          if (environment.debug) console.log("[nova-expedicao]", "onkeyup ENTER", this.buffer);

          if (/^re/i.test(this.buffer)) {
            this.buffer && this.doAction("CONSULTA-REFERENCIA", this.buffer);
          } else {
            this.app.notificationErrorCompact("ERRO NA LEITURA DE CÓDIGO DE BARRAS<br> Apenas devem ser lidas etiquetas de controlo / gravação para integração em guias de transporte.");
          }

          this.buffer = "";
        }

        // //no segundo shift
        // if (e.key == "Shift") {
        //   if (this.buffer != "r")
        //     this.buffer = "";
        // }

        if (regex.test(e.key))
          this.buffer = this.buffer + e.key;
      } else {
        if (e.key == "Enter") {
          let ae = document.activeElement;
          (ae as any).blur();
          this.buffer = "";
        }
      }
    };
  }

  private deregisterListeners() {
    document.onkeyup = null;
  }

  descricaoOfTipo(tipo) {
    return this.tipos.find((el) => el.nvcTipo == tipo).nvcDescricao;
  }

  //region Ações diversas
  gravarCabecalhoGuia(vmw: VmWrapper<GuiaTransporte>) {
    this.isBusy = true;
    return this.app.api
      .post("api/plano-expedicao/guia-transporte", vmw.stateToPOJSO())
      // .post("api/plano-expedicao/linha-guia-transporte", vmw.stateToPOJSO("stateToPOJSOQuantidade"))
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravarCabecalhoGuia(vmw.nextLevel());
                } else {
                  //return {action: "INITIAL-LOAD", payload: {}};
                  return this.doAction("ESCOLHE-GUIA", this._indexEmEdicao);
                  //throw new Error("A ação foi cancelada.");
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        //console.log("returning", GuiaTransporte.fromPOJSO(obj));
        return this.doAction("UPDATE-GUIA-EDICAO", GuiaTransporte.fromPOJSO(obj));
      })
      .catch(e => this.app.notificationErrorCompact(e))
      ;
  }

  adicionaLinha(i: number) {
    //let linha = new GuiaTransporteLinha();
    this.isBusy = true;
    console.log("adiciona linha no índice", i);

    return this.app.api
      .post("api/plano-expedicao/linha-guia-transporte/" + this.guiaEmEdicao.idGuiaTransporte, i)
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        console.log("returning", GuiaTransporte.fromPOJSO(obj));
        // return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
        this.doAction("UPDATE-GUIA-EDICAO", GuiaTransporte.fromPOJSO(obj));
      })
      .catch(e => this.app.notificationErrorCompact(e))
      ;
  }

  apagarLinha(vmw: VmWrapper<GuiaTransporteLinha>) {
    this.isBusy = true;
    return this.app.api
      .deleteVerb("api/plano-expedicao/linha-guia-transporte", null, vmw.stateToPOJSO())
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.apagarLinha(vmw.nextLevel());
                } else {
                  return {action: "INITIAL-LOAD", payload: {}};
                  //throw new Error("A ação foi cancelada.");
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        // console.log("returning", GuiaTransporte.fromPOJSO(obj));
        return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
      })
      ;
  }

  gravarLinha(vmw: VmWrapper<GuiaTransporteLinha>) {
    this.isBusy = true;
    return this.app.api
      .post("api/plano-expedicao/linha-guia-transporte-quantidade", vmw.stateToPOJSO("stateToPOJSOQuantidade"))
      // .post("api/plano-expedicao/linha-guia-transporte", vmw.stateToPOJSO("stateToPOJSOQuantidade"))
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravarLinha(vmw.nextLevel());
                } else {
                  return {action: "INITIAL-LOAD", payload: {}};
                  //throw new Error("A ação foi cancelada.");
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        // console.log("returning", GuiaTransporte.fromPOJSO(obj));
        return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
      })
      ;
  }

  gravarLinhaDetalhes(vmw: VmWrapper<GuiaTransporteLinha>) {
    this.isBusy = true;
    return this.app.api
      .post("api/plano-expedicao/linha-guia-transporte-detalhes", vmw.stateToPOJSO("stateToPOJSOQuantidade"))
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravarLinhaDetalhes(vmw.nextLevel());
                } else {
                  console.log("nope!!!", vmw);
                  return {action: "INITIAL-LOAD", payload: {}};
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
      });
  }

  gravarLinhaDireto(vmw: VmWrapper<GuiaTransporteLinha>) {
    this.isBusy = true;
    return this.app.api
      .postProcessed("api/plano-expedicao/linha-guia-transporte-direto", vmw.stateToPOJSO())
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravarLinhaDireto(vmw.nextLevel());
                } else {
                  return {action: "INITIAL-LOAD", payload: {}};
                  //throw new Error("A ação foi cancelada.");
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        // console.log("returning", GuiaTransporte.fromPOJSO(obj));
        return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
      })
      ;
  }

  apagarGuia(vmw: VmWrapper<GuiaTransporte>) {
    this.isBusy = true;
    return this.app.api
      .deleteVerb("api/plano-expedicao/guia-transporte", null, vmw.stateToPOJSO())
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.apagarGuia(vmw.nextLevel());
                } else {
                  this._indexEmEdicao = -1;
                  return {action: "INITIAL-LOAD", payload: {}};
                  //throw new Error("A ação foi cancelada.");
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        // console.log("returning", GuiaTransporte.fromPOJSO(obj));
        this._indexEmEdicao = -1;
        return {action: "INITIAL-LOAD", payload: {}};
      })
      ;
  }

  apagarLinhasVazio(vmw: VmWrapper<GuiaTransporte>) {
    this.isBusy = true;
    return this.app.api
      .deleteProcessed("api/plano-expedicao/linhas-guia-transporte-vazias", null, vmw.stateToPOJSO())
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.apagarLinhasVazio(vmw.nextLevel());
                } else {
                  return {action: "UPDATE-GUIA-EDICAO", payload: vmw.payload};
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
      })
      ;
  }

  integrarGuiaPrimavera(vmw: VmWrapper<number>) {
    this.isBusy = true;
    return this.app.api
      .post("api/plano-expedicao/emite-documento-pre-guia", vmw.stateToPOJSO())
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.integrarGuiaPrimavera(vmw.nextLevel());
                } else {
                  this.isBusy = false;
                  // this._indexEmEdicao = -1;
                  // return {action: "INITIAL-LOAD", payload: {}};
                  //throw new Error("A ação foi cancelada.");
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        // console.log("returning", GuiaTransporte.fromPOJSO(obj));
        this._indexEmEdicao = -1;
        return {action: "INITIAL-LOAD", payload: {}};
      })
      ;
  }

  fecharGuiaTransporte(vmw: VmWrapper<GuiaTransporte>) {
    this.isBusy = true;
    return this.app.api
      .postProcessed("api/plano-expedicao/fecha-guia-transporte", vmw.stateToPOJSO())
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  return this.fecharGuiaTransporte(vmw.nextLevel());
                } else {
                  this.isBusy = false;
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        // console.log("returning", GuiaTransporte.fromPOJSO(obj));
        this._indexEmEdicao = -1;
        return {action: "INITIAL-LOAD", payload: {}};
      })
      ;
  }

  moverLinha(linha: GuiaTransporteLinha, offset: number) {
    this.isBusy = true;
    return this.app.api
      .post(`api/plano-expedicao/mover-linha-guia?offset=${offset}`, linha.stateToPOJSO())
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        this.doAction("UPDATE-GUIA-EDICAO", GuiaTransporte.fromPOJSO(obj));
      })
      .catch(err => {
        this.isBusy = false;
        this.app.notificationErrorCompact(err);
      })
  }

  reordenarLinhas(payload) {
    this.isBusy = true;
    return this.app.api
      .post(`api/plano-expedicao/ordena-linhas?id=${this.guiaEmEdicao.idGuiaTransporte}&order=${payload.order}&direction=${payload.direction}`, {})
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        this.doAction("UPDATE-GUIA-EDICAO", GuiaTransporte.fromPOJSO(obj));
      })
      .catch(err => {
        this.isBusy = false;
        this.app.notificationErrorCompact(err);
      })
  }

  //endregion Ações diversas

  //region Dialog Launchers

  /**
   * Mostra um popup pré-filtrado e atua consoante a sua forma de invocação
   * @param init
   * @param targetAction
   * @param linhaGt
   */
  showPickerCandidatosPrg(init: PickerProximoPrgPayload = {nvc_cliente_armazem: this.clienteArmazem.nvcClienteArmazem, disponiveis: '0', int_delta: 0}, targetAction: string = "PICK-LINHA", linhaGt?: GuiaTransporteLinha) {
    this.app.ds
      .open({viewModel: PickerProximoPrg, model: init, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          // console.log('good - ', response.output);
          if (response.output && response.output.action)
            this.doAction(targetAction, {registo: response.output.registo, linhaGt});
        } else {
          //console.log('bad!');
        }
        //console.log(response.output);
      });
  }

  showPickerData(dia?, action = null) {
    //let init: PickerProximoPrgPayload = {nvc_cliente_armazem: this.clienteArmazem.nvcClienteArmazem};
    this.app.ds
      .open({viewModel: PickerData, model: {dataInicial: dia || this.dia, action: action}, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          if (response.output && response.output.action)
            this.doAction(response.output.action, response.output.payload);
        } else {
        }
      });
  }

  showPickerMorada() {
    //let init: PickerProximoPrgPayload = {nvc_cliente_armazem: this.clienteArmazem.nvcClienteArmazem};
    this.app.ds
      .open({viewModel: PickerMorada, model: '', centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          if (response.output && response.output.action)
            this.doAction(response.output.action, response.output.payload);
        }
      });
  }

  showPickerLinhaEncomenda() {
    let pl: PickerLinhaEncomendaPayload = {nvc_cliente_armazem: this.clienteArmazem.nvcClienteArmazem, id_guia_transporte: this.guiaEmEdicao.idGuiaTransporte};
    this.app.ds
      .open({viewModel: PickerLinhaEncomenda, model: pl, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          if (response.output && response.output.action)
            this.doAction(response.output.action, response.output.payload);
        }
      });
  }

  //endregion

  //region Direct Actions
  transfere(tipo: string = "xls", guia: GuiaTransporte = this.guiaEmEdicao) {
    if (tipo != "xls") tipo = "pdf";
    let payload: any = {id_guia_transporte: guia.idGuiaTransporte};

    let route = "api/plano-expedicao/report/GuiaTransporte?tipo=" + tipo;
    if (this.debug === "debug") route += "&definicao=1";
    this.app.api
      .post(route, payload)
      .then(r => this.app.api.processBlobResponse(r))
      .then(blob => this.app.api.processBlobDownload(blob, `${guia.intNumero}-${this.clienteArmazem.nvcNome}-${dateISOString(new Date())}.${tipo}`))
      .catch(e => this.app.notificationErrorCompact(e));
  }

  //endregion

  //region Action Creators
  /**
   * @param i - índice da tabela com potenciais linhas de transporte.
   */
  escolheGuiaLista(i: number) {
    this.doAction("ESCOLHE-GUIA", i);
  }

  public updateLinha(linha: GuiaTransporteLinha) {
    console.log("updateLinha", linha);
    this.doAction("GRAVAR-LINHA", linha);
  }

  public updateDetalhesLinha(linha: GuiaTransporteLinha) {
    console.log("updateDetalhesLinha", linha);
    this.doAction("GRAVAR-LINHA-DETALHES", linha);
  }

  public removerLinha(linha: GuiaTransporteLinha) {
    this.isBusy = true;
    this.doAction("APAGAR-LINHA", linha);
  }

  public remover(guia: GuiaTransporte) {
    if (guia.nvcEstado != 'aberto') {
      //this.app.notificationWarning("Não é possível apagar guias que tenham dado origem a faturas no Primavera.");
      return;
    }
    this.isBusy = true;
    this.doAction("APAGAR-GUIA", guia);
  }

  public integraErp() {
    this.doAction("INTEGRA-ERP", this.guiaEmEdicao);
  }

  //endregion

  /**
   * Dispatcher de ações para esta view (contido)
   * @param action
   * @param payload
   */
  public doAction(action: string, payload: any = {}) {
    console.log("Acção [guia-transporte]", action, payload);
    try {
      switch (action) {
        case 'LOAD-GUIA': {
          if (this.id && this.id > 0)
            return this.app.api.getProcessed(`api/plano-expedicao/guia-transporte/${this.id}`)
              .then(gt => {
                this.guiaEmEdicao = GuiaTransporte.fromPOJSO(gt);
                this.isBusy = false;
              })
              .catch(e => this.app.notificationErrorCompact(e));
          else {
            this.guiaEmEdicao = new GuiaTransporte({ dtmDataExpedicao: this.dia });
            return this.isBusy = false;
          }
        }


        case "REORDENAR": {
          console.log(this.guiaEmEdicao.idGuiaTransporte, payload);
          this.reordenarLinhas(payload);
          break;
        }

        case 'APAGAR-GUIA': {
          this.apagarGuia((payload as GuiaTransporte).wrapIt()).then(r => {
            //console.log(r);
            if (r && r.action) { this.doAction(r.action, r.payload);}
          });
          break;
        }

        case 'LIMPAR-LINHAS-VAZIO': {
          this.apagarLinhasVazio((payload as GuiaTransporte).wrapIt()).then(r => {
            //console.log(r);
            if (r && r.action) { this.doAction(r.action, r.payload);}
          });
          break;
        }

        case 'UPDATE-CABECALHO': {
          this.isBusy = true;
          this.gravarCabecalhoGuia(this.guiaEmEdicao.wrapIt());
          break;
        }

        case 'MUDA-DATA-GUIA-TRANSPORTE': {
          this.app.notificationInfo("Escolha a nova data para o documento. <br>Todos os registos do plano dependentes serão transferidos para a data pretendida");
          this.showPickerData(this.guiaEmEdicao.dtmDataDocumento, "ALTERA-DATA-GUIA-TRANSPORTE");
          break;
        }

        case "ALTERA-DATA-GUIA-TRANSPORTE": {
          this.guiaEmEdicao.dtmDataExpedicao = payload;
          this.app.confirmaAction(this.guiaEmEdicao.wrapIt(), 'api/plano-expedicao/altera-data')
            .then(r => {
              //if (r === false) this.app.notificationShort("Acção cancelada. O ficheiro não foi criado");
              if (r) {
                this.app.notificationSuccess(`A data foi alterada.`);
                this.doAction("ESCOLHE-DATA", payload);
                //this.controller.ok({action: "REFRESH", payload: r})
              }
              return;
            })
            .catch(err => this.app.notificationErrorCompact(err));
          break;
        }

        case 'PICKER-MORADA': {
          this.showPickerMorada();
          break;
        }


        case 'FILL-MORADA': {
          this.guiaEmEdicao.nvcClienteDestinatario = payload;
          this.doAction('UPDATE-CABECALHO');
          break;
        }

        case 'FETCH-NUMERO-GT': {
          this.app.api.get('api/plano-expedicao/sugere-numero-guia-transporte', {id: this.guiaEmEdicao.idGuiaTransporte || 0})
            .then(r => this.app.api.processResponse(r))
            .then((o: any) => {
              this.guiaEmEdicao.intNumero = o.intNumero;
              this.doAction('UPDATE-CABECALHO', {});
            })
            .catch(e => this.app.notificationErrorCompact(e));
          break;
        }

        case 'PICKER-LINHA-ENCOMENDA': {
          this.showPickerLinhaEncomenda();
          break;
        }

        //doing: contextualizar para linhas dependentes, i.e., um botão que permita escolher uma lista de artigos similares qual a aplicar no dependente
        case 'PICKER-CANDIDATOS': {
          // console.log("PICKER-CANDIDATOS", payload, payload.__index);
          let linha = payload as GuiaTransporteLinha;
          if (payload.__index === undefined) {
            this.showPickerCandidatosPrg();
            break;
          } else {
            console.log("Foi passada uma linha");
            if (linha.idGuiaTransporteLinhaOrigem) {
              console.log("dependente");
              this.showPickerCandidatosPrg({nvc_cliente_armazem: this.clienteArmazem.nvcClienteArmazem, disponiveis: '0', int_delta: 0, nvc_artigo_terminacao: linha.nvcArtigoTerminacao}, "REPLACE-DEPENDENTE", linha);
            } else {
              console.log("raiz");
              this.showPickerCandidatosPrg({nvc_cliente_armazem: this.clienteArmazem.nvcClienteArmazem, disponiveis: '0', int_delta: 0, nvc_artigo_terminacao: linha.nvcArtigoTerminacao}, "ADD-DEPENDENTE", linha);
            }
          }
          break;
        }

        // o utilizador selecionou uma linhas do picker de candidatos.
        case 'PICK-LINHA': {
          // console.log('PICK-LINHA', payload, 'guia em edição:', this.guiaEmEdicao);
          let registoOrigem = payload.registo;
          if (!this.guiaEmEdicao) throw new Error("Deve selecionar uma guia de transporte na lista, ou criar uma nova.");
          let linha = new GuiaTransporteLinha({
            idGuiaTransporte   : this.guiaEmEdicao.idGuiaTransporte,
            idRpOrigem         : registoOrigem.id_registo_plano,
            nvcArtigoTerminacao: registoOrigem.nvc_artigo_terminacao,
            nvcDescricaoArtigo : registoOrigem.nvc_descricao_artigo,
            nvcCodigoEncomenda : registoOrigem.nvc_codigo_encomenda,
            nvcMoeda           : 'eur',
            intNumero          : this.guiaEmEdicao.GuiaTransporteLinha.length + 1
          });
          this.doAction('GRAVAR-LINHA', linha);
          break;
        }

        //region Etiquetas de Controlo
        case "PICKER-ETIQUETA":
          return this.app.ds.open({viewModel: PickerRemoteGrid, model: {codigoTabela: "RegistoEtiquetaGuia"}})
            .whenClosed(r => {
              if (!r.wasCancelled) {
                if (environment.debug) console.log("[guia-transporte]", "Resultado Picker", r);
                this.isBusy = false;

                this.doAction("ADD-ETIQUETA", r.output.rowRef)
                // return this.toggleArtigo(r.output)
                //   .then(_ => this.rgArtigos.refreshPage());
              }
              return Promise.resolve("false");
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err));

        case "PICKER-NUMERO-ETIQUETA":
          this.deregisterListeners();
          return this.app.ds.open({viewModel: ConfirmacaoPromptDialog, model:{msg: "Inserir o número de etiqueta", tipo: "Número de etiqueta", forma: "input"}})
            .whenClosed(r => {
              this.registerListeners();
              this.isBusy = false;
              if(!r.wasCancelled) {
                this.doAction("ADD-ETIQUETA", {idRegistoEtiqueta: +r.output.resposta.replace(/\D/g, '')});
              }
              return false;
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err));

        case "CONSULTA-REFERENCIA": {
          if (this.guiaEmEdicao.nvcEstado == 'aberto') {
            let ref: string = payload;
            if (!ref || !/^re/i.test(ref)) {
              return this.isBusy = this.app.notificationErrorCompact("ERRO NA LEITURA DE CÓDIGO DE BARRAS<br> Apenas devem ser lidas etiquetas de controlo / gravação nesta página.");
            }
            this.doAction("ADD-ETIQUETA", {idRegistoEtiqueta: +ref.replace(/\D/g, '')});
          }
          break;
        }

        case "ADD-ETIQUETA": {
          let etiqueta = RegistoEtiqueta.fromPOJSO(payload);
          //console.log(etiqueta);
          return confirmaActionTyped(this, etiqueta, 'api/plano-expedicao/adiciona-etiqueta?idGt=' + this.guiaEmEdicao.idGuiaTransporte)
            .then(r => {
              if (r !== false) {
                if (environment.debug) console.log("[guia-transporte]", "return ", r);
                this.app.notificationSuccess("Etiqueta associada");
                this.doAction("INITIAL-LOAD");
                //this.doAction("UPDATE-GUIA-EDICAO", GuiaTransporte.fromPOJSO(r));
              }
            })
        }
        //endregion Etiquetas de Controlo

        case 'ADD-DEPENDENTE': {
          // console.log('ADD-DEPENDENTE', payload, 'guia em edição:', this.guiaEmEdicao);
          let registoOrigem = payload.registo;
          if (!this.guiaEmEdicao) throw new Error("Deve selecionar uma guia de transporte na lista, ou criar uma nova.");
          let linha = new GuiaTransporteLinha({
            idGuiaTransporte           : this.guiaEmEdicao.idGuiaTransporte,
            idGuiaTransporteLinhaOrigem: payload.linhaGt.idGuiaTransporteLinha,
            idRpOrigem                 : registoOrigem.id_registo_plano,
            nvcArtigoTerminacao        : registoOrigem.nvc_artigo_terminacao,
            nvcDescricaoArtigo         : registoOrigem.nvc_descricao_artigo,
            nvcCodigoEncomenda         : registoOrigem.nvc_codigo_encomenda,
            nvcMoeda                   : 'eur',
            intNumero                  : this.guiaEmEdicao.GuiaTransporteLinha.length + 1
          });
          this.doAction('GRAVAR-LINHA-DIRETO', linha);
          break;
        }

        case 'REPLACE-DEPENDENTE': {
          // console.log('REPLACE-DEPENDENTE', payload, 'guia em edição:', this.guiaEmEdicao);
          let registoOrigem = payload.registo;
          if (!this.guiaEmEdicao) throw new Error("Deve selecionar uma guia de transporte na lista, ou criar uma nova.");
          let linha        = payload.linhaGt;
          linha.idRpOrigem = registoOrigem.id_registo_plano;

          this.doAction('GRAVAR-LINHA-DIRETO', linha);
          break;
        }

        case 'GRAVAR-LINHA': {
          this.gravarLinha((payload as GuiaTransporteLinha).wrapIt()).then(r => {
            if (r && r.action) { this.doAction(r.action, r.payload); }
          }).catch(e => this.isBusy = this.app.notificationErrorCompact(e));
          break;
        }

        case 'GRAVAR-LINHA-DETALHES': {
          this.gravarLinhaDetalhes((payload as GuiaTransporteLinha).wrapIt()).then(r => {
            if (r && r.action) { this.doAction(r.action, r.payload); }
          }).catch(e => this.isBusy = this.app.notificationErrorCompact(e));
          break;
        }

        //é necessário para evitar os cálculos sobre as quantidades.
        case 'GRAVAR-LINHA-DIRETO': {
          this.gravarLinhaDireto((payload as GuiaTransporteLinha).wrapIt()).then(r => {
            if (r && r.action) { this.doAction(r.action, r.payload); }
          }).catch(e => this.isBusy = this.app.notificationErrorCompact(e));
          break;
        }

        case 'APAGAR-LINHA': {
          let GuiaTransporteLinha = (payload as GuiaTransporteLinha);
          let cl                  = 0;
          if (GuiaTransporteLinha.intQuantidade === 0) cl = 1;
          this.apagarLinha(GuiaTransporteLinha.wrapIt(cl)).then(r => {
            if (r && r.action) { this.doAction(r.action, r.payload); }
          }).catch(e => this.isBusy = this.app.notificationErrorCompact(e));
          break;
        }

        //region Integração primavera & armazem
        case 'INTEGRA-ERP': {
          let wrap = new VmWrapper<number>({payload: (payload as GuiaTransporte).idGuiaTransporte, confirmLevel: 0});
          this.integrarGuiaPrimavera(wrap).then(r => {
            if (r && r.action) {this.doAction(r.action, r.payload)}
          }).catch(e => this.isBusy = this.app.notificationErrorCompact(e));
          break;
        }

        case 'FECHA-GUIA-TRANSPORTE': {
          this.fecharGuiaTransporte((payload as GuiaTransporte).wrapIt()).then(r => {
            if (r && r.action) {this.doAction(r.action, r.payload)}
          }).catch(e => this.isBusy = this.app.notificationErrorCompact(e));
          break;
        }

        case 'MUDA-TIPO': {
          if (this.guiaEmEdicao.nvcTipo != payload) {
            this.isBusy               = true;
            this.guiaEmEdicao.nvcTipo = payload;
            this.doAction("UPDATE-CABECALHO")
          }
          break;
        }

        case 'INTEGRA-ARM': {
          this.isBusy = true;
          this.app
            .confirmaAction(this.guiaEmEdicao.wrapIt(), 'api/plano-expedicao/transfere-armazem-expedicao')
            .then(r => {
              this.isBusy = false;
              if (r && r.action) { this.doAction("INITIAL-LOAD", {}) }
              return;
            })
            .catch(err => {
              this.app.notificationErrorCompact(err);
              this.isBusy = false
            });

          break;
        }
        //endregion Integração com o primavera

        default: {
          console.log("Acção desconhecida [guia-transporte]", action, payload);
        }
      }
    } catch (err) {
      this.app.notificationErrorCompact(err);
    }
  }

  log(arg) {
    console.log("guia-transporte", ...arg);
  }
}
