import {superRound} from "../utils/ItNumeric";
import {dotStringToObj, isPrimitive} from "../utils/ItMultiPurpose";
import environment from "../environment";
import {ValidationRules} from "aurelia-validation";

/**
 * Created by herna on 5/4/2017.
 *
 * v. 1.2 (2019-05-05) setState() procura propriedades em notação dot (navegações) e reconstrói um objeto hierárquico
 * v. 1.1 (2019-05-05) stateToPOJSOSafeId(), getStaticType() e ViewModelImporter
 */
export class BaseViewModel {
  public __index: number = 0;

  public setState(obj: any, publicOnly: boolean = false): BaseViewModel {
    if(obj == undefined) {
      if(environment.debug) console.warn("[baseviewmodel]","obj indefenido. o fromPOJSO devia proteger esses casos");
      return this;
    }
    // console.log("asd");
    let keys = Object.keys(obj);

    //há propriedades em "dot.notation"? se sim "abre" o objeto.
    if(keys.some(el => el.includes("."))) {
      //a referência passada em obj é MUTADA, caso contrário as chamadas seguintes ao setState não iriam usufruir da deconstrução
      keys.forEach(el => {
        let v = obj[el];
        if(el.includes(".")) {delete obj[el];}
        dotStringToObj(el, v, obj)
      });
      //recalcular as keys
      keys = Object.keys(obj);
    }

    //parse seletivo do objeto com garantia de tipo
    for (let prop of keys) {
      //if (!obj.hasOwnProperty(prop)) continue;
      if (publicOnly && prop.startsWith("_")) continue;

      if (prop.startsWith("int") || (prop.startsWith("id") && !prop.endsWith("Navigation")) || prop.startsWith("flt") || prop.startsWith('dec')) {
        this[prop] = +obj[prop];
      } else if ((prop.startsWith("nvc")  && !prop.endsWith("Navigation")) || prop == "timestamp") {
        if (obj[prop] == "null" || obj[prop] === null || obj[prop] === undefined)
          this[prop] = null;
        else
          this[prop] = "" + obj[prop];
      } else if (prop.startsWith("str")  && !prop.endsWith("Navigation")) {
        if (obj[prop] == "null" || obj[prop] === null || obj[prop] === undefined)
          this[prop] = null;
        else
          this[prop] = "" + obj[prop];
      } else if (prop.startsWith("bit")) {
        this[prop] = (+obj[prop] === 1);
      } else if (prop.startsWith("dtm")) {
        //Para lidar com as datas usa-se o derivado do resultado (new Date()).toISOString().substr(0,10)
        //que produz uma string na forma "yyyy-MM-dd"
        if (obj[prop] == "null" || obj[prop] === null || obj[prop] === undefined)
          this[prop] = null;
        else {

          this[prop] = ("" + obj[prop]).substr(0, 10);
        }
      }
    }

    return this;
  }

  /**
   * Carrega um estado para uma instância existente
   * @param obj
   * @param {string[]} props
   * @returns {BaseViewModel}
   */
  public copyState(obj: any, props: string[] = []): BaseViewModel {
    let keys = Object.keys(obj);
    if (props && props.length > 0) keys = props;

    for (let prop of keys) {
      if (obj[prop] && isPrimitive(obj[prop]))
        this[prop] = obj[prop];
      else if (obj[prop] === null) this[prop] = null;
    }
    return this;
  }

  /**
   *
   * @param obj
   * @return {Promise<T>}
   */
  public promisedSetState(obj) {
    return promisify(this.setState(obj));
  }

  /**
   * Devolve um array com todas as propriedades públicas desta classe.
   * @returns {string[]}
   */
  public getPublicKeys(): string[] {
    let list: string[] = [];
    for (let prop in this) {
      if (this.hasOwnProperty(prop) && !prop.startsWith("_")) {
        list.push(prop);
      }
    }
    return list;
  }

  /**
   * Gera um POJSO com o estado do objeto.
   * Toma decisões sobre os valores baseando-se no nome dos atributos do objeto.
   * @param excludeEmpty diz se as propriedades a vazio não devem ser incluídas no objeto do estado
   * @param skipRelations diz se as relações devem ser incluídas (ou não)
   * @return {{}}
   */
  public getState(excludeEmpty: boolean = true, skipRelations: boolean = true): any {
    let retObj = {};
    for (let prop of this.getPublicKeys()) {

      //as seguintes propriedades nunca são enviadas
      // if(["dtmDataI", "dtmDataA", "dtmDatains", "dtmDataultact"].includes(prop)) continue;
      if (["dtmDataI", "dtmDataA"/*, "dtmDatains", "dtmDataultact"*/].includes(prop)) continue;

      // não envia o timestamp se este estiver a vazio?
      if (prop == "timestamp" && !this[prop]) continue;
      if (excludeEmpty && prop.startsWith("id") && (this[prop] === 0 || this[prop] == "0")) continue;
      if (excludeEmpty && prop.startsWith("dtm") && this[prop] == "") continue;

      //não incluí propriedades que comecem com letra maiúscula quando skipRelations
      if (skipRelations && startsWithUpperCase(prop)) continue;
      if (skipRelations && prop.endsWith("Navigation")) continue;
      if (skipRelations && prop.startsWith("_")) continue;

      //incluír, automáticamente as chamadas ao estado das relações
      if (this[prop] && typeof this[prop].getState === "function") {
        //se a propriedade contiver a função getState
        if (skipRelations) continue;
        retObj[prop] = this[prop].getState(excludeEmpty, skipRelations);
      } else if (Array.isArray(this[prop])) {
        //a propriedade é um array, se os elementos forem primitivas preenche
        // se tem elementos baseviewmodel? too much! -> que se faça explícitamente do stateToPOJSO, daqui sai a vazio
        let arr = this[prop].filter(el => isPrimitive(el));
        if (arr.length > 0) {
          retObj[prop] = this[prop].filter(el => isPrimitive(el));
        }
        continue;
        //if (skipRelations) continue;
        //retObj[prop] = [];
      } else {
        retObj[prop] = this[prop];
      }

      if (prop.startsWith("bit")) retObj[prop] = !!this[prop];
      if (prop.startsWith("flt") || prop.startsWith("dec")) retObj[prop] = superRound(this[prop]);
      // if (prop)
    }
    return retObj;
  }

  public static fromPOJSO(obj: any): BaseViewModel {
    let model = new BaseViewModel();
    model.setState(obj);

    return model;
  }

  // public fromPOJSO(obj:any):this{
  //   return class this.fromPOJSO()
  // }

  public stateToPOJSO(): any {
    //
  }

  // transforma o estado interno num adequado ao transporte num ficheiro
  public stateToPOJSOSafeId(): any {
    //
  }

  /**
   * Calcula a diferença (superficial) entre duas instâncias de BaseViewModel. É suficiente para decidir se a navegação deve ser interrompida ou não.
   * @param obj
   * @param {boolean} publicOnly
   * @return {string[]} - Lista com os nomes das propriedades que são diferentes.
   */
  public difference(obj: any, publicOnly: boolean = false): string[] {
    // console.log("asd");
    // let keys             = Object.keys(obj);
    let ownKeys          = Object.keys(this);
    let result: string[] = [];

    if (!obj) return result;

    for (let prop of ownKeys) {
      //if (!obj.hasOwnProperty(prop)) continue;
      if (publicOnly && prop.startsWith("_")) continue;
      if (["dtmDataI", "dtmDataA"/*, "dtmDatains", "dtmDataultact"*/].includes(prop)) continue;

      if ((prop.startsWith("int") || (prop.startsWith("id") && !prop.endsWith("Navigation"))) && +this[prop] != +obj[prop]) {
        result.push(prop);
      } else if ((prop.startsWith("flt") || prop.startsWith("dec")) && ("" + this[prop] !== "" + obj[prop])) {
        result.push(prop);
      } else if ((prop.startsWith("nvc") || prop == "timestamp") && ("" + this[prop] !== "" + obj[prop])) {
        result.push(prop);
      } else if (prop.startsWith("bit") && (!!this[prop] !== (+obj[prop] === 1))) {
        result.push(prop);
      } else if (prop.startsWith("dtm") && ("" + this[prop] !== "" + obj[prop])) {
        result.push(prop);
      }
    }

    return result;
  }

  public cloneInstance(): BaseViewModel {
    return BaseViewModel.fromPOJSO(this.stateToPOJSO());
  }

  /**
   * Devolve uma referência da representação estática da classe em uso
   * Resolve a lacuna do `typeof` de jS
   */
  public getStaticType(): any {
    return BaseViewModel;
  }
}

export let promisify = <T>(obj: any): Promise<T> => Promise.resolve(obj);

/**
 * Verifica se uma string começa com uppercase (relações)
 * @param testString
 * @returns {boolean}
 */
export function startsWithUpperCase(testString: string = ""): boolean {
  let first = testString.charAt(0);
  return (first === first.toUpperCase() && first !== first.toLowerCase());
}

//Classe para ajudar às importações / exportações de json
export class ViewModelImporter {
  public jsonString: string          = null;
  // public modelRef:BaseViewModel      = null;
  public intent: "import" | "export" = "import";

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ViewModelImporter>,) {
    if (fields) Object.assign(this, fields);
  }

  // public parse() {
  //   try {
  //     let obj   = JSON.parse(this.jsonString);
  //     let model = this.modelRef.getStaticType().fromPOJSO(obj);
  //     if (environment.debug) console.log("[baseviewmodel]", "modelo regenerado", model);
  //     return model;
  //   } catch (e) {
  //     console.error(e);
  //     return null;
  //   }
  // }
}

//region aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: ViewModelImporter) => m.jsonString).displayName('Bloco JSON').required()
  .on(ViewModelImporter);
//endregion
