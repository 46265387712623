import {autoinject, computedFrom} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import {PlanoDefinition} from "../models/PlanoColumn";
import {ClienteArmazem} from "../models/ClienteArmazem";

@autoinject()
export class ConfigFolhaExpedicao {
  protected controller: DialogController;
  private app: GlobalServices;
  private listaClientes: ClienteArmazem[];
  private selectedClientesIds: string[] = [];


  constructor(controller: DialogController, app: GlobalServices) {
    this.controller           = controller;
    this.app                  = app;
  }

  activate(model) {
    ClienteArmazem.memoizeMultiple(this.app.api)
      .then(r => {this.listaClientes = r; this.selectAll()});
  }

  attached() {
  }

  cleanup() {
  }

  selectAll(){
    this.selectedClientesIds = this.listaClientes.map(el => el.nvcClienteArmazem);
  }

  selectNone(){
    this.selectedClientesIds = [];
  }

  submit(){
    console.log("selectedClientesIds", this.selectedClientesIds);
    this.controller.ok({selectedClientesIds: this.selectedClientesIds.map(el => el)});
  }
}
