// Auto-gerado com typewriter

import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {ArtigoFamilia} from './ArtigoFamilia';
import {ArtigoTerminacao} from './ArtigoTerminacao';
// import {Registo} from './Registo';
import {Utilizador} from './Utilizador';
import {ErpGceArtigosComposicao} from "./ErpGceArtigosComposicao";

export class Artigo extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public nvcArtigo: string        = null;
  public nvcDescricao: string     = null;
  public nvcCodigoErp: string     = null;
  public nvcUnidadeErp: string    = null;
  public nvcTemplateAnexo: string = null;
  public bitAtivo: boolean        = false;

  //extra
  public decStock: number = 0.0;
  public decStockReservado: number = 0.0;

  public quantidadeEncomenda:number = 0;

  // public idUtilizador: number                             = 0;
  // public idUtilizadorultact: number                       = null;
  // public dtmDatains: string                               = null;
  // public _dtmDatains: Date                                = new Date();
  // public dtmDataultact: string                            = null;
  // public _dtmDataultact: Date                             = new Date();
  // public timestamp: number[]                              = [];
  public artigoFamilia: ArtigoFamilia[]           = [];
  public artigoTerminacao: ArtigoTerminacao[]     = [];
  // public registo: Registo[]                               = [];
  // public idUtilizadorNavigation: Utilizador       = null;
  // public idUtilizadorultactNavigation: Utilizador = null;

  public ErpGceArtigosComposicaoStrCodArtigoNavigation: ErpGceArtigosComposicao[] = [];

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Artigo>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): Artigo {
    let model = new Artigo();
    model.setState(obj);
    model.ErpGceArtigosComposicaoStrCodArtigoNavigation = ErpGceArtigosComposicao.multipleFromPOJSO(obj.erpGceArtigosComposicaoStrCodArtigoNavigation);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): Artigo[] {
    if (objs && Array.isArray(objs)) return objs.map(Artigo.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Artigo>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): Artigo { return Artigo.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `Artigo ${this.nvcArtigo} - ${this.nvcDescricao}`;
  }

  setQuantidadeEncomendada(intQtyTotalEncomenda: number) {
    this.quantidadeEncomenda = intQtyTotalEncomenda;
    this.ErpGceArtigosComposicaoStrCodArtigoNavigation.forEach(el => el.setQuantidadeEncomendada(intQtyTotalEncomenda))
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Artigo) => m.nvcArtigo).displayName("nvcArtigo").required()
  .ensure((m: Artigo) => m.nvcDescricao).displayName("nvcDescricao").required()
  .ensure((m: Artigo) => m.nvcCodigoErp).displayName("nvcCodigoErp").required().satisfiesRule("equals", "nvcArtigo")
  .on(Artigo);

