import {BaseViewModel} from "./BaseViewModel";
import {ValidationRules} from "aurelia-validation";
import {VmWrapper} from "./VmWrapper";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class EmailGuiaTransporteVm extends BaseViewModel {
  public to: string = "";
  public cc: string = "";
  public bcc: string = "";
  public subject: string = "";
  public textMessage: string = `Bonjour á tous,


Vous trouverez en annexe les bl´s de l'expedition d'hier.

Merci.
`;

  public dictionaries: {id_guia_transporte: number}[] = [];

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<EmailGuiaTransporteVm>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any): EmailGuiaTransporteVm {
    throw new Error("not implemented!");
  }

  public static multipleFromPOJSO(objs: any[]): EmailGuiaTransporteVm[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(EmailGuiaTransporteVm.fromPOJSO);
    return [];
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  public warpIt(cl?: number) {
    return new VmWrapper<EmailGuiaTransporteVm>({payload: this, confirmLevel: (+cl || 0)});
  }
  //endregion
}

ValidationRules
  .ensure((m: EmailGuiaTransporteVm) => m.to).displayName("Destinatário").required().withMessage(`\${$displayName} é obrigatório`)
  .ensure((m: EmailGuiaTransporteVm) => m.textMessage).displayName("Mensagem").required().withMessage(`\${$displayName} é obrigatório`)
  .on(EmailGuiaTransporteVm);
