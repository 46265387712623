/**
 * Created by hernani on 2017-06-20.
 */
export class CleanIsoDateValueConverter {
  toView(value) {
    if(!value) return " ";
    if(typeof value === "string") return value.substr(0,19).replace("T", " ");
    return value;
  }

  // fromView(value) {
  //   return value;
  // }
}