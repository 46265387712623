//Aurelia Imports
import {autoinject, computedFrom} from "aurelia-framework";
//App Imports
import {GlobalServices} from "../../services/global-services";
import {Logger} from "aurelia-logging";
import {FolhaExpedicao} from "../../models/FolhaExpedicao";
import {VmWrapper} from "../../models/VmWrapper";
import {ConfirmacaoDialog} from "../../dialogs/confirmacao-dialog";
import {PickerData} from "../../dialogs/picker-data";
import {ConfigFolhaExpedicao} from "../../dialogs/config-folha-expedicao";
import {Router} from "aurelia-router";

@autoinject()
export class encomenda {
  private logger: Logger;
  private app: GlobalServices;

  private dia: string;
  private folhaExpedicao: FolhaExpedicao;
  /*
    0 - Ver todos (correntes + faltas)
    1 - correntes
    2 - faltas
   */
  private intTipoFolha: number;

  private isBusy: boolean = false;
  private debug: string;

  private total: number = 1;
  private valor: number = 2;

  private topOffset: number     = 0;
  private tableViewPort: number = 0;

  @computedFrom('folhaExpedicao.idFolhaExpedicao')
  get canPrint() {
    if (!this.folhaExpedicao) return false;
    return +this.folhaExpedicao.idFolhaExpedicao > 0;
  }

  @computedFrom('intTipoFolha')
  get visualizacaoTipo() {
    switch (this.intTipoFolha) {
      case 1:
        return "Correntes";
      case 2:
        return "Faltas";
    }
    return "Todos";
  }

  // private linhas: any;

  // protected planoExpedicao: PlanoExpedicaoV;

  // get total(){
  //   return this.linhas.reduce((acc, el) => (acc + (el.qtd)?+el.qtd:0), 0);
  // }
  //
  // get valor(){
  //   return this.linhas.reduce((acc, el) => (acc + (el.valor)?+el.valor:0), 0);
  // }

  constructor(g: GlobalServices, protected router:Router) {
    this.app = g;
  }

  activate(p) {
    this.dia   = p.dia;
    this.debug = p.debug;
    console.log(p, this.dia);
    this.doAction("INITIAL-LOAD", {});
    if (p.intTipoFolha != undefined) {
      this.intTipoFolha = +p.intTipoFolha;
    } else {
      this.intTipoFolha = 1;
    }
  }

  attached() {
    let $table = $("#table-sticky");
    $table.height(window.innerHeight - $table.offset().top - 56);
  }

  getPlano() {
    this.isBusy = true;
    return this.app.api.getProcessed("api/armazem/folha-expedicao", {dia: this.dia})
      // .then(r => this.app.api.processResponse(r))
      .then(o => FolhaExpedicao.fromPOJSO(o))
      .catch(e => {
        console.log("last catch", e);
        this.app.notificationErrorCompact(e);
      });
  }

  gravaFolhaExpedicaoServer(vmw: VmWrapper<FolhaExpedicao>) {
    this.isBusy = true;
    return this.app.api
      .postProcessed("api/armazem/add-folha-expedicao", vmw.stateToPOJSO())
      .then((obj: any) => {
        console.log("Resposta do servidor", obj);
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravaFolhaExpedicaoServer(vmw.nextLevel());
                } else {
                  this.isBusy = false;
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        } else
        // console.log("returning", GuiaTransporte.fromPOJSO(obj));
          return {action: "INITIAL-LOAD", payload: {}};
      })
  }

  public gravaFolhaExpedicaoParcialServer(vmw: VmWrapper<string[]>) {
    return this.app.api
      .postProcessed(`api/armazem/folha-expedicao-parcial/${this.dia}`, vmw.stateToPOJSO())
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravaFolhaExpedicaoParcialServer(vmw.nextLevel());
                } else {
                  this.isBusy = false;
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        return {action: "INITIAL-LOAD", payload: {}};
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }

  //region Direct Actions

  showPickerData(dia?) {
    //let init: PickerProximoPrgPayload = {nvc_cliente_armazem: this.clienteArmazem.NvcClienteArmazem};
    this.app.ds
      .open({viewModel: PickerData, model: dia || this.dia, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          if (response.output && response.output.action)
            this.doAction(response.output.action, response.output.payload);
        } else {
        }
      });
  }

  transfere(tipo: string = "xls", report = "FolhaExpedicao") {
    if (tipo != "xls") tipo = "pdf";
    let payload: any = {id_folha_expedicao: this.folhaExpedicao.idFolhaExpedicao, int_tipo_folha: +this.intTipoFolha};
    console.log("payload", payload);

    let route = `api/plano-expedicao/report/${report}?tipo=${tipo}`;
    if (this.debug === "debug") route += "&definicao=1";
    this.app.api
      .post(route, payload)
      .then(r => this.app.api.processBlobResponse(r))
      .then(blob => {
        let URL         = window.URL || (window as any).webkitURL;
        let downloadUrl = URL.createObjectURL(blob);
        let a           = document.createElement("a");
        // safari doesn't support theese yet
        a.href          = downloadUrl;
        a.download      = `folha-expedicao-${this.dia}.${tipo}`;
        document.body.appendChild(a);
        a.click();
        this.app.notificationSuccess("Ficheiro transferido para a pasta dos downloads com o nome: <strong>" + a.download + "</strong>");
        setTimeout(function () {
          URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);
        }, 200); // cleanup
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }

  //endregion

  //region Action creators
  public gravaFolhaExpedicao() {
    // return ?
    this.doAction("GRAVA-FOLHA-EXPEDICAO", this.folhaExpedicao);
  }

  public gravaFolhaExpedicaoParcial() {
    // return ?
    this.app.ds.open({viewModel: ConfigFolhaExpedicao})
      .whenClosed(resp => {
        if (!resp.wasCancelled) {
          console.log("Dialog result", resp.output);
          this.doAction("GRAVA-FOLHA-EXPEDICAO-PARCIAL", resp.output.selectedClientesIds);
        } else {}
      });
  }

  //endregion

  /**
   * Dispatcher de ações para esta view (contido)
   * @param action
   * @param payload
   */
  public doAction(action: string, payload: any = {}) {
    console.log("Acção [folha-expedicao]", action, payload);
    try {
      switch (action) {
        case 'INITIAL-LOAD': {
          this.getPlano()
            .then((r: any) => {
              this.folhaExpedicao = r;
              this.isBusy         = false;
              this.total          = this.folhaExpedicao.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intQuantidadeEnviar, 0);
              this.valor          = this.folhaExpedicao.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intControlo, 0);
            })
            .catch(e => this.app.notificationErrorCompact(e));
          break;
        }

        case 'ESCOLHE-DATA': {
          console.log("ESCOLHE-DATA", payload);
          this.isBusy = true;
          this.router.navigate(`${document.location.origin}/#/folha-expedicao/${payload}`);
          break;
        }

        case 'TIPO-VISIBILIDADE': {
          //console.debug("TIPO-VISIBILIDADE", payload);
          this.intTipoFolha = +payload;
          this.total        = this.folhaExpedicao.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intQuantidadeEnviar, 0);
          this.valor        = this.folhaExpedicao.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intControlo, 0);
          break;
        }

        case "GRAVA-FOLHA-EXPEDICAO": {
          this.gravaFolhaExpedicaoServer((payload as FolhaExpedicao).wrapIt())
            .then(r => {
              if (r && r.action) { this.doAction(r.action, r.payload);}
            });
          break;
        }

        case "GRAVA-FOLHA-EXPEDICAO-PARCIAL": {
          if (!Array.isArray(payload) || payload.length == 0) throw new Error("Deve escolher pelo menos um cliente");
          this.isBusy = true;
          let vm      = new VmWrapper<string[]>({payload: payload, confirmLevel: 0});
          this.gravaFolhaExpedicaoParcialServer(vm)
            .then(r => {
              if (r && r.action) { this.doAction(r.action, r.payload);}
            });
          break;
        }

        default: {
          console.log("Acção desconhecida [folha-expedicao]", action, payload);
          this.app.notificationError("Acção desconhecida");
        }
      }
    } catch (err) {
      this.app.notificationErrorCompact(err);
    }
  }
}
