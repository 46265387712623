//Aurelia Imports
import {autoinject} from "aurelia-framework";
//App Imports
import {Logger} from "aurelia-logging";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {dateISOString} from "../../utils/ItNumeric";
import {DateFilterTemplate, DateFilterTemplateGreaterEqual, identityNumericFilter, IdentityStringFilter} from "../../utils/EjHelper";
import {Router} from "aurelia-router";

@autoinject()
export class FolhasConferencia {
  private logger: Logger;
  private app: GlobalServices;

  private id: number;
  private linhas: any;

  private ejGrid: HTMLDivElement = null;


  // protected planoConferencia: PlanoConferenciaV;

  // get total() {
  //   return this.linhas.reduce((acc, el) => (acc + (el.qtd) ? +el.qtd : 0), 0);
  // }
  //
  // get valor() {
  //   return this.linhas.reduce((acc, el) => (acc + (el.valor) ? +el.valor : 0), 0);
  // }

  constructor(g: GlobalServices,protected router:Router) {
    this.app = g;
  }

  activate() {
    // this.id = +p.id;
    // console.log(p, this.id);
  }

  canDeactivate() {
    try {$(this.ejGrid).ejGrid("destroy");} catch (err) {console.log(err)}
    return true;
  }

  attached() {
    //console.log("activate!");
    this.app.auth.promisedActiveToken()
      .then(token => {
        let dataSource = ej.DataManager({
          //done: injetar a localização do serviço
          url        : environment.endpoint + "api/ejg/FolhaConferencia",
          crossDomain: true,
          //requestType : "post",
          adaptor    : new ej.WebMethodAdaptor(),
          headers    : [{Authorization: `Bearer ${token}`}]
        });

        $(this.ejGrid).ejGrid({
          locale          : "pt-PT",
          dataSource      : dataSource,
          allowPaging     : true,
          pageSettings    : {pageSize: 20},
          allowSorting    : true,
          allowSearching  : true,
          allowFiltering  : true,
          cssClass        : "grid-picker",
          filterSettings  : {
            showFilterBarStatus: true,
            // filterType         : "menu"
          },
          sortSettings  : {sortedColumns: [{field: "dtm_data_expedicao", direction: "descending"}]},
          recordClick     : (args) => {
            // console.log("Click", args);
            let a = <Date> args.data.dtm_data_expedicao;
            this.router.navigate(`${document.location.origin}/#/folha-conferencia/${dateISOString(a)}`);
          },
          //@formatter:off
            columns       : [
{field: "dtm_data_expedicao" , headerText: "Data"           , width:  40  , textAlign: "center" , format: "{0:yyyy-MM-dd}"                   , type: "date"                              , filterBarTemplate: DateFilterTemplateGreaterEqual} ,
{field: "nvc_observacoes"    , headerText: "Observações"    , width:  350 , type:"string"       , filterBarTemplate: IdentityStringFilter }  ,
{field: "totalEnviar"            , headerText: "Total a enviar" , width:  60  , type:"number"       , filterBarTemplate: identityNumericFilter } ,
{field: "totalControlo"          , headerText: "Controlo"       , width:  60  , type:"number"       , filterBarTemplate: identityNumericFilter}  ,
{field: "nvc_estado"         , headerText: "Estado"         , width:  40  , type: "string"      , textAlign: "center"                        , filterBarTemplate: IdentityStringFilter } ,
]
            //@formatter:on
        });
      });
  }

}
