import {bindable} from 'aurelia-framework';

export class MySelect2 {
  @bindable value;
  @bindable placeholder    = "Escolha uma opção";
  @bindable debug: boolean = false;
  @bindable options: any[] = [];
  @bindable withEmpty      = true; //with-empty="false"
  @bindable allowEmpty     = false; //with-empty="false"
  public domElement: HTMLSelectElement;
  public domAnchor: HTMLDivElement;
  private select2: any;
  private parentElement: any;

  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("valueChanged", newValue, oldValue);
    if (newValue != oldValue && this.select2) {
      this.select2.val("" + newValue).trigger("change");
      $(this.domElement).trigger("change")
    }
  }

  // optionsChanged(newValue, oldValue) {
  //   if (this.debug) console.log("optionsChanged", newValue, oldValue);
  //   if(newValue != oldValue) {
  //     this.initSelect2();
  //   }
  // }

  optionsChanged(newValue, oldValue) {
    if (this.debug) console.log("MySelect2", "optionsChanged", this, newValue, oldValue);

    if (this.domElement) {
      //Limpa options anteriores
      $(this.domElement).empty().trigger("change");

      //Adiciona valor vazio
      if (this.withEmpty)
        this.domElement.innerHTML = `<option value="" selected ${this.allowEmpty ? '' : 'disabled'}>${this.placeholder}</option>`;

      //Adiciona novas options
      this.options.forEach(data => {
        let newOption = new Option(data.text, data.id, false, false);
        $(this.domElement).append(newOption);
      });
      $(this.domElement).val("").trigger("change");
    }
  }

  selectedOption() {
    return $(this.domElement).select2("data");
  }

  resetOption() {
    $(this.domElement).val("").trigger("change");
  }

  detached() {
    $(this.domElement).select2("destroy");
  }

  attached() {
    this.initSelect2();
  }

  private initSelect2() {
    if (this.debug) console.log("[MySelect2]", "attached", this);
    this.select2 = $(this.domElement).select2({
      language         : "pt",
      width            : "100%",
      debug            : this.debug,
      data             : this.options,
      dropdownAutoWidth: true,
      //dropdownParent   : this.domAnchor
      //placeholder: this.placeholder
    }).on('select2:select', (e) => {
      let data = e.params.data;
      if (this.debug) console.log("[MySelect2]", "select2:select", e, data);
      this.value = data.id;
      if (this.value == "null") this.value = "";
      this.changeEvent();
      //$(this.domElement).trigger("change");
      //this.select2.trigger("change");
    }).on('select2:close', (e) => {
      // if (this.debug) console.log("[MySelect2]", "select2:close", e, this.value);
      // if (!this.value) this.value = "";
      // this.changeEvent();
      //$(this.domElement).trigger("change");
    });

    //inicialização do valor do select
    if (this.value === 0) {
      if (this.debug) console.log("[MySelect2]", "inicialização a ZERO", this.value);
      this.select2.val("").trigger("change");
    } else if (this.value || this.value === false || this.value === 0) {
      if (this.debug) console.log("[MySelect2]", "inicialização", this.value);
      this.select2.val("" + this.value).trigger("change");
    }
    if (this.debug) console.log("[MySelect2]", "attached", this.select2);

  }

  private changeEvent() {
    if (this.debug) console.log("[MySelect2]", "changeEvent");
    // https://stackoverflow.com/a/2856602
    if (this.parentElement.onchange) {
      if (this.debug) console.log("[MySelect2]", "direct call to onchange");
      this.parentElement.onchange(new Event("change"));
      return;
    }
    if ("createEvent" in document) {
      if (this.debug) console.log("[MySelect2]", "event creation to onchange");
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("change", true, true);
      this.parentElement.dispatchEvent(evt);
    }
    else
      (this.parentElement as any).fireEvent("onchange");
  }
}
