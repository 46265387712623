import {BaseViewModel} from "./BaseViewModel";
import {GuiaTransporteLinha} from "./GuiaTransporteLinha";
import {Api} from "../services/api";
import {GlobalServices} from "../services/global-services";
import {VmWrapper} from "./VmWrapper";
import {ConfirmacaoDialog} from "../dialogs/confirmacao-dialog";
import {estadosGuiaTransporte} from "./EstadoGuiaTransporte";
import {ValidationRules} from "aurelia-validation";
import {UtilizadorPermissao} from "./UtilizadorPermissao";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class Utilizador extends BaseViewModel {
  idUtilizador: number            = 0;
  nvcUsername: string             = "";
  nvcEmail: string                = "";
  nvcOldPassword: string          = "";
  nvcPassword: string             = "";
  nvcPasswordConfirmation: string = "";
  nvcPerfil: string               = "";
  bitActivo: boolean              = false;
  bitSuper: boolean               = false;
  timestamp: number               = 0;

  //relações
  private UtilizadorPermissao: UtilizadorPermissao[] = [];

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Utilizador>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): Utilizador {
    let model = new Utilizador();
    model.setState(obj);
    model.__index = index;
    if (obj.utilizadorPermissao && Array.isArray(obj.utilizadorPermissao)) {
      model.UtilizadorPermissao = UtilizadorPermissao.multipleFromPOJSO(obj.utilizadorPermissao);
    }

    return model;
  }

  public static multipleFromPOJSO(objs: any): Utilizador[] {
    if (objs) {
      if (Array.isArray(objs)) return objs.map((el, i) => Utilizador.fromPOJSO(el, i));
      return [Utilizador.fromPOJSO(objs, 0)];
    }
    return [];
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  public cloneInstance(): Utilizador {
    console.log("clone");
    return Utilizador.fromPOJSO(this.stateToPOJSO());
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<RegistoProducao>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<Utilizador>({payload: this, confirmLevel: (+cl || 0)});
  }

  //endregion

  public getPermissoesAtivas():UtilizadorPermissao[]{
    return this.UtilizadorPermissao.filter(el => el.bitPermissao);
  }

  toString = () => `Utilizador ${this.nvcUsername} (# ${this.idUtilizador})`;
}

ValidationRules.customRule(
  'matchesProperty',
  (value, obj, otherPropertyName) =>
  value === null
  || value === undefined
  || value === ''
  || obj[otherPropertyName] === null
  || obj[otherPropertyName] === undefined
  || obj[otherPropertyName] === ''
  || value === obj[otherPropertyName],
  'Ambos os campos de password devem coincidir', otherPropertyName => ({otherPropertyName})
);

ValidationRules
  .ensure((m: Utilizador) => m.nvcUsername).displayName("nome de utilizador").required().withMessage(`\${$displayName} é obrigatório`)
  .ensure((m: Utilizador) => m.nvcEmail).displayName("email").required().withMessage(`\${$displayName} é obrigatório`).email().withMessage(`\${$displayName} não é um email válido`)

  .ensure((m: Utilizador) => m.nvcPassword).displayName("palavra chave")
  .required().when(m => m.idUtilizador === 0).withMessage(`\${$displayName} é obrigatório`)
  .ensure((m: Utilizador) => m.nvcPasswordConfirmation).displayName("confirmação da palavra chave")
  .required().when(m => m.idUtilizador === 0).withMessage(`\${$displayName} é obrigatório`).satisfiesRule('matchesProperty', 'nvcPassword')
  .on(Utilizador);

