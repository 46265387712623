import {bindable} from 'aurelia-framework';
import {Operador} from "../../models/Operador";
import {OperadorDerivation} from "../../models/BaseViewModelDerivations";

export class SelectOperador {
  private debug: boolean           = false;

  //nome do operador selecionado
  private nome: string             = null;

  //codigo do operador selecionado
  private idOperador: number       = null;

  //lista de operadores associada ao utilizador
  @bindable operadores: Operador[] = [];

  //operador selecionado
  @bindable operador: Operador;

  /**
   * referência à acção executada no click de um botão selecionado
   *
   * A função existente no viewModel deve seguir a seguinte assinatura.
   * doAction (action:string, payload?:any)
   *
   */
  @bindable parentAction: any;

  /**
   * função que transfere o operador para ...
   * executa, internamente na seleção e deseleção
   */
  @bindable transferTo:OperadorDerivation;

  MENU                   = 0;
  SELECTED               = 1;
  INLINE                 = 2;

  // dados para aceitar um operador inline
  nomeTemp: string       = null;
  idOperadorTemp: number = null;

  private internalState = 0;

  // nomeChanged(newNome, oldNome) {
  //   if (this.debug) console.log("SelectOperador", "nomeChanged", this, newNome, oldNome);
  // }
  //
  operadorChanged(newValue, oldValue) {
    if (this.debug) console.log("SelectOperador", "operadorChanged", this, newValue, oldValue);
    if (newValue) {
      this.nome          = this.operador.nvcNome || this.operador.nvcNomeOperadorTemp;
      this.idOperador    = this.operador.idOperador || this.operador.idOperadorTemp;
      this.internalState = this.SELECTED;
    }
  }

  attached() {
    if (this.debug) console.log("SelectOperador", "attached", this);
  }

  resetSelection() {
    this.nome          = null;
    this.idOperador    = null;
    this.operador      = null;
    this.internalState = this.MENU;
    this.transferTo && (this.transferTo.Operador = null);
  }

  select(operador: Operador) {
    if (this.debug) console.log("SelectOperador", "select", operador);

    this.operador      = operador;
    this.transferTo && (this.transferTo.Operador = operador);
    // this.nome          = operador.nvcNome || operador.nvcNomeOperadorTemp;
    // this.idOperador    = operador.idOperador || operador.idOperadorTemp;
    // this.internalState = this.SELECTED;
  }

  selectTemp() {
    if (this.debug) console.log("SelectOperador", "selectTemp", this.nomeTemp, this.idOperadorTemp);
    //seleciona uma instância temporária
    this.select(new Operador({idOperadorTemp: this.idOperadorTemp, nvcNomeOperadorTemp: this.nomeTemp}));
    //reset dos dados temporários
    this.nomeTemp       = null;
    this.idOperadorTemp = null;
  }

  makeTemp() {
    if (this.debug) console.log("SelectOperador", "makeTemp");
    this.nome          = null;
    this.idOperador    = null;
    this.internalState = this.INLINE;
  }

  cancelTemp() {
    if (this.debug) console.log("SelectOperador", "cancelTemp");
    this.nomeTemp       = null;
    this.idOperadorTemp = null;
    this.internalState  = this.MENU;
  }
}
