import {autoinject} from "aurelia-framework";
import {Router, RouterConfiguration} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {artigoSubRoutes} from "../../app-routes";
import environment from "../../environment";

@autoinject()
export class ArtigoComponent {
  public app: GlobalServices;
  private childRouter: Router;
  public activeSubRoute:string;

  constructor(g: GlobalServices) { this.app = g;}

  //region Acções
  canActivate(p) {
    if (environment.debug) console.log("[analise-component]", "canActivate");
    let oneOf = this.app.auth.oneOf(["App.ArtigosVer", "App.FamiliasVer"]);
    if (!oneOf) this.app.notificationErrorCompact("O utilizador não dispõe de permissões para aceder às listagens de artigos registados na aplicação.");
    return (oneOf);
  }

  //endregion

  //configuração do childRouter
  configureRouter(config: RouterConfiguration, router: Router) {
    //config.title = 'Análise';
    router.reset();
    config.map(artigoSubRoutes);
    this.childRouter = router;

    if (environment.debug) console.log("[analise-component]", "configureRouter", "child", router);
  }
}
