/**
 * Created by hernani on 2017-06-20.
 */

export class NumberStringValueConverter {
  toView(value) {
    // console.log("NumberStringValueConverter", value);
    return "" + value;
  }

  fromView(value) {
    // let exp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
    //     result = exp.exec(hex);
    //
    return +value || 0;
  }
}