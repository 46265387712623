import {BaseViewModel} from "./BaseViewModel";
import {FolhaExpedicaoLinha} from "./FolhaExpedicaoLinha";
import {Api} from "../services/api";
import {VmWrapper} from "./VmWrapper";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class FolhaExpedicao extends BaseViewModel {

  public idFolhaExpedicao: number = 0;
  public dtmDataExpedicao: string = "";
  public _dtmDataExpedicao: Date  = null;
  public nvcObservacoes: string   = "";
  public nvcCabecalho: string     = "";
  public timestamp: string        = "";

  //relações
  public FolhaExpedicaoLinha: FolhaExpedicaoLinha[] = [];

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<FolhaExpedicao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): FolhaExpedicao {
    let model = new FolhaExpedicao();
    model.setState(obj);
    if(model.dtmDataExpedicao) model._dtmDataExpedicao = new Date(model.dtmDataExpedicao);
    //relações
    if (obj.folhaExpedicaoLinha && Array.isArray(obj.folhaExpedicaoLinha) && obj.folhaExpedicaoLinha.length > 0) {
      model.FolhaExpedicaoLinha = FolhaExpedicaoLinha.multipleFromPOJSO(obj.folhaExpedicaoLinha);
    }
    model.__index = index;

    return model;
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    if (this.FolhaExpedicaoLinha && Array.isArray(this.FolhaExpedicaoLinha) && this.FolhaExpedicaoLinha.length > 0) {
      ret.folhaExpedicaoLinha = this.FolhaExpedicaoLinha.map(e => e.stateToPOJSO());
    }
    return ret;
  }

  public cloneInstance(): FolhaExpedicao {
    return FolhaExpedicao.fromPOJSO(this.stateToPOJSO());
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<GuiaTransporte>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<FolhaExpedicao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public load(dia: Date, api:Api){

  }

  public save(api: Api) {
    return api
      .post("api/armazem/add-folha-expedicao", this.stateToPOJSO())
      .then(r => api.processResponse(r))
      .then(FolhaExpedicao.fromPOJSO)
      ;
  }
  //endregion

//region op

  public getLinhasTipo(tipo:number){
    switch (tipo){
      case 1: return this.FolhaExpedicaoLinha.filter(el => el.nvcObservacoes != 'Falta');
      case 2: return this.FolhaExpedicaoLinha.filter(el => el.nvcObservacoes == 'Falta');
    }
    return this.FolhaExpedicaoLinha;
  }
//endregion

}
