/**
 * v 2.00 (2019-05-18) versionamento
 */

//region post
/**
 * One-liner para envolver qualquer coisa que tenha acesso ao `global-services` e executar uma acção(POST) passível de confirmação
 * v 2
 * @param something -  um viewmodel / dialog, básicamente qualquer coisa com acesso ao global-services com o nome app
 * @param modelo - um Modelo de domínio
 * @param {string} route - o endereço para onde fazer o POST
 * @return {Promise<boolean|any>} o payload da resposta ou a resposta (se não existir a chave payload) se correu tudo bem, false se houve cócó
 */
export const confirmaActionTyped = (something, modelo, route: string) =>
  something.app.confirmaActionTyped(modelo.wrapIt(), route)
    .then(r => {
      if (something.app.processConfirmation(r)) {
        return r.payload || r;
      }
      return false;
    })
    .catch(err => something.app.notificationErrorCompact(err))
    .then(r => (something.isBusy = false) || r);

/**
 * One-liner para envolver qualquer coisa que tenha acesso ao `global-services` e executar uma acção(POST) passível de confirmação
 * Semelhante à versão anterior mas opera sobre qualquer conjunto de dados não formalizado em Classe.
 *
 * @param something -  um viewmodel / dialog, básicamente qualquer coisa com acesso ao global-services com o nome app
 * @param modelo VmWrapper genérico
 * @param {string} route - o endereço para onde fazer o POST
 * @param signal - se a API ativa automáticamente o pageLoadingFrame
 * @return {Promise<boolean|any>} o payload da resposta ou a resposta (se não existir a chave payload) se correu tudo bem, false se houve cócó
 */
export const confirmaActionTypedVmw = (something, modelo, route: string, signal:boolean = true) =>
  something.app.confirmaActionTyped(modelo, route, signal)
    .then(r => {
      if (something.app.processConfirmation(r)) {
        return r.payload || r;
      }
      return false;
    })
    .catch(err => something.app.notificationErrorCompact(err))
    .then(r => (something.isBusy = false) || r);

/**
 * One-liner para envolver qualquer coisa que tenha acesso ao `global-services` e executar uma acção(POST) passível de confirmação
 *
 * v 1
 * 2019-03-25
 *
 * @param something -  um viewmodel / dialog, básicamente qualquer coisa com acesso ao global-services com o nome app
 * @param modelo - um Modelo de domínio
 * @param {string} route - o endereço para onde fazer o POST
 * @param uploadConfirmationLevel - o nível de confirmação a partir do qual os ficheiros são enviados com o POST
 * @return {Promise<boolean|any>} o payload da resposta ou a resposta (se não existir a chave payload) se correu tudo bem, false se houve cócó
 */
export const confirmaActionTypedMpfd = (something, modelo, route: string, uploadConfirmationLevel = 1) => {
  if (typeof (modelo as any).mpfd !== "function") {throw new Error("O modelo não dispõe de um método para geração de payload multipart/form-data 'mpfd()'.")}
  return something.app.confirmaMpFdActionTyped(modelo.mpfd(), route, uploadConfirmationLevel)
    .then(r => {
      if (something.app.processConfirmation(r)) {
        return r.payload || r;
      }
      return false;
    })
    .catch(err => something.app.notificationErrorCompact(err))
    .then(r => (something.isBusy = false) || r);
};

//endregion post
//region delete
/**
 * One-liner para envolver qualquer coisa que tenha acesso ao `global-services` e executar um DELETE passível de confirmação
 * v 2
 * @param something -  um viewmodel / dialog, básicamente qualquer coisa com acesso ao global-services com o nome app
 * @param modelo - um Modelo de domínio
 * @param {string} route - o endereço para onde fazer o POST
 * @return {Promise<boolean|any>} o payload da resposta ou a resposta (se não existir a chave payload) se correu tudo bem, false se houve cócó
 */
export const confirmaDeletionTyped = (something, modelo, route: string) =>
  something.app.confirmaDeletionTyped(modelo.wrapIt(), route)
    .then(r => {
      if (something.app.processConfirmation(r)) {
        return r.payload || r;
      }
      return false;
    })
    .catch(err => something.app.notificationErrorCompact(err))
    .then(r => (something.isBusy = false) || r);

/**
 * One-liner para envolver qualquer coisa que tenha acesso ao `global-services` e executar um DELETE passível de confirmação
 * @param something -  um viewmodel / dialog, básicamente qualquer coisa com acesso ao global-services com o nome app
 * @param modelo VmWrapper Genérico
 * @param {string} route - o endereço para onde fazer o POST
 * @param signal
 * @return {Promise<boolean|any>} o payload da resposta ou a resposta (se não existir a chave payload) se correu tudo bem, false se houve cócó
 */
export const confirmaDeletionTypedVmw = (something, modelo, route: string, signal:boolean = true) =>
  something.app.confirmaDeletionTyped(modelo, route, signal)
    .then(r => {
      if (something.app.processConfirmation(r)) {
        return r.payload || r;
      }
      return false;
    })
    .catch(err => something.app.notificationErrorCompact(err))
    .then(r => (something.isBusy = false) || r);

//endregion delete
