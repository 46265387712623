/**
 * Created by hernani on 2017-04-27.
 * https://github.com/axwalker/aurelia-observer-patterns/wiki/Observer-patterns-in-Aurelia
 * Não sei se isto não vai complicar o processo
 * 
 * Acabou por não ser usado
 */
export class Signal<T> {
  handlers: ((T) => any)[] = [];

  add(f: (t: T) => any) {
    this.handlers.push(f);
  }

  dispatch(t: T) {
    this.handlers.forEach(f => f(t));
  }
}
