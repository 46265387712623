import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../../../services/global-services";
import environment from "../../../environment";
import {RegistoEtiqueta} from "../../../models/RegistoEtiqueta";
import {confirmaActionTyped} from "../../../services/api-envelopes";
import {MoviemntoArmazemExpedicao} from "../../../models/MoviemntoArmazemExpedicao";

@autoinject()
export class EntradaEtiquetasDialog {
  protected isBusy: boolean = false;
  protected app: GlobalServices;

  protected refInicial: number;
  protected buffer: string = "";

  protected inputManual: string = "";
  protected inputElement: HTMLInputElement;

  protected entrada: RegistoEtiqueta[] = [];
  protected saida: RegistoEtiqueta[]   = [];
  protected resto: RegistoEtiqueta[]   = [];

  constructor(protected controller: DialogController) {}

  canActivate(p: any) {
    if (!p) {
      console.error("EntradaEtiquetasDialog", "Não foram passados parâmetros de entrada.");
    }
    this.app        = p.app;
    this.refInicial = p.refInicial;
    return true;
  }

  activate() {
    if (environment.debug) console.log("[entrada-etiquetas-dialog]", "activate", this);

    if (this.refInicial > 0) {
      this.goFetch(this.refInicial);
    }
  }

  attached() {
    this.registerListeners();
  }

  unbind() {
    if (environment.debug) console.log("[entrada-etiquetas-dialog]", "unbind");
    document.onkeyup = null;

    this.inputElement && (this.inputElement.onkeyup = null);
  }

  //region diversos
  goFetch(id: number) {
    if (this.entrada.findIndex(el => el.idRegistoEtiqueta == id) >= 0) {
      this.app.notificationWarning("A etiqueta já está marcada para entrada.");
      return Promise.resolve(false);
    }
    if (this.saida.findIndex(el => el.idRegistoEtiqueta == id) >= 0) {
      this.app.notificationWarning("A etiqueta já está marcada para saída.");
      return Promise.resolve(false);
    }
    if (this.resto.findIndex(el => el.idRegistoEtiqueta == id) >= 0) {
      this.app.notificationWarning("Sabe-se que a etiqueta não é elegivel para transição no armazém de expedição.");
      return Promise.resolve(false);
    }

    return this.app.api.getProcessed('api/expedicao/etiqueta', {id: id})
      .then(r => RegistoEtiqueta.fromPOJSO(r))
      .then(re => {
        if (re.estado == "activo") {
          this.entrada.push(re);
        } else if (re.estado == "entrada") {
          this.saida.push(re);
        } else {
          this.app.notificationWarning("A etiqueta não é elegível para entrada ou saída de armazém.");
          this.resto.push(re);
        }

        return Promise.resolve(true);
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }

  /**
   * Remove linhas das listas temporárias
   * @param {"entrada" | "saida"} onde
   * @param {RegistoEtiqueta} etiqueta
   */
  private remover(onde: "entrada" | "saida", etiqueta: RegistoEtiqueta) {
    if (onde == "entrada") {
      let posicao = this.entrada.findIndex(el => el.idRegistoEtiqueta == etiqueta.idRegistoEtiqueta);
      if (posicao >= 0) this.entrada.splice(posicao, 1);
      //this.entrada = [...this.entrada];
      //this.entrada.sl
    } else {
      let posicao = this.saida.findIndex(el => el.idRegistoEtiqueta == etiqueta.idRegistoEtiqueta);
      if (posicao >= 0) this.saida.splice(posicao, 1);
    }
  }

  /**
   * Adiciona uma linha (reg. Etiqueta) manualmente, pelo seu número (ID)
   */
  private adicionaManual() {
    let id           = this.inputManual && this.inputManual.replace(/\D/g, '');
    this.inputManual = "";
    if (+id > 0) {
      this.goFetch(+id)
        .catch(err => this.app.notificationErrorCompact(err))
        .then(_ => this.inputElement.focus());
    }
  }

  /**
   * Regista as rotinas de tratamento de eventos de input genéricas, para lidar com códigos de barras da família (RE*9999999999)
   */
  private registerListeners() {
    document.onkeyup = (e) => {
      if (environment.debug) console.log("[nova-expedicao]", "onkeyup", e);

      //ignora-se o input oriundo de inputs e botões
      if (!["button", "input"].includes(e.srcElement.localName)) {

        const regex = /^[a-z0-9]$/i;
        if (e.key == "Enter") {
          // console.log("[nova-expedicao]", "onkeyup ENTER", e);
          //console.log("[nova-expedicao]", "onkeyup ENTER", this.buffer);
          if (environment.debug) console.log("[nova-expedicao]", "Enter", this.buffer);
          if (/^re/i.test(this.buffer)) {
            this.buffer && this.goFetch(+(this.buffer.replace(/\D/g, '')));
          } else {
            this.app.notificationErrorCompact("ERRO NA LEITURA DE CÓDIGO DE BARRAS<br> Apenas devem ser lidas etiquetas de controlo / gravação nesta página.");
          }
          this.buffer = "";
        }

        if (regex.test(e.key))
          this.buffer = this.buffer + e.key;
      } else {
        if (e.key == "Shift" && e.srcElement.localName == "button") {
          let ae = document.activeElement;
          (ae as any).blur();
        }
        if (e.key == "Enter") {
          let ae = document.activeElement;
          (ae as any).blur();
          this.buffer = "";
        }
      }
    };

    this.inputElement.onkeyup = (e) => {
      if (e.key == "Enter") {
        this.adicionaManual();
      }
    }
  }

  /**
   * Fecha o poupup
   */
  private cancelar() {
    this.controller.cancel();
  }

  /**
   * Submete as listas temporárias para entrada/saída
   */
  private submeter() {
    if (this.entrada.length == 0 && this.saida.length == 0) {
      this.app.notificationErrorCompact("Não há elementos para submissão. Adicione etiquetas lendo os seus códigos de barras ou inserindo o seu número manualmente.");
      return Promise.resolve(false);
    }
    let modelo = new MoviemntoArmazemExpedicao({entradas: this.entrada, saidas: this.saida});
    confirmaActionTyped(this, modelo, 'api/expedicao/regista-movimento-armazem')
      .then(r => {
        if (environment.debug) console.log("[entrada-etiquetas-dialog]", "resposta submeter", r);
        this.controller.ok(r);
      });
  }

  //endregion
}
