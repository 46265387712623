import {autoinject, bindable, bindingMode} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import {Api} from "../../services/api";
//monkey patch!!!
//https://github.com/flatpickr/flatpickr/issues/1102
import * as _flatpickr from 'flatpickr';

const flatpickr: any = (<any>_flatpickr).default || _flatpickr;

/**
 * Um CE com choices de base que se comporta como o select2 mas bem.
 */
@autoinject()
export class TimePickr {
  @bindable debug: boolean                                          = true;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string = "";
  @bindable timePicker: boolean                                     = false;
  @bindable config: _flatpickr.default.Options.Options              = {
    enableTime: true,
    enableSeconds:true,
    noCalendar: true,
    dateFormat: "H:i:S",
    time_24hr: true
  };

  //o elemento HTML select(multiple)
  private inputElem: HTMLInputElement = null;

  //  Cache otimista para respostas do servidor
  private responseCache: any = {};
  private instance: _flatpickr.default.Instance;
  //
  private timeConfig = {

  };

  constructor(private app: GlobalServices, private api: Api) {}

  attached() {
    if (this.debug) console.log("[time-pickr]", "attached");
    this.instance = flatpickr(this.inputElem, this.config);
  }

  //o value é um array de strings com as keys das opções selecionadas muda em duas ocasiões
  //1. inicialização do plugin
  //2. escolha de um elemento
  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("[time-pickr]", "valueChanged", newValue, oldValue);
    if (newValue) {
      if (!this.instance) {
        if (this.debug) console.log("[time-pickr]", "valueChanged", "inicialização do plugin");
      } else {
        if (this.debug) console.log("[time-pickr]", "valueChanged", "escolha de um elemento");
        this.instance.setDate(newValue);
      }
    }
  }
}
