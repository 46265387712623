//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import environment from "../environment";
import {GlobalServices} from "../services/global-services";
import {UtilizadorPermissao} from "../models/UtilizadorPermissao";
import {RemoteGridActions} from "../it-features/remote-grid/interfaces";
import {Utilizador} from "../models/Utilizador";
import {RemoteGrid} from "../it-features/remote-grid/remote-grid";
import {UtilizadorClienteArmazem} from "../models/UtilizadorClienteArmazem";
import {PickerRemoteGrid} from "../it-features/remote-grid/picker-remote-grid";
import {OperadorDialog} from "../dialogs/operador-dialog";
import {Operador} from "../models/Operador";
import {UtilizadorOperador} from "../models/UtilizadorOperador";
import {confirmaDeletionTyped} from "../services/api-envelopes";
import {UtilizadorDialog} from "../dialogs/utilizador-dialog";
import {UtilizadorPasswordDialog} from "../dialogs/utilizador-password-dialog";

@autoinject()
export class planoComponent {
  public app: GlobalServices;
  //private utilizadorSelecionado: number = 0;
  private modelo: Utilizador;
  private modeloOriginal: Utilizador;

  private isBusy: boolean = false;

  private rg: RemoteGrid;
  private rgPermissao: RemoteGrid;
  private rgPlano: RemoteGrid;
  private rgOperador: RemoteGrid;

  @computedFrom("modelo")
  public get utilizadorEmEdicao(): boolean {
    return this.modelo && (this.modelo.idUtilizador > 0);
  }

  constructor(g: GlobalServices) { this.app = g;}

  canActivate() {
    if (!this.app.auth.can("App.VerUtilizadores")) {
      this.app.notificationError("Não dispõe da permissao para visualizar os utilizadores");
      return false;
    }
    return true
  }

  // canDeactivate() {
  //   this.cleanup();
  //   return true;
  // }

  // cleanup() {
  //   try {
  //     if ($("#GridUtilizadores").hasClass("ej-grid"))
  //       $("#GridUtilizadores").ejGrid("destroy");
  //   } catch (err) {console.log("canDeactivate", err)}
  // }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  showUtilizadorDialog(id: number) {
    //let init: PickerProximoPrgPayload = {nvc_cliente_armazem: this.clienteArmazem.nvcClienteArmazem, disponiveis: '0'};
    this.app.ds
      .open({viewModel: UtilizadorDialog, model: {id: id}, centerHorizontalOnly: true, rejectOnCancel: false, lock: true})
      .whenClosed(response => {
        this.rg.refreshPage();
        this.isBusy = false;
      });
  }

  /**
   * cria instancia do Utilizador
   * @param obj
   */
  private parse(obj: any) {
    this.modelo         = Utilizador.fromPOJSO(obj);
    this.modeloOriginal = Utilizador.fromPOJSO(obj);
    //this.utilizadorSelecionado = this.modelo.idUtilizador;
  }

  public doActionPermissao(action: string, payload?: any) {
    //if (environment.debug) console.log("[configuracoes]", "{doActionPermissao}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case RemoteGridActions.RG_CELL_CHANGE: {
          let utilizadorPermissao = UtilizadorPermissao.fromPOJSO(payload.rowRef);
          //garante que há um id de utilizador no modelo que se envia
          if (!utilizadorPermissao.idUtilizador) utilizadorPermissao.idUtilizador = this.modelo.idUtilizador;
          return this.app.confirmaActionTyped(utilizadorPermissao.wrapIt(), 'api/auth/set-permissao')
            .then(r => {
              if (this.app.processConfirmation(r)) {
                this.app.notificationSuccess(`Permissão de ${this.modelo} atualizada`);
                this.rgPermissao.commit(payload);
              } else {
                this.rgPermissao.rollback(payload);
              }
            })
            .catch(err => {
              this.rgPermissao.rollback(payload);
              this.app.notificationErrorCompact(err)
            })
            .then(_ => this.isBusy = false);
        }
        default: {
          this.isBusy = false;
        }
      }
    } catch (err) {
      this.isBusy = this.app.notificationErrorCompact(err);
    }
  }

  public doActionPlano(action: string, payload?: any) {
    // if (environment.debug) console.log("[configuracoes]", "{doActionPermissao}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case RemoteGridActions.RG_CELL_CHANGE: {
          let utilizadorClienteArmazem = UtilizadorClienteArmazem.fromPOJSO(payload.rowRef);
          //garante que há um id de utilizador no modelo que se envia
          if (!utilizadorClienteArmazem.idUtilizador) utilizadorClienteArmazem.idUtilizador = this.modelo.idUtilizador;
          return this.app.confirmaActionTyped(utilizadorClienteArmazem.wrapIt(), 'api/auth/set-plano')
            .then(r => {
              if (this.app.processConfirmation(r)) {
                this.app.notificationSuccess(`Acesso de ${this.modelo} ao plano ${utilizadorClienteArmazem.nvcClienteArmazem} atualizado.`);
                // this.rgPlano.commit(payload);
                this.rgPlano.refreshPage();
              } else {
                this.rgPlano.rollback(payload);
              }
            })
            .catch(err => {
              this.rgPlano.rollback(payload);
              this.app.notificationErrorCompact(err)
            })
            .then(_ => this.isBusy = false);
        }
        default: {
          // if (environment.debug) console.error("[configuracoes]", "Acção DESCONHECIDA [configuracoes]", action);
          this.isBusy = false;
        }
      }
    } catch (err) {
      this.isBusy = this.app.notificationErrorCompact(err);
    }
  }

  public doActionOperador(action: string, payload?: any) {
    if(environment.debug) console.log("[configuracoes]","doActionOperador", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case RemoteGridActions.RG_CELL_CHANGE: {

          //Se a edição aconteceu no bitActivo faz toggle à associação com o operador
          if(payload.field == "bitActivo")
            return this.toggleOperador(payload);

          //Noutro lado upsert
          let operador = Operador.fromPOJSO(payload.rowRef);
          return this.app.confirmaActionTyped(operador.wrapIt(), 'api/auth/cria-operador')
            .then(r => {
              if (this.app.processConfirmation(r)) {
                this.app.notificationSuccess(`Os dados do ${operador} foram atualizados.`);
              }
            })
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false)
            .then(_ => this.rgOperador.refreshPage());
        }
        default: {
          this.isBusy = false;
        }
      }
    } catch (err) {
      this.isBusy = this.app.notificationErrorCompact(err);
    }
  }

  public toggleOperador(payload: any) {
    let utilizadorOperador = UtilizadorOperador.fromPOJSO(payload.rowRef);

    //garante que há um id de utilizador no modelo que se envia
    if (!utilizadorOperador.idUtilizador) utilizadorOperador.idUtilizador = this.modelo.idUtilizador;
    if (environment.debug) console.log("[configuracoes]", "doActionOperador", utilizadorOperador);
    return this.app.confirmaActionTyped(utilizadorOperador.wrapIt(), 'api/auth/set-operador')
      .then(r => {
        if (this.app.processConfirmation(r)) {
          this.app.notificationSuccess(`A associação do operador com o ${this.modelo} foi atualizada.`);
        }
      })
      .catch(err => this.app.notificationErrorCompact(err))
      .then(_ => this.isBusy = false)
      .then(_ => this.rgOperador.refreshPage());
  }

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[configuracoes]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          break;
        }
        case "ADICIONA-UTILIZADOR": {
          this.showUtilizadorDialog(0);
          break;
        }
        case "ASSOCIA-OPERADOR": {
          this.app.ds.open({viewModel: PickerRemoteGrid, model: {codigoTabela: "OperadorPicker?p1=" + this.modelo.idUtilizador}, rejectOnCancel: false})
            .whenClosed(r => {
              if (!r.wasCancelled) {
                if (environment.debug) console.log("[configuracoes]", "Resultado Picker", r);
                return this.toggleOperador(r.output);
              }
              return Promise.resolve("false");
            })
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false);
          break;
        }
        case "ADICIONA-OPERADOR": {
          this.app.ds.open({viewModel: OperadorDialog, model: {model: new Operador(), app: this.app}})
            .whenClosed(r => {
              if (!r.wasCancelled) {
                if (environment.debug) console.log("[configuracoes]", "Resultado", r);
                //this.rgOperador.refreshPage();
                if (this.modelo.idUtilizador > 0)
                  this.doAction("ASSOCIA-OPERADOR");
              }
            })
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false);
          break;
        }
        case "DESASSOCIA-PLANOS": {
          return confirmaDeletionTyped(this, this.modelo, 'api/auth/unset-planos')
            .then(r => {
              if(environment.debug) console.log("[configuracoes]","DESASSOCIA-PLANOS resposta", r);
              if (r) {
                this.app.notificationSuccess(`Dados de ${this.modelo} atualizados`);
                this.rgPlano.refreshPage();
              }
            })
        }
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[configuracoes]", "RG_CELL_CHANGE", payload);
          this.parse(payload.rowRef);
          return this.app.confirmaActionTyped(this.modelo.wrapIt(), 'api/auth/cria-utilizador')
            .then(r => {
              if (this.app.processConfirmation(r)) {
                this.app.notificationSuccess(`Dados de ${this.modelo} atualizados`);
                this.parse(r.payload);
                this.rg.commit(payload);
              } else {
                this.rg.rollback(payload);
              }
            })
            .catch(err => {
              this.rg.rollback(payload);
              this.app.notificationErrorCompact(err)
            })
            .then(_ => this.isBusy = false);
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[configuracoes]", "RG_ROW_CLICK", payload);
          this.parse(payload.rowRef);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          if (environment.debug) console.log("[configuracoes]", "RG_ROW_DBL_CLICK", payload);
          //this.parse(payload.rowRef);

          // this.showUtilizadorDialog(this.modelo.idUtilizador);

          // this.app.ds.open({viewModel: OperadorDialog, model: {model: this.modelo, app: this.app}})
          //   .whenClosed(r => {
          //     if (!r.wasCancelled) {
          //       if (environment.debug) console.log("[configuracoes]", "Resultado", r);
          //       //this.rgOperador.refreshPage();
          //       if (this.modelo.idUtilizador > 0)
          //         this.doAction("ASSOCIA-OPERADOR");
          //     }
          //   })
          //   .catch(err => this.app.notificationErrorCompact(err))
          //   .then(_ => this.isBusy = false);

          this.app.ds
            .open({viewModel: UtilizadorPasswordDialog, model: {utilizador: this.modelo, confirma: false}, centerHorizontalOnly: false})
            .whenClosed(response => {
              if (!response.wasCancelled) {
                this.app.notificationShort("Palavra chave alterada");
                this.app.refresh();
              }
            });
          //
          // this.isBusy = false;
          break;
        }
        default: {
          if (environment.debug) console.error("[configuracoes]", "Acção DESCONHECIDA [configuracoes]", action);
          this.isBusy = false;
        }
      }
    } catch (err) {
      this.isBusy = this.app.notificationErrorCompact(err);
    }
  }
}
