// Auto-gerado com typewriter

import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {Api} from "../services/api";
import environment from "../environment";

export class Operador extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idOperador: number              = null;
  public nvcNome: string                 = null;
  public nvcNomeCompleto: string         = null;
  public nvcPin: string                  = null;
  public nvcSalt: string                 = null;
  public nvcHash: string                 = null;
  public nvcEmail: string                = null;
  public bitActivo: boolean              = false;
  public bitUsaAutenticacao: boolean     = false;
  public nvcOldPassword: string          = "";
  public nvcPassword: string             = "";
  public nvcPasswordConfirmation: string = "";
  // public idUtilizadorI: number                            = 0;
  // public idUtilizadorA: number                            = 0;
  // public dtmDataI: string                                 = null;
  // public _dtmDataI: Date                                  = new Date();
  // public dtmDataA: string                                 = null;
  // public _dtmDataA: Date                                  = new Date();
  // public timestamp: number[]                              = [];

  // public documentoControlo: DocumentoControlo[]           = [];
  // public documentoControloLinha: DocumentoControloLinha[] = [];
  // public registo: Registo[]                               = [];
  // public utilizadorOperador: UtilizadorOperador[]         = [];
  // public idUtilizadorANavigation: Utilizador              = null;
  // public idUtilizadorINavigation: Utilizador              = null;

  //extra
  public idOperadorTemp: number = null;
  public nvcNomeOperadorTemp: string = null;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Operador>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  static getClassName() { return 'POCMeioPagamento'; }

  // getClassName(){ return Operador.getClassName(); }

  public static fromPOJSO(obj: any): Operador {
    let model = new Operador();
    model.setState(obj);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): Operador[] {
    if (objs && Array.isArray(objs)) return objs.map(Operador.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  /**
   * Memoizing dos operadores associados ao utilizador atual
   * @param {Api} api
   * @param {boolean} rebuild
   * @returns {Promise<Operador[]>}
   */
  public static memoizeMultiple(api: Api, rebuild = false) {
    if (environment.debug) console.log("[operador]", "memoizeMultiple");
    if (sessionStorage.getItem(this.getClassName()) === null || rebuild) {
      return api.getProcessed("api/auth/operadores-associados")
        .then(r => {
          sessionStorage.setItem(this.getClassName(), JSON.stringify(r));
          return this.multipleFromPOJSO(<any>r)
        });
    }
    return Promise.resolve(this.multipleFromPOJSO(JSON.parse(sessionStorage.getItem(this.getClassName()))));
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Operador>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): Operador { return Operador.fromPOJSO(this.stateToPOJSO());}

  //endregion

  //region métodos próprios
  public copiaNome() {
    if (!this.nvcNomeCompleto) { this.nvcNomeCompleto = this.nvcNome;}
  }

  //endregion métodos próprios

  public toString() {
    if(+this.idOperador > 0)
      return `Op# ${this.idOperador} - ${this.nvcNome}`;
    if(+this.idOperadorTemp > 0 || !!this.nvcNomeOperadorTemp)
      return `Op(N.R.)# ${this.idOperadorTemp} - ${this.nvcNomeOperadorTemp}`;
    return `Operador Desconhecido na Base de dados`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Operador) => m.idOperador).displayName("Código").satisfiesRule("digits")
  .ensure((m: Operador) => m.nvcNome).displayName("Nome (alias)").required()
  .ensure((m: Operador) => m.nvcNomeCompleto).displayName("Nome Completo").required()
  .ensure((m: Operador) => m.nvcPin).displayName("Pin").required()
  // .ensure((m: Operador) => m.nvcHash).displayName("Palavra chave").required().when(m => !m.nvcPin)
  // .ensure((m: Operador) => m.nvcSalt).displayName("Confirmação Palavra-chave").required().when(m => !m.nvcPin)
  //.ensure((m: Operador) => m.nvcPassword).displayName("palavra chave")
  .ensure((m: Operador) => m.nvcPasswordConfirmation).displayName("Confirmação da palavra chave").satisfiesRule('equals', 'nvcPassword')
  .on(Operador);

