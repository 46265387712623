// import {ColConfigInterface, DataSource, EntityInterface, GridConnector, Selection, SortObjectInterface} from "aurelia-v-grid";
import environment from "../../environment";
// import Choices = require("choices.js");
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {GlobalServices} from "../../services/global-services";
import {autoinject} from "aurelia-framework";
import {PlanoExpedicao} from "../../models/PlanoExpedicao";
// import Choices from "../../../custom_typings/choices";

//import * from "choices.js/assets/styles/css";
@autoinject()
export class ChoicesVm {
  message: string;
  public iref: HTMLInputElement;
  public smRef: HTMLSelectElement;
  public smRefFetch: HTMLSelectElement;
  public strArr1: string[] = ["dois", "valores"];
  public strArr2: string[] = ["dois", "valores"];
  public strArr3: string[] = ["LASCO", "INDOR"];
  public strArrMc: string[] = ["LASCO", "INDOR"];

  public singleVal: string = "LASCO";

  private planos: ClienteArmazem[] = [];
  private model: PlanoExpedicao = null;

  public str1: string;

  constructor(public app: GlobalServices) {}

  canActivate(p){
    this.model = new PlanoExpedicao();
    return ClienteArmazem.memoizeMultiple(this.app.api)
      .then(r => this.planos = r)
      .then(_ => {
        if(p && +p.id > 0) {
          if(environment.debug) console.log("[choices]","canActivate", "pedir linha ao servidor");
          return this.app.api.getProcessed('api/plano-expedicao/linha', {id: +p.id})
            .then(r => this.model = PlanoExpedicao.fromPOJSO(r))
        }
      })
      .then(r => true)
      .catch(err => this.app.notificationErrorCompact(err));
  }

  attached() {
    if (environment.debug) console.log("[choices]", "attached");
    // const choices = new Choices(this.iref, {
    //   delimiter       : ',',
    //   editItems       : true,
    //   maxItemCount    : 5,
    //   removeItemButton: true,
    //   choices: [
    //     {
    //       value   : 'um',
    //       label   : 'Option 1',
    //       // selected: true,
    //       // disabled: false,
    //     },
    //     {
    //       value           : 'dois',
    //       label           : 'Option 2',
    //       // selected        : false,
    //       // disabled        : true,
    //       // customProperties: {
    //       //   description: 'Custom description about Option 2',
    //       //   random     : 'Another random custom property'
    //       // },
    //     }
    //   ],
    // });
    //
    // const choicesSm = new Choices(this.smRef, {
    //   choices: [
    //     {
    //       value   : 'um',
    //       label   : 'Option 1',
    //       // selected: true,
    //       // disabled: false,
    //     },
    //     {
    //       value           : 'dois',
    //       label           : 'Option 2',
    //       // selected        : false,
    //       // disabled        : true,
    //       // customProperties: {
    //       //   description: 'Custom description about Option 2',
    //       //   random     : 'Another random custom property'
    //       // },
    //     }
    //   ],
    //   removeItemButton: true,
    //   addItems: true
    // });

    console.log(this);
    const choices3 = new Choices(this.smRefFetch, {
      removeItemButton: true,
      addItems        : true
    }).ajax((callback) => {
      console.log(callback);
      this.app.api.getProcessed("api/cliente/list")
        .then(r => {
          return ClienteArmazem.multipleFromPOJSO(<any>r)
        })
        .then(ca => {
          console.log("returned", ca);
          callback(ca, "nvcClienteArmazem", "nvcNome");
        })
        .catch(err => this.app.notificationErrorCompact(err))
    });
  }

  log(n) {
    if (n == 1 && environment.debug) console.log("[choices]", "log 1", this.strArr1);
    if (n == 2 && environment.debug) console.log("[choices]", "log 2", this.strArr2);
    if (n == 3 && environment.debug) console.log("[choices]", "log 3", this.strArr3);
  }

  alteraArray() {
    this.strArr3 = ["LASCO", "SCAP"];
  }

  alteraArray2() {
    this.planos = this.planos.slice(0, 4);
  }

  doAction(action, payload){
    console.log(action, payload, this);
  }
}

// class CDataSource extends DataSource {
//   constructor(selection, options?) {
//     super(selection, options);
//   }
//
//   orderBy(attribute: string | SortObjectInterface, addToCurrentSort?: boolean): void {
//     if (environment.debug) console.log("[vgrid]", "CDataSource.orderby", attribute, addToCurrentSort);
//     //super.orderBy(attribute, addToCurrentSort);
//     //return;
//   }
// }
