import {BaseViewModel} from "./BaseViewModel";
import {ValidationRules} from "aurelia-validation";
import {PlanoExpedicao} from "./PlanoExpedicao";
import {VmWrapper} from "./VmWrapper";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class FusaoPlanoExpedicaoVm extends BaseViewModel {
  public LinhaErp: PlanoExpedicao;
  public LinhaPlano: PlanoExpedicao;
  public idLinhaErp: number;
  public idLinhaPlano: number;

  //relações
  public _PlanoExpedicao: PlanoExpedicao = null;

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<FusaoPlanoExpedicaoVm>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any): FusaoPlanoExpedicaoVm {
    throw new Error("not implemented!");
  }

  public static multipleFromPOJSO(objs: any[]): FusaoPlanoExpedicaoVm[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(FusaoPlanoExpedicaoVm.fromPOJSO);
    return [];
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<FusaoPlanoExpedicaoVm>({payload: this, confirmLevel: (+cl || 0)});
  }

  //endregion
}

ValidationRules
  .ensure((m: FusaoPlanoExpedicaoVm) => m.idLinhaPlano).displayName("Linha virtual").required()
  .ensure((m: FusaoPlanoExpedicaoVm) => m.idLinhaErp).displayName("Linha Com sincronia").required()
  .on(FusaoPlanoExpedicaoVm);
