import {Aurelia} from 'aurelia-framework'
import environment from './environment';
import * as moment from "moment";
import {setupValidationRules} from "./validation/custom-rules";

//Configure Bluebird Promises.
// if (environment.debug)
//   (<any>Promise).config({warnings: {wForgottenReturn: false}});
// else
//   (<any>Promise).config({warnings: false});

//Configure Moment
moment.locale("pt");

// region configurar o flatpickr globalmente
import * as _flatpickr from 'flatpickr';
const flatpickr: any = (<any>_flatpickr).default || _flatpickr;

var Portuguese = {
  weekdays: {
    shorthand: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    longhand: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"]
  },
  months: {
    shorthand: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    longhand: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
  },
  rangeSeparator: " até "
};
flatpickr.localize(Portuguese);
// endregion configurar o flatpickr globalmente

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin('aurelia-animator-css')
    .plugin('aurelia-validation')
    .plugin('aurelia-dialog', config => {
      config.useDefaults();
      config.settings.lock                 = true;
      config.settings.startingZIndex       = 200;
      config.settings.keyboard             = [/*'Enter', */'Escape'];
      config.settings.centerHorizontalOnly = true;
      config.useCSS("");
      //config.settings.rejectOnCancel = true;
    })
    .feature('resources')
    .feature('it-v-grid')
    .feature('it-features')
  ;

  if (environment.debug) {
    aurelia.use.developmentLogging();
  }

  // if (environment.testing) {
  //   aurelia.use.plugin('aurelia-testing');
  // }
  //
  if(environment.debug) console.log("[mobile-main]", "Setting isMobile to true.");
  window && (window["isMobile"] = true);

  setupValidationRules();

  return aurelia.start().then(() => aurelia.setRoot('mobile-app'));
}
