import {autoinject, computedFrom} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import {PlanoDefinition} from "../models/PlanoColumn";

@autoinject()
export class ConfigTabelaVirtual {
  protected controller: DialogController;
  public localState: PlanoDefinition;
  private app: GlobalServices;

  constructor(controller: DialogController, app: GlobalServices) {
    this.controller           = controller;
    this.app                  = app;
  }

  activate(localState) {
    // console.log("Activate:", localState);
    this.localState = localState;
  }

  aplicaFiltros(){
    this.cleanup();
    return this.controller.ok({action: "UPDATE-DEFINICAO-TABELA", payload: this.localState});
  }

  apagaLocalStorage(){
    this.cleanup();
    return this.controller.ok({action: "RESET-DEFINICAO-TABELA", payload: null});
  }

  attached() {
    $("#dataMin").ejDatePicker({
      locale          : "pt-PT",
      value     : this.localState.dataMin, // sets the current date
      dateFormat: "yyyy-MM-dd", // sets the date format
      width     : "100%",
      change    : (v) => {
        console.log("date changed", v, new Date());
        try {
          if (v.value)
            this.localState.dataMin = v.value;
          else
            this.localState.dataMin = null;
        } catch (err) {
          this.localState.dataMin = null;
        }
        // this.validationController.validate({object: this.localState, propertyName: 'DtmMovimento'});
      }
    });
    $("#dataMax").ejDatePicker({
      locale          : "pt-PT",
      value     : this.localState.dataMax, // sets the current date
      dateFormat: "yyyy-MM-dd", // sets the date format
      width     : "100%",
      change    : (v) => {
        console.log("date changed", v, new Date());
        try {
          if (v.value)
            this.localState.dataMax = v.value;
          else
            this.localState.dataMax = null;
        } catch (err) {
          this.localState.dataMax = null;
        }
        // this.validationController.validate({object: this.localState, propertyName: 'DtmMovimento'});
      }
    });
  }

  cleanup() {
    $("#dataMin").ejDatePicker("destroy");
    $("#dataMax").ejDatePicker("destroy");
  }

  clearDate(which:string){
    if(this.localState[which]) {
      this.localState[which] = null;
      $("#"+which).ejDatePicker({value: null});
    }
  }
}
