import {autoinject, bindable, computedFrom} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import {Api} from "../../services/api";
// import Choices from "../../../custom_typings/choices";
// import Choices = require("choices.js");

/**
 * Um CE com choices de base que se comporta como o select2 mas bem.
 */
@autoinject()
export class RemoteChoices {
  @bindable debug: boolean          = false;
  @bindable value: any[]            = [];
  @bindable modelValue: any[]       = [];
  @bindable config: Choices.Options = {
    placeholderValue: 'Procurar...',
    shouldSort      : false,
    searchFloor     : 3,     // or whatever value makes sense for your data / API
    searchChoices   : false, // see bullet point 2 above
    duplicateItemsAllowed  : false,  // this is ignored, see bullet point 3 above
    removeItemButton: true
  };

  /**
   * O endereço para onde pedir os dados
   * @type {string}
   */
  @bindable ap: string = "";

  /**
   * O valor da escolha (o id, código)
   * é tratado pelo plugin por value
   * @type {string}
   */
  @bindable valueKey: string = "id";

  /**
   * O texto a aparecer na escolha
   * é tratado pelo plugin por label
   * @type {string}
   */
  @bindable labelText: string = "text";

  /**
   * Se estiver definida e uma pesquisa não contiver resultados é invocada.
   */
  @bindable creationCallback: any;

  //??? será que é útil?
  public clearSearchOnSelect: boolean = true;

  //a referência da instância do plugin
  public choices: Choices;

  //o elemento HTML select(multiple)
  private selElem: HTMLSelectElement = null;

  //  Cache otimista para respostas do servidor
  private responseCache: any = {};

  //
  constructor(private app: GlobalServices, private api: Api) {}

  attached() {
    if (this.debug) console.log("[remote-choices]", "attached");

    if (!this.ap) {
      console.error("O <remote-choices> deve ser invocado com um endereço remoto de api");
      return;
    }

    if (this.debug) console.log("[remote-choices]", "inicializar o plugin", this.config);
    this.choices = new Choices(this.selElem, this.config);

    if (this.value && this.value.length > 0) {
      this.forceChoicesRedraw();
    }
    //window["c"] = this.choices
  }

  //o value é um array de strings com as keys das opções selecionadas muda em duas ocasiões
  //1. inicialização do plugin
  //2. escolha de um elemento
  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("[remote-choices]", "valueChanged", newValue, oldValue);
    if (newValue) {
      if (!this.choices) {
        if (this.debug) console.log("[remote-choices]", "valueChanged", "inicialização do plugin");
        //sem ir ao servidor não se consegue adivinhar qual a label do item em questao,
        // estas opcções são embutidas no objeto de configuração com tratamento igual
        this.config.choices = newValue.map(el => ({value: el, label: el, selected: true, customProperties: null}));
      } else {
        if (this.debug) console.log("[remote-choices]", "valueChanged", "escolha de um elemento");
        this.forceChoicesRedraw();
      }
    }
  }

  /**
   * Faz a chamada ao servidor como GET.
   * todo: opcção para usar POST?
   * Fluxo retirado de: https://github.com/jshjohnson/Choices/issues/162
   */
  serverLookup() {
    if (!this.ap) return false;

    // we can't use choices.currentValue because this is blank if we set searchChoices
    // to false. Instead we use the actual input field's value.
    let q = (this.choices as any).input.value;
    if (this.debug) console.log("q", q);
    if (this.responseCache && this.responseCache[q]) {
      return this.populateOptions(this.responseCache[q])
    }

    //chamada ao AP
    this.api.getProcessed(this.ap, {q})
      .then(r => {
        if (this.debug) console.log("[remote-choices]", "resposta", r);
        if (Array.isArray(r)) {
          if (r.length > 0) {
            //com resultados
            this.responseCache[q] = r;
            return this.populateOptions(this.responseCache[q])
          } else {
            //sem resultados, invoca callback
            this.creationCallback && this.creationCallback({action: "NEW-CHOICE", payload: {ap: this.ap, suggestion: q}})
          }
        } else {
          //alguma coisa correu mal. deita a toalha ao chão
          console.error("A resposta não está no formato certo");
        }
      });
  }

  populateOptions(options) {
    // We have to cull duplicates ourselves here, because Choices doesn't do it for
    // us. There's probably a better way of doing this, but here's the easy way:
    let toRemove = this.choices.getValue(true) || [];
    if (this.debug) console.log("[remote-choices]", "populateOptions", this.choices.currentState.choices, this.choices.currentState.items);

    // transforma a resposta de acordo com a valueKey - labelText para um formato mais conveniente para o plugin
    let choicesFromOptions = options.map(el => ({value: el[this.valueKey], label: el[this.labelText], customProperties: el}));

    // remove os resultados que possam já estar selecionados
    choicesFromOptions = choicesFromOptions.filter(el => !toRemove.includes(el.value));
    this.forcePopulateOptions(choicesFromOptions)
  };

  forcePopulateOptions(choicesFromOptions) {
    // injeta as opções (se não houver resultados injeta uma resposta sem resultados)
    if (choicesFromOptions.length > 0) {
      // this.choices.setChoices(options, this.valueKey, this.labelText, true);
      this.choices.setChoices(choicesFromOptions, "value", "label", true);
    } else {
      this.choices.setChoices([{value: '-1000000000000', label: 'Sem Resultados.', selected: false, disabled: true,},], "value", "label", true);
    }
  };

  cleanChoices() {
    if (this.debug) console.log("[remote-choices]", "cleanChoices");
    this.clearSearchOnSelect &&
    this.choices.setChoices([], "value", "label", true);
  }

  /**
   * Força o desenho das ocções associadas a value
   * (vai ao servidor buscá-las)
   *
   * Atua quando o array value é alterado por fora do elemento
   */
  forceChoicesRedraw() {
    if (!this.ap) return;
    this.api.getProcessed(this.ap, {q: this.value})
      .then(r => {
        if (this.debug) console.log("[remote-choices]", "forceChoicesRedraw", r);
        //limpa as escolhas
        this.choices.clearStore();
        let choicesFromOptions = r.map(el => ({value: el[this.valueKey], label: el[this.labelText], selected: this.value.includes(el[this.valueKey]), customProperties: el}));
        this.forcePopulateOptions(choicesFromOptions);
      })
      .catch(err => console.error(err));
  }
}
