//import {setInterval} from "timers";
import {autoinject} from 'aurelia-framework';
import {Api} from "../../services/api";

@autoinject()
export class CustomComponent {
  private _api:Api;
  message = 'Dashboard';


  constructor(api:Api) {
    this._api = api;
  }

  //region Acções
  criaEpoca(){
    this._api.post("api/epoca/criar", 2017).then(console.log);
  }
  //endregion

  // attached() {
  //   console.log("CustomComponent attached");
  // }
}
