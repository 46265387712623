import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";
import {GuiaTransportePlanoExpedicaoVm} from "../models/GuiaTransportePlanoExpedicaoVm";
import {ConfirmacaoDialog} from "./confirmacao-dialog";
import {VmWrapper} from "../models/VmWrapper";
import {DateFilterTemplateGreaterEqual, identityNumericFilter, IdentityStringFilter} from "../utils/EjHelper";
import {NovaProgramacaoLinhaEncomenda} from "./nova-programacao-linha-encomenda";

@autoinject()
export class PickerLinhaEncomenda {
  protected controller: DialogController;
  private app: GlobalServices;
  private model: PickerLinhaEncomendaPayload = null;

  constructor(gs: GlobalServices, controller: DialogController) {
    this.app        = gs;
    this.controller = controller;
  }

  activate(args: PickerLinhaEncomendaPayload) {
    //obter a lista de clientes.
    console.log("PickerLinhaEncomenda activate", args);
    this.model = args;
  }

  attached() {
    //console.log("activate!");
    this.app.auth.promisedActiveToken()
      .then(token => {

        let filteredColumns = [];
        if (this.model.nvc_cliente_armazem && this.model.nvc_cliente_armazem != 'RAOUL') { filteredColumns.push({field: "nvc_cliente_armazem", operator: "equal", value: this.model.nvc_cliente_armazem, matchcase: false})}
        filteredColumns.push({field: "nvc_estado", operator: "equal", value: 'aberto', matchcase: false});

        let columns = [
//@formatter:off
{field: "nvc_artigo_terminacao"   , headerText:"Referência"   , headerTooltip:"Referência do artigo"              , width:80  , type: 'string' , filterBarTemplate: IdentityStringFilter} ,
{field: "nvc_descricao_artigo"    , headerText:"Descrição"    , headerTooltip:"Descrição do artigo"               , width:200 , type: 'string' , filterBarTemplate: IdentityStringFilter} ,
{field: "nvc_tipo_gravacao"       , headerText:"Gravação"     , headerTooltip:"Gravação associada"                , width:40  , type: 'string' , filterBarTemplate: IdentityStringFilter} ,
{field: "nvc_codigo_encomenda"    , headerText:"CDE"          , headerTooltip:"Código de Encomenda"               , width:60  , type: 'string' , filterBarTemplate: IdentityStringFilter} ,
{field: "int_qty_a_expedir"       , headerText:"Encomendadas" , headerTooltip:"Unidades Encomendadas por Expedir" , width:40  , type: 'number' , filterBarTemplate: identityNumericFilter , textAlign: 'right'                                      , cssClass: "success"}                                ,
{field: "total_prg"                   , headerText:"Programadas"  , headerTooltip:"Unidades Programadas"              , width:30  , type: 'number' , filterBarTemplate: identityNumericFilter , textAlign: 'right'}                                     ,
{field: "total_lcm"                   , headerText:"Lançadas"     , headerTooltip:"Unidades Lançadas"                 , width:30  , type: 'number' , filterBarTemplate: identityNumericFilter , textAlign: 'right'}                                     ,
{field: "total_sai"                   , headerText:"Saída"        , headerTooltip:"Unidades Expedidas"                , width:30  , type: 'number' , filterBarTemplate: identityNumericFilter , textAlign: 'right'}                                     ,
{field: "int_qty_total_encomenda" , headerText:"Encomenda"    , headerTooltip:"Unidades Encomendadas"             , width:40  , type: 'number' , filterBarTemplate: identityNumericFilter , textAlign: 'right'                                      , cssClass: "success"}                                ,
{field: "dtm_pedido"              , headerText:"Data Pedido"  , headerTooltip:"Data Pedido"                       , width:60  , type: 'date'   , textAlign: "center"                      , format: "{0:yyyy-MM-dd}"                                , filterBarTemplate: DateFilterTemplateGreaterEqual } ,
{field: "stock_entrada"               , headerText:"ENT"          , headerTooltip:"Stock Armazém de entrada"          , width:30  , type: 'number' , filterBarTemplate: identityNumericFilter , cssClass: "warning"}                                    ,
{field: "stock_producao"              , headerText:"PROD"         , headerTooltip:"Stock Armazém de Saída"            , width:30  , type: 'number' , filterBarTemplate: identityNumericFilter , cssClass: "warning"}                                    ,
{field: "nvc_estado"              , headerText:"Estado"       , headerTooltip:"Estado da linha do plano"          , width:40  , type: 'string' , visible:true }                          ,
{field: "nvc_cliente_armazem"     , headerText:"Plano"        , headerTooltip:"Plano de origem"                   , width:40  , type: 'string' , filterBarTemplate: IdentityStringFilter  , visible: (this.model.nvc_cliente_armazem === 'RAOUL') /*|| environment.debug*/} ,
//@formatter:on
        ];

        let g = $("#Grid").ejGrid({
          locale        : "pt-PT",
          dataSource    : ej.DataManager({
            //done: injetar a localização do serviço
            url        : environment.endpoint + "api/ejg/PlanoExpedicao",
            crossDomain: true,
            //requestType : "post",
            adaptor    : new ej.WebMethodAdaptor(),
            headers    : [{Authorization: `Bearer ${token}`}]
          }),
          allowPaging   : true,
          pageSettings  : {pageSize: 12},
          allowSorting  : true,
          allowSearching: true,
          allowFiltering: true,
          sortSettings  : {sortedColumns: [{field: "dtm_pedido", direction: "ascending"}]},
          filterSettings: {
            filteredColumns: filteredColumns,
          },

          recordClick: (args) => {
            console.log("Click", args);
            //$("#Grid").ejGrid("destroy");
            let idPlanoExpedicao = +args.data.id_plano_expedicao;
            if (idPlanoExpedicao <= 0) {
              this.app.notificationError("A linha do plano de expedição não é válida");
              return;
            }
            let pl = new GuiaTransportePlanoExpedicaoVm(
              {
                idGuiaTransporte    : this.model.id_guia_transporte,
                idPlanoExpedicao    : idPlanoExpedicao,
                intQuantidade       : ~~args.data.int_qty_total_encomenda - ~~args.data.total_prg,
                nvcDescricaoArtigo  : args.data.nvc_descricao_artigo,
                nvcArtigoTerminacao : args.data.nvc_artigo_terminacao,
                intQtyTotalEncomenda: ~~args.data.int_qty_total_encomenda - ~~args.data.total_prg
              });
            if (environment.debug) console.log("[picker-linha-encomenda]", "recordClick", pl, args.data);
            this.app.ds.open({viewModel: NovaProgramacaoLinhaEncomenda, model: {model: pl, app: this.app}})
              .whenClosed(r => {
                if (!r.wasCancelled) {
                  // this.controller.ok({action: 'PICK-LINHA-ENCOMENDA', payload: args.data});
                  this.controller.ok(r.output);
                }
              })
              .catch(err => this.app.notificationErrorCompact(err));
            //this.submitWithConfirm(pl.wrapIt());
            // this.controller.ok({action: 'PICK-LINHA-ENCOMENDA', payload: args.data});
          },
          columns    : columns
        });
      });
  }

  // submitWithConfirm(pl: VmWrapper<GuiaTransportePlanoExpedicaoVm>) {
  //   // GlobalServices.processVmWrapResponse(this.app, `api/plano-expedicao/linha-guia-transporte-de-linha-encomenda`, pl.wrapIt(), (r) => {console.log('success callback', r);}, (r) => {console.log('cancelation callback', r);});
  //   this.app.api.post(`api/plano-expedicao/linha-guia-transporte-de-linha-encomenda`, pl)
  //     .then(r => this.app.api.processResponse(r))
  //     .then((obj: any) => {
  //       console.log(obj);
  //       if (obj.tipo) {
  //         if (obj.tipo === "confirm") {
  //           let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
  //           return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
  //             .whenClosed(resp => {
  //               if (!resp.wasCancelled) {
  //                 //O operador escolheu SIM: aumenta o nível de confirmação
  //                 return this.submitWithConfirm(pl.nextLevel());
  //               }
  //             });
  //         }
  //         throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
  //       }
  //       ///if(r.tipo)
  //       this.controller.ok({action: 'UPDATE-GUIA-EDICAO', payload: GuiaTransporte.fromPOJSO(obj)});
  //     })
  //     .catch(err => this.app.notificationErrorCompact(err));
  // }

  resetSearch() {
    // let instance = $("#Grid").ejGrid("clearFiltering");
    let instance = $("#Grid").ejGrid("instance");
    instance.clearFiltering("nvc_artigo_terminacao");
    instance.clearFiltering("nvc_descricao_artigo");
    instance.clearFiltering("nvc_tipo_gravacao");
    // instance.clearFiltering("nvc_acabamento");
    instance.clearFiltering("int_qty_a_expedir");
    instance.clearFiltering("total_prg");
    instance.clearFiltering("total_lcm");
    instance.clearFiltering("total_sai");
    instance.clearFiltering("int_qty_total_encomenda");
    instance.clearFiltering("nvc_codigo_encomenda");
    instance.clearFiltering("dtm_pedido");
    instance.clearFiltering("stock_entrada");
    instance.clearFiltering("stock_producao");
    instance.clearFiltering("nvc_estado");
  }
}

export interface PickerLinhaEncomendaPayload {
  nvc_cliente_armazem: string;
  id_guia_transporte: number;
}

