import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
import {ClienteArmazem} from "./ClienteArmazem";
import {ValidationRules} from "aurelia-validation";

/**
 * Created by herna on 7/12/2017.
 * ViewModel
 */
export class GuiaTransportePlanoExpedicaoVm extends BaseViewModel {
  public idPlanoExpedicao: number;
  public idGuiaTransporte: number;
  public intQuantidade: number;

  public nvcArtigoTerminacao: string;
  public nvcDescricaoArtigo: string;
  public intQtyTotalEncomenda: number;

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<GuiaTransportePlanoExpedicaoVm>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): GuiaTransportePlanoExpedicaoVm {
    // console.log("fromPOJSO", obj);
    let model = new GuiaTransportePlanoExpedicaoVm();
    model.setState(obj);
    model.__index = index;

    return model;
  }

  public static multipleFromPOJSO(objs: any): GuiaTransportePlanoExpedicaoVm[] {
    if (objs) {
      if (Array.isArray(objs)) return objs.map((el, i) => GuiaTransportePlanoExpedicaoVm.fromPOJSO(el, i));
      return [GuiaTransportePlanoExpedicaoVm.fromPOJSO(objs, 0)];
    }
    return [];
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<GuiaTransportePlanoExpedicaoVm>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<GuiaTransportePlanoExpedicaoVm>({payload: this, confirmLevel: (+cl || 0)});
  }

  //endregion
}

ValidationRules
  .ensure((m: GuiaTransportePlanoExpedicaoVm) => m.intQuantidade).displayName("Quantidade pretendida").required()
  // .ensureObject()
  // .satisfies(m => {
  //   console.error("validar", m);
  //   return +m.intQuantidade <= +m.IntQtyTotalEncomenda
  // }).withMessage(`O valor não pode ser superior ao disponível`)
  .on(GuiaTransportePlanoExpedicaoVm);