//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import {Artigo} from "../../models/Artigo";
import {ArtigoComponent} from "./artigo-component";
import {Familia} from "../../models/Familia";
import {FamiliaDialog} from "./ce/familia-dialog";
import {UtilizadorPermissao} from "../../models/UtilizadorPermissao";
import {ArtigoFamilia} from "../../models/ArtigoFamilia";
import {confirmaActionTyped} from "../../services/api-envelopes";
import {PickerRemoteGrid} from "../../it-features/remote-grid/picker-remote-grid";

@autoinject()
export class ArtigoListagem {
  private isBusy: boolean = false;
  private familia: Familia;
  private rg: RemoteGrid;
  private rgArtigos: RemoteGrid;

  constructor(public app: GlobalServices, private parent: ArtigoComponent) { }

  canActivate() {
    if (environment.debug) console.log("[familia-listagem]", "canActivate");
    if (!this.app.auth.can("App.ArtigosVer")) {
      this.app.notificationError("Não dispõe da permissao para visualizar a listagem de artigos");
      return false;
    }
    this.parent.activeSubRoute = "familias";
    return true
  }

  // canDeactivate() {
  //   this.cleanup();
  //   return true;
  // }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  public doActionArtigo(action: string, payload?: any) {
    //if (environment.debug) console.log("[configuracoes]", "{doActionPermissao}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case RemoteGridActions.RG_CELL_CHANGE: {
          return this.toggleArtigo(payload);
        }
        default:
          return this.isBusy = false;
      }
    } catch (err) {
      this.isBusy = this.app.notificationErrorCompact(err);
    }
  }

  private toggleArtigo(payload: any) {
    let af = ArtigoFamilia.fromPOJSO(payload.rowRef);
    if (af.nvcFamilia != this.familia.nvcFamilia) af.nvcFamilia = this.familia.nvcFamilia;
    return confirmaActionTyped(this, af, "api/artigo/associa-familia").then(_ => this.rgArtigos.refreshPage());
  }

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[familia-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "EDITAR-FAMILIA": {
          let familia = new Familia();
          if (payload != null) { familia = payload;}
          return this.app.ds.open({viewModel: FamiliaDialog, model: {model: familia, app: this.app}, rejectOnCancel: false})
            .whenClosed(r => {
              if (!r.wasCancelled) {
                this.rg.refreshPage();
                return this.app.notificationShort("Família de artigos atualizada");
              }
              return false;
            })
            .catch(err => this.app.notificationErrorCompact(err))
            .then(r => (this.isBusy = false) || r);
        }
        case "ADICIONAR-ARTIGO":
          return this.app.ds.open({viewModel: PickerRemoteGrid, model: {codigoTabela: "ArtigoSemFamiliaPicker?p1=" + this.familia.nvcFamilia}})
            .whenClosed(r => {
              if (!r.wasCancelled) {
                if (environment.debug) console.log("[configuracoes]", "Resultado Picker", r);
                return this.toggleArtigo(r.output)
                  .then(_ => this.rgArtigos.refreshPage());
              }
              return Promise.resolve("false");
            })
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false);

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[familia-listagem]", "RG_CELL_CHANGE", payload);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[familia-listagem]", "RG_ROW_CLICK", payload);
          if (payload && payload.rowRef && payload.rowRef.nvcFamilia)
            this.familia = Familia.fromPOJSO(payload.rowRef);
          else
            this.familia = null;
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          if (environment.debug) console.log("[familia-listagem]", "RG_ROW_DBL_CLICK", payload);
          if (payload && payload.rowRef && payload.rowRef.nvcFamilia)
            this.familia = Familia.fromPOJSO(payload.rowRef);
          else
            this.familia = null;
          if(this.familia) this.doAction("EDITAR-FAMILIA", this.familia);
          this.isBusy = false;
          break;
        }
        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[familia-listagem]", "Acção DESCONHECIDA [familia-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
