// Auto-gerado com typewriter

import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {ArtigoTerminacao} from './ArtigoTerminacao';
import {Utilizador} from './Utilizador';

export class Terminacao extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public nvcTerminacao: string                    = null;
  public nvcDescricao: string                     = null;
  public idUtilizador: number                     = 0;
  public idUtilizadorultact: number               = null;
  public dtmDatains: string                       = null;
  public _dtmDatains: Date                        = new Date();
  public dtmDataultact: string                    = null;
  public _dtmDataultact: Date                     = new Date();
  public timestamp: number[]                      = [];
  public artigoTerminacao: ArtigoTerminacao[]     = [];
  public idUtilizadorNavigation: Utilizador       = null;
  public idUtilizadorultactNavigation: Utilizador = null;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Terminacao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): Terminacao {
    let model = new Terminacao();
    model.setState(obj);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): Terminacao[] {
    if (objs && Array.isArray(objs)) return objs.map(Terminacao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public warpIt(cl?: number) {
    return new VmWrapper<Terminacao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): Terminacao { return Terminacao.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `Terminacao`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Terminacao) => m.nvcTerminacao).displayName("nvcTerminacao").required()
  .ensure((m: Terminacao) => m.nvcDescricao).displayName("nvcDescricao").required()
  .ensure((m: Terminacao) => m.idUtilizador).displayName("idUtilizador").required()
  .ensure((m: Terminacao) => m.idUtilizadorultact).displayName("idUtilizadorultact").required()
  .ensure((m: Terminacao) => m.dtmDatains).displayName("dtmDatains").required()
  .ensure((m: Terminacao) => m.dtmDataultact).displayName("dtmDataultact").required()
  .ensure((m: Terminacao) => m.timestamp).displayName("timestamp").required()
  .ensure((m: Terminacao) => m.artigoTerminacao).displayName("artigoTerminacao").required()
  .ensure((m: Terminacao) => m.idUtilizadorNavigation).displayName("idUtilizadorNavigation").required()
  .ensure((m: Terminacao) => m.idUtilizadorultactNavigation).displayName("idUtilizadorultactNavigation").required()
  .on(Terminacao);

