import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

import {GlobalServices} from "../services/global-services";
import {ClienteDestinatario} from "../models/ClienteDestinatario";

@autoinject()
export class MoradaDialog {
  protected validationController: ValidationController;
  protected controller: DialogController;
            app: GlobalServices;

  protected modelo: ClienteDestinatario;
  protected isBusy: boolean = false;

  constructor(controller: DialogController, app: GlobalServices, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    this.app                  = app;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  /**
   * Aqui dá muito mais jeito a transmissão de uma instância ao invés do simples id.
   * @param payload
   */
  activate(payload: { model: ClienteDestinatario }) {
    console.log("MoradaDialog", payload);
    this.modelo = payload.model;
  }

  attached() {}

  cleanup() {
  }

  gravaMorada() {
    return this.app.api.post("api/cliente/save-morada", this.modelo.stateToPOJSO())
      .then(r => this.app.api.processResponse(r))
      .then(r => {
        console.log("resposta gravaMorada", r);
        this.app.notificationSuccess("O utilizador foi gravado na base de dados.");
        this.cleanup();
        return true;
      });
      // .catch(e => {this.app.notificationErrorCompact(e); throw e;});
  }

  submit() {
    // console.log("Submit", planoExpedicao, this.planoExpedicao);
    // let o = this.planoExpedicao.dto();
    // console.log(o);
    this.validationController.validate()
      .then(r => {
        if (r.valid) {
          this.gravaMorada().then(_ => {
            this.controller.ok({action: "REFRESH", payload: {}});
          })
            .catch(e => this.app.notificationErrorCompact(e));
        } else {
          this.app.notificationErrorCompact("Verifique os erros assinalados no formulário.")
        }
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }
}
