import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

import {GlobalServices} from "../services/global-services";
import {Operador} from "../models/Operador";
import environment from "../environment";

@autoinject()
export class OperadorDialog {
  //static inject = [DialogController, ValidationControllerFactory];
  protected validationController: ValidationController;
  protected controller: DialogController;
  protected app: GlobalServices;

  protected idOperador: number = -1;
  protected operador: Operador;
  protected operadorOriginal: Operador;

  protected isBusy:boolean = false;

  constructor(controller: DialogController, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  canActivate(p:any) {
    if (!p.model || !p.app) {
      console.error("Um dos parametros (model, app) está em falta pelo que não se pode abir o popup de criação/alteração de um operador.");
      return false;
    }
    this.app  = p.app;
    this.parseOperador((p.model as Operador).stateToPOJSO());

    return true;
  }

  //attached() {}

  parseOperador(obj: any) {
    this.operador         = Operador.fromPOJSO(obj);
    this.operadorOriginal = Operador.fromPOJSO(obj);
    return true;
  }

  gravaOperador() {
    return this.app.confirmaActionTyped(this.operador.wrapIt(), "api/auth/cria-operador")
      .then(r => {
        if (this.app.processConfirmation(r)) {
          this.app.notificationSuccess(`Dados de ${this.operador} atualizados`);
          this.controller.ok(this.operador);
        }
        // this.isBusy = false;
      })
      .catch(err => this.app.notificationErrorCompact(err))
      .then(_ => this.isBusy = false);
  }

  dialogSubmit() {
    return this.validationController.validate()
      .then(r => {
        if (r.valid) {
          this.gravaOperador()
        } else {
          this.app.notificationErrorCompact("Verifique os erros.");
          console.error(r.results);
        }
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }
}
