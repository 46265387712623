// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Utilizador} from './Utilizador';
import {Artigo} from './Artigo';
import {ClienteArmazem} from './ClienteArmazem';

//export classe
export class TraducaoPlanoExpedicaoControloGravacao extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public nvcClienteArmazemExpedicao: string                   = null;
  public nvcArtigoExpedicao: string                           = null;
  public nvcTipoGravacao: string                              = null;
  public nvcTipoControlo: string                              = null;
  public bitActivo: boolean                                   = true;
  public idUtilizadorI: number                                = 0;
  public idUtilizadorA: number                                = 0;
  public dtmDataA: string                                     = null;
  public timestamp: string                                    = null;
  public idUtilizadorANavigation: Utilizador                  = null;
  public idUtilizadorINavigation: Utilizador                  = null;
  public nvcArtigoExpedicaoNavigation: Artigo                 = null;
  public nvcClienteArmazemExpedicaoNavigation: ClienteArmazem = null;

  public setArtigoExpedicao(art:Artigo) {
    this.nvcArtigoExpedicaoNavigation = art;
    this.nvcArtigoExpedicao = art.nvcArtigo;
  }

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<TraducaoPlanoExpedicaoControloGravacao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): TraducaoPlanoExpedicaoControloGravacao {
    let model = new TraducaoPlanoExpedicaoControloGravacao();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): TraducaoPlanoExpedicaoControloGravacao[] {
    if (objs && Array.isArray(objs)) return objs.map(TraducaoPlanoExpedicaoControloGravacao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<TraducaoPlanoExpedicaoControloGravacao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): TraducaoPlanoExpedicaoControloGravacao { return TraducaoPlanoExpedicaoControloGravacao.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `TraducaoPlanoExpedicaoControloGravacao`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: TraducaoPlanoExpedicaoControloGravacao) => m.nvcClienteArmazemExpedicao).displayName("Cliente").required()
  .ensure((m: TraducaoPlanoExpedicaoControloGravacao) => m.nvcArtigoExpedicao).displayName("Referência").required()
  .ensure((m: TraducaoPlanoExpedicaoControloGravacao) => m.nvcTipoGravacao).displayName("Gravação (Referência)").required()
  .ensure((m: TraducaoPlanoExpedicaoControloGravacao) => m.nvcTipoControlo).displayName("Tipo de Controlo").required()
  .ensure((m: TraducaoPlanoExpedicaoControloGravacao) => m.bitActivo).displayName("Activo").required()
  .on(TraducaoPlanoExpedicaoControloGravacao);
