import {BaseViewModel} from "../models/BaseViewModel";

/**
 * Created by hernani on 2017-10-17.
 */
export interface Select2Option {
  id: number | string,
  text: string,
  selected?: boolean
}

/**
 *
 * @param to Url parcial (deve começar com /, ex: "/dashboard/index.php)
 */
export function jsRedirect(to: string) {
  let target = to;
  if (to == null) {
    jsRefresh();
    return;
  } else if (to.startsWith("http")) {
    target = to;
  } else if (to.startsWith("/")) {
    target = location.origin + to;
  } else if (to.startsWith("?")) {
    target = location.origin + location.pathname + to;
  }
  window.location.href = target;
}

/**
 */
export function jsRefresh() {
  window.location.href = window.location.origin + window.location.pathname + window.location.search;
}

/**
 *  gera um array de start(=) até end(<)
 https://stackoverflow.com/a/36963945
 */
export const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);

export const tiposGravacao = ["Or", "Gr", "Pall", "Perm", "Or Rose", "S/Gravação", "Aguarda Gravação"];


//region strings
/**
 * Naive Camelize -> na realidade é um lcfirst
 * Passa uma string "NvcPropriedade" para a forma "nvcPropriedade"
 * @param str
 */
export const camelCase = (str) => str.charAt(0).toLowerCase() + str.slice(1);

/**
 * Separa uma string nas suas maíusculas num array de strings
 * @param str
 */
export const splitWords = (str) => str.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g);

/**
 * Passa uma string da forma "UmaStringPascalCase" para "uma-string-pascal-case"
 * @param str
 */
export const hiphenize = (str) => splitWords(str).map(camelCase).join("-");

// discuss at: http://locutus.io/php/lcfirst/
// original by: Brett Zamir (http://brett-zamir.me)
//   example 1: lcfirst('Kevin Van Zonneveld')
//   returns 1: 'kevin Van Zonneveld'
export function lcfirst(str) {
  str += '';
  let f = str.charAt(0).toLowerCase();
  return f + str.substr(1);
}

/**
 * Remove os caracteres de acentuação
 * @param str
 */
export const removeDiacritics      = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
export const removeDiacriticsLower = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

/**
 * transforma uma string para outra amigável com as conveções de nomes de ficheiros
 * @param str
 */
export const filenameFriendly = (str) => removeDiacritics(str).replace(/[^a-z0-9\-]/gi, '_');

export const trimLongToSpan = (data: string, len = 150) => {
  //const len = 150;
  if (data && data.length > len) {
    return `<span title='${data}'>${data.substr(0, len)} ...</span>`
  }
  return data || "";
};

export const translateEfName = (str) => {
  const words = str
    .replace(/^(nvc|int|bit|dtm|flt)/gi, "")
    .replace(/^(id)/gi, "ref")
    .split(/(?=[A-Z])/).join(' ');
  return words.charAt(0).toUpperCase() + words.slice(1);
};
//endregion

//region Object
/**
 * Copia as propriedades de um objeto `source` para `target`.
 * Este método cria uma mutação no `target`
 * @param source
 * @param target
 * @param {string} skipPrefix
 */
export const copyProperties = (source: any, target: any, skipPrefix: string = "_") => {
  // console.log("asd");
  let keys = Object.keys(source);

  for (let prop of keys) {
    if (skipPrefix && prop.startsWith("_")) continue;
    target[prop] = source[prop];
  }

  return target;
};

/**
 * Diz se a propriedade é uma primitiva
 * https://stackoverflow.com/a/31538091
 * @param prop
 * @returns {boolean}
 */
export const isPrimitive = (prop:any) => prop !== Object(prop);


/**
 * Transforma uma string na forma "a.b.c" para um objeto {a: {b: {c: value}}} e executa um merge a um objeto passado em obj
 * https://stackoverflow.com/a/31976476
 *
 * @param path - o caminho da propriedade
 * @param value - o valor da propriedade
 * @param obj - o objeto original?
 */
export const dotStringToObj = (path,value,obj) => {
  let parts = path.split(".");
  let part;
  let last = parts.pop();
  while(part = parts.shift()) {
    if( typeof obj[part] != "object") obj[part] = {};
    obj = obj[part]; // update "pointer"
  }
  obj[last] = value;
};
//endregion Object

//region IP
export let findIP = new Promise(r => {
  let w: any                                                                                                    = window,
      a = new (w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection)({iceServers: []}), b = () => {""};
  a.createDataChannel("");
  a.createOffer(c => a.setLocalDescription(c, b, b), b);
  a.onicecandidate = c => {try {c.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(r)} catch (e) {""}}
});

/*
export let findIP = (r) => {
  let w: any = window,
      a      = new (w.RTCPeerConnection || w.mozRTCPeerConnection || w.webkitRTCPeerConnection)({iceServers: []}),
      b      = () => {;};
  a.createDataChannel("");
  a.createOffer(c => a.setLocalDescription(c, b, b), b);
  a.onicecandidate = c => {try {c.candidate.candidate.match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g).forEach(r)} catch (e) {;}}
};
*/

/*Usage example*/
//findIP.then(ip => document.write('your ip: ', ip)).catch(e => console.error(e))
//endregion IP

//region BLOBS

/**
 * Conversão de um data uri para um blob
 * @param dataURI uma string do tipo "data:image/pdf;base64,iVB..."
 * @param mime MimeType
 *
 * @link https://gist.github.com/maria-p/8633b51f629ea8dbd27e
 *
 * mais referências:
 * https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
 */
export const dataURItoBlob = (dataURI, mime = 'image/jpeg')  => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mime });
};

export const BlobToDataURI = (blob: Blob) => new Promise((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

export const BlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
    resolve((<string>reader.result).split(",")[1]);
  };
  reader.readAsDataURL(blob);
});

// export const BlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
//   const reader = new FileReader;
//   reader.onerror = reject;
//   reader.onload = () => {
//     resolve((<string>reader.result).split(",")[1]);
//   };
//   reader.readAsDataURL(blob);
// });
//

//endregion BLOBS
