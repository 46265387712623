/**
 * Created by hernani on 2017-06-20.
 */

export class PositiveNumberValueConverter {
  /**
   * Envia para o ecrã apenas se o valor for diferente de zero
   */
  public toView(value: any) {
    if (value && (+value) > 0) {
      return value;
    } else {
      return 0;
    }
  }

  // public fromView(value: any) {
  //   if (value) {
  //     let check = value * 1;
  //     if (isNaN(check)) {
  //       return value;
  //     } else {
  //       return value * 1;
  //     }
  //
  //   } else {
  //     return value;
  //   }
  // }
}
