// Auto-gerado com typewriter

import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {Utilizador} from './Utilizador';
import {Artigo} from './Artigo';
import {Terminacao} from './Terminacao';

export class ArtigoTerminacao extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public nvcArtigo: string                        = null;
  public nvcTerminacao: string                    = null;
  public bitAtivo: boolean                        = false;
  public nvcForma: string                         = null;
  public idUtilizador: number                     = 0;
  public idUtilizadorultact: number               = null;
  public dtmDatains: string                       = null;
  public _dtmDatains: Date                        = new Date();
  public dtmDataultact: string                    = null;
  public _dtmDataultact: Date                     = new Date();
  public timestamp: number[]                      = [];
  public idUtilizadorNavigation: Utilizador       = null;
  public idUtilizadorultactNavigation: Utilizador = null;
  public nvcArtigoNavigation: Artigo              = null;
  public nvcTerminacaoNavigation: Terminacao      = null;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ArtigoTerminacao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): ArtigoTerminacao {
    let model = new ArtigoTerminacao();
    model.setState(obj);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): ArtigoTerminacao[] {
    if (objs && Array.isArray(objs)) return objs.map(ArtigoTerminacao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public warpIt(cl?: number) {
    return new VmWrapper<ArtigoTerminacao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ArtigoTerminacao { return ArtigoTerminacao.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `ArtigoTerminacao`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: ArtigoTerminacao) => m.nvcArtigo).displayName("nvcArtigo").required()
  .ensure((m: ArtigoTerminacao) => m.nvcTerminacao).displayName("nvcTerminacao").required()
  .ensure((m: ArtigoTerminacao) => m.bitAtivo).displayName("bitAtivo").required()
  .ensure((m: ArtigoTerminacao) => m.nvcForma).displayName("nvcForma").required()
  .ensure((m: ArtigoTerminacao) => m.idUtilizador).displayName("idUtilizador").required()
  .ensure((m: ArtigoTerminacao) => m.idUtilizadorultact).displayName("idUtilizadorultact").required()
  .ensure((m: ArtigoTerminacao) => m.dtmDatains).displayName("dtmDatains").required()
  .ensure((m: ArtigoTerminacao) => m.dtmDataultact).displayName("dtmDataultact").required()
  .ensure((m: ArtigoTerminacao) => m.timestamp).displayName("timestamp").required()
  .ensure((m: ArtigoTerminacao) => m.idUtilizadorNavigation).displayName("idUtilizadorNavigation").required()
  .ensure((m: ArtigoTerminacao) => m.idUtilizadorultactNavigation).displayName("idUtilizadorultactNavigation").required()
  .ensure((m: ArtigoTerminacao) => m.nvcArtigoNavigation).displayName("nvcArtigoNavigation").required()
  .ensure((m: ArtigoTerminacao) => m.nvcTerminacaoNavigation).displayName("nvcTerminacaoNavigation").required()
  .on(ArtigoTerminacao);

