import {bindable, observable} from 'aurelia-framework';

export class NumberFilter {
  @bindable value;
  @observable innerValue = 0;
  @observable op = "=";
  lock:boolean = false;

  // valueChanged(newValue, oldValue) {
  //   this.lock = true;
  //   let parts = newValue.split(" ");
  //   this.op = parts[0];
  //   this.innerValue = parts[1];
  // }

  innerValueChanged(newValue, oldValue) {
    this.value = this.op + " " + this.innerValue;
  }

  opChanged(newValue, oldValue) {
    this.value = this.op + " " + this.innerValue;
  }
}

