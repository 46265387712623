//Aurelia Imports
import {autoinject, computedFrom, LogManager} from "aurelia-framework";
import {Logger} from "aurelia-logging";
//App Imports
import {GlobalServices} from "../../services/global-services";
import {VmWrapper} from "../../models/VmWrapper";
import {ConfirmacaoDialog} from "../../dialogs/confirmacao-dialog";
import {PickerData} from "../../dialogs/picker-data";
import {FolhaConferencia} from "../../models/FolhaConferencia";
import {FolhaConferenciaLinha} from "../../models/FolhaConferenciaLinha";
import environment from "../../environment";
import {Router} from "aurelia-router";

@autoinject()
export class FolhaConferenciaRoute {
  private logger: Logger;
  private app: GlobalServices;

  private dia: string;
  private folhaConferencia: FolhaConferencia;
  /*
    0 - Ver todos (correntes + faltas)
    1 - correntes
    2 - faltas
   */
  private intTipoFolha: number;

  private isBusy: boolean       = false;
  private debug: string;
  private total: number         = 0;
  private totalExpedido: number = 0;

  //region getters & setters
  @computedFrom('folhaConferencia.idFolhaConferencia')
  get canPrint() {
    if (!this.folhaConferencia) return false;
    return +this.folhaConferencia.idFolhaConferencia > 0;
  }

  @computedFrom('folhaConferencia.FolhaConferenciaLinha')
  get totalDespachado() {
    if(environment.debug) console.log("[folha-conferencia]","get totalDespachado");
    if (!this.folhaConferencia) return 0;
    let total = this.folhaConferencia.FolhaConferenciaLinha.reduce((acc, el) => acc + (+el.intQuantidadeDespachada), 0);
    if(environment.debug) console.log("[folha-conferencia]","get totalDespachado", total);
    return total;
  }

  //endregion

  constructor(g: GlobalServices, protected router:Router) {
    this.app    = g;
    this.logger = LogManager.getLogger('Folha Conferencia');
  }

  activate(p) {
    this.dia   = p.dia;
    this.debug = p.debug;
    console.log(p, this.dia);
    this.doAction("INITIAL-LOAD", {});
    if (p.intTipoFolha != undefined) {
      this.intTipoFolha = +p.intTipoFolha;
    } else {
      this.intTipoFolha = 1;
    }
  }

  attached() {
    let $table = $("#table-sticky");
    $table.height(window.innerHeight - $table.offset().top - 56);
  }

  getPlano() {
    this.isBusy = true;
    return this.app.api.getProcessed("api/armazem/folha-conferencia", {dia: this.dia})
      // .then(r => this.app.api.processResponse(r))
      .then(o => FolhaConferencia.fromPOJSO(o))
    // .catch(e => {
    //   console.log("last catch", e);
    //   this.app.notificationErrorCompact(e);
    // });
  }

  private gravaLinha(vmw: VmWrapper<FolhaConferenciaLinha>) {
    this.isBusy = true;
    return this.app.api.postProcessed(`api/armazem/linha-folha-conferencia`, vmw.stateToPOJSO())
      .then((obj: any) => {
        console.log("Resposta do servidor", obj);
        if (obj.tipo === "confirm") {
          let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
          return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
            .whenClosed(resp => {
              if (!resp.wasCancelled) {
                //O operador escolheu SIM: aumenta o nível de confirmação
                return this.gravaLinha(vmw.nextLevel());
              } else {
                this.isBusy = false;
              }
            });
        } else {
          this.folhaConferencia.FolhaConferenciaLinha[vmw.payload.__index] = FolhaConferenciaLinha.fromPOJSO(obj, vmw.payload.__index);
          this.folhaConferencia.FolhaConferenciaLinha                      = [...this.folhaConferencia.FolhaConferenciaLinha];
          this.folhaConferencia.FolhaConferenciaLinha.forEach(el => el.recalcula());
          this.isBusy                                                      = false;
        }
      })
  }

  private apagaFolhaConferencia(vmw: VmWrapper<string>) {
    this.isBusy = true;
    return this.app.api.deleteProcessed(`api/armazem/folha-conferencia`, null, vmw.stateToPOJSO())
      .then((obj: any) => {
        console.log("Resposta do servidor", obj);
        if (obj.tipo === "confirm") {
          let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
          return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
            .whenClosed(resp => {
              if (!resp.wasCancelled) {
                //O operador escolheu SIM: aumenta o nível de confirmação
                return this.apagaFolhaConferencia(vmw.nextLevel());
              } else {
                this.isBusy = false;
              }
            });
        } else {
          this.doAction('INITIAL-LOAD', null);
        }
      })
  }

  //region Direct Actions

  showPickerData(dia?) {
    //let init: PickerProximoPrgPayload = {nvc_cliente_armazem: this.clienteArmazem.NvcClienteArmazem};
    this.app.ds
      .open({viewModel: PickerData, model: dia || this.dia, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          if (response.output && response.output.action)
            this.doAction(response.output.action, response.output.payload);
        } else {
        }
      });
  }

  transfere(tipo: string = "xls") {
    if (tipo != "xls") tipo = "pdf";
    let payload: any = {id_folha_conferencia: this.folhaConferencia.idFolhaConferencia, int_tipo_folha: +this.intTipoFolha};

    let route = "api/plano-expedicao/report/FolhaConferencia?tipo=" + tipo;
    if (this.debug === "debug") route += "&definicao=1";
    this.app.api
      .post(route, payload)
      .then(r => this.app.api.processBlobResponse(r))
      .then(blob => {
        let URL         = window.URL || (window as any).webkitURL;
        let downloadUrl = URL.createObjectURL(blob);
        let a           = document.createElement("a");
        // safari doesn't support theese yet
        a.href          = downloadUrl;
        a.download      = `folha-conferencia-${this.dia}.${tipo}`;
        document.body.appendChild(a);
        a.click();
        this.app.notificationSuccess("Ficheiro transferido para a pasta dos downloads com o nome: <strong>" + a.download + "</strong>");
        setTimeout(function () {
          URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);
        }, 200); // cleanup
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }

  //endregion

  /**
   * Dispatcher de ações para esta view (contido)
   * @param action
   * @param payload
   */
  public doAction(action: string, payload: any = {}) {
    this.logger.debug("Acção [folha-conferencia]", action, payload);
    try {
      switch (action) {
        case 'INITIAL-LOAD': {
          this.getPlano()
            .then((r: any) => {
              this.logger.debug("Folha de conferência carregada do servidor", r);
              this.folhaConferencia = r;
              this.isBusy           = false;
              this.total         = this.folhaConferencia.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intQuantidadeEnviar, 0);
              this.totalExpedido = this.folhaConferencia.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intQuantidadeExpedida, 0);
            })
            .catch(e => {
              this.app.notificationErrorCompact(e);
              this.isBusy = false;
            });
          break;
        }

        case 'ESCOLHE-DATA': {
          this.logger.debug("ESCOLHE-DATA", payload);
          this.isBusy = true;
          this.router.navigate(`${document.location.origin}/#/folha-conferencia/${payload}`);
          break;
        }

        case 'TIPO-VISIBILIDADE': {
          //console.debug("TIPO-VISIBILIDADE", payload);
          this.intTipoFolha  = +payload;
          this.total         = this.folhaConferencia.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intQuantidadeEnviar, 0);
          this.totalExpedido = this.folhaConferencia.getLinhasTipo(this.intTipoFolha).reduce((acc, el) => acc + el.intQuantidadeExpedida, 0);
          break;
        }

        case 'UPDATE-LINHA': {
          let vmw = (payload as FolhaConferenciaLinha).wrapIt();
          this.gravaLinha(vmw);
          break;
        }

        case 'REMOVE-DOCUMENTO': {
          let vmw = new VmWrapper<string>({payload: this.dia, confirmLevel: 0});
          this.apagaFolhaConferencia(vmw);
          break;
        }

        default: {
          this.logger.warn("Acção desconhecida [folha-conferencia]", action, payload);
          this.app.notificationError("Acção desconhecida");
        }
      }
    } catch (err) {
      this.logger.error(err);
      this.app.notificationErrorCompact(err);
    }
  }
}
