import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
// monkey patch
import * as _cropperjs from "cropperjs";
import {dataURItoBlob} from "../../utils/ItMultiPurpose";
import environment from "../../environment";

const Cropper: any = (<any>_cropperjs).default || _cropperjs;

@autoinject()
export class ImgEditDialog {
  protected controller: DialogController;
  private imgElement: HTMLImageElement;
  private file: File;
  private nome: string;
  private cropper: Cropper;
  private imgoutput: string;

  private tamanho: DOMRect      = new DOMRect();
  private tamanhoCorte: DOMRect = new DOMRect();

  constructor(controller: DialogController) {
    this.controller = controller;
  }

  // canDeactivate(){}

  canActivate(p) {
    if (p.file) {
      //poorman cloner
      let data = new FormData();
      data.append("file", p.file, p.file.name);
      //let _file = data.get("file");
      this.file = data.get("file") as File;
      // this.imgoutput = URL.createObjectURL(this.file);
      this.nome = this.file.name;

      this.tamanho.width  = 0;
      this.tamanho.height = 0;

      data = null;
    } else {
      throw new Error("O popup de edição de imagens não pode ser aberto pois o ficheiro não foi corretamente transmitido.");
    }
  }

  attached() {
    //this.imgElement.src    = this.imgoutput;
    this.nome              = this.file.name;
    this.imgElement.src    = URL.createObjectURL(this.file);
    //esperar que a imagem seja exibida no ecrã
    this.imgElement.onload = () => {
      if (environment.debug) console.log("[img-edit-dialog]", "handleSize");
      this.tamanho.width  = this.imgElement.naturalWidth;
      this.tamanho.height = this.imgElement.naturalHeight;
      this.enableCropper();
    };
    //this.imgElement.onchange = this.handleSize;
  }

  detached() {
    if (environment.debug) console.log("[img-edit-dialog]", "detached");
    this.disableCropper();
  }

  /**
   * Instancia o plugin cropper
   */
  enableCropper(ac:boolean = false) {
    //
    if (environment.debug) console.log("[img-edit-dialog]", "enableCropper");
    if (this.cropper) this.disableCropper();

    this.cropper = new Cropper(this.imgElement, {
      ///aspectRatio: 16 / 9,
      autoCrop: false,
      autoCropArea: 1,
      crop    : (event) => {
        //reter as coordenadas de cropping para as exibir no ecrã
        this.tamanhoCorte.width  = Math.round(+event.detail.width);
        this.tamanhoCorte.height = Math.round(+event.detail.height);

      },
    });
  }

  clearCrop() {
    this.cropper && this.cropper.clear();
  }

  fullArea() {
    this.cropper && this.cropper.crop();
  }

  /**
   * Destroi o plugin Cropper
   */
  disableCropper() {
    if (environment.debug) console.log("[img-edit-dialog]", "disableCropper");
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
  }

  previewCrop() {
    if (environment.debug) console.log("[img-edit-dialog]", "previewCrop");
    this.doCrop();
    this.disableCropper();
    this.imgElement.src = URL.createObjectURL(this.file);
    this.enableCropper();
    //this.imgElement
  }

  /**
   * Efetiva um corte
   */
  doCrop() {
    if (environment.debug) console.log("[img-edit-dialog]", "doCrop");
    if (this.cropper) {
      let croppedData = this.cropper.getData();
      let canvasData  = this.cropper.getCanvasData();
      let cropBoxData = this.cropper.getCropBoxData();

      //extrair um JPEG
      let url = this.cropper.getCroppedCanvas({
        fillColor: '#fff',
      }).toDataURL("image/jpg");

      //substituição do ficheiro para uma nova definição (com crop aplicado) (método 1)
      this.file = new File([dataURItoBlob(url)], this.nome, {type: 'image/jpg'});

      this.tamanho.width  = this.tamanhoCorte.width;
      this.tamanho.height = this.tamanhoCorte.height;
    }
  }

  /**
   * Fecha o popup e efetua o corte.
   */
  aceitar() {
    //detectar área de corte diferente do tamanho global da imagem?
    if (this.tamanhoCorte.width > 0 && this.tamanhoCorte.height > 0 && this.tamanho.width != this.tamanhoCorte.width && this.tamanho.height != this.tamanhoCorte.height) {
      this.doCrop();
    } else {
      //tenho de trocar o nome da imagem
      //this.file.name = this.nome;
    }
    return this.controller.ok({file: this.file});
  }

}
