import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from "../../../models/BaseViewModel";
import {VmWrapper} from "../../../models/VmWrapper";
import {ZzRemoteGridColumn} from "./ZzRemoteGridColumn";

export class ZzRemoteGrid extends BaseViewModel {
  public idZzRemoteGrid: number = 0;
  public dbConnection: string   = '';
  public nome: string           = '';
  public sqlDataSource: string  = '';
  public permanentSqlSearchFragment: string;
  public permanentSqlOrderFragment: string;
  public usedPagingType: string = '';
  public sqlTop: string;
  public rowCssPrefix: string   = '';
  public rdlTemplate: string;
  public titulo: string;
  public fontSize: number       = 0;
  public orientation: string    = '';
  public rowTemplate: string    = null;
  public headerTemplate: string = null;

  //relações
  public ZzRemoteGridColumn: ZzRemoteGridColumn[] = [];

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ZzRemoteGrid>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): ZzRemoteGrid {
    let model = new ZzRemoteGrid();

    model.idZzRemoteGrid             = +obj.idZzRemoteGrid;
    model.dbConnection               = obj.dbConnection;
    model.nome                       = obj.nome;
    model.sqlDataSource              = obj.sqlDataSource;
    model.permanentSqlSearchFragment = obj.permanentSqlSearchFragment;
    model.permanentSqlOrderFragment  = obj.permanentSqlOrderFragment;
    model.usedPagingType             = obj.usedPagingType;
    model.sqlTop                     = obj.sqlTop;
    model.rowCssPrefix               = obj.rowCssPrefix;
    model.rdlTemplate                = obj.rdlTemplate;
    model.titulo                     = obj.titulo;
    model.fontSize                   = +obj.fontSize;
    model.orientation                = obj.orientation;
    model.headerTemplate             = obj.headerTemplate;
    model.rowTemplate                = obj.rowTemplate;

    model.ZzRemoteGridColumn = ZzRemoteGridColumn.multipleFromPOJSO(obj.zzRemoteGridColumn);
    //model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): ZzRemoteGrid[] {
    if (objs && Array.isArray(objs)) return objs.map(ZzRemoteGrid.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state                = this.getState();
    state.zzRemoteGridColumn = this.ZzRemoteGridColumn.map(el => el.stateToPOJSO());
    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ZzRemoteGrid>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ZzRemoteGrid { return ZzRemoteGrid.fromPOJSO(this.stateToPOJSO());}

  getStaticType(): any {
    return ZzRemoteGrid;
  }

  // transforma o estado interno num adequado ao transporte num ficheiro
  public stateToPOJSOSafeId(): any {
    let state = this.getState();
    //no fundo apagam-se os ids
    if (state.idZzRemoteGrid) state.idZzRemoteGrid = 0;
    state.zzRemoteGridColumn = this.ZzRemoteGridColumn.map(el => el.stateToPOJSOSafeId());
    return state;
    //
  }

  //endregion estado

  public gerarTemplateModoLista() {

  }

  public toString() {
    return `ZzRemoteGrid`;
  }

  public toFgString() {
    return `<template>
<div class="row">
${this.ZzRemoteGridColumn.reduce((acc, el) => acc + el.toFgString(), "")}
</div>
<div class="row">
    <div class="col-md-6">
    </div>
    <div class="col-md-6 text-right">
      <button click.trigger="doAction('OK')" class="btn btn-success"><i class='fa fa-save'></i> Gravar</button>
      <button click.trigger="doAction('CANCEL')" class="btn btn-warning"><i class="fa fa-times"></i> Fechar</button>
    </div>
</div>
</template>`
  }
}

//region aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: ZzRemoteGrid) => m.idZzRemoteGrid).displayName('idZzRemoteGrid').required()
  .ensure((m: ZzRemoteGrid) => m.dbConnection).displayName('dbConnection').required()
  .ensure((m: ZzRemoteGrid) => m.nome).displayName('nome').required()
  .ensure((m: ZzRemoteGrid) => m.sqlDataSource).displayName('sqlDataSource').required()
  // .ensure((m: ZzRemoteGrid) => m.permanentSqlSearchFragment).displayName('permanentSqlSearchFragment').required()
  // .ensure((m: ZzRemoteGrid) => m.permanentSqlOrderFragment).displayName('permanentSqlOrderFragment').required()
  // .ensure((m: ZzRemoteGrid) => m.usedPagingType).displayName('usedPagingType').required()
  // .ensure((m: ZzRemoteGrid) => m.sqlTop).displayName('sqlTop').required()
  // .ensure((m: ZzRemoteGrid) => m.rowCssPrefix).displayName('rowCssPrefix').required()
  // .ensure((m: ZzRemoteGrid) => m.rdlTemplate).displayName('rdlTemplate').required()
  // .ensure((m: ZzRemoteGrid) => m.titulo).displayName('titulo').required()
  // .ensure((m: ZzRemoteGrid) => m.fontSize).displayName('fontSize').required()
  // .ensure((m: ZzRemoteGrid) => m.orientation).displayName('orientation').required()
  .on(ZzRemoteGrid);
//endregion
