import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";
import {MobileApp} from "../mobile-app";

@autoinject()
export class Utilizador {
  message: string;
  navigation: any;
  // private vHeaderHeight: number = 50;
  // public vRowHeight: number     = 50;
  // private vFooterHeight         = 25;

  constructor(private app: GlobalServices, protected mobileApp: MobileApp) {
  }

  canActivate() {
    if (environment.debug) console.log("[index]", "canActivate", this);
  }
}
