// Auto-gerado com typewriter

import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {Operador} from './Operador';
import {Utilizador} from './Utilizador';

export class UtilizadorOperador extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idUtilizador: number               = 0;
  public idOperador: number                 = 0;
  public idOperadorNavigation: Operador     = null;
  public idUtilizadorNavigation: Utilizador = null;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<UtilizadorOperador>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): UtilizadorOperador {
    let model = new UtilizadorOperador();
    model.setState(obj);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): UtilizadorOperador[] {
    if (objs && Array.isArray(objs)) return objs.map(UtilizadorOperador.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<UtilizadorOperador>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): UtilizadorOperador { return UtilizadorOperador.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `UtilizadorOperador`;
  }
}

/*
// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: UtilizadorOperador) => m.idUtilizador).displayName("idUtilizador").required()
  .ensure((m: UtilizadorOperador) => m.idOperador).displayName("idOperador").required()
  .ensure((m: UtilizadorOperador) => m.idOperadorNavigation).displayName("idOperadorNavigation").required()
  .ensure((m: UtilizadorOperador) => m.idUtilizadorNavigation).displayName("idUtilizadorNavigation").required()
  .on(UtilizadorOperador);
*/
