import {BaseViewModel} from "./BaseViewModel";
import {Api} from "../services/api";
import {VmWrapper} from "./VmWrapper";
import {FolhaConferenciaLinha} from "./FolhaConferenciaLinha";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class FolhaConferencia extends BaseViewModel {
  public idFolhaConferencia: number = 0;
  public dtmDataExpedicao: string   = "";
  public _dtmDataExpedicao: Date    = null;
  public nvcObservacoes: string     = "";
  public nvcCabecalho: string       = "";
  public nvcEstado: string          = "";
  public timestamp: string          = "";

  //relações
  public FolhaConferenciaLinha: FolhaConferenciaLinha[] = [];

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<FolhaConferencia>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): FolhaConferencia {
    let model = new FolhaConferencia();
    model.setState(obj);
    if (model.dtmDataExpedicao) model._dtmDataExpedicao = new Date(model.dtmDataExpedicao);
    //relações
    if (obj.folhaConferenciaLinha && Array.isArray(obj.folhaConferenciaLinha) && obj.folhaConferenciaLinha.length > 0) {
      model.FolhaConferenciaLinha = FolhaConferenciaLinha.multipleFromPOJSO(obj.folhaConferenciaLinha);
    }
    model.__index = index;

    return model;
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    if (this.FolhaConferenciaLinha && Array.isArray(this.FolhaConferenciaLinha) && this.FolhaConferenciaLinha.length > 0) {
      ret.folhaConferenciaLinha = this.FolhaConferenciaLinha.map(e => e.stateToPOJSO());
    }
    return ret;
  }

  public cloneInstance(): FolhaConferencia {
    return FolhaConferencia.fromPOJSO(this.stateToPOJSO());
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<GuiaTransporte>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<FolhaConferencia>({payload: this, confirmLevel: (+cl || 0)});
  }

  public save(api: Api) {
    return api
      .post("api/plano-expedicao/add-folha-expedicao", this.stateToPOJSO())
      .then(r => api.processResponse(r))
      .then(FolhaConferencia.fromPOJSO)
      ;
  }

  //endregion
//region op
  public getLinhasTipo(tipo:number){
    switch (tipo){
      case 1: return this.FolhaConferenciaLinha.filter(el => el.nvcObservacoes != 'Falta');
      case 2: return this.FolhaConferenciaLinha.filter(el => el.nvcObservacoes == 'Falta');
    }
    return this.FolhaConferenciaLinha;
  }
//endregion
}
