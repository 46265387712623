import {BaseViewModel} from "./BaseViewModel";
import {RegistoPlano} from "./RegistoPlano";
import {ValidationRules} from "aurelia-validation";
import {tiposRegisto} from "./TipoRegistoPlano";
import {CalendarioExpedicaoEpoca} from "./CalendarioExpedicaoEpoca";
import {DuplicacaoPlanoExpedicaoVm} from "./DuplicacaoPlanoExpedicaoVm";
import {VmWrapper} from "./VmWrapper";
import {RegistoEtiqueta} from "./RegistoEtiqueta";
import {GuiaTransporteLinha} from "./GuiaTransporteLinha";
import {Cliente} from "./Cliente";
import {Artigo} from "./Artigo";
import {dateISOString} from "../utils/ItNumeric";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class PlanoExpedicao extends BaseViewModel {
  public idPlanoExpedicao: number       = 0;
  public idPlanoExpedicaoOrigem: number = 0;
  public nvcArtigoTerminacao: string    = "";
  public nvcDescricaoArtigo: string     = "";
  public dtmPedido: string              = dateISOString(new Date());
  public dtmRececao: string             = dateISOString(new Date());
  public nvcEstado: string              = "";
  public nvcClienteArmazem: string      = "";
  public nvcCliente: string             = '';
  public nvcCodigoEncomenda: string     = "";
  public nvcRg: string                  = "";
  public nvcObservacoes: string         = "";
  public nvcTipoGravacao: string        = "";
  public nvcAcabamento: string          = "";
  public intQtyTotalEncomenda: number   = 0;
  public intQtyLancadas: number         = 0;
  public intPorProgramar: number        = 0;
  public intPorLancar: number           = 0;
  public intTotalCalculado: number      = 0;
  public intPorSair: number             = 0;
  public timestamp: string              = "";
  public nvcTipo: string                = "plano";
  public nvcIdDocErp: string;
  public nvcIdLinhaDocErp: string;
  public decDescontoEncomenda: number   = 0;
  public intTotalPrg: number            = 0;
  public intTotalProd: number           = 0;
  public intTotalSai: number            = 0;
  public intTotalCnf: number            = 0;

  //campos extra
  public _qtyTotal: number        = 0;
  public _qtyProgramadas: number  = 0;
  public _qtyPorProgramar: number = 0;
  public _qtyDespachadas: number  = 0;
  public _qtyPorLancar: number    = 0;
  public _qtyEmFalta: number      = 0;
  public _selected: boolean       = false;

  //extra ET
  public intQtyTotalPorProduzir: number = 0;

  public nvcClienteDescricao: string = '';

  //PRESENCA DE fNCs - pode funcionar de duas maneiras: +false ou contagem
  public temFnc: number = 0;

  //relações
  public RegistoPlanoIdPlanoExpedicaoNavigation: RegistoPlano[] = [];
  // public _datasDeRegisto: string[]                                       = [];
  public _RegistosReOrdenados: RegistoPlano[]                   = [];
  private _RegistosAgrupados: RegistosAgrupados[];

  public NvcClienteNavigation: Cliente;
  public NvcArtigoTerminacaoNavigation: Artigo = null;
  //public ArtigoNavigation: Artigo;

  // public

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<PlanoExpedicao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index?: number): PlanoExpedicao {
    let model = new PlanoExpedicao();
    model.setState(obj);
    model.__index = +index;
    if (obj.registoPlanoIdPlanoExpedicaoNavigation && Array.isArray(obj.registoPlanoIdPlanoExpedicaoNavigation) && obj.registoPlanoIdPlanoExpedicaoNavigation.length > 0) {
      model.RegistoPlanoIdPlanoExpedicaoNavigation = RegistoPlano.multipleFromPOJSO(obj.registoPlanoIdPlanoExpedicaoNavigation);
    }

    if (obj.nvcClienteNavigation) model.NvcClienteNavigation = Cliente.fromPOJSO(obj.nvcClienteNavigation);
    if (obj.nvcArtigoTerminacaoNavigation) {
      model.NvcArtigoTerminacaoNavigation = Artigo.fromPOJSO(obj.nvcArtigoTerminacaoNavigation);
      model.setQuantidadeEncomendada();
    }

    return model;
  }

  public static flatten(obj: any): any[] {
    // console.log("salta?", obj);
    //"salta" linhas filho
    if (+obj.IdPlanoExpedicaoOrigem > 0) {
      return [];
    }
    if (obj.inverseIdPlanoExpedicaoOrigemNavigation && Array.isArray(obj.inverseIdPlanoExpedicaoOrigemNavigation)) {
      return [obj, ...obj.inverseIdPlanoExpedicaoOrigemNavigation];
    }
    //caso defeito
    return [obj];
  }

  public static multipleFromPOJSO(objs: any[], apenasAtivos: boolean = true): PlanoExpedicao[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) {
      let flat = [];
      for (let o of objs) {
        let t = PlanoExpedicao.flatten(o);
        flat  = [...flat, ...t];
      }
      if (apenasAtivos)
        flat = flat.filter(el => el.nvcEstado.startsWith("a"));
      //return objs.map(PlanoExpedicao.fromPOJSO);
      return flat.map(PlanoExpedicao.fromPOJSO);
    }
    return [];
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    if (this.RegistoPlanoIdPlanoExpedicaoNavigation && Array.isArray(this.RegistoPlanoIdPlanoExpedicaoNavigation) && this.RegistoPlanoIdPlanoExpedicaoNavigation.length > 0) {
      ret.RegistoPlanoIdPlanoExpedicaoNavigation = this.RegistoPlanoIdPlanoExpedicaoNavigation.map(e => e.stateToPOJSO());
    }
    if (this.NvcClienteNavigation) {
      ret.nvcClienteNavigation = this.NvcClienteNavigation.stateToPOJSO();
    }
    return ret;
  }

  public stateToPOJSOSimple(): any {
    console.log("stateToPOJSOSimple", this);
    let ret                  = this.getState(false);
    ret.intQtyTotalEncomenda = +this._qtyTotal;
    return ret;
  }

  public cloneInstance(): PlanoExpedicao {
    return PlanoExpedicao.fromPOJSO(this.stateToPOJSO());
  }

  public wrapIt(cl?: number): VmWrapper<PlanoExpedicao> {
    return new VmWrapper<PlanoExpedicao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public getStaticType(): any {
    return PlanoExpedicao;
  }

  //endregion

  //region operações com registos -----------------------------------------------------------------------------------------------------------------------------------------------

  /**
   * Atualiza um registo existente ou adiciona um novo à linha.
   * Quando o modelo do registo é passado a esta função este já deve ser a resposta do servidor.
   * @param registo
   * @return {PlanoExpedicao}
   */
  public addRegisto(registo: RegistoPlano) {
    //console.log("addRegisto", registo, this);
    let findIndex = this.RegistoPlanoIdPlanoExpedicaoNavigation.findIndex(el => el.idRegistoPlano == registo.idRegistoPlano);
    if (findIndex >= 0) {
      this.RegistoPlanoIdPlanoExpedicaoNavigation[findIndex] = registo;
    } else {
      // todo: manter a ordem?
      // if (registo._dtmMovimento < this._periodoMin) {
      //
      // }
      let index = this.RegistoPlanoIdPlanoExpedicaoNavigation.findIndex(el => el._dtmMovimento > registo._dtmMovimento);
      if (index < 0) {
        this.RegistoPlanoIdPlanoExpedicaoNavigation.push(registo);
      } else {
        this.RegistoPlanoIdPlanoExpedicaoNavigation.splice(index, 0, registo);
      }
    }
    // this.sortRegistos();

    //atualiza os campos calculados _qtyTotal _qtyEmFalta
    return this;
  }

  // /**
  //  * Sobrepõe os registos associados à linha do plano
  //  * @param registos
  //  * @return {PlanoExpedicao}
  //  */
  // public setRegistos(registos: RegistoPlano[]) {
  //   if (Array.isArray(registos) && registos.length > 0)
  //     this.RegistoPlanoIdPlanoExpedicaoNavigation = registos;
  //   else
  //     this.RegistoPlanoIdPlanoExpedicaoNavigation = [];
  //   return this.sortRegistos();
  // }
  //
  // /**
  //  * Método fluente (em desuso por melhor definição de DTO)
  //  * @return {PlanoExpedicao}
  //  */
  // public sortRegistos() {
  //   this.RegistoPlanoIdPlanoExpedicaoNavigation.sort((a, b) => {
  //     if (a._dtmMovimento < b._dtmMovimento) {
  //       return -1;
  //     } else if (a.dtmMovimento == b.dtmMovimento) {
  //       return 0;
  //     } else return 1;
  //   });
  //   return this;
  // }

  /**
   * Pré arrumação dos registos a desenhar no plano
   * funde programações & cria faltas
   */
  public reordenaRegistosParaVisualizacaoEt(periodoVisivel: CalendarioExpedicaoEpoca[], inverseLookup) {
    // console.log("reordenaRegistosParaVisualizacao", periodoVisivel, inverseLookup);
    //Extrai um array com todas as datas usadas pelos registos.
    let datas: string[] = this.RegistoPlanoIdPlanoExpedicaoNavigation.map(el => el.dtmMovimento).filter((v, i, a) => a.indexOf(v) === i);

    let groupedObj: RegistosAgrupados[] = [];
    let _ontem: Date                    = new Date(Date.now() - 86400000);

    for (let data of datas) {
      // console.log(data, groupedObj);
      // à medida que processa os registos vai tomando conta dos índices em inverseLookup.
      // Caso não se encontre usa-se o periodoVisivel para determinar o índice.
      // Se as datas não estiverm compreendidas no período visível min = max = -1
      let _d: Date         = new Date(data);
      let indexDia: number = inverseLookup[data];// || periodoVisivel.findIndex(el => el._DtmData > _d);

      // a data não está definida no periodoVisivel aproxima-a para a seguinte
      if (indexDia === undefined) {
        indexDia = periodoVisivel.findIndex(el => el._dtmData > _d);
        if (indexDia === 0) indexDia = -1;
      }
      // se o registo estiver fora dos limites visíveis nem se preocupa com ele?
      // if (indexDia < 0) continue;

      let iterObj: RegistosAgrupados = groupedObj.find(el => el.index == indexDia);
      if (!iterObj) {
        iterObj       = new RegistosAgrupados();
        iterObj.index = indexDia;
        groupedObj.push(iterObj);
      }

      //isola os registos do dia e regista o indíce no plano
      let registosDoDia = this.RegistoPlanoIdPlanoExpedicaoNavigation.filter(el => el.dtmMovimento == data);
      //registosDoDia.forEach(el => el._indicePlano = indexDia);

      //filtra por tipo LCM
      // iterObj.lcm = [...iterObj.lcm, ...registosDoDia.filter(el => el.nvcTipo === tiposRegisto.LCM)];

      //filtra por tipo PRG
      iterObj.prg = registosDoDia.filter(el => el.nvcTipo === tiposRegisto.PRG);

      //filtra por tipo PROD
      iterObj.prod = registosDoDia.filter(el => el.nvcTipo === tiposRegisto.PROD);

      //filtra por tipo SAI
      iterObj.sai = registosDoDia.filter(el => el.nvcTipo === tiposRegisto.SAI);
    }
    this._RegistosAgrupados = groupedObj;
    return groupedObj;
  }

  /**
   * Obtém os registos que ainda não foram listados no método reordenaRegistosComDependentes
   * @return {RegistoPlano[]}
   */
  public getRegistosAdicionais() {
    return this.RegistoPlanoIdPlanoExpedicaoNavigation.filter(el => el.nvcTipo == tiposRegisto.QTY);
  }

  /**
   * Simulações, what else?
   * @param registo
   * @return {string}
   */
  public simulaAddRegisto(registo: RegistoPlano): string {
    let novalinha = this.cloneInstance();

    novalinha.addRegisto(registo);
    if (novalinha.getTotalProgramadas() > novalinha.getTotalEncomenda()) return "A linha irá ficar com maior quantidade Programada do que a declarada na encomenda de fornecedor.";
    return "ok";
  }

  /**
   * Devolve uma instância do registo do plano pelo seu id
   * @param idRegisto
   * @return {undefined|RegistoPlano}
   */
  public getRegisto(idRegisto: number) {
    let registoPlano = this.RegistoPlanoIdPlanoExpedicaoNavigation.find(el => el.idRegistoPlano == +idRegisto);
    //[edit e-t] injeta sempre a navegação para o plano de expedição
    if (registoPlano) {
      registoPlano.IdPlanoExpedicaoNavigation = this;
    }
    return registoPlano;
  }

  /**
   * Devolve uma instância do registo pe
   * @param data
   * @return {undefined|RegistoPlano[]}
   */
  public getRegistoPorData(data: string) {
    return this.RegistoPlanoIdPlanoExpedicaoNavigation.filter(el => el.dtmMovimento == data);
  }

  /**
   * Devolve uma instância do registo pe
   * @param datas
   * @return {undefined|RegistoPlano[]}
   */
  public getRegistosPorDatas(datas: string[]) {
    return datas.map(el => this.getRegistoPorData(el));
  }

  /**
   * Devolve uma instância do registo pe
   * @param indices
   * @return {undefined|RegistoPlano[]}
   */
  public getRegistosPorIndices(indices: number[]) {
    return this._RegistosAgrupados.filter(ra => indices.includes(ra.index));
  }

  /**
   * O total de peças previstas para a encomenda (total de peças na linha + somatório de registos do tipo QTY)
   * @return {number}
   */
  public getTotalEncomenda(): number {
    return this.intQtyTotalEncomenda + this.RegistoPlanoIdPlanoExpedicaoNavigation.filter(e => e.nvcTipo == tiposRegisto.QTY).reduce((acc, el) => (acc + (+el.intDelta)), 0);
  }

  // /**
  //  * O total de peças lançadas na linha
  //  * @return {number}
  //  */
  // public getTotalLancadas(): number {
  //   return this.RegistoPlanoIdPlanoExpedicaoNavigation.filter(e => e.nvcTipo == tiposRegisto.LCM).reduce((acc, el) => (acc + (+el.intDelta)), 0);
  // }

  /**
   * O total de peças despachadas (ou com guia de transporte criada)
   * @return {number}
   */
  public getTotalDespachadas(): number {
    return this.RegistoPlanoIdPlanoExpedicaoNavigation.filter(e => e.nvcTipo == tiposRegisto.SAI).reduce((acc, el) => (acc + (+el.intDelta)), 0);
  }

  /**
   * O total de peças destinadas para programação.
   * @return {number}
   */
  public getTotalProgramadas(): number {
    return this.RegistoPlanoIdPlanoExpedicaoNavigation.filter(e => e.nvcTipo == tiposRegisto.PRG).reduce((acc, el) => (acc + (+el.intDelta)), 0);
  }

  public getTotalPorProgramar(): number { return this.getTotalEncomenda() - this.getTotalProgramadas();}

  // public getTotalPorLancar(): number { return this.getTotalEncomenda() - this.getTotalLancadas();}

  //public getData
  //endregion

  //region Duplicação
  duplicateMe() {
    let duplicacaoPlanoExpedicaoVm = new DuplicacaoPlanoExpedicaoVm({
      idPlanoExpedicaoOriginal       : this.idPlanoExpedicao,
      nvcTipoGravacao                : this.nvcTipoGravacao,
      nvcAcabamento                  : this.nvcAcabamento,
      idRegistoPlanoOriginario       : null,
      intQtyTotalEncomenda           : 0,
      nvcArtigoTerminacaoSobreposicao: this.nvcArtigoTerminacao,
      nvcDescricaoArtigoSobreposicao : this.nvcDescricaoArtigo,
      nvcRgSobreposicao              : this.nvcRg,
      nvcObservacoes                 : `Gerado de #${this.idPlanoExpedicao}: ${this.nvcArtigoTerminacao}/${this.nvcCodigoEncomenda}`
    });
    //console.log(duplicacaoPlanoExpedicaoVm);
    return duplicacaoPlanoExpedicaoVm;
  }

  //endregion

  //region E-T

  public setQuantidadeEncomendada() {
    if (this.NvcArtigoTerminacaoNavigation) {
      this.NvcArtigoTerminacaoNavigation.setQuantidadeEncomendada(+this.intQtyTotalEncomenda);
    }
  }

  public setCliente(rowRef: any): PlanoExpedicao {
    //console.log("setCliente", "rowRef", rowRef);
    let cliente = Cliente.fromPOJSO(rowRef);
    //apenas faz set ao plano associado se não existir nenhum definido quando o cliente ainda não o está
    if (!this.nvcClienteArmazem) {
      this.nvcClienteArmazem = cliente.nvcClienteArmazem;
    }
    this.nvcCliente           = cliente.nvcCliente;
    this.NvcClienteNavigation = cliente;
    return this;
  }

  public setArtigo(rowRef: any): PlanoExpedicao {
    let artigo               = Artigo.fromPOJSO(rowRef);
    this.nvcArtigoTerminacao = artigo.nvcArtigo;
    this.nvcDescricaoArtigo  = artigo.nvcDescricao;
    return this;
  }

  //endregion E-T

  public ToString(): string {
    return `Linha Encomenda ${this.nvcClienteArmazem} [#${this.idPlanoExpedicao}] Ref:${this.nvcArtigoTerminacao} CDE:${this.nvcCodigoEncomenda} Qty:${this.getTotalEncomenda()}`;
  }

  public toString(): string {
    return `Linha Encomenda ${this.nvcClienteArmazem} [#${this.idPlanoExpedicao}] Ref:${this.nvcArtigoTerminacao} CDE:${this.nvcCodigoEncomenda} Qty:${this.getTotalEncomenda()}`;
  }
}

// export let PlanoExpedicaoRules =
ValidationRules
  .ensure((m: PlanoExpedicao) => m.nvcDescricaoArtigo).required()
  .ensure((m: PlanoExpedicao) => m.nvcArtigoTerminacao).displayName("artigo").required()
  .ensure((m: PlanoExpedicao) => m.nvcClienteArmazem).displayName("plano").required()
  .ensure((m: PlanoExpedicao) => m.nvcCliente).displayName("cliente").required()
  .ensure((m: PlanoExpedicao) => m.dtmPedido).displayName("Data do pedido").required()
  .ensure((m: PlanoExpedicao) => m.intQtyTotalEncomenda).displayName("Qty Total Encomenda")
  .satisfies(value => (Number.parseInt("" + value) >= 0)).withMessage(`A quantidade da encomenda deve ser um natural.`)
  .satisfies(value => (Number.parseInt("" + value) > 0)).withMessage(`A quantidade da encomenda deve ser positiva`).when(m => m.idPlanoExpedicao == 0)
  // .ensure((m: PlanoExpedicao) => m.dtmPedido).displayName("Data do pedido").required()
  //.ensure((m: PlanoExpedicao) => m.DtmRececao).displayName("Data para a receção").required()
  .on(PlanoExpedicao);

/**
 * Esta interface ajuda a criar um grupo (diário) de registos do plano,
 */
export class RegistosAgrupados {
  index: number;
  //lcm: RegistoPlano[],
  prg?: RegistoPlano[]  = [];
  prod?: RegistoPlano[] = [];
  cnf?: RegistoPlano[]  = [];
  sai?: RegistoPlano[]  = [];
  fal?: RegistoPlano[]  = [];
}

export const tipoLinhaPlanoExpedicao = {
  'erp'     : 'erp',
  'plano'   : 'plano',
  'controlo': 'controlo',
};
