import {autoinject, bindable, bindingMode, inlineView} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";

/**
 * wrapper para um elemento HMTL img que usa um get (com headers de authorization) e um blob para mostrar uma dada imagem
 *
 * o objetivo é o de manter uma interface simples do tipo
 * exemplo de uso
 * <it-img src="api/qualidade/img?id=${modelo.id}"></it-img>
 */
@autoinject()
@inlineView('<template><img src.bind="url" class="${className}" alt="${fileName}"></template>')
export class ItImg {
  @bindable({bindingMode: bindingMode.oneTime}) private debug: boolean = true;
  @bindable() private src: string;
  @bindable() private className: string                                = "img-responsive";
  @bindable() private fileName: string;

  private internalObject:any;

  //guarda um url válido, seja para uma imagem ou para um blob
  private url: string;

  constructor(private app: GlobalServices) {}

  public srcChanged(newVal, oldVal) {
    if (newVal != oldVal) {
      this.fetchResource();
    }
  }

  /**
   * cleanup
   */
  detached(){
    URL.revokeObjectURL(this.url);
    URL.revokeObjectURL(this.internalObject);
  }

  public fetchResource() {
    if (this.debug) console.log("[it-img]", "fetchResource");
    return this.app.api.getBlob(this.src)
      .then(blob => {
        //cria-se um ficheiro se tivermos um nome
        if (!this.fileName) {
          this.internalObject = blob;
        } else {
          this.internalObject = new File([blob], this.fileName);
        }
        this.url = URL.createObjectURL(this.internalObject);
      })
      .catch(err => {
        console.error(err);
        this.url = "/img/not-found.png";
      })
    //   .then(r => this.app.api.processBlobResponse)
  }
}
