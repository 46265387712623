import {BaseViewModel} from "./BaseViewModel";
import {ValidationRules} from "aurelia-validation";
import {GuiaTransporteLinha} from "./GuiaTransporteLinha";
import {RegistoPlano} from "./RegistoPlano";
import {Cliente} from "./Cliente";
import {Api} from "../services/api";
import {GlobalServices} from "../services/global-services";
import {VmWrapper} from "./VmWrapper";
import {ConfirmacaoDialog} from "../dialogs/confirmacao-dialog";
import {estadosGuiaTransporte} from "./EstadoGuiaTransporte";
import environment from "../environment";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class GuiaTransporte extends BaseViewModel {
  public idGuiaTransporte: number           = 0;
  public nvcCliente: string                 = "";
  public dtmDataExpedicao: string           = "";
  public dtmDataDocumento: string           = "";
  public nvcClienteDestinatario: string     = "";
  public nvcObservacoes: string             = "";
  public nvcCabecalho: string               = "";
  public intNumero: number                  = 0;
  public nvcEstado: string                  = "aberto";
  public nvcLocalCarga: string              = "N/instalações:";
  public nvcLocalEntrega: string            = "V/instalações";
  public nvcObservacoesLocalCarga: string   = "";
  public nvcObservacoesLocalEntrega: string = "";
  public nvcTipo: string                    = "erp";
  //public timestamp: number = 0;
  //public _dtmDataExpedicao: Date = null;
  //public _dtmDataDocumento: Date = null;

  //relações
  public GuiaTransporteLinha: GuiaTransporteLinha[] = [];
  public NvcClienteNavigation: Cliente              = null;

  public LinhasFlat() {
    if (environment.debug) console.log("[guiatransporte]", "LinhasFlat", this.GuiaTransporteLinha);
    let a = [];
    this.GuiaTransporteLinha.forEach(el => {
      a.push(el);
      el.InverseIdGuiaTransporteLinhaOrigemNavigation.forEach(el => a.push(el));
      console.log(a)
    });
    return a;
  }

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<GuiaTransporte>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): GuiaTransporte {
    //if (environment.debug) console.log("[guiatransporte]", "fromPOJSO", obj);
    let model = new GuiaTransporte();
    model.setState(obj);

    //limpar quaisquer quebras de linhas mal codificadas.
    if (model.nvcClienteDestinatario) model.nvcClienteDestinatario = model.nvcClienteDestinatario.replace(/\\n/g, "\n");
    if (obj.guiaTransporteLinha) model.GuiaTransporteLinha = GuiaTransporteLinha.multipleFromPOJSO(obj.guiaTransporteLinha);
    if (obj.nvcClienteNavigation) model.NvcClienteNavigation = Cliente.fromPOJSO(obj.nvcClienteNavigation);
    model.__index = index;

    return model;
  }

  public static multipleFromPOJSO(objs: any): GuiaTransporte[] {
    //if (environment.debug) console.log("[guiatransporte]", "multipleFromPOJSO", objs);
    if (objs) {
      if (Array.isArray(objs)) return objs.map((el, i) => GuiaTransporte.fromPOJSO(el, i));
      return [GuiaTransporte.fromPOJSO(objs, 0)];
    }
    return [];
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    if (this.GuiaTransporteLinha && Array.isArray(this.GuiaTransporteLinha) && this.GuiaTransporteLinha.length > 0) {
      ret.guiaTransporteLinha = this.GuiaTransporteLinha.map(e => e.stateToPOJSO());
    }
    return ret;
  }

  public cloneInstance(): GuiaTransporte {
    return GuiaTransporte.fromPOJSO(this.stateToPOJSO());
  }

  getStaticType(): any {
    return GuiaTransporte;
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<GuiaTransporte>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<GuiaTransporte>({payload: this, confirmLevel: (+cl || 0)});
  }

  // public save(api: Api) {
  //   return api
  //     .post("api/plano-expedicao/add-guia-transporte", this.stateToPOJSO())
  //     //todo: passo intermédio? para verificação de erros?
  //     .then(r => api.processResponse(r))
  //     .then(GuiaTransporte.fromPOJSO)
  //     ;
  // }
  //
  // /**
  //  * Gravação envolvida com confirmações de nível.
  //  * @param app
  //  * @param vmw
  //  * @return {Promise<TResult2|TResult1>}
  //  */
  // public static saveWithConfirm(app: GlobalServices, vmw: VmWrapper<GuiaTransporte>) {
  //   return app.api
  //     .post("api/plano-expedicao/add-guia-transporte", vmw.stateToPOJSO())
  //     .then(r => app.api.processResponse(r))
  //     .then((obj: any) => {
  //       if (obj.tipo) {
  //         if (obj.tipo === "confirm") {
  //           let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => { return acc + '<li>' + el + '</li>' }, '')}</ul>`;
  //           return app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
  //             .whenClosed(resp => {
  //               if (!resp.wasCancelled) {
  //                 //O operador escolheu SIM: aumenta o nível de confirmação
  //                 return GuiaTransporte.saveWithConfirm(app, vmw.nextLevel());
  //               } else {
  //                 throw new Error("A ação foi cancelada.");
  //               }
  //             });
  //         }
  //         if (obj.tipo === "select") {
  //           return {action: "FILL-GAPS", payload: GuiaTransporte.fromPOJSO(obj.modelo)}
  //           // return {action: "FILL-GAPS", payload: vmw.payload}
  //         }
  //         throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
  //       }
  //       return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
  //     })
  //     //.then(GuiaTransporte.fromPOJSO)
  //     ;
  // }

  //endregion

  //region Linhas
  public getQtyTotal() {
    return this.GuiaTransporteLinha.reduce((acc, el) => acc + (+el.intQuantidade || 0), 0)
  }

  public get intTotal() {
    return this.GuiaTransporteLinha.reduce((acc, el) => acc + (+el.intQuantidade || 0), 0)
  }

  public getCssClassMenu(): string {
    if (this.nvcEstado == estadosGuiaTransporte.aberto) {
      return "";
    }
    return "danger";
  }

  //não usar?
  public uri() {
    //http://localhost:9000/#/plano/guia-transporte/2018-07-30/FMI?numero=10551
    return `#/plano/guia-transporte/${this.dtmDataDocumento}/${this.nvcCliente}?numero=${this.intNumero}`;
  }

  public toString() {
    return `Guia de Transporte Nº ${this.intNumero}`;
  }

  public adicionarLinha() {
    var num = this.GuiaTransporteLinha.length == 0 ? 0 : Math.max.apply(Math, this.GuiaTransporteLinha.map(function (o) { return o.intNumero; }));
    this.GuiaTransporteLinha.push(new GuiaTransporteLinha({intNumero: num + 1}));
  }

  public removerLinha(el) {
    this.GuiaTransporteLinha = this.GuiaTransporteLinha.filter(lin => lin != el);
  }

  /**
   * restaura um cliente a partir de uma linha de picker
   * @param rowRef
   */
  public setCliente(rowRef: any) {
    let cliente               = Cliente.fromPOJSO(rowRef);
    this.nvcCliente           = cliente.nvcCliente;
    this.NvcClienteNavigation = cliente;
  }

  public setGuiaTransporteLinha(rowRef) {
    if (!rowRef) return;
    let linha = RegistoPlano.fromPOJSO(rowRef);
    if (this.GuiaTransporteLinha.some(el => el.idRpOrigem == linha.idRegistoPlano)) {
      //todo: extender a condição do exists da view para quando existir um caso anulado
      throw new Error("O registo de produção já se encontra adicionado na guia atual.");
    }
  }

  /**
   * Adiciona uma linha por inferência a partir de um RegistoPlano do tipo PROD
   * @param rp
   */
  public adicionaLinhaRp(rp:RegistoPlano) {
    let num   = this.GuiaTransporteLinha.length == 0 ? 0 : Math.max.apply(Math, this.GuiaTransporteLinha.map( (o) =>  o && +o.intNumero));
    // if (this.GuiaTransporteLinha.some(el => el.idRpOrigem == rp.idRegistoPlano)) {
    //   throw new Error("O registo de produção já se encontra adicionado na guia atual.");
    // }

    this.GuiaTransporteLinha.push(new GuiaTransporteLinha({
      intNumero                      : +num + 1,
      idRpOrigem                     : rp.idRegistoPlano,
      intQuantidade                  : rp.intDelta,
      nvcArtigoTerminacao            : rp.IdPlanoExpedicaoNavigation && rp.IdPlanoExpedicaoNavigation.nvcArtigoTerminacao,
      nvcDescricaoArtigo             : rp.IdPlanoExpedicaoNavigation && rp.IdPlanoExpedicaoNavigation.nvcDescricaoArtigo,
      nvcArtigoTerminacaoSobreposicao: rp.IdPlanoExpedicaoNavigation && rp.IdPlanoExpedicaoNavigation.nvcArtigoTerminacao,
      nvcDescricaoArtigoSobreposicao : rp.IdPlanoExpedicaoNavigation && rp.IdPlanoExpedicaoNavigation.nvcDescricaoArtigo,
      nvcCodigoEncomenda             : rp.IdPlanoExpedicaoNavigation && rp.IdPlanoExpedicaoNavigation.nvcCodigoEncomenda,
    }));
  }

  /**
   * adiciona linhas de GT com base numa lista de registos PROD livres
   *
   * apenas adiciona registos que ainda não estejam associados
   *
   * @param rps
   */
  adicionaLinhasRpProd(rps: RegistoPlano[]) {
      if(rps && Array.isArray(rps)) {
        //isolar os ids de Rp existentes em linhas
        let rpExistentes = this.GuiaTransporteLinha.filter(el => el.idRpOrigem > 0).map(el => el.idRpOrigem);
        rps
          .filter(rp => !rpExistentes.includes(rp.idRegistoPlano))
          .forEach(rp => this.adicionaLinhaRp(rp))
      }
  }

  //?? why??
  // public setGuiaTransporteCliente(rowRef) {
  //   if (!rowRef) return;
  //   //console.log("SET GUIA TRANSPORTE CLIENTE", rowRef);
  //   let cliente               = Cliente.fromPOJSO(rowRef);
  //   this.nvcCliente           = cliente.nvcCliente;
  //   this.NvcClienteNavigation = cliente;
  // }
}

//endregion
ValidationRules
//.ensure((m: GuiaTransporte) => m.intNumero).displayName("Número").required().satisfies(x => x > 0)
  .ensure((m: GuiaTransporte) => m.dtmDataExpedicao).displayName("Data").required()
  .ensure((m: GuiaTransporte) => m.nvcCliente).displayName("Cliente").required()
  .ensure((m: GuiaTransporte) => m.nvcClienteDestinatario).displayName("Morada").required()
  .ensure((m: GuiaTransporte) => m.GuiaTransporteLinha).displayName("Linhas").minItems(1)
  .on(GuiaTransporte);
