import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";
import {GlobalServices} from "../services/global-services";
import {DuplicacaoPlanoExpedicaoVm} from "../models/DuplicacaoPlanoExpedicaoVm";
import {PickerArtigo} from "./picker-artigo";
import {ConfirmacaoDialog} from "./confirmacao-dialog";
import {VmWrapper} from "../models/VmWrapper";
import {tiposGravacao} from "../utils/ItMultiPurpose";
import environment from "../environment";

@autoinject()
export class DuplicaLinhaPlanoDialog {
  //static inject = [DialogController, ValidationControllerFactory];
  protected validationController: ValidationController;
  protected controller: DialogController;
            app: GlobalServices;
  // renderer: BootstrapFormRenderer;
  protected duplicacaoVm: DuplicacaoPlanoExpedicaoVm;
  private rowIndex: number;
  private cellIndex: number;
  private isBusy: boolean = false;

  constructor(controller: DialogController, app: GlobalServices, vcf: ValidationControllerFactory) {
    console.log("DuplicaLinhaPlanoDialog constructor");
    this.controller           = controller;
    this.app                  = app;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    // this.validationController.reset();
  }

  activate(payload: DuplicaLinhaPlanoPayload) {
    //console.log(payload);
    this.duplicacaoVm = payload.duplicacaoVm;
    this.rowIndex     = payload.rowIndex;
    this.cellIndex    = payload.cellIndex;
    if (environment.debug) console.log("[duplica-linha-plano-dialog]", payload, this.duplicacaoVm);
  }

  detached() {
    // this.validationController.removeRenderer(this.renderer);
    // this.validationController.reset();
  }

  attached() {
    // this.renderer = new BootstrapFormRenderer();
    // this.validationController.addRenderer(this.renderer);
    // $("#DtmPedido").ejDatePicker({
    // locale          : "pt-PT",
    //   value     : this.duplicacaoVm.DtmPedido, // sets the current date
    //   dateFormat: "yyyy-MM-dd", // sets the date format
    //   width     : "100%",
    //   change    : (v) => {
    //     // console.log("date changed", v);
    //     try {
    //       if (v.value)
    //         this.duplicacaoVm.DtmPedido = v.value;
    //       else
    //         this.duplicacaoVm.DtmPedido = null;
    //     } catch (err) {
    //       this.duplicacaoVm.DtmPedido = null;
    //     }
    //     this.validationController.validate({object: this.duplicacaoVm, propertyName: 'DtmPedido'});
    //   }
    // });
    // $("#DtmRececao").ejDatePicker({
    // locale          : "pt-PT",
    //   value     : this.duplicacaoVm.DtmRececao, // sets the current date
    //   dateFormat: "yyyy-MM-dd", // sets the date format
    //   width     : "100%",
    //   change    : (v) => {
    //     // console.log("date changed", v);
    //     try {
    //       if (v.value) {
    //
    //         this.duplicacaoVm.DtmRececao = v.value;
    //         // console.log(this.duplicacaoVm.dto());
    //       }
    //       else
    //         this.duplicacaoVm.DtmRececao = null;
    //     } catch (err) {
    //       this.duplicacaoVm.DtmRececao = null;
    //     }
    //     this.validationController.validate({object: this.duplicacaoVm, propertyName: 'DtmRececao'});
    //   }
    // });
    $('#nvcTipoGravacao').ejAutocomplete({
      locale         : "pt-PT",
      width          : "100%",
      watermarkText  : "Gravação - A, O, P, S",
      emptyResultText: "Outros",
      showPopupButton: true,
      dataSource     : tiposGravacao,
      focusIn        : function (argument: any) {
        console.log(argument);
        $("#nvcTipoGravacao").ejAutocomplete("open");
      },
      change         : (v) => {
        // console.log("change", v, this.duplicacaoVm.nvcTipoGravacao);
        try {
          if (v.value != this.duplicacaoVm.nvcTipoGravacao) {
            this.duplicacaoVm.nvcTipoGravacao = v.value;
          }
          // else
          //   this.duplicacaoVm.nvcTipoGravacao = "";
        } catch (err) {
          this.duplicacaoVm.nvcTipoGravacao = "";
        }
        // console.log("change end", v, this.duplicacaoVm.nvcTipoGravacao, this.duplicacaoVm.stateToPOJSO());
        this.validationController.validate({object: this.duplicacaoVm, propertyName: 'nvcTipoGravacao'});
      }
    });
  }

  cleanup() {
    //chamam-se os destrutores dos controlos criados no popup antes de invocar o seu fecho.
    // $("#DtmPedido").ejDatePicker('destroy');
    // $("#DtmRececao").ejDatePicker('destroy');
    $("#nvcTipoGravacao").ejAutocomplete('destroy');
  }

  submit() {
    console.log("Submit", this.duplicacaoVm);
    // let o = this.duplicacaoVm.dto();
    // console.log(o);

    this.validationController.validate()
      .then(r => {
        // console.log("Validation Results", r);
        if (r.valid) {
          this.isBusy = true;
          this.gravaDuplicacao(this.duplicacaoVm.warpIt());
        }
      })
  }

  gravaDuplicacao(vmw: VmWrapper<DuplicacaoPlanoExpedicaoVm>) {
    return this.app.api
      .postProcessed("api/plano-expedicao/duplica-linha", vmw.stateToPOJSO())
      // .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravaDuplicacao(vmw.nextLevel());
                } else {
                  this.isBusy = false;
                  //return {action: "INITIAL-LOAD", payload: {}};
                  //return this.doAction("ESCOLHE-GUIA", this._indexEmEdicao);

                  //throw new Error("A ação foi cancelada.");
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        //console.log("returning", GuiaTransporte.fromPOJSO(obj));
        this.cleanup();
        //todo: melhorar o caso positivo
        return this.controller.ok({action: "REFRESH-TABELA", payload: null});
      })
      .catch(err => {
        console.error("Erro", err);
        this.app.notificationErrorCompact(err);
        this.isBusy = false;
      });
  }

  showPickerArtigo() {
    this.app.ds
      .open({viewModel: PickerArtigo, model: null, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          //console.log('good - ', response.output);
          this.duplicacaoVm.nvcArtigoTerminacaoSobreposicao = response.output.nvc_forma;
          this.duplicacaoVm.nvcDescricaoArtigoSobreposicao  = response.output.nvc_descricao;
        } else {
          //console.log('bad!');
        }
        //console.log(response.output);
      }).catch(err => this.app.notificationErrorCompact(err));
  }

  // listaRegistosLinha() {
  //   this.cleanup();
  //   return this.controller.ok({action: "LISTAGEM-REGISTOS-LINHA", payload: {rowIndex: this.rowIndex, cellIndex: this.cellIndex}})
  // }
}

export interface DuplicaLinhaPlanoPayload {
  duplicacaoVm: DuplicacaoPlanoExpedicaoVm,
  rowIndex: number,
  cellIndex: number
}