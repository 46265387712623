import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";
import {GuiaTransportePlanoExpedicaoVm} from "../models/GuiaTransportePlanoExpedicaoVm";
import {ConfirmacaoDialog} from "./confirmacao-dialog";
import {VmWrapper} from "../models/VmWrapper";
import {GuiaTransporte} from "../models/GuiaTransporte";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

@autoinject()
export class NovaProgramacaoLinhaEncomenda {

  // funcionalidade usada nas guias de transporte (PARA ABATER, KILL IT!)
  protected controller: DialogController;
  private app: GlobalServices;
  private model: GuiaTransportePlanoExpedicaoVm = null;
  private validationController: ValidationController;

  constructor(controller: DialogController, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  activate(p) {
    //obter a lista de clientes.
    if (!p.model || !p.app) {
      console.error("os parametros do dialog não foram corretamente passados")
    }
    if (environment.debug) console.log("[nova-programacao-linha-encomenda]", "activate", p);
    this.app   = p.app;
    this.model = p.model;
  }

  attached() {
    //
  }

  validar() {
    this.validationController.validate()
      .then(vr => {
        if (vr.valid) {
          this.submitWithConfirm(this.model.wrapIt());
        } else {
          this.app.notificationErrorCompact("O formulário apresenta erros")
        }
      })
      .catch(err => this.app.notificationErrorCompact(err))
  }

  submitWithConfirm(pl: VmWrapper<GuiaTransportePlanoExpedicaoVm>) {
    // GlobalServices.processVmWrapResponse(this.app, `api/plano-expedicao/linha-guia-transporte-de-linha-encomenda`, pl.wrapIt(), (r) => {console.log('success callback', r);}, (r) => {console.log('cancelation callback', r);});
    this.app.api.post(`api/plano-expedicao/linha-guia-transporte-de-linha-encomenda`, pl)
      .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        console.log(obj);
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.submitWithConfirm(pl.nextLevel());
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        ///if(r.tipo)
        this.controller.ok({action: 'UPDATE-GUIA-EDICAO', payload: GuiaTransporte.fromPOJSO(obj)});
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }
}
