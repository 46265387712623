//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import {PickerRemoteGrid} from "../../it-features/remote-grid/picker-remote-grid";
import {Artigo} from "../../models/Artigo";
import {RegistoEtiqueta} from "../../models/RegistoEtiqueta";
import {ComposeDialog, ComposeDialogOptions} from "../../dialogs/compose-dialog";
import {planoComponent} from "../plano-component";
import {ArmazemEtiqueta} from "../../models/ArmazemEtiqueta";

@autoinject()
export class EtiquetasListagem {
  private isBusy: boolean = false;
  private rg: RemoteGrid;

  constructor(public app: GlobalServices, private parent: planoComponent) { }

  canActivate() {
    if (!this.app.auth.can("App.VerPlano")) {
      this.app.notificationError("Não dispõe da permissao para visualizar etiquetas de entrada de armazem");
      return false;
    }
    //this.parent.activeSubRoute = "expedicao_listagem";
    return true
  }

  // canDeactivate() {
  //   this.cleanup();
  //   return true;
  // }

  // cleanup() {
  //   try {
  //     if ($("#GridUtilizadores").hasClass("ej-grid"))
  //       $("#GridUtilizadores").ejGrid("destroy");
  //   } catch (err) {console.log("canDeactivate", err)}
  // }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[etiquetas-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          break;
        }
        case "EDITA-ETIQUETA": {
          let etiqueta = ArmazemEtiqueta.fromPOJSO(payload);
          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : {
                modelo : etiqueta,
                invoker: this,
                options: new ComposeDialogOptions({
                  withDefaultFooter: false,
                  mainView         : '../routes/plano/ce/armazem-etiqueta-dialog.html',
                  postUri          : 'api/plano-expedicao/armazem-etiqueta'
                }),
              }
            })
            .whenClosed(r => {
              //if (environment.debug) console.log("[expedicao-tabela-virtual]", "ADICIONA-ETIQUETA", r);
              if (!r.wasCancelled) {
                this.app.notificationSuccess("Etiqueta actualizada");
                this.rg.refreshPage(true);
              }
            });
        }

        case "APAGA-ETIQUETA-ARMAZEM": {
          let etiqueta = ArmazemEtiqueta.fromPOJSO(payload);
          return this.app.confirmaDeletionTyped(etiqueta.wrapIt(), "api/plano-expedicao/armazem-etiqueta")
            .then(r => {
              if (this.app.processConfirmation(r)) {
                this.app.notificationSuccess("Etiqueta anulada");
                this.rg.refreshPage(true);
              }
            })
            .catch(err => this.app.notificationErrorCompact(err));
        }

        case 'IMPRIME-ETIQUETA-ARMAZEM': {
          let pl = {idArmazemEtiqueta: payload.idArmazemEtiqueta};
          return this.app.report("EtiquetaArmazemA?tipo=pdf", pl, "EntradaArmazem" + payload.idArmazemEtiqueta, true)
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false);
        }

        case 'TRANSFERE-ETIQUETA-ARMAZEM': {
          let pl = {idArmazemEtiqueta: payload.idArmazemEtiqueta};
          return this.app.report("EtiquetaArmazemA?tipo=pdf", pl, "EntradaArmazem" + payload.idArmazemEtiqueta, false)
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false);
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[etiquetas-listagem]", "RG_CELL_CHANGE", payload);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // let artigo = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          // this.doAction("EDITAR-ARTIGO", artigo);
          // this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[etiquetas-listagem]", "RG_ROW_CLICK", payload);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // let registoEtiqueta = ArmazemEtiqueta.fromPOJSO(selecaoPriArtigo);

          // let artigo           = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          //this.doAction("ETIQUETA", registoEtiqueta);
          this.isBusy = false;
          break;
        }
        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[etiquetas-listagem]", "Acção DESCONHECIDA [etiquetas-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
