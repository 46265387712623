import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import {dateISOString} from "../utils/ItNumeric";
import environment from "../environment";
import {data} from "../routes/testes/dummyData";

@autoinject()
export class PickerData {
  protected controller: DialogController;
  private app: GlobalServices;
  private dataSelecionada: Date;
  private pickerDataInput:HTMLDivElement;
  private action: string = "ESCOLHE-DATA";

  constructor(gs: GlobalServices, controller: DialogController) {
    this.app        = gs;
    this.controller = controller;
    // this.as         = auth;
  }

  activate(m:{dataInicial:any, action:string}) {
    if (environment.debug) console.log("[PickerData]", "activate", m);
    let {dataInicial, action} = m;
    //
    // if (typeof dataInicial === typeof "") {
    //   this.dataSelecionada = new Date(dataInicial);
    // } else if (typeof dataInicial === typeof new Date()) {
    //   this.dataSelecionada = dataInicial;
    // } else {
    //   this.dataSelecionada = new Date();
    // }
    //
    this.dataSelecionada = dataInicial;
    this.action = action || this.action;
  }

  attached() {
    // $(this.pickerDataInput).ejDatePicker({
    //   locale          : "pt-PT",
    //   value        : this.dataSelecionada,
    //   dateFormat   : "yyyy-MM-dd", // sets the date format
    //   width        : "100%",
    //   displayInline: true, //sets inline to represent datePicker as DatePicker calendar
    //   select       : (v) => {
    //     let optionalParams = {action: this.action, payload: v.value};
    //     //console.log("date changed", v, optionalParams);
    //     setTimeout(() => {
    //       if (environment.debug) console.log("[PickerData]", "change Date");
    //       this.controller.ok(optionalParams);
    //     }, 200);
    //     // $(this.pickerDataInput).ejDatePicker("destroy");
    //     //console.log("date changed 2", v, optionalParams);
    //   }
    // });
  }

  canDeactivate() {
    console.log("canDeactivate");
    $(this.pickerDataInput).ejDatePicker("destroy");
  }

  changed() {
    let optionalParams = {action: this.action, payload: this.dataSelecionada};
    console.log("changed", optionalParams);
    this.controller.ok(optionalParams);
  }

  //detached
}
