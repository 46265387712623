import {bindable, bindingMode} from 'aurelia-framework';
import {EstadoReclamacao, Reclamacao} from "../../models/Reclamacao";

export class EstadoFnc {
  @bindable({defaultBindingMode: bindingMode.oneTime}) debug: boolean = true;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: Reclamacao;

  private EstadoReclamacao = EstadoReclamacao;

  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("[estado-fnc]", "valueChanged", newValue, oldValue);
  }


  bind(a, b) {
    if(this.debug) console.log("[estado-fnc]","bind()", a, b, this.value, this.value.nvcEstado);
    //aqui posso fazer a captura do primeiro valor, visto que quando esta função existe o primeiro valueChanged não é invocado
  }


  mudaEstado(novoEstado:string) {
    if(this.debug) console.log("[estado-fnc]","mudaEstado para", novoEstado);

    //todo:validar a transição com mensagem de erro
    //todo:Depois de se ir para cancelado não há volta atrás

    this.value.nvcEstado = novoEstado;
  }
}

