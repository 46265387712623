//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {Router, RouterConfiguration} from "aurelia-router";
import {Epoca} from "../models/Epoca";
import {GlobalServices} from "../services/global-services";
import {planoSubRoutes} from "../app-routes";
import environment from "../environment";

@autoinject()
export class planoComponent {
  public message            = 'Dashboard';
  public epocas: Epoca[] = [];
  public selectedEpoca: Epoca;
  public app: GlobalServices;
  private childRouter: Router;

  constructor(g: GlobalServices) { this.app = g;}

  attached() {
    //this.listaEpocas();
  }

  //region Acções
  // criaEpoca() {
  //   this.app.api.post("api/epoca/criar", 2017).then(console.log);
  // }

  // listaEpocas() {
  //   this.app.api.get("api/epoca/todas").then(r => r.json()).then(r => {this.epocas = Epoca.multipleFromPOJSO(<any>r)}).catch(console.log);
  // }

  // attached() {
  //   console.log("AppRouter", this.app.router.baseUrl, "LocalRouter", this.router.baseUrl, "sett");
  //   setTimeout(() => {
  //     //   // this.app.router.navigateToRoute('folha-expedicao', {dia: '2017-02-02', ca: 'LA'});
  //     this.app.router.navigateToRoute('plano/guia-transporte', {dia: '2017-02-02', ca: 'LA'});
  //     //   // this.router.navigateToRoute('expedicao', {dia: '2017-02-02', ca: 'LA'});
  //   }, 1000);
  // }

  //endregion

  configureRouter(config: RouterConfiguration, router: Router) {
    config.title = 'iTech-ON';
    config.map(planoSubRoutes);
    this.childRouter = router;

    if(environment.debug) console.log("[plano-component]", this.childRouter);
  }
}
