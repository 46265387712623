import {NavigationInstruction, Next, Redirect, Router, RouterConfiguration} from "aurelia-router";
import environment from "./environment";
import {Aurelia, autoinject, computedFrom} from "aurelia-framework";
import {ActionInterface, GlobalServices} from "./services/global-services";
import {versao} from "./app";
import {mobileRoutes} from "./mobile-routes";
import {GuiaTransporte} from "./models/GuiaTransporte";
import {ComposeDialog, ComposeDialogOptions} from "./dialogs/compose-dialog";
import {doActionGlobal} from "./do-action";

@autoinject()
export class MobileApp {
  public message = 'App';
  private router: Router;
  private versao = versao + versaoMobile;

  private activeRoute = 'mobile_inicio';
  // NOTA: para já não se irá introduzir nenhum mecanismo semi-automático de configuração de routes/navegação
  // public navigation: MobileNavStruct;

  // @computedFrom("app.auth.authenticated")
  // get authenticated(): boolean {
  //   if (environment.debug) console.log("[mobile-app]", "get authenticated", this.app.auth.authenticated);
  //   return this.app.auth.authenticated;
  // }

  constructor(private aurelia: Aurelia, private app: GlobalServices) {
    if (environment.debug) console.log("[mobile-app]", "constructor", "Aurelia app started", this.aurelia, this.versao);
    this.app.aureliaMain = this;
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    // if (environment.debug) console.log("[mobile-app]", "configureRouter", config, router);
    let step = {
      run: (navigationInstruction: NavigationInstruction, next) => {
        if (environment.debug) console.log("[mobile-app]", "navigationInstruction", navigationInstruction.getAllInstructions());
        //capturar a route activa
        this.activeRoute = navigationInstruction.config.name;
        if (navigationInstruction.getAllInstructions().some(i => i.config.auth)) {
          let isLoggedIn = this.app && this.app.auth && this.app.auth.authenticated;
          if (!isLoggedIn) {
            return next.cancel(new Redirect('login'));
          }
        }
        return next();
      }
    };

    //this.navigation = MobileNavStruct.translateRoutes(mobileRoutes);
    config.title = 'Mobile-App';
    config.addAuthorizeStep(step);
    config.map(mobileRoutes);
    this.router = router;
  }

  logout() {
    //this.logger.info(`Utilizador ${this.authService.userName} terminou a sessão.`);
    this.app.auth.logout();
    this.router.navigateToRoute("login");
  }

  attached(){
    this.app.notificationWarning("asd");
  }

  /**
   * doActionGlobal
   * Quando as acções invocadas não são encontradas na route do componente "transbordam" para esta função.
   *
   * A acção `Q` ou  `QUEUE` processa uma lista de acções descritas pela `ActionInterface`
   *
   * NOTAS:
   * Cuidado com os composes e os respectivos `invokers`, deverão ser na forma: `invoker: context || this,`
   *
   * @param {string} action
   * @param payload
   * @param context
   * @return {Promise<boolean>}
   */
  public doActionGlobal = doActionGlobal.bind(this);
}

/**
 * Ainda está para se ver se a versão (comum) se mantém do modo que está
 * @type {string}
 */
export const versaoMobile = "m0";
