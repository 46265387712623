import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {GlobalServices} from "../../../services/global-services";
import {Familia} from "../../../models/Familia";
import {BootstrapFormRenderer} from "../../../services/bootstrap-form-renderer";
import {confirmaActionTyped, confirmaDeletionTyped} from "../../../services/api-envelopes";

@autoinject()
export class FamiliaDialog {
  //static inject = [DialogController, ValidationControllerFactory];
  protected validationController: ValidationController;
  protected controller: DialogController;
  protected app: GlobalServices;

  protected familia: Familia;
  protected familiaOriginal: Familia;

  protected isBusy: boolean = false;

  constructor(controller: DialogController, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  canActivate(p: any) {
    if (!p.model || !p.app) {
      console.error("Um dos parametros (model, app) está em falta pelo que não se pode abir o popup de criação/alteração de família.");
      return false;
    }
    this.app = p.app;
    this.parseFamilia((p.model as Familia).stateToPOJSO());
    return true;
  }

  //attached() {}

  parseFamilia(obj: any) {
    this.familia         = Familia.fromPOJSO(obj);
    this.familiaOriginal = Familia.fromPOJSO(obj);
    return true;
  }

  gravaFamilia() {
    return confirmaActionTyped(this, this.familia, "api/artigo/cria-familia")
      .then(r => r && this.controller.ok(this.familia));
  }

  apagarFamilia() {
    return confirmaDeletionTyped(this, this.familia, "api/artigo/familia")
      .then(r => r && this.app.refresh());
  }

  dialogSubmit() {
    return this.validationController.validate()
      .then(r => {
        if (r.valid) {
          this.isBusy = true;
          this.gravaFamilia()
        } else {
          this.app.notificationErrorCompact("Verifique os erros.");
          console.error(r.results);
        }
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }
}
