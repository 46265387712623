import {BaseViewModel} from "./BaseViewModel";
import {ValidationRules} from "aurelia-validation";
/**
 * Created by herna on 5/2/2017.
 */
export class ClienteDestinatario extends BaseViewModel {
  public idClienteDestinatario: number = 0;
  public nvcCliente: string = "";
  public nvcAlias: string = "";
  public nvcMorada: string = "";
  public timestamp: string = "";

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ClienteDestinatario>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public stateToPOJSO(){
    return this.getState();
  }

  public static fromPOJSO(obj: any, index:number = 0): ClienteDestinatario {
    let model = new ClienteDestinatario();
    model.setState(obj);
    model.__index = index;
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): ClienteDestinatario[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(ClienteDestinatario.fromPOJSO);
    return [];
  }
}

ValidationRules
  .ensure((m: ClienteDestinatario) => m.nvcCliente).displayName("Código Cliente").required().withMessage(`\${$displayName} é obrigatório`)
  .ensure((m: ClienteDestinatario) => m.nvcAlias).displayName("Alias").required().withMessage(`\${$displayName} é obrigatório`)
  .ensure((m: ClienteDestinatario) => m.nvcMorada).displayName("Morada").required().withMessage(`\${$displayName} é obrigatório`)
  .on(ClienteDestinatario);
