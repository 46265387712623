// Auto-gerado com typewriter
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Artigo} from './Artigo';
import {ClienteArmazem} from './ClienteArmazem';

//export classe
export class TraducaoPlanoExpedicaoControlo extends BaseViewModel{
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public nvcClienteArmazemExpedicao : string = null;
    public nvcArtigoExpedicao : string = null;
    public nvcClienteArmazemControlo : string = null;
    public nvcArtigoControlo : string = null;
    public bitActivo : boolean = false;
    public idUtilizadorI : number = 0;
    public idUtilizadorA : number = 0;
    public dtmDataI:string = null;
    // public _dtmDataI:Date = new Date();
    public dtmDataA:string = null;
    // public _dtmDataA:Date = new Date();
    public timestamp : string;
    // public idUtilizadorANavigation : Utilizador = null;
    // public idUtilizadorINavigation : Utilizador = null;
    public nvcArtigoControloNavigation : Artigo = null;
    public nvcArtigoExpedicaoNavigation : Artigo = null;
    public nvcClienteArmazemControloNavigation : ClienteArmazem = null;
    public nvcClienteArmazemExpedicaoNavigation : ClienteArmazem = null;

    public setArtigoExpedicao(art:Artigo) {
        this.nvcArtigoExpedicaoNavigation = art;
        this.nvcArtigoExpedicao = art.nvcArtigo;
    }


    public setArtigoControlo(art:Artigo) {
        this.nvcArtigoControloNavigation = art;
        this.nvcArtigoControlo = art.nvcArtigo;
    }

    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<TraducaoPlanoExpedicaoControlo>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): TraducaoPlanoExpedicaoControlo {
        let model = new TraducaoPlanoExpedicaoControlo();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): TraducaoPlanoExpedicaoControlo[] {
        if (objs && Array.isArray(objs)) return objs.map(TraducaoPlanoExpedicaoControlo.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<TraducaoPlanoExpedicaoControlo>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): TraducaoPlanoExpedicaoControlo { return TraducaoPlanoExpedicaoControlo.fromPOJSO(this.stateToPOJSO());}    
    //endregion

    public toString(){
        return `TraducaoPlanoExpedicaoControlo`;
    }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    .ensure((m: TraducaoPlanoExpedicaoControlo) => m.nvcClienteArmazemExpedicao).displayName("Cliente (Plano)").required()
    .ensure((m: TraducaoPlanoExpedicaoControlo) => m.nvcArtigoExpedicao).displayName("Referência (Plano)").required()
    .ensure((m: TraducaoPlanoExpedicaoControlo) => m.nvcClienteArmazemControlo).displayName("Cliente (Controlo)").required()
    .ensure((m: TraducaoPlanoExpedicaoControlo) => m.nvcArtigoControlo).displayName("Referência (Controlo)").required()
    .ensure((m: TraducaoPlanoExpedicaoControlo) => m.bitActivo).displayName("bitActivo").required()
  .on(TraducaoPlanoExpedicaoControlo);
