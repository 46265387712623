//Aurelia Imports
import {autoinject, computedFrom} from "aurelia-framework";
//App Imports
import {Logger} from "aurelia-logging";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {dateISOString} from "../../utils/ItNumeric";
import {EmailGuiaTransporteVm} from "../../models/EmailGuiaTransporteVm";
import {EmailGuiaTransporte} from "../../dialogs/email-guia-transporte";
import {DateFilterTemplateGreaterEqual, identityNumericFilter, IdentityStringFilter} from "../../utils/EjHelper";
import {PickerRemoteGrid} from "../../it-features/remote-grid/picker-remote-grid";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import {Artigo} from "../../models/Artigo";
import {ArtigoDialog} from "../artigo/ce/artigo-dialog";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import {GuiaTransporte} from "../../models/GuiaTransporte";

@autoinject()
export class GuiasTransporte {
  private app: GlobalServices;

  private id: number;
  private linhas: any;

  private hasSelection: boolean = false;

  private ejGrid: HTMLDivElement = null;
  private instance: any;
  private isBusy: boolean = false;
  private rg: RemoteGrid;


  constructor(g: GlobalServices) {
    this.app = g;
  }

  activate() {
  }

  canDeactivate() {
    try {$(this.ejGrid).ejGrid("destroy");} catch (err) {console.log(err)}
    return true;
  }

  attached() {
    //console.log("activate!");
    // this.app.auth.promisedActiveToken()
    //   .then(token => {
    //     let dataSource = new ej.DataManager({
    //       //done: injetar a localização do serviço
    //       url        : environment.endpoint + "api/ejg/GuiaTransporte",
    //       crossDomain: true,
    //       //requestType : "post",
    //       adaptor    : new ej.WebMethodAdaptor(),
    //       headers    : [{Authorization: `Bearer ${token}`}]
    //     });
    //
    //     $(this.ejGrid).ejGrid({
    //       locale        : "pt-PT",
    //       dataSource    : dataSource,
    //       allowPaging   : true,
    //       pageSettings  : {pageSize: 20},
    //       allowSorting  : true,
    //       allowSearching: true,
    //       allowFiltering: true,
    //       allowSelection: true,
    //       selectionType : ej.Grid.SelectionType.Multiple,
    //       cssClass      : "grid-picker",
    //       filterSettings: {
    //         showFilterBarStatus: true,
    //         //filterType         : "menu"
    //       },
    //       sortSettings  : {sortedColumns: [{field: "int_numero", direction: "descending"}]},
    //       recordClick   : (args) => {
    //         // console.log("Click", args, this);
    //         // if(args.model && Array.isArray(args.model.selectedRowIndices) && args.model.selectedRowIndices.length > 0) {
    //         //   this._hasSelection = true;
    //         // } else {
    //         //   this._hasSelection = false;
    //         // }
    //         // let a = <Date> args.data.dtm_data_documento;
    //         // this.app.router.navigate(`${document.location.origin}/#/plano/guia-transporte/${dateISOString(a)}/${args.data.nvc_cliente_armazem}?linha=${args.data.indice_dia}`);
    //       },
    //       columns       : [
    //         {width: 20, headerText: "Acções", textAlign: ej.TextAlign.Center, template: '<a href="#/plano/guia-transporte/{{:dtm_data}}/{{:nvc_cliente_armazem}}?linha={{:indice_dia}}"><i class="fa fa-eye"></i></a>'},
    //         //  { headerText : "Acções", textAlign : ej.TextAlign.Center,
    //         //   commands : [ { type: "button", buttonOptions: { text: "<i class='fa fa-ver'></i>", width: "28", click: (e) => {this.onClickView(e); } }},],
    //         //   width: 60,
    //         // },
    //         //@formatter:off
    //           {field: "int_numero"              , headerText: "Nº"          , width:  20, type:"number", textAlign: "center"                     , filterBarTemplate:identityNumericFilter }  ,
    //           {field: "dtm_data_documento"      , headerText: "Data"        , width:  40, type: "date" , textAlign: "center"                     , format: "{0:yyyy-MM-dd}"                   , filterBarTemplate: DateFilterTemplateGreaterEqual},
    //           {field: "nvc_cliente_armazem"     , headerText: "Cód"         , width:  20, type:"string", filterBarTemplate: IdentityStringFilter},
    //           {field: "nvc_nome"                , headerText: "Cliente"     , width:  60, type:"string", filterBarTemplate: IdentityStringFilter},
    //           {field: "nvc_cliente_destinatario", headerText: "Destinatário", width: 200, type:"string", filterBarTemplate: IdentityStringFilter},
    //           {field: "total"                   , headerText: "Quantidade"  , width:  20, type:"number", textAlign: "right"                      , filterBarTemplate: identityNumericFilter  },
    //           {field: "nvc_estado"              , headerText: "Estado"      , width:  40, type:"string", textAlign: "center"                     , filterBarTemplate: IdentityStringFilter}   ,
    //           {field: "nvc_tipo"                , headerText: "Tipo"        , width:  20, type:"string", textAlign: "center"                     , filterBarTemplate: IdentityStringFilter}   ,
    //         ]
    //         //@formatter:on
    //     });
    //   })
    //   .then(_ => {
    //     this.instance = $(this.ejGrid).ejGrid("instance");
    //   });
  }

  // onClickEdit(e: any) {
  //   // console.log("onClickEdit", e);
  //   // e.e.preventDefault();
  //   e.e.stopPropagation();
  //   let grid   = $("#GridUtilizadores").ejGrid("instance");
  //   let index  = $(e.target).closest("tr").index();
  //   let record = grid.getCurrentViewData()[index];
  //   // console.log(record);
  //   this.showUtilizadorDialog(+record.id_utilizador);
  // }

  emailSelection() {
    let records = this.instance.getSelectedRecords();
    let vm      = new EmailGuiaTransporteVm({dictionaries: records});
    this.app.ds.open({viewModel: EmailGuiaTransporte, model: vm})
      .whenClosed(resp => {
        if (!resp.wasCancelled) {
          this.sendEmail(resp.output.payload);
        } else {
        }
      });
  }

  sendEmail(vm: EmailGuiaTransporteVm) {
    this.app.api.postProcessed('api/plano-expedicao/email-reports/GuiaTransporte', vm.stateToPOJSO())
      .then(_ => this.app.notificationSuccess(`Email enviado para ${vm.to}`))
      .catch(err => {this.app.notificationErrorCompact(err)});
  }

  getSelection() {
    let records = this.instance.getSelectedRecords();
    this.transfere(records.map(el => {return {id_guia_transporte: el.id_guia_transporte}}));
  }

  previewSelection() {
    let records = this.instance.getSelectedRecords();
    this.transferePdf(records.map(el => {return {id_guia_transporte: el.id_guia_transporte}}), true);
  }

  transfere(payload: { id_guia_transporte: number }[]) {
    // let payload: any = {id_guia_transporte: this.guiaEmEdicao.IdGuiaTransporte};

    let route = "api/plano-expedicao/reports-zip/GuiaTransporte";
    return this.app.api
      .post(route, payload)
      .then(r => this.app.api.processBlobResponse(r))
      .then(blob => {
        // I can't really preview a zip in browser, why?, why??, WHY????
          // if (preview) {
          //   return this.app.api.processBlobPreview(blob, `guias-transporte-${dateISOString(new Date())}.zip`)
          // }
          return this.app.api.processBlobDownload(blob, `guias-transporte-${dateISOString(new Date())}.zip`)
        }
      )
      .catch(e => this.app.notificationErrorCompact(e));
  }

  transferePdf(payload: { id_guia_transporte: number }[], preview: boolean = false) {
    // let payload: any = {id_guia_transporte: this.guiaEmEdicao.IdGuiaTransporte};

    let route = "api/plano-expedicao/reports/GuiaTransporte";
    return this.app.api
      .post(route, payload)
      .then(r => this.app.api.processBlobResponse(r))
      .then(blob => {
          if (preview) {
            return this.app.api.processBlobPreview(blob, `guias-transporte-${dateISOString(new Date())}.pdf`)
          }
          return this.app.api.processBlobDownload(blob, `guias-transporte-${dateISOString(new Date())}.pdf`)
        }
      )
      .catch(e => this.app.notificationErrorCompact(e));
  }

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[guias-transporte]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          break;
        }

        case "TRANSFERE-SELECAO": {
          let sel = this.rg.selecaoAtual().map(el => ({id_guia_transporte: el.idGuiaTransporte}));
          if(environment.debug) console.log("[guias-transporte]","TRANSFERE-SELECAO", sel);
          return this.transfere(sel)
            .then(_ => this.isBusy = false);
        }

        case "PREVIEW-SELECAO": {
          let sel = this.rg.selecaoAtual().map(el => ({id_guia_transporte: el.idGuiaTransporte}));
          if(environment.debug) console.log("[guias-transporte]","TRANSFERE-SELECAO", sel);
          return this.transferePdf(sel, true)
            .then(_ => this.isBusy = false);
        }

        case "VER-GT": {
          let gt = payload as GuiaTransporte;
          this.app.router.navigate(`#/plano/guia-transporte/${gt.dtmDataDocumento}/${gt.nvcCliente}?numero=${gt.intNumero}`);
          return Promise.resolve(true);
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[guias-transporte]", "RG_CELL_CHANGE", payload);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // let artigo = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          // this.doAction("EDITAR-ARTIGO", artigo);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[guias-transporte]", "RG_ROW_CLICK", payload);
          // carregaram na grid, use-se a seleção para determinar se existe uma
          // let selecao = payload as ActionFromRemoteGrid;
          // if (environment.debug) console.log("[guias-transporte]", "RG_ROW_CLICK", this.rg.selecaoAtual());

          this.hasSelection = this.rg.selecaoAtual().length > 0;

          this.isBusy = false;
          break;
        }
        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[guias-transporte]", "Acção DESCONHECIDA [guias-transporte]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
