import {bindable, bindingMode, View} from 'aurelia-framework';
import {NovaExpedicao} from "../nova-expedicao";
import {RegistoEtiqueta} from "../../../models/RegistoEtiqueta";

export class RegistoEtiquetaCell {
  @bindable({defaultBindingMode: bindingMode.oneWay}) etiqueta: RegistoEtiqueta;
  private p: NovaExpedicao;
  private owner: View;

  created(owningView: View, myView: View) {
    //if(environment.debug) console.log("[registo-etiqueta-cell]", "created", owningView, myView);
    this.p     = (owningView.bindingContext as NovaExpedicao);
    this.owner = owningView;
  }
}

