import {ExpedicaoTabelaVirtual} from "../routes/plano/expedicao-tabela-virtual";
import {computedFrom} from "aurelia-framework";
import environment from "../environment";

/**
 * Created by hernani on 2017-05-29.
 */
export class PlanoColumn {
  public open: boolean        = false;
  public debug: boolean       = environment.debug;
  public visible: boolean     = true;
  public background: string   = "#cccccc";
  public align: Alignment     = "center";
  public width: number        = 40;
  public name: string         = "Name";
  public title: string        = "Title";
  public orderSuffix: string  = "";
  public order: string        = "";
  public hint: string         = "Hint";
  public bind: string         = "propertyToBind";
  // text to search
  public value: string []     = [];
  public partialValue: string = "";
  public filterType: string   = "";

  public _parent: PlanoDefinition = null;

  @computedFrom('value')
  public get search() {
    return this.value.join(",");
  }

  public set search(txt: string) {
    if (this.debug) console.log("[PlanoColumn]", `Search [${this.name}]: ${this.value} -> ${txt}`);

    this.value = txt.replace(/(\r\n|\n|\r)/gm, "").split(",");
    if (this.debug) console.log("[PlanoColumn]", `Aplicar pesquisa em [${this.name}]:`, this.value);
    this._parent.doSearch();
    // if (this.value != txt) {
    //   // Remove qualquer tipo de quebra de linhas do texto
    //
    // }
  }

  @computedFrom('value')
  public get primeiro(): string {
    if (this.value[0]) {return this.value[0];} else return "";
  }

  public set primeiro(val: string) {
    if (this.debug) console.log("[PlanoColumn]", `Set primeiro`, val, this.value);

    this.value[0] = val;
    this.search   = this.value.join(",");
  }

  @computedFrom('value')
  public get segundo(): string {
    if (this.value[1]) {return this.value[1];} else return "";
  }

  public set segundo(val: string) {
    if (this.debug) console.log("[PlanoColumn]", `Set segundo`, val, this.value);
    if (!this.value[0]) {this.value[0] = ""}
    this.value[1] = val;
    this.search   = this.value.join(",");
  }

  public constructor(fields: Partial<PlanoColumn>, parent?: PlanoDefinition) {
    Object.assign(this, fields);
    this._parent = parent;
  }

  /**
   * Consulta a existência de um valor no array de pesquisas
   * @param {string} val
   * @return {boolean}
   */
  public hasValue(val: string): boolean {
    return (this.value.findIndex(el => el == val) > -1);
  }

  public toggleValue(e: Event, val: string) {
    if (this.debug) console.log("Toggling value", val, "Event", e);
    e.preventDefault();
    e.stopPropagation();
    let index = this.value.findIndex(el => el == val);
    if (index < 0) {
      if (this.debug) console.log("Adding value", val);
      this.value = [...this.value, val];
    } else {
      if (this.debug) console.log("Removing value", val);
      this.value = this.value.splice(index, 1);
    }
    this._parent.doSearch();
  }

  public getPsv() {
    return this.value.join("+");
  }

  public getPsvExcept(val: string) {
    let arr = this.value.filter(el => el != val);
    return arr.join("+");
  }

  public getState() {
    return {
      name : this.name,
      value: this.value
    }
  }

  public shallowState() {
    return new PlanoColumn({
      visible   : this.visible,
      background: this.background,
      align     : this.align,
      width     : this.width,
      name      : this.name,
      title     : this.title,
      hint      : this.hint,
      bind      : this.bind,
      value     : this.value,
      filterType: this.filterType,
      _parent   : null
    })
  }
}

export class OrderColumn {
  public name: string         = "";
  public direction: Direction = "ASC";

  public constructor(fields: Partial<OrderColumn>) {
    Object.assign(this, fields);
  }

  public getState() {
    return {
      name     : this.name,
      direction: this.direction
    }
  }

  public deepClone() {
    return new OrderColumn({
      name     : this.name,
      direction: this.direction
    });
  }
}

/**
 * representação de um conjunto de colunas para edificação de um plano.
 */
export class PlanoDefinition {
  public clienteArmazem: string[] = ["LA"];
  public tipoPlano: string        = "expedicao";
  public epoca: number            = 0;
  public columns: PlanoColumn[]   = [];
  public order: OrderColumn[]     = [];

  //localizadores de janela
  public dataMin: Date              = null;
  public dataMax: Date              = null;
  public mostraTodosOsDias: boolean = false;

  //visualização
  public registoProd: boolean   = true;
  public registoPrg: boolean    = true;
  public registoSaiFal: boolean = true;
  public registoFal: boolean    = true;

  //Estado das linhas (apenas ativas ou todas)
  public apenasAtivos: boolean = true;

  //Tipo das linhas (apenas ativas ou todas)
  public verVirtuais: boolean = true;

  //localizadores para página
  // a página deve ser sempre par?
  public pageElements: number = 26;
  public pageNumber: number   = 0;

  // bufferMultiplier -> simboliza o multiplicador aplicado para desenho de linhas intermédias, que não triggam a paginação virtual.
  public bufferMultiplier: number = 3;
  public rowHeight: number        = 28;

  public margin: number = 15;

  // auxiliar para pesquisa de familias
  public familia: string = null;

  // auxiliar para pesquisa de FNC
  public comFnc?: boolean = null;
  public idsFnc: string   = "";

  // string que determina se a memória de filtro é usada
  public memoriaAtual: string;

  //parent reference
  public _container: ExpedicaoTabelaVirtual;

  //https://github.com/Microsoft/TypeScript/issues/3895#issuecomment-295298700
  public constructor(fields: Partial<PlanoDefinition>) {
    Object.assign(this, fields);
  }

  public AddColumn(column: PlanoColumn): PlanoDefinition {
    column._parent = this;
    this.columns.push(column);
    return this;
  }

  public setColumnParent(): PlanoDefinition {
    this.columns.forEach(el => el._parent = this);
    return this;
  }

  //todo: meio de persistência no localstorage

  public visibleWidth(extra = 0) {
    let w = this.columns.filter(e => e.visible).reduce((acc, e) => acc + e.width, 0);
    return w + 60 + extra;
  }

  public cumulativeWidth(index) {
    let w = this.columns.reduce((acc, e, i) => {
      if (e.visible && i < index) return acc + e.width;
      return acc;
    }, 0);
    return w + this.margin + 60;
  }

  //devolve uma instancia de representação de uma coluna, pelo seu nome.
  public column(name: string): PlanoColumn {
    return this.columns.find(el => el.name === name);
  }

  public doSearch() {
    if (environment.debug) console.log("doSearch");
    // if(this.memoriaAtual){
    //   console.error("STORE NEW MEMORY SET");
    // }
    this._container.doAction("PAGINA-PRIMEIRA", null);
  }

  public getSearchPayload() {
    let payload = {
      search      : this.columns.map(el => el.getState()),
      order       : this.order.map(el => el.getState()),
      epoca       : this.epoca,
      dataMin     : this.dataMin,
      dataMax     : this.dataMax,
      apenasAtivos: this.apenasAtivos,
      verVirtuais : this.verVirtuais,
      familia     : this.familia,
      comFnc      : this.comFnc,
      idsFnc      : this.idsFnc
    };

    return payload;
  }

  public shallowState() {
    return <any>{
      clienteArmazem   : this.clienteArmazem,
      tipoPlano        : this.tipoPlano,
      epoca            : this.epoca,
      columns          : this.columns.map(el => el.shallowState()),
      order            : this.order.map(el => el.deepClone()),
      dataMin          : this.dataMin,
      dataMax          : this.dataMax,
      mostraTodosOsDias: this.mostraTodosOsDias,
      verVirtuais      : this.verVirtuais,
      pageElements     : this.pageElements,
      pageNumber       : this.pageNumber,
      bufferMultiplier : this.bufferMultiplier,
      rowHeight        : this.rowHeight,
      margin           : this.margin,
      registoLcm       : this.registoProd,
      registoPrg       : this.registoPrg,
      registoSaiFal    : this.registoSaiFal,
      apenasAtivos     : this.apenasAtivos,
      familia          : this.familia,
      comFnc           : this.comFnc,
      idsFnc           : this.idsFnc
    }
  }

  public deepClone() {
    let pD        = new PlanoDefinition(this.shallowState());
    pD._container = this._container;
    return pD.setColumnParent();
  }

  //region localStorage
  public toLocalStorage() {
    let shallowJson = JSON.stringify(this.shallowState());
    localStorage.setItem("plano-" + this.tipoPlano, shallowJson);
    if (environment.debug) console.log("[PlanoDefinition]", "configurações do plano memorizadas", shallowJson);
  }

  static fromLocalStorage(container: ExpedicaoTabelaVirtual): PlanoDefinition {
    let tipo      = container.tipoPlano;
    let defaultPd = container.defaultPlanoDefinition();
    if (localStorage.hasOwnProperty("plano-" + tipo)) {
      let shallowJson = localStorage.getItem("plano-" + tipo);
      let obj         = JSON.parse(shallowJson);
      // console.log(obj);
      let pD          = new PlanoDefinition(JSON.parse(shallowJson));
      if (obj.columns.length < defaultPd.columns.length) {
        // console.log("As definições do plano gravadas não obedecem ao formato certo. <br>");
        let msg = "As definições do plano gravadas não obedecem ao formato certo. " +
          "<br>Tal facto deve-se a uma atualização do software ou à impossibilidade do browser aceder à memória local." +
          "<br>Caso esta mensagem seja recorrente deve procurar suporte técnico.";
        localStorage.removeItem("plano-" + tipo);
        container.app.notificationWarning(msg);
        return null;
      }
      pD.columns = obj.columns.map(el => new PlanoColumn(el));
      // reset aos valores que ficaram registados em localStorage
      pD.columns.forEach(el => el.value = []);
      pD.order      = obj.order.map(el => new OrderColumn(el));
      pD._container = container;
      if (environment.debug) console.info("Configurações do plano restauradas:", obj);
      return pD.setColumnParent();
    }
    return null;
  }

  public saveMemory(key: string = "planoMem1") {
    let obj: any = {
      columns: this.columns.map(el => el.shallowState()),
      order  : this.order.map(el => el.deepClone()),
    };
    if (environment.debug) console.log("Setting Memory", key, obj);
    localStorage.setItem(key, JSON.stringify(obj));
  }

  public restoreMemory(key: string = "planoMem1") {
    if (environment.debug) console.log("restoring Memory", key);

    if (localStorage.hasOwnProperty(key)) {
      let json   = localStorage.getItem(key);
      let obj    = JSON.parse(json);
      let cc     = this.columns[0].value;
      let ppOuPl = this.columns[this.columns.length - 1].shallowState();

      this.columns                          = obj.columns.map(el => new PlanoColumn(el));
      this.columns[this.columns.length - 1] = ppOuPl;
      this.order                            = obj.order.map(el => new OrderColumn(el));
      this.columns[0].value                 = cc;
      this.setColumnParent();
      if (environment.debug) console.log("restoring Memory", key, "Done", obj);

      return true;
    }
    return false;
  }

  public clearMemory(key: string = "") {
    if (key) {
      localStorage.removeItem(key);
    } else {
      localStorage.removeItem("memory1");
      localStorage.removeItem("memory2");
      localStorage.removeItem("memory3");
    }
  }

  @computedFrom("memoriaAtual")
  public get hasSlot1() {
    return localStorage.hasOwnProperty("memory1");
  }

  @computedFrom("memoriaAtual")
  public get hasSlot2() {
    return localStorage.hasOwnProperty("memory2");
  }

  @computedFrom("memoriaAtual")
  public get hasSlot3() {
    return localStorage.hasOwnProperty("memory3");
  }

  //endregion

  //region order
  public addOrder(column: PlanoColumn) {
    let index = this.order.findIndex(el => el.name == column.name);
    if (index < 0) {
      //não há ordenação definida para a coluna
      this.columns.forEach(el => el.orderSuffix = "");
      this.order = [new OrderColumn({name: column.name, direction: "DESC"})];
      //column.title = column.title + "---";
    } else {
      this.order[index].direction = (this.order[index].direction == "ASC") ? "DESC" : "ASC";
    }
    column.orderSuffix = this.orderIndication(column);
    column.order       = this.ordering(column);
    this.doSearch();
  }

  public appendOrder(column: PlanoColumn, direction = '') {
    if (environment.debug) console.log("[PlanoColumn]", "appendOrder", column, direction);
    let index = this.order.findIndex(el => el.name == column.name);
    if (index < 0) {
      //não há ordenação definida para a coluna
      this.order.push(new OrderColumn({name: column.name, direction: <Direction>direction || "DESC"}));
    } else {
      this.order[index].direction = <Direction>direction || ((this.order[index].direction == "ASC") ? "DESC" : "ASC");
    }
    column.orderSuffix = this.orderIndication(column);
    column.order       = this.ordering(column);
    this.doSearch();
  }

  public ordering(column) {
    let index = this.order.findIndex(el => el.name == column.name);
    if (index < 0) {
      return "";
    } else {
      return this.order[index].direction;
    }
  }

  public orderIndication(column) {
    let index = this.order.findIndex(el => el.name == column.name);
    if (index < 0) {
      return "";
    } else {
      return (this.order[index].direction != "ASC") ? "<i class='fa fa-arrow-up'></i>" : "<i class='fa fa-arrow-down'></i>";
    }
  }

  public clearOrder() {
    this.columns.forEach(el => {
      el.orderSuffix = "";
      el.order       = ""
    });
    this.order = [];
    this.doSearch();
  }

  public reportOrderTranslation = {
    'CC'             : 'nvc_cliente_armazem',
    'CodArtigo'      : 'nvc_artigo_terminacao',
    'Descricao'      : 'nvc_descricao_artigo',
    'Gravacao'       : 'nvc_tipo_gravacao',
    'DtmPedido'      : 'dtm_pedido',
    'DtmRecep'       : 'dtm_rececao',
    'Encomenda'      : 'cliente',
    'RG'             : 'RG',
    'Qty'            : 'int_total_calculado',
    'QtyDespachadas' : 'int_por_sair',
    "QtyPorLancar"   : "int_por_lancar",
    "QtyPorProgramar": "int_por_programar",
  }

  //endregion
}

export type Alignment = "auto" | "left" | "center" | "right";
export type Direction = "ASC" | "DESC";
