//import {setInterval} from "timers";
import {autoinject, bindable, computedFrom} from "aurelia-framework";
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {GlobalServices} from "../../services/global-services";
import {PlanoDefinition} from "../../models/PlanoColumn";
import environment from "../../environment";
import {Router} from "aurelia-router";

@autoinject
export class CabecalhoTabelaVirtual {
  public x;
  @bindable mD: PlanoDefinition;
  public endpoint: any;
  public token: string;
  public app: GlobalServices;
  private listaClientes: ClienteArmazem[];
  private clientesSelecionados: string[];
  private listaClientesPSV: string = "";

  // @bindable teste;

  public constructor(app: GlobalServices, protected router:Router) {
    this.app   = app;
    this.token = this.app.auth.activeToken();
    this.listaClientesPSV = this.app.auth.getPlanos().join("+");
    // this.endpoint =
  }

  todosOsClientesPsv() {
    if(environment.debug) console.log("[cabecalho-tabela-virtual]","todosOsClientesPsv", this.listaClientes);
    if(this.listaClientes)
      return this.listaClientes.map(el => el.nvcClienteArmazem).join("+");
    return [];
  }

  endpointFor(tipo: string) {
    return {
      debug         : true,
      url           : `${environment.endpoint}api/plano-expedicao/filtro/${tipo}`,
      method        : "post",
      dataType      : 'json',
      data          : (term, page) => {
        console.log("[data", term, page, this.mD);
        return JSON.stringify(Object.assign({q: term}, this.mD.getSearchPayload()));
      },
      delay         : 250,
      headers       : {
        "Authorization": "Bearer " + this.token,
        "Content-Type" : "application/json",
      },
      processResults: function (data, params) {
        if (environment.debug) console.log("[CabecalhoTabelaVirtual]", "processResults", data, params);
        // parse the results into the format expected by Select2 since we are using custom formatting functions we do not need to
        // alter the remote JSON data, except to indicate that infinite scrolling can be used
        // console.log("processResults", data, params);
        params.page = params.page || 1;
        return {
          results   : data.items,
          pagination: {
            more: (params.page * 30) < data.total_count
          }
        };
      },
    }
  }

  attached() {
    if (environment.debug) console.log("[cabecalho-tabela-virtual]", "attached", this);
    this.clientesSelecionados = this.mD.columns[0].value;
    if (environment.debug) console.log("[cabecalho-tabela-virtual]", "attached", this.clientesSelecionados);

    let planos = this.app.auth.getPlanos();
    console.log(planos);

    ClienteArmazem.memoizeMultiple(this.app.api)
      .then(r => {this.listaClientes = r.filter(ca => planos.includes(ca.nvcClienteArmazem))});
  }

  toggleCliente(ca: string = "", event: MouseEvent) {
    if (environment.debug) console.log("[cabecalho-tabela-virtual]", "toggleCliente", ca, event);
    let el: Element;
    el = event.srcElement.closest("button");
    if (environment.debug) console.log("[cabecalho-tabela-virtual]", "toggleCliente", el);
    if (this.clientesSelecionados.includes(ca)) {
      el.classList.remove("active");
      //remover cliente
      this.clientesSelecionados = this.clientesSelecionados.filter(el => el != ca);
      if (this.clientesSelecionados.length == 0) this.clientesSelecionados = [ca];
    } else {
      el.classList.add("active");
      this.clientesSelecionados.push(ca);
    }
  }

  aplicarCliente() {
    if (environment.debug) console.log("[cabecalho-tabela-virtual]", "aplicarCliente", this.clientesSelecionados);
    this.mD.columns[0].open = false;
    this.mD.columns[0].value = this.clientesSelecionados;

    this.router.navigate(`/plano/${this.mD.tipoPlano}/${this.mD.columns[0].getPsv()}`, {replace:true});
  }

  clearFilters() {
    // console.log("clearFilters");
    this.mD.columns.forEach(el => el.open = false);
    for (let i = 1; i < this.mD.columns.length; i++) {
      this.mD.columns[i].value = [];
    }
    this.mD.memoriaAtual = "";
    this.mD.familia = null;
    this.mD.idsFnc = "";
    this.mD.comFnc = null;
    this.refresh();
  }

  refresh() {
    this.mD._container.doAction("REFRESH-TABELA", null);
  }

  ordenar(event: MouseEvent, column, direction = '') {
    if (environment.debug) console.log('[CabecalhoTabelaVirtual] Ordenar', event, column, direction);
    if (direction) {
      this.mD.appendOrder(column, direction);
    } else if (event.shiftKey) {
      this.mD.appendOrder(column, '');
      //adicionar
    } else {
      this.mD.addOrder(column);
    }
  }
}
