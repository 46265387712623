import {Aurelia} from 'aurelia-framework'
import environment from './environment';
import {setupValidationRules} from "./validation/custom-rules";
import * as moment from 'moment';
import * as Quill from "quill";
import 'moment/locale/pt';
import {jsRedirect} from "./utils/ItMultiPurpose";

 moment.locale("pt");

// forçar https
if (document.location.protocol.startsWith("https"))
{
  if(environment.debug) console.log("[main]", "HTTPS OK!");
} else {
  if(environment.debug) console.log("[main]", "REDIRECTING TO HTTPS", document.location.hostname, document.location.hostname.endsWith("localhost"));
  if (!environment.debug && !document.location.hostname.endsWith("localhost"))
    jsRedirect("https://" + document.location.host);
}

// region configurar o flatpickr globalmente
import * as _flatpickr from 'flatpickr';
const flatpickr: any = (<any>_flatpickr).default || _flatpickr;

var Portuguese = {
  weekdays: {
    shorthand: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    longhand: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"]
  },
  months: {
    shorthand: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    longhand: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
  },
  rangeSeparator: " até "
};
flatpickr.localize(Portuguese);
// endregion configurar o flatpickr globalmente

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin('aurelia-animator-css')
    .plugin('aurelia-event-aggregator')
    .plugin('aurelia-validation')
    .plugin('aurelia-dialog', config => {
      config.useDefaults();
      config.settings.lock                 = true;
      config.settings.startingZIndex       = 200;
      config.settings.keyboard             = [/*'Enter', */'Escape'];
      config.settings.centerHorizontalOnly = true;
      //config.settings.rejectOnCancel = true;
    })
    .feature('it-v-grid')

    .globalResources('resources/value-converters/number-string')
    .feature('it-features')
    .feature('resources')
  ;

  //definir block level para Quill como div (RDL porta-se melhor)
  //https://github.com/quilljs/quill/issues/861
  let Block     = Quill.import('blots/block');
  Block.tagName = 'DIV';
  Quill.register(Block, true);

  let quillOptions = {
    debug      : environment.debug ? 'info' : false,
    // modules: {
    //   toolbar: '#toolbar'
    // },
    placeholder: 'Inserir texto...',
    //readOnly: true
  };

  aurelia.use.plugin('aurelia-quill-plugin', quillOptions);

  if (environment.debug) { aurelia.use.developmentLogging();}
  if (environment.testing) { aurelia.use.plugin('aurelia-testing');}

  setupValidationRules();

  // apenas se usam estas opções no caso de se usar o plugin aurelia-quill (desaconselhado no caso de se pretender grande )
  // let quillOptions = {
  //   debug      : environment.debug ? 'info' : false,
  //   // modules: {
  //   //   toolbar: '#toolbar'
  //   // },
  //   placeholder: 'Inserir texto...',
  //   //readOnly: true
  // };
  //
  // aurelia.use.plugin('aurelia-quill-plugin', quillOptions);

  if(environment.debug) console.log("[main]","Setting isMobile to false.");
  window && (window["isMobile"] = (window.innerWidth < 720 || false));

  return aurelia.start().then(() => aurelia.setRoot());
}
