import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import {PlanoExpedicao} from "../models/PlanoExpedicao";
import {TipoRegistoPlano} from "../models/TipoRegistoPlano";
import {RegistoPlano} from "../models/RegistoPlano";
import environment from "../environment";
import {ComposeDialog, ComposeDialogOptions} from "./compose-dialog";
import {RegistoEtiqueta} from "../models/RegistoEtiqueta";
import {ControloDiario} from "../models/ControloDiario";

@autoinject()
export class ListaRegistoPlanoDialog {
  // protected controller: DialogController;
  // private app: GlobalServices;
  protected localState: { linha: PlanoExpedicao, rowIndex: number, cellIndex: number, withActions: boolean };
  protected tipos = TipoRegistoPlano.tipos();

  protected linhaPlano: PlanoExpedicao;
  private withActions: boolean = true;

  constructor(protected controller: DialogController, protected app: GlobalServices) {
    // this.controller           = controller;
    // this.app                  = app;
  }

  canActivate(localState) {
    if (environment.debug) console.log("[lista-registo-plano-dialog]", "Activate ListaRegistoPlanoDialog:", localState);
    this.localState = localState;

    //atribuição defensiva para valor defeito. apenas olha para a definição explícita de false
    if (localState.withActions === false) {this.withActions = false;}

    return this.app.api.getProcessed('api/plano-expedicao/linha-completa', {id: this.localState.linha.idPlanoExpedicao})
      .then(r => {
        if (environment.debug) console.log("[lista-registo-plano-dialog]", "canActivate", "resposta do servidor", r);
        this.linhaPlano = PlanoExpedicao.fromPOJSO(r);
        if (environment.debug) console.log("[lista-registo-plano-dialog]", "canActivate", "resposta processada", this.linhaPlano);
        return true;
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }

  // attached() {
  //   //
  // }

  removeRegisto(idRegisto: number) {
    return this.controller.ok({action: "REMOVE-REGISTO", payload: {linha: this.localState.linha, registo: idRegisto, rowIndex: this.localState.rowIndex, confirmLevel: 0}});
  }

  editaRegisto(idRegisto: number) {
    //console.log("Editar registo", idRegisto);
    return this.controller.ok({action: "EDITA-REGISTO", payload: {linha: this.localState.linha, registo: idRegisto, rowIndex: this.localState.rowIndex}});
  }

  editaLinhaEncomenda() {
    return this.controller.ok({action: "EDITA-LINHA", payload: {linha: this.localState.linha, rowIndex: this.localState.rowIndex}});
  }

  criaNovoRegisto() {
    return this.controller.ok({action: "CRIA-REGISTO", payload: {rowIndex: this.localState.rowIndex, cellIndex: this.localState.cellIndex}})
  }

  duplicaLinhaPrg(registo: RegistoPlano) {
    return this.controller.ok({action: "DUPLICA-LINHA-PRG", payload: {registoPlano: registo}})
  }

  detalhesEtiqueta(etiqueta: RegistoEtiqueta) {
    return this.app.ds.open({
      viewModel: ComposeDialog,
      model    : {
        modelo : etiqueta,
        invoker: this,
        options: new ComposeDialogOptions({
          withDefaultFooter: false,
          trackChanges     : false,
          title            : "Ver detalhes de etiqueta nº " + etiqueta.idRegistoEtiqueta + " #CD" + etiqueta.idControloDiario,
          okLabel          : "",
          mainView         : './lista-registo-plano-dialog-detalhes-etiqueta.html',
          //postUri          : 'api/controlo/etiqueta'
        }),
      }
    });
  }
}
