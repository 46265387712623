/**
 * Em typescript não é possível multi-inheritance
 * Mas é possível um mecanismo de misxins (que se assemelham aos traits PHP, ou à implementação de uma interface c# com métodos de extensão.)
 * //https://github.com/Microsoft/TypeScript/wiki/What's-new-in-TypeScript#support-for-mix-in-classes
 * O código que daí resulta é um pouco JS a mais
 *
 * o problema pode ser resolvido por acumulação
 */
import {computedFrom} from "aurelia-framework";
import {Operador} from "./Operador";
import {BaseViewModel} from "./BaseViewModel";

/**
 * Esta classe Intermedia adiciona os campos necessários para adição de informação sobre um operador a um BaseViewModel
 */
export class OperadorDerivation extends BaseViewModel {
  public idOperador: number          = null;
  public idOperadorTemp: number      = null;
  public nvcNomeOperadorTemp: string = null;

  public idOperadorNavigation: Operador = null;

  constructor(...args: any[]) {
    super();
  }

  @computedFrom("idOperador", "idOperadorNavigation")
  public get Operador(): Operador {
    // console.log("getter " + this, this, this.idOperadorNavigation);
    if(this.idOperador || this.idOperadorTemp || this.nvcNomeOperadorTemp)
      return this.idOperadorNavigation || new Operador({idOperador: this.idOperador, idOperadorTemp: this.idOperadorTemp, nvcNomeOperadorTemp: this.nvcNomeOperadorTemp, nvcNome: this.nvcNomeOperadorTemp});
    return this.idOperadorNavigation;
  }

  public set Operador(operador: Operador) {
    if (operador) {
      this.idOperadorNavigation = operador;
      this.idOperador           = operador.idOperador;
      this.idOperadorTemp       = operador.idOperadorTemp;
      this.nvcNomeOperadorTemp  = operador.nvcNomeOperadorTemp;
    } else {
      this.idOperadorNavigation = null;
      this.idOperador           = null;
      this.idOperadorTemp       = null;
      this.nvcNomeOperadorTemp  = null;
    }
  }

  // public regenOperador() {
  //   console.log("regenOperador");
  //   this.idOperadorNavigation = new Operador({idOperador: this.idOperador, idOperadorTemp: this.idOperadorTemp, nvcNomeOperadorTemp: this.nvcNomeOperadorTemp, nvcNome: this.nvcNomeOperadorTemp});
  //   console.log("regenOperador", this.idOperadorNavigation);
  //   //if (this.idOperador || this.idOperadorTemp)
  // }
}