import {FrameworkConfiguration} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    './elements/ej-date-picker',
    './elements/estado-fnc',
    './elements/my-select2',
    './elements/my-select2-tags',
    './elements/number-filter',
    './elements/loading-indicator',
    './elements/loader.html',
    './elements/form-group.html',
    './elements/f-g.html',
    './elements/page-loading-frame.html',
    './elements/select-operador',
    './elements/smoke-curtain.html'
  ]);

  // value-converters
  config.globalResources([
    './value-converters/clean-iso-date',
    './value-converters/date-pt',
    './value-converters/identity-formatter',
    './value-converters/number-formatter',
    './value-converters/other-zero',
    './value-converters/positive-number',
    './value-converters/trim-10',
  ]);
}
