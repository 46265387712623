// Auto-gerado com typewriter
import {ValidationRules} from "aurelia-validation";
import {computedFrom} from "aurelia-framework";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {ControloDiario, TipoControlo} from './ControloDiario';
import {DocumentoControlo} from './DocumentoControlo';
import {OperadorDerivation} from "./BaseViewModelDerivations";
import {dateISOString, dia} from "../utils/ItNumeric";
import {VRegistoPlanoPrgOriginal} from "./VRegistoPlanoPrgOriginal";
import environment from "../environment";
import {TipoRegistoPlano} from "./TipoRegistoPlano";

//export classe
export class ControloPlanoExpedicao extends OperadorDerivation {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idPlanoExpedicao: number                         = 0;
  public idPlanoExpedicaoOrigem: number                   = null;
  public idDocumentoControlo: number                      = null;
  public nvcClienteArmazem: string                        = null;
  public nvcArtigo: string                                = null;
  public nvcArtigoDescricao: string                       = null;
  public nvcEstado: string                                = null;
  public nvcCodigoEncomenda: string                       = null;
  public nvcGravacao: string                              = null;
  public bitControlo: boolean                             = null;
  public nvcBuffer: string                                = null;
  public nvcTipo: string                                  = null;
  public controloDiario: ControloDiario[]                 = [];
  public idDocumentoControloNavigation: DocumentoControlo = null;

  //lista com os registos PRG e as suas quantidades originais (antes de divisão, sendo que esta cria PRG originais também)
  public RegistosPrgOriginais: VRegistoPlanoPrgOriginal[] = [];
  public Faltas: VRegistoPlanoPrgOriginal[]               = [];

  //mapa de controlos diários
  //public Diarios: Map<string, ControloDiario> = new Map<string, ControloDiario>();
  public Diarios: any    = {};
  public DiarioSort: any = {};

  public PrgDias:any = {};

  //region EXTRA
  public nvcTipoPlano: string;

  public numLinha: number; //não sei se dará jeito. logo se vê
  public boas: number            = 0;
  public ks: number              = 0;
  public fs: number              = 0;
  public apart: number           = 0;
  public totalControlado: number = 0;

  //public stock: number = 0;

  public etiqBoas: number        = 0;
  public etiqKs: number          = 0;
  public etiqFs: number          = 0;
  public etiqApart: number       = 0;
  public totalEtiquetado: number = 0;

  //excedentes de controlo
  public excBoas: number  = 0;
  public excK: number     = 0;
  public excF: number     = 0;
  public excApart: number = 0;
  public exc: number      = 0;

  //gravacao
  public gravBoas: number     = 0;
  public gravKs: number       = 0;
  public gravFs: number       = 0;
  public gravApart: number    = 0;
  public totalGravado: number = 0;

  //excedentes de gravação
  public excGravBoas: number  = 0;
  public excGravK: number     = 0;
  public excGravF: number     = 0;
  public excGravApart: number = 0;
  public excGrav: number      = 0;

  public intFalta: number           = 0;
  public intFaltaOriginal: number   = 0;
  public intFaltaControlada: number = 0;
  public intFaltaGravada: number    = 0;
  public intFaltaEtiquetada: number = 0;

  public intPrgOriginal:number = 0;
  public intSai:number = 0;

  public get color() {
    return this.idPlanoExpedicao % 2 ? "red" : "blue";
  };

  @computedFrom("nvcTipo")
  public get nvcTipoShort() {
    return this.nvcTipo.substr(0, 1);
  }

  public set nvcTipoShort(s: string) {
    if (s.toLowerCase() == "g")
      this.nvcTipo = "gravacao";
    else
      this.nvcTipo = "controlo";
  }

  //endregion EXTRA

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ControloPlanoExpedicao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  /*
    /!**
     * Copia o estado de outro objeto para o corrente (apenas primitivas). a composição de árvores complexas deve ser conseguida com mais código
     *!/
    public copyState(obj: any, props: string[] = []): ControloPlanoExpedicao {
      super.copyState(obj, props);

      let hoje = dateISOString(new Date());

      //obj.registoPlanoPrgOriginal && obj.registoPlanoPrgOriginal.map(el => );
      if(obj.registoPlanoPrgOriginal) {
        //os registos do passado que não são falta não são incorporados.
        // model.Faltas = obj.registoPlanoPrgOriginal.filter(rppo => rppo.dtmMovimento < hoje);
        this.Faltas = VRegistoPlanoPrgOriginal.multipleFromPOJSO(obj.registoPlanoPrgOriginal.filter(rppo => rppo.bitFalta));
        this.RegistosPrgOriginais = obj.registoPlanoPrgOriginal.filter(rppo => rppo.dtmMovimento >= hoje && !rppo.bitFalta);
      }

      //parse dos ControlosDiários
      obj.controloDiario && (this.controloDiario = ControloDiario.multipleFromPOJSO(obj.controloDiario));
      //this.controloDiario.forEach( cd => cd.copyState())

      //injeção de ControlosDiários sem correspondente de programação
      let rangeDatas = Object.keys(this.Diarios);
      //console.log("rangeDatas")
      rangeDatas.forEach(el => {
        let d = this.controloDiario.find(cd => cd.dtmData == el);
        if(d == null) {
          d = new ControloDiario({dtmData: el, nvcEstadoControlo: "TEMP"});
        }

        //todo: 2 otimizar isto, visto que é garantido que as PRG estão ordenadas
        let prg = this.RegistosPrgOriginais.filter(reg => reg.dtmMovimento == el);
        if(prg && prg.length > 0) {
          //há programações Originais para o dia.
          //todo: 3 procurar se a programação original não está já satisfeita.
          prg.forEach(p => d.encaixarPrgOriginal(p))
        }
        this.setDiario(d);
      });

      this.controloDiario && this.controloDiario.forEach(el => el.idControloPlanoExpedicaoNavigation = this);
      // this.dtmData = this.dtmData && this.dtmData.substring(0, 10);
      //
      // this.idOperadorNavigation = new Operador({idOperador: obj.idOperador, idOperadorTemp: obj.idOperadorTemp, nvcNomeOperadorTemp: obj.nvcNomeOperadorTemp});
      //
      // obj.registoControlo && (this.registoControlo = RegistoControlo.multipleFromPOJSO(obj.registoControlo));
      // obj.registoControloInicial && (this.registoControloInicial = RegistoControloInicial.multipleFromPOJSO(obj.registoControloInicial));
      // obj.registoEtiqueta && (this.registoEtiqueta = RegistoEtiqueta.multipleFromPOJSO(obj.registoEtiqueta));
      //
      // //backlinks
      // this.registoControlo.forEach(el => el.idControloDiarioNavigation = this);
      // this.registoControloInicial.forEach(el => el.idControloDiarioNavigation = this);
      return this;
    }

  */

  /**
   * @param obj
   * @return {ControloPlanoExpedicao}
   */
  public static fromPOJSO(obj: any): ControloPlanoExpedicao {
    let model = new ControloPlanoExpedicao();
    model.setState(obj);
    return model;
  }

  /**
   * Este parse terá de contemplar bastantes situações.
   * Em princípio o objeto ControloPlanoExpedicao apenas terá de enviar a sua raiz, ou não
   */
  public static specialFromPOJSOControlo(obj: any, rangeDatas: dia[]): ControloPlanoExpedicao {
    //console.log("specialFromPOJSOControlo", obj, rangeDatas, rangeDatas[0].dia);
    let model = new ControloPlanoExpedicao();
    model.setState(obj);
    let hoje = dateISOString(new Date());

    // if(model.idPlanoExpedicao == 9531) {
    //   console.log("isola id");
    // }

    if (obj.registosPrgOriginal) {
      //os registos do passado que não são falta não são incorporados.
      let objs       = obj.registosPrgOriginal.filter(rppo => rppo.bitFalta && rppo.dtmMovimento < rangeDatas[0].dia);
      // console.log("objs", objs);
      model.Faltas   = VRegistoPlanoPrgOriginal.multipleFromPOJSO(objs);
      model.intFalta = model.intFaltaOriginal = model.intFaltaControlada = model.intFaltaGravada = 0;
      model.Faltas.forEach(el => {
        model.intFalta += el.intDelta;
        model.intFaltaOriginal += el.intDeltaOriginal;
        //nota: isto pode causar registos que mostram maior quantidade controlada do que aquela que é pedida pela linha. Por agora fica assim, mas é preciso ver com o miguel se está bem
        model.intFaltaControlada += el.intQuantidadeControlada;
        model.intFaltaGravada    += el.intQuantidadeGravada;
        model.intFaltaEtiquetada += el.intQuantidadeEtiquetada;
        //console.log(model.intFaltaEtiquetada, el.intQuantidadeEtiquetada);
      });
      model.RegistosPrgOriginais = obj.registosPrgOriginal;//.filter(rppo => rppo.dtmMovimento >= hoje && !rppo.bitFalta);
    }

    //parse dos ControlosDiários
    obj.controloDiario && (model.controloDiario = ControloDiario.multipleFromPOJSO(obj.controloDiario));

    //injeção de ControlosDiários sem correspondente de programação
    rangeDatas.forEach(el => {
      let d = model.controloDiario.find(cd => cd.dtmData == el.dia);
      if (d == null) {
        d                                    = new ControloDiario({dtmData: el.dia, nvcEstadoControlo: "TEMP"});
        d.idControloPlanoExpedicaoNavigation = model;
      }

      //todo: 2 otimizar isto, visto que é garantido que as PRG estão ordenadas
      let prg = model.RegistosPrgOriginais.filter(reg => reg.dtmMovimento == el.dia);
      // apenas se espera uma programação para cada dia por linha de encomenda
      if (prg && prg.length > 0) {
        //há programações Originais para o dia.
        //prg.forEach(p => d.encaixarPrgOriginal(p));
        d.encaixarPrgOriginal(prg[0]);
      }
      model.setDiario(d);
      if (d.idControloDiario > 0) {

        model.boas += (d.boas - d.transpBoas);
        model.ks += (d.ks - d.transpK);
        model.fs += (d.fs - d.transpF);
        model.apart += (d.apart - d.transpApart);

        model.etiqBoas += d.etiqBoas;
        model.etiqKs += d.etiqK;
        model.etiqFs += d.etiqF;
        model.etiqApart += d.etiqApart;

        model.gravBoas += d.gravBoas;
        model.gravKs += d.gravK;
        model.gravFs += d.gravF;
        model.gravApart += d.gravApart;
      }
    });
    model.totalControlado = model.boas + model.ks + model.fs + model.apart;
    model.totalEtiquetado = model.etiqBoas + model.etiqKs + model.etiqFs + model.etiqApart;
    model.totalGravado    = model.gravBoas + model.gravKs + model.gravFs + model.gravApart;

    model.excBoas  = model.boas - model.etiqBoas;
    model.excK     = model.ks - model.etiqKs;
    model.excF     = model.fs - model.etiqFs;
    model.excApart = model.apart - model.etiqApart;
    model.exc      = model.excBoas + model.excK + model.excF + model.excApart;

    model.excGravBoas  = model.gravBoas - model.etiqBoas;
    model.excGravK     = model.gravKs - model.etiqKs;
    model.excGravF     = model.gravFs - model.etiqFs;
    model.excGravApart = model.gravApart - model.etiqApart;
    model.excGrav      = model.excGravBoas + model.excGravK + model.excGravF + model.excGravApart;

    model.controloDiario && model.controloDiario.forEach(el => el.idControloPlanoExpedicaoNavigation = model);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): ControloPlanoExpedicao[] {
    if (objs && Array.isArray(objs)) return objs.map(ControloPlanoExpedicao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ControloPlanoExpedicao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ControloPlanoExpedicao { return ControloPlanoExpedicao.fromPOJSO(this.stateToPOJSO());}

  //endregion

  //region métodos próprios

  public infereDiariosPeriodo(rangeDatas: dia[]) {

    //injeção de ControlosDiários sem correspondente de programação
    rangeDatas.forEach(el => {
      let d = this.controloDiario.find(cd => cd.dtmData == el.dia);
      if (d == null) {
        d                                    = new ControloDiario({dtmData: el.dia, nvcEstadoControlo: "TEMP"});
        d.idControloPlanoExpedicaoNavigation = this;
      }

      //todo: 2 otimizar isto, visto que é garantido que as PRG estão ordenadas
      let prg = this.RegistosPrgOriginais.filter(reg => reg.dtmMovimento == el.dia);
      // apenas se espera uma programação para cada dia por linha de encomenda
      if (prg && prg.length > 0) {
        //há programações Originais para o dia.
        //prg.forEach(p => d.encaixarPrgOriginal(p));
        d.encaixarPrgOriginal(prg[0]);
      }
      this.setDiario(d);

      this.totalControlado += d.intTotalControladas;
      this.totalEtiquetado += d.intTotalEtiquetadas;
      this.totalGravado += d.intTotalGravadas;
    });
    this.exc     = this.totalControlado - this.totalEtiquetado;
    this.excGrav = this.totalGravado - this.totalEtiquetado;
    this.controloDiario && this.controloDiario.forEach(el => el.idControloPlanoExpedicaoNavigation = this);
    return this;
  }

  public getDiario(dia: string) {
    if (this.Diarios && this.Diarios[dia])
      return this.Diarios[dia];

    return this.controloDiario.find(el => el.dtmData == dia);
  }

  /**
   * Adiciona um diário a um dia do objeto mapa
   * @param {ControloDiario} d
   */
  public setDiario(d: ControloDiario) {
    d.idPlanoExpedicao         = this.idPlanoExpedicao;
    //if(environment.debug) console.log("[controloplanoexpedicao]","setDiario");
    this.DiarioSort[d.dtmData] = d.intTotalPrgOriginal || 0;
    if (this.Diarios[d.dtmData]) {
      //a key já existe? deve-se atualizar o que se acha que sim
      this.Diarios[d.dtmData] = d;
    } else {
      //else adiciona um diário temporário para descrever a programação no dia
      if (/*d.intTotalParaControlo || */d.intTotalControladas || d.intTotalPrgOriginal)
        this.Diarios[d.dtmData] = d;
    }
  }

  //endregion métodos próprios

  //region faltas
  /**
   * Parse de lista de faltas e de controlos diários correspondentes. Chamado na abertura do dialog de detalhes de faltas
   */
  public mergeDiariosFaltas(obj: any, diaIni: string): ControloPlanoExpedicao {
    if (obj.registosPrgOriginal) {
      //os registos do passado que não são falta não são incorporados.
      let objs    = obj.registosPrgOriginal.filter(rppo => rppo.bitFalta && rppo.dtmMovimento < diaIni);
      this.Faltas = VRegistoPlanoPrgOriginal.multipleFromPOJSO(objs);

      this.intFalta = this.intFaltaOriginal = this.intFaltaControlada = this.intFaltaGravada = 0;
      this.Faltas.forEach(el => {
        this.intFalta += el.intDelta;
        this.intFaltaOriginal += el.intDeltaOriginal;
        this.intFaltaControlada += el.intQuantidadeControlada;
        this.intFaltaGravada += el.intQuantidadeGravada;
        //procura controlos diários associados à falta

        if (obj.controloDiario) {
          let cd = obj.controloDiario.find(c => c.idRegistoPlano == el.idRegistoPlano);
          if (cd) {
            el.ControloDiario = ControloDiario.fromPOJSO(cd);
          } else {
            el.ControloDiario = new ControloDiario({idPlanoExpedicao: this.idPlanoExpedicao, nvcEstadoControlo: "TEMP", dtmData: el.dtmMovimento});
            el.ControloDiario.encaixarPrgOriginalTipado(el);
          }
          //circular ref
          el.ControloDiario.idVRegistoPlanoPrgOriginalNavigation = el;
          el.ControloDiario.idControloPlanoExpedicaoNavigation   = this;

          //todo: proteger isto contra multipla inserção
          this.controloDiario.push(el.ControloDiario);
        }
      });
      // if (environment.debug) console.log("[controloplanoexpedicao]", "mergeDiariosFaltas", this.Faltas);
    }
    //parse dos ControlosDiários
    //obj.controloDiario && (model.controloDiario = ControloDiario.multipleFromPOJSO(obj.controloDiario));
    return this;
  }

  //
  //
  // public mergeDiariosFaltas2(obj: any, diaIni: string): ControloPlanoExpedicao {
  //   if (obj.registosPrgOriginal) {
  //
  //     // this.intFalta = this.intFaltaOriginal = this.intFaltaControlada = this.intFaltaGravada = 0;
  //
  //     this.Faltas.forEach(el => {
  //       // this.intFalta += el.intDelta;
  //       // this.intFaltaOriginal += el.intDeltaOriginal;
  //       // this.intFaltaControlada += el.intQuantidadeControlada;
  //       // this.intFaltaGravada += el.intQuantidadeGravada;
  //       //procura controlos diários associados à falta
  //
  //       console.log("WTF?", el);
  //       if (obj.controloDiario) {
  //         let cd = obj.controloDiario.find(c => c.idRegistoPlano == el.idRegistoPlano);
  //         if (cd) {
  //           el.ControloDiario = ControloDiario.fromPOJSO(cd);
  //         } else {
  //           el.ControloDiario = new ControloDiario({idPlanoExpedicao: this.idPlanoExpedicao, nvcEstadoControlo: "TEMP", dtmData: el.dtmMovimento});
  //           el.ControloDiario.encaixarPrgOriginalTipado(el);
  //         }
  //         //circular ref
  //         el.ControloDiario.idVRegistoPlanoPrgOriginalNavigation = el;
  //         el.ControloDiario.idControloPlanoExpedicaoNavigation   = this;
  //
  //         //todo: proteger isto contra multipla inserção
  //         this.controloDiario.push(el.ControloDiario);
  //       }
  //     });
  //     // if (environment.debug) console.log("[controloplanoexpedicao]", "mergeDiariosFaltas", this.Faltas);
  //   }
  //   //parse dos ControlosDiários
  //   //obj.controloDiario && (model.controloDiario = ControloDiario.multipleFromPOJSO(obj.controloDiario));
  //   return this;
  // }
  //
  //

  public recalculaFaltas() {
    this.intFalta = this.intFaltaOriginal = this.intFaltaControlada = this.intFaltaGravada = 0;
    this.Faltas.forEach(el => {
      this.intFalta += el.intDelta;
      this.intFaltaOriginal += el.intDeltaOriginal;
      //nota: isto pode causar registos que mostram maior quantidade controlada do que aquela que é pedida pela linha. Por agora fica assim, mas é preciso ver com o miguel se está bem
      this.intFaltaControlada += el.intQuantidadeControlada;
      this.intFaltaGravada += el.intQuantidadeGravada;
      this.intFaltaEtiquetada += el.intQuantidadeEtiquetada;
      //console.log(model.intFaltaEtiquetada, el.intQuantidadeEtiquetada);
    });
  }

  //endregion faltas

  public toString() {
    return `Controlo - Plano ${this.nvcClienteArmazem}: ${this.nvcArtigo} - ${this.nvcArtigoDescricao}`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: ControloPlanoExpedicao) => m.idPlanoExpedicao).displayName("idPlanoExpedicao").required()
  .ensure((m: ControloPlanoExpedicao) => m.idPlanoExpedicaoOrigem).displayName("idPlanoExpedicaoOrigem").required()
  .ensure((m: ControloPlanoExpedicao) => m.idDocumentoControlo).displayName("idDocumentoControlo").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcClienteArmazem).displayName("nvcClienteArmazem").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcArtigo).displayName("nvcArtigo").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcArtigoDescricao).displayName("nvcArtigoDescricao").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcEstado).displayName("nvcEstado").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcCodigoEncomenda).displayName("nvcCodigoEncomenda").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcGravacao).displayName("nvcGravacao").required()
  .ensure((m: ControloPlanoExpedicao) => m.bitControlo).displayName("bitControlo").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcBuffer).displayName("nvcBuffer").required()
  .ensure((m: ControloPlanoExpedicao) => m.idOperador).displayName("idOperador").required()
  .ensure((m: ControloPlanoExpedicao) => m.idOperadorTemp).displayName("idOperadorTemp").required()
  .ensure((m: ControloPlanoExpedicao) => m.nvcNomeOperadorTemp).displayName("nvcNomeOperadorTemp").required()
  .ensure((m: ControloPlanoExpedicao) => m.controloDiario).displayName("controloDiario").required()
  .ensure((m: ControloPlanoExpedicao) => m.idDocumentoControloNavigation).displayName("idDocumentoControloNavigation").required()
  .ensure((m: ControloPlanoExpedicao) => m.idOperadorNavigation).displayName("idOperadorNavigation").required()
  .on(ControloPlanoExpedicao);
