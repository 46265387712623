// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {ControloPlanoExpedicao} from './ControloPlanoExpedicao';
import {RegistoPlanoPrgOriginal} from "./RegistoPlanoPrgOriginal";
import {OperadorDerivation} from "./BaseViewModelDerivations";
import {RegistoControlo} from "./RegistoControlo";
import {RegistoEtiqueta} from "./RegistoEtiqueta";
import environment from "../environment";
import {Operador} from "./Operador";
import {VRegistoPlanoPrgOriginal} from "./VRegistoPlanoPrgOriginal";
import {dateISOString} from "../utils/ItNumeric";
import {RegistoEntradaControlo} from "./RegistoEntradaControlo";

//export classe
export class ControloDiario extends OperadorDerivation {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idControloDiario: number          = 0;
  public idPlanoExpedicao: number          = 0;
  public idRegistoPlano: number            = null;
  public nvcTipo: string                   = TipoControlo.Controlo;
  public dtmData: string                   = null;
  public intTotalPrgOriginal: number       = 0;
  public intTotalControladas: number       = 0;
  public intTotalGravadas: number          = 0;
  public intTotalEtiquetadas: number       = 0;
  public intExcedenteControlado: number    = 0;
  public intExcedenteGravado: number       = 0;
  public intExcedenteControloUsado: number = 0;
  public intExcedenteGravacaoUsado: number = 0;
  public nvcEstadoControlo: string         = null;
  public timestamp: number[]               = [];

  //relações
  public idControloPlanoExpedicaoNavigation: ControloPlanoExpedicao     = null;
  public idVRegistoPlanoPrgOriginalNavigation: VRegistoPlanoPrgOriginal = null;

  public registoControlo: RegistoControlo[] = [];
  public registoEtiqueta: RegistoEtiqueta[] = [];

  public registoEntradaControloIdControloDiarioNavigation: RegistoEntradaControlo[]      = [];
  public registoEntradaControloIdControloDiarioSaidaNavigation: RegistoEntradaControlo[] = [];

  //region EXTRA
  public _intDisponiveis: number = 0;

  //controladas
  public boas: number  = 0;
  public ks: number    = 0;
  public fs: number    = 0;
  public apart: number = 0;

  //etiquetadas
  public etiqBoas: number  = 0;
  public etiqK: number     = 0;
  public etiqF: number     = 0;
  public etiqApart: number = 0;

  //excedentes de controlo
  public excBoas: number  = 0;
  public excK: number     = 0;
  public excF: number     = 0;
  public excApart: number = 0;
  public exc: number      = 0;

  public stock: number = 0;

  public transpBoas: number  = 0;
  public transpK: number     = 0;
  public transpF: number     = 0;
  public transpApart: number = 0;
  public transporte: number  = 0;

  //gravadas
  public gravBoas: number  = 0;
  public gravK: number     = 0;
  public gravF: number     = 0;
  public gravApart: number = 0;

  //endregion EXTRA

  @computedFrom("intTotalPrgOriginal", "intTotalControladas")
  public get intTotalParaControlo() {
    return this.intTotalPrgOriginal - this.intTotalControladas;
  }

  @computedFrom("intTotalControladas", "intExcedenteControlado")
  public get intCapControladas() {
    return this.intTotalControladas - this.intExcedenteControlado;
    // return this.intTotalControladas - this.intExcedenteControloUsado;
  }

  @computedFrom("intTotalGravadas", "intExcedenteGravado")
  public get intCapGravadas() {
    return this.intTotalGravadas - this.intExcedenteGravado;
  }

  @computedFrom("registoControlo")
  public get controlos(): RegistoControlo[] {
    return this.registoControlo.filter(el => el.nvcTipo == TipoControlo.Controlo);
  }

  @computedFrom("registoControlo")
  public get gravacoes(): RegistoControlo[] {
    return this.registoControlo.filter(el => el.nvcTipo == TipoControlo.Gravacao);
  }

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ControloDiario>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  /**
   * Copia o estado de outro objeto para o corrente (apenas primitivas). a composição de árvores complexas deve ser conseguida com mais código
   */
  public copyState(obj: any, props: string[] = []): ControloDiario {
    let grafoCompleto = false;
    this.boas         = this.ks = this.fs = this.apart = this.etiqBoas = this.etiqApart = this.etiqF = this.etiqK = 0;
    super.copyState(obj, props);
    this.dtmData = this.dtmData && this.dtmData.substring(0, 10);

    this.idOperadorNavigation = new Operador({idOperador: obj.idOperador, idOperadorTemp: obj.idOperadorTemp, nvcNomeOperadorTemp: obj.nvcNomeOperadorTemp});

    if (obj.registoControlo) {
      this.registoControlo = RegistoControlo.multipleFromPOJSO(obj.registoControlo);
      grafoCompleto        = grafoCompleto || this.registoControlo.length > 0;
    }
    if (obj.registoEtiqueta) {
      this.registoEtiqueta = RegistoEtiqueta.multipleFromPOJSO(obj.registoEtiqueta);
      grafoCompleto        = grafoCompleto || this.registoEtiqueta.length > 0;
    }
    if (obj.registoEntradaControloIdControloDiarioNavigation) {
      this.registoEntradaControloIdControloDiarioNavigation = RegistoEntradaControlo.multipleFromPOJSO(obj.registoEntradaControloIdControloDiarioNavigation);
      grafoCompleto                                         = grafoCompleto || this.registoEntradaControloIdControloDiarioNavigation.length > 0;
    }
    if (obj.registoEntradaControloIdControloDiarioSaidaNavigation) {
      this.registoEntradaControloIdControloDiarioSaidaNavigation = RegistoEntradaControlo.multipleFromPOJSO(obj.registoEntradaControloIdControloDiarioSaidaNavigation);
      grafoCompleto                                              = grafoCompleto || this.registoEntradaControloIdControloDiarioSaidaNavigation.length > 0;
    }

    obj.idVRegistoPlanoPrgOriginalNavigation && (this.idVRegistoPlanoPrgOriginalNavigation = VRegistoPlanoPrgOriginal.fromPOJSO(obj.idVRegistoPlanoPrgOriginalNavigation));

    //é necessário?
    obj.idPlanoExpedicaoNavigation && (this.idControloPlanoExpedicaoNavigation = ControloPlanoExpedicao.fromPOJSO(obj.idPlanoExpedicaoNavigation));

    //backlinks
    this.registoControlo.forEach(el => {el.idControloDiarioNavigation = this;});
    this.registoEtiqueta.forEach(el => {el.idControloDiarioNavigation = this;});

    grafoCompleto && this.recalcula();

    return this;
  }

  public static fromPOJSO(obj: any): ControloDiario {
    return new ControloDiario().copyState(obj);
  }

  public static multipleFromPOJSO(objs: any | any[]): ControloDiario[] {
    if (objs && Array.isArray(objs)) return objs.map(ControloDiario.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state = this.getState();

    // state.registoControloInicial = this.registoControloInicial.map(el => el.stateToPOJSO());
    state.registoControlo = this.registoControlo.map(el => el.stateToPOJSO());
    state.registoEtiqueta = this.registoEtiqueta.map(el => el.stateToPOJSO());

    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ControloDiario>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ControloDiario {
    let controloDiario = ControloDiario.fromPOJSO(this.stateToPOJSO());
    return controloDiario;
  }

  //endregion

  //region registos_plano

  /**
   * Obtem o RU de Controlo na posição ou um novo para a posição
   * @param {number} index
   * @return {any}
   */
  public getRegistoControlo(index: number = -1) {
    if (environment.debug) console.log("[controlodiario]", "getRuControlo", index);
    if (index >= 0) {
      return this.registoControlo[index] || (this.registoControlo[index] = this.novoRuControlo());
    } else {
      let len = this.registoControlo.push(this.novoRuControlo());
      return this.registoControlo[len - 1];
    }
  }

  public novoRuControlo() {
    let hoje = dateISOString(new Date());
    let rc   = new RegistoControlo({intQuantidade: 0, bitValido: true, dtmData: hoje, idControloDiario: this.idControloDiario});
    // let registo                               = new Registo({intQuantidade: 0, bitValido: true, dtmData: this.dtmData, nvcTipo: TipoRegistoControlo.CTR, regAdControlo: new RegAdControlo({})});
    // registo.regAdControlo.idRegistoNavigation = registo;
    return rc;
  }

  /**
   * Recalculo local do diario
   *
   * não é claro se este cálculo necessita ser realizado
   */
  public recalcula() {
    // console.log("RECALCULA", this.idControloDiario);
    //this.boas = this.ks = this.fs = this.apart = this.etiqBoas = this.etiqApart = this.etiqF = this.etiqK = 0;

    //transporte de entrada
    this.registoEntradaControloIdControloDiarioNavigation.forEach(el => {

      this.boas += el.intBoas;
      this.ks += el.intK;
      this.fs += el.intF;
      this.apart += el.intApart;
    });

    //registos de controlo
    this.registoControlo.forEach(el => {
      if (el.bitValido) {
        if (el.nvcTipo == TipoControlo.Controlo) {
          this.boas += el.intBoas;
          this.ks += el.intK;
          this.fs += el.intF;
          this.apart += el.intApart;
        } else {
          this.gravBoas += el.intBoas;
          this.gravK += el.intK;
          this.gravF += el.intF;
          this.gravApart += el.intApart;
        }
      }
    });
    //só mexe neste valor quando realmente interessa
    let totalControladas = this.boas + this.fs + this.ks + this.apart;
    if (totalControladas > 0) {
      this.intTotalControladas = totalControladas;
    }
    let totalGravadas = this.gravBoas + this.gravF + this.gravK + this.gravApart;
    if (totalControladas > 0) {
      this.intTotalGravadas = totalGravadas;
    }

    //transporte de saída
    this.registoEntradaControloIdControloDiarioSaidaNavigation.forEach(el => {
      this.transpBoas += el.intBoas;
      this.transpK += el.intK;
      this.transpF += el.intF;
      this.transpApart += el.intApart;
    });
    this.transporte = this.transpBoas + this.transpK + this.transpF + this.transpApart;

    //registos de etiqueta
    this.registoEtiqueta.forEach(el => {
      if (el.bitValido) {
        this.etiqBoas += el.intBoas;
        this.etiqK += el.intK;
        this.etiqF += el.intF;
        this.etiqApart += el.intApart;
      }
    });

    //excedentes de etiqueta
    if (this.nvcTipo == TipoControlo.Controlo) {
      this.excBoas  = this.boas - this.etiqBoas;
      this.excK     = this.ks - this.etiqK;
      this.excF     = this.fs - this.etiqF;
      this.excApart = this.apart - this.etiqApart;
    } else {
      this.excBoas  = this.boas - this.gravBoas;
      this.excK     = this.ks - this.gravK;
      this.excF     = this.fs - this.gravF;
      this.excApart = this.apart - this.gravApart;
    }
    this.exc = this.excBoas + this.excK + this.excF + this.excApart;
  }

  /**
   * Se o diário não contém uma programação injeta p
   * @param {RegistoPlanoPrgOriginal} p
   */
  public encaixarPrgOriginal(p: any) {
    //if (environment.debug) console.log("[controlodiario]", "encaixarPrgOriginal", p, this);
    let vRppo = VRegistoPlanoPrgOriginal.fromPOJSO(p);
    this.encaixarPrgOriginalTipado(vRppo);
  }

  public encaixarPrgOriginalTipado(vRppo: VRegistoPlanoPrgOriginal) {
    this.idVRegistoPlanoPrgOriginalNavigation = vRppo;
    this.idRegistoPlano                       = vRppo.idRegistoPlano;
    this.intTotalPrgOriginal                  = vRppo.intDeltaOriginal;
  }

  //endregion registos_plano

  public toString() {
    return `ControloDiario ${this.dtmData}`;
  }

  public representacaoControlo() {
    return `Controladas: ${this.intCapControladas}
• Programadas: ${this.intTotalPrgOriginal}
• Total Controlado: ${this.intTotalControladas} (+${this.intExcedenteControlado})
• Excedente Usado: ${this.intExcedenteControloUsado}`;
  }

  public representacaoEtiquetada() {
    return `Etiquetadas: ${this.idVRegistoPlanoPrgOriginalNavigation && this.idVRegistoPlanoPrgOriginalNavigation.intQuantidadeEtiquetada || this.intTotalEtiquetadas}
• Programadas: ${this.intTotalPrgOriginal}`;
  }

  public representacaoGravacao() {
    return `Gravadas: ${this.intCapGravadas}
• Programadas: ${this.intTotalPrgOriginal}
• Total Gravado: ${this.intTotalGravadas} (+${this.intExcedenteGravado})
`;

  }

  /**
   * Representação em string para mostrar o título nas casas com gravação no plano
   */
  public representacaoPlano() {
    return `C: ${this.intTotalControladas}, G: ${this.intTotalGravadas}, E: ${this.intTotalEtiquetadas}`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: ControloDiario) => m.idControloDiario).displayName("idControloDiario").required()
  .ensure((m: ControloDiario) => m.dtmData).displayName("dtmData").required()
  .ensure((m: ControloDiario) => m.intTotalControladas).displayName("intTotalControladas").required()
  .ensure((m: ControloDiario) => m.nvcEstadoControlo).displayName("nvcEstadoControlo").required()
  .ensure((m: ControloDiario) => m.idOperador).displayName("idOperador").required()
  .on(ControloDiario);

export const TipoControlo = {
  Controlo : "controlo",
  Gravacao : "gravacao",
  Invertido: "invertido"
};

export const TiposControloObj = [
  {nvcTipo: TipoControlo.Controlo, id: TipoControlo.Controlo, nvcDescricao: "Controlo", text: "Controlo"},
  {nvcTipo: TipoControlo.Gravacao, id: TipoControlo.Gravacao, nvcDescricao: "Gravação", text: "Gravação"},
  {nvcTipo: TipoControlo.Invertido, id: TipoControlo.Invertido, nvcDescricao: "Invertido", text: "Invertido (Gravação -> Controlo)"},
];
