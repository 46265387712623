import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
import {Permissao} from "./Permissao";

/**
 * Created by herna on 17/7/2017.
 * DomainModel
 */
export class UtilizadorPermissao extends BaseViewModel {
  idUtilizador: number = 0;
  nvcPermissao: string  = "";
  bitPermissao: boolean = false;
  timestamp: string     = "";

  //relacoes
  private NvcPermissaoNavigation:Permissao = null;

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<UtilizadorPermissao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): UtilizadorPermissao {
    let model = new UtilizadorPermissao();
    model.setState(obj);
    model.__index = index;
    if (obj.nvcPermissaoNavigation) {
      model.NvcPermissaoNavigation = Permissao.fromPOJSO(obj.nvcPermissaoNavigation);
    }

    return model;
  }

  public static multipleFromPOJSO(objs: any): UtilizadorPermissao[] {
    if (objs) {
      if (Array.isArray(objs)) return objs.map((el, i) => UtilizadorPermissao.fromPOJSO(el, i));
      return [UtilizadorPermissao.fromPOJSO(objs, 0)];
    }
    return [];
  }

  public stateToPOJSO(): any {
    return this.getState(false);
  }

  public cloneInstance(): UtilizadorPermissao {
    console.log("clone");
    return UtilizadorPermissao.fromPOJSO(this.stateToPOJSO());
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<UtilizadorPermissao>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<UtilizadorPermissao>({payload: this, confirmLevel: (+cl || 0)});
  }

  //endregion
}
