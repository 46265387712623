import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices, GlobalServicesContainer} from "../../services/global-services";
import {RemoteGridActions} from "./interfaces";
import {ColConfigInterface, FilterObjectInterface, SortObjectInterface} from "../../it-v-grid";
import {RemoteGrid} from "./remote-grid";
import environment from "../../environment";

/**
 * picker de simples uso.
 * v 2.01 opcção para uso arbitrário de modo lista nos pickers
 * v 2
 *
 */

@autoinject()
export class PickerRemoteGrid {
  protected controller: DialogController;
  private app: GlobalServices;
  private isReady: boolean = false;

  //region bindables da RemoteGrid
  private debug: boolean                         = false;
  private codigoTabela: string                   = "check";
  private vgridStyle: string                     = "height:75vh; width:100%";
  private paginacao: string                      = "auto";
  private defaultSort: SortObjectInterface[]     = [];
  private defaultFilter: FilterObjectInterface[] = [];
  private initialFilter: FilterObjectInterface[] = [];

  private columnsOverride: ColConfigInterface[]  = [];
  private descricao: string = null;

  //endregion bindables da RemoteGrid

  //region propriedades específicas do dialog
  private prompt: string     = '<i class="fa fa-info fa-2x text-info"></i><strong style="position: relative; top: -4px;">Escolha uma linha da tabela</strong>';
  private informacao: string = null;
  //endregion propriedades específicas do dialog

  /**
   * por enquanto, sem uso no contexto do picker
   */
  private doAction: any;
  private selectionMode: "none" | "single" | "multiple" = "single";
  private rgPicker: RemoteGrid;
  private invoker: GlobalServicesContainer;
  private botoesAdicionais: {
    label: string,
    action: string,
    payload?: any
    title?: string,
    className?: string,
  }[] = [];

  //o título do popup
  private title: string;
  private listMode: boolean = false;

  constructor(controller: DialogController) {
    this.controller = controller;
    // console.log(this);
  }

  canActivate(p) {
    if (this.debug) console.log("[picker-remote-grid]", "canActivate", p);
    if (!p) {
      console.error("É necessária a introdução de alguns parâmetros para uso deste Picker");
      return false;
    } else {
      if (!p.codigoTabela) {
        console.error("O codigoTabela não foi especificado. Não é possível invocar o picker.");
        return false;
      }
      this.codigoTabela = p.codigoTabela;
      //inicializar variáveis ligadas à RG
      //todo: usar uma classe/interface para passagem destes parametros.
      if (p.vgridStyle) this.vgridStyle = p.vgridStyle;
      if (p.paginacao) this.paginacao = p.paginacao;
      if (p.defaultSort) this.defaultSort = p.defaultSort;
      if (p.defaultFilter) this.defaultFilter = p.defaultFilter;
      if (p.initialFilter) this.initialFilter = p.initialFilter;
      if (p.doAction) this.doAction = p.doAction;
      if (p.selectionMode) this.selectionMode = p.selectionMode;
      if (p.descricao) this.descricao = p.descricao;
      if (p.debug) this.debug = true;
      //
      if (p.informacao) this.informacao = p.informacao;
      if (p.prompt){
        this.prompt = p.prompt;
      }

      //se não for passado o parametro listMode assume-se o ambiente mobile como tal
      if(p.listMode === undefined) {
        this.listMode = !!window["isMobile"];
      } else {
        this.listMode = !!p.listMode;
      }

      if (p.overrideActionsColumn) this.columnsOverride = [{colField: "accoes", colRowTemplate: "<button class='btn btn-xxs' title='Seleccionar a linha'><i class='fa fa-arrow-right'></i></button>", colWidth: 40, colHidden: true}];
      if (p.title) this.title = "" + p.title;

      //adição de invoker
      this.invoker = p.invoker;

      //declarações de botões adicionais
      this.botoesAdicionais = p.botoesAdicionais || [];
    }
    return true;
  }

  attached() {
    this.isReady = true;
  }

  canDeactivate() {
    if (this.debug) console.log("[picker-remote-grid]", "canDeactivate");
    return true;
  }

  public doActionPicker(action: string, payload?: any) {
    if (this.debug) console.log("[picker-remote-grid]", "doAction Picker");

    //um picker reage a.... selecções (picks)
    //tada!
    try {
      switch (action) {
        case "OK": {
          let selection = this.rgPicker.selecaoAtual();
          // let objs = selection.map(el => Object.assign({}, el));
          let objs      = JSON.parse(JSON.stringify(selection));
          //console.log("OK", selection, objs);
          this.controller.ok(objs);
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (this.selectionMode == "single") {
            this.controller.ok(payload);
          }
          break;
        }
        default: {
          if (this.invoker && typeof this.invoker.doAction === "function") {
            if (environment.debug) console.log("[picker-remote-grid]", "calling doAction of invoker", action, payload);
            //fechar o dialog atual
            this.controller.cancel();
            return this.invoker.doAction(action, payload);
          }
          this.app.notificationErrorCompact(["Acção DESCONHECIDA [picker-remote-grid]", action]);
        }
      }
    } catch (err) {
      console.error("[picker-remote-grid]", "Erro", err);
    }
  }
}


