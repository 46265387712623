import {BaseViewModel} from "./BaseViewModel";
/**
 * Created by herna on 5/2/2017.
 * DomainModel (virtual) que classifica com estado cada linha do plano de expedição
 */
export class EstadoGuiaTransporte extends BaseViewModel {
  public nvcEstado: string    = "";
  public nvcDescricao: string = "";

  //relações:

  public static fromPOJSO(obj: any): EstadoGuiaTransporte {
    let model = new EstadoGuiaTransporte();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): EstadoGuiaTransporte[] {
    if (objs && Array.isArray(objs)) return objs.map(EstadoGuiaTransporte.fromPOJSO);
    return [];
  }

  public static estados() {
    return [
      {nvcEstado: 'aberto', nvcDescricao: 'A Guia de Transporte ainda não foi utilizada para geração de uma fatura'},
      //{nvcEstado: 'Expedido', nvcDescricao: 'Expedido'},
      {nvcEstado: 'fechado', nvcDescricao: 'Já foi gerada uma fatura para esta guia de transporte.'},
    ]
  }
}

export const estadosGuiaTransporte = {
  aberto  : "aberto",
  fechado : "fechado",
};
