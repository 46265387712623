/**
 * Created by herna on 4/30/2017.
 * https://gist.github.com/jdanyow/611ca9293371aed1c587a2511c3ef13c
 */
import {ValidationControllerFactory, ValidationController, ValidationRules} from 'aurelia-validation';
import {BootstrapFormRenderer} from '../services/bootstrap-form-renderer';


export class LoginFormModel {
  userName                          = '';
  password                          = '';
  controller?: ValidationController = null;

  constructor(controllerFactory) {
    this.controller = controllerFactory.createForCurrentScope();
    this.controller.addRenderer(new BootstrapFormRenderer());
  }
}

ValidationRules
  .ensure((a: LoginFormModel) => a.userName).required().withMessage(`\${$displayName} é obrigatório`)
  .ensure((a: LoginFormModel) => a.password).required().withMessage(`\${$displayName} é obrigatório`)
  .on(LoginFormModel);
