// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {ControloDiario, TipoControlo} from './ControloDiario';
import {OperadorDerivation} from "./BaseViewModelDerivations";
import {GuiaTransporteLinha} from "./GuiaTransporteLinha";
import {Artigo} from "./Artigo";

//export classe
export class RegistoEtiqueta extends OperadorDerivation {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idRegistoEtiqueta: number = 0;
  public idControloDiario: number  = 0;
  private _intQuantidade: number;
  public dtmData: string;
  // public _dtmData: Date                             = new Date();
  public bitValido: boolean        = true;
  public bitSinal: boolean         = false;
  public intFactor: number         = 0;

  //a mentira de etiqueta
  public nvcArtigoSobreposicao: string          = null;
  public nvcArtigoDescricaoSobreposicao: string = null;
  public nvcCodigoEncomendaSobreposicao: string = null;
  public nvcGravacaoSobreposicao: string        = null;

  //identificação e numeração
  public nvcCodigoInterno: string;
  public intNumeroSequencial: number = 0;
  public intNumeroPagina: number     = 0;
  public nvcGrupo: string;

  //public _intQuantidade: number = 0;
  public intBoas: number  = 0;
  public intK: number     = 0;
  public intF: number     = 0;
  public intApart: number = 0;
  public nvcObservacoes: string;

  //impressão
  public dtmImpressao: string = null;

  //conferência
  public bitConferido: boolean                                = null;
  public dtmDataConferencia: string                           = null;
  public bitSaida: boolean                                    = null;
  public dtmDataSaida: string;
  //public _dtmDataSaida:Date = new Date();
  public idGuiaTransporteLinha: number                        = null;
  public idGuiaTransporteLinhaNavigation: GuiaTransporteLinha = null;

  //audit
  public dtmDataI: string;
  public dtmDataA: string;
  public timestamp: number[] = [];

  public idControloDiarioNavigation: ControloDiario = null;
  // public idOperadorNavigation: Operador             = null;
  // public idUtilizadorANavigation: Utilizador        = null;
  // public idUtilizadorINavigation: Utilizador        = null;

  //EXTRA
  public intLimite: number      = 0;
  public intLimiteBoas: number  = 0;
  public intLimiteK: number     = 0;
  public intLimiteF: number     = 0;
  public intLimiteApart: number = 0;

  public intNumeroEmbalagens: number    = 1;
  public intCapacidadeEmbalagem: number = 100;
  public intReservadoCapacidade: number = 0;

  public idRegistoPlano: number;

  @computedFrom("intBoas", "intF", "intK", "intApart")
  get intQuantidade(): number {
    this._intQuantidade = (+this.intBoas) + (+this.intF) + (+this.intK) + (+this.intApart);
    this.recalculaEmbalagens(false);
    this.intCapacidadeEmbalagem = this.intReservadoCapacidade || this.intCapacidadeEmbalagem;
    // this.idControloDiarioNavigation && this.idControloDiarioNavigation.recalcula();
    return this._intQuantidade;
  }

  set intQuantidade(value: number) {
    this._intQuantidade = value;
  }

  @computedFrom("bitValido", "bitConferido", "bitSaida", "idGuiaTransporteLinha")
  get estado(): string {
    if (this.idGuiaTransporteLinha > 0) return "expedido";
    if (this.bitSaida) return "saida";
    if (this.bitConferido) return "entrada";
    if (this.bitValido) return "activo";
    return "anulado";
  }

  // @computedFrom("intNumeroEmbalagens", "_intQuantidade")
  // get intNumeroEmbalagensB(): number {
  //   return this.intNumeroEmbalagens;
  // }
  //
  // set intNumeroEmbalagensB(value: number) {
  //   this.intNumeroEmbalagens = +value || 1;
  //
  //   if(this._intQuantidade && (this.intCapacidadeEmbalagem * this.intNumeroEmbalagens > this._intQuantidade)) {
  //     this.intCapacidadeEmbalagemB = Math.ceil(this._intQuantidade / this.intNumeroEmbalagens);
  //   }
  // }
  //
  // @computedFrom("intCapacidadeEmbalagem")
  // get intCapacidadeEmbalagemB(): number {
  //   return this.intCapacidadeEmbalagem;
  // }
  //
  // set intCapacidadeEmbalagemB(value: number) {
  //
  //   this.intCapacidadeEmbalagem = +value || 1;
  //   if(this._intQuantidade && (this.intCapacidadeEmbalagem * this.intNumeroEmbalagens < this._intQuantidade)) {
  //     this.intNumeroEmbalagensB = Math.ceil(this._intQuantidade / this.intCapacidadeEmbalagem);
  //   }
  // }

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<RegistoEtiqueta>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public copyState(obj: any, props: string[] = []): RegistoEtiqueta {
    super.copyState(obj, props);
    obj.dtmDataI && (this.dtmDataI = obj.dtmDataI.replace("T", " "));
    obj.dtmDataA && (this.dtmDataA = obj.dtmDataA.replace("T", " "));

    if (obj.idControloDiarioNavigation) {
      this.idControloDiarioNavigation = ControloDiario.fromPOJSO(obj.idControloDiarioNavigation);
      this.idRegistoPlano             = this.idControloDiarioNavigation.idRegistoPlano;
    }

    if (obj.idGuiaTransporteLinhaNavigation) {
      this.idGuiaTransporteLinhaNavigation = GuiaTransporteLinha.fromPOJSO(obj.idGuiaTransporteLinhaNavigation);
    }
    return this;
  }

  public static fromPOJSO(obj: any): RegistoEtiqueta {
    return new RegistoEtiqueta().copyState(obj);
  }

  public static multipleFromPOJSO(objs: any | any[]): RegistoEtiqueta[] {
    if (objs && Array.isArray(objs)) return objs.map(RegistoEtiqueta.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state = this.getState();
    //envia para o servidor o valor reservado?
    // state.intCapacidadeEmbalagem = this.intReservadoCapacidade || this.intCapacidadeEmbalagem;
    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<RegistoEtiqueta>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): RegistoEtiqueta {
    //console.log("cloneInstance", this.stateToPOJSO());
    return RegistoEtiqueta.fromPOJSO(this.stateToPOJSO());
  }

  public difference(etiqueta): string[] {
    let diff = super.difference(etiqueta);
    diff     = diff.filter(m => !["intCapacidadeEmbalagem"].includes(m));
    return diff;
  }

  //endregion

  public inicializaParaEdicao() {
    this.intLimite = this.idControloDiarioNavigation.intTotalPrgOriginal;
    //faz umas continhas para mostrar indicações na interface que podem facilitar o preenchimento do form
    if (this.idControloDiarioNavigation.nvcTipo == TipoControlo.Controlo) {
      this.intLimiteBoas  = this.idControloDiarioNavigation.boas - this.idControloDiarioNavigation.etiqBoas + this.intBoas;
      this.intLimiteK     = this.idControloDiarioNavigation.ks - this.idControloDiarioNavigation.etiqK + this.intK;
      this.intLimiteF     = this.idControloDiarioNavigation.fs - this.idControloDiarioNavigation.etiqF + this.intF;
      this.intLimiteApart = this.idControloDiarioNavigation.apart - this.idControloDiarioNavigation.etiqApart + this.intApart;
    } else if (this.idControloDiarioNavigation.nvcTipo == TipoControlo.Invertido) {
      //básicamente é igual ao processo de "Controlo"
      this.intLimiteBoas  = this.idControloDiarioNavigation.boas - this.idControloDiarioNavigation.etiqBoas + this.intBoas;
      this.intLimiteK     = this.idControloDiarioNavigation.ks - this.idControloDiarioNavigation.etiqK + this.intK;
      this.intLimiteF     = this.idControloDiarioNavigation.fs - this.idControloDiarioNavigation.etiqF + this.intF;
      this.intLimiteApart = this.idControloDiarioNavigation.apart - this.idControloDiarioNavigation.etiqApart + this.intApart;
    } else {
      this.intLimiteBoas  = this.idControloDiarioNavigation.gravBoas - this.idControloDiarioNavigation.etiqBoas + this.intBoas;
      this.intLimiteK     = this.idControloDiarioNavigation.gravK - this.idControloDiarioNavigation.etiqK + this.intK;
      this.intLimiteF     = this.idControloDiarioNavigation.gravF - this.idControloDiarioNavigation.etiqF + this.intF;
      this.intLimiteApart = this.idControloDiarioNavigation.gravApart - this.idControloDiarioNavigation.etiqApart + this.intApart;
    }

    this.nvcArtigoSobreposicao          = this.nvcArtigoSobreposicao || this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcArtigo;
    this.nvcArtigoDescricaoSobreposicao = this.nvcArtigoDescricaoSobreposicao || this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcArtigoDescricao;
    this.nvcGravacaoSobreposicao        = this.nvcGravacaoSobreposicao || this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcGravacao;
    this.nvcCodigoEncomendaSobreposicao = this.nvcCodigoEncomendaSobreposicao || this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcCodigoEncomenda;
  }

  public resetPropriedadesArtigo() {
    this.nvcArtigoSobreposicao          = this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcArtigo;
    this.nvcArtigoDescricaoSobreposicao = this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcArtigoDescricao;
    this.nvcGravacaoSobreposicao        = this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcGravacao;
    this.nvcCodigoEncomendaSobreposicao = this.idControloDiarioNavigation.idControloPlanoExpedicaoNavigation.nvcCodigoEncomenda;
  }

  public recalculaEmbalagens(limpaReserva: boolean = true) {
    //qd a função é executada pelo evento de change do numero de páginas a reserva fuuushhh!
    if (limpaReserva) this.intReservadoCapacidade = 0;
    if (this._intQuantidade) {
      this.intCapacidadeEmbalagem = Math.ceil(this._intQuantidade / this.intNumeroEmbalagens);
    }
  }

  public recalculaCapacidade() {
    this.intReservadoCapacidade = this.intCapacidadeEmbalagem;
    if (this._intQuantidade) {
      this.intNumeroEmbalagens = Math.ceil(this._intQuantidade / this.intCapacidadeEmbalagem);
    }
  }

  /**
   * Setter para assistir no preenchimento do artigo/descrição de uma qualquer etiqueta
   * @param rowRef
   */
  public setArtigoRg(rowRef: any) {
    //console.log(rowRef);
    let a                               = rowRef as Artigo;
    this.nvcArtigoSobreposicao          = a.nvcArtigo;
    this.nvcArtigoDescricaoSobreposicao = a.nvcDescricao;
  }

  /**
   * Assume a quantidade disponível nas boas (considerando o seu tipo)
   */
  public assumeBoas() {
    this.intBoas = this.intLimiteBoas;
  }

  public toString() {
    return `Etiqueta Controlo Nº ${this.idRegistoEtiqueta} de ${this.dtmData} Total: ${this.intQuantidade}`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: RegistoEtiqueta) => m.intQuantidade).displayName("Quantidade").required()
  .ensure((m: RegistoEtiqueta) => m.dtmData).displayName("Data").required()
  .ensure((m: RegistoEtiqueta) => m.intBoas).displayName("Ok").required()
  .ensure((m: RegistoEtiqueta) => m.intK).displayName("K").required()
  .ensure((m: RegistoEtiqueta) => m.intF).displayName("F").required()
  .ensure((m: RegistoEtiqueta) => m.intApart).displayName("ntApart").required()
  // .ensure((m: RegistoEtiqueta) => m.nvcCodigoInterno).displayName("CodigoInterno").required()
  .ensure((m: RegistoEtiqueta) => m.idOperador).displayName("Operador").required().when(m => !m.idOperadorTemp)
  .on(RegistoEtiqueta);
