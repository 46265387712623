import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {MobileApp} from "../../mobile-app";
import {RegistoPlano} from "../../models/RegistoPlano";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../../services/bootstrap-form-renderer";
import {PlanoExpedicao, tipoLinhaPlanoExpedicao} from "../../models/PlanoExpedicao";
import {tiposRegisto} from "../../models/TipoRegistoPlano";
import {dateISOString} from "../../utils/ItNumeric";
import {confirmaActionTyped} from "../../services/api-envelopes";

@autoinject()
export class RegistoProducao {
  message: string;
  navigation: any;

  //modelos
  public modelo: RegistoPlano;
  public modeloOriginal: RegistoPlano;
  private validationController: ValidationController;
  private isBusy: boolean = true;

  constructor(private app: GlobalServices, protected mobileApp: MobileApp, vcf: ValidationControllerFactory) {
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  canActivate(p: any, b, c) {
    //todo: captura de quais permissões?
    //edita RP?
    //sobrepõe RP?
    if (environment.debug) console.log("[registo-producao]", "canActivate", this, p, b, c);

    if(p) {
      if(p.id > 0) {
        //NÃO SE PODE EDITAR O REGISTO POR ESTA VIA
      } else {
        this.parseModelo({dtmMovimento: dateISOString(new Date()), nvcTipo: tiposRegisto.PROD});
        return true;
      }
    }
  }

  attached() {
    this.isBusy = false;
  }

  /**
   * Pode navegar para fora?
   */
  canDeactivate() {
    // if(environment.debug) console.log("[registo-producao]","canDeactivate");
    let diff = [];
    if (this.modelo) {
      diff = this.modelo.difference(this.modeloOriginal);
    }
    if (diff.length > 0) {
      if (environment.debug) console.log("[registo-producao]", "canDeactivate", this.modelo, this.modeloOriginal);
      return this.app.confirmaPopup(["Existem alterações por gravar.", "Deseja sair <b>SEM GRAVAR AS ALTERAÇÕES?</b>"])
        .then(r => {
          if(r) {this.app.notificationWarning("Alterações descartadas");}
          this.isBusy = false;
          return r;
        });
    }
    return true;
  }

  parseModelo(obj: Partial<RegistoPlano> = {}) {
    this.modelo         = RegistoPlano.fromPOJSO(obj);
    this.modeloOriginal = RegistoPlano.fromPOJSO(obj);
  }

  /**
   * Dispatcher de ações para esta view
   * @param action
   * @param payload
   * @param context
   */
  public doAction(action: string, payload?: any, context?: any) {
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "Acção [expedicao-tabela-virtual]", action, payload, context);
    this.isBusy = true;
    //assumir um context
    context = context || this;
    switch (action) {
      case "INIT": {
        this.isBusy = false;
        // this.app.router.navigateToRoute('mobile_inicio');
        return Promise.resolve(false);
      }

      case "CRIAR-ENCOMENDA": {
        let encomenda = new PlanoExpedicao({nvcClienteArmazem: 'PL-NP', nvcTipo: tipoLinhaPlanoExpedicao.plano});
        return this.doAction("EDITA-PLANO-EXPEDICAO-COMPOSE", encomenda);
      }

      case "VALIDAR": {
        //valida todos os controlos no ecrã
        return this.validationController.validate()
          .then(r => {
            if (r.valid) { return this.doAction("GRAVAR");} else return this.isBusy = this.app.notificationErrorCompact("O formulário apresenta erros");
          })
          .catch(err => this.isBusy = this.app.notificationErrorCompact(err));
      }

      case "GRAVAR": {
        return confirmaActionTyped(this, this.modelo, 'api/plano-expedicao/registo-plano')
          .then(r => {
            if(r) {
              this.app.notificationSuccess("Registo de produção gravado.");
            }
            if (environment.debug) console.log("[registo-producao]", "GRAVAR(post) - resposta do servidor", r);
            this.parseModelo(r);
            return this.app.router.navigateToRoute('mobile_inicio');
          });
      }

      default: {
        if (environment.debug) console.log("[expedicao-tabela-virtual]", "Acção desconhecida [expedicao-tabela-virtual]", action, payload);
        console.log("[expedicao-tabela-virtual]", "Acção desconhecida [expedicao-tabela-virtual]", action, payload);
        if (this.app.aureliaMain && typeof this.app.aureliaMain.doActionGlobal === "function") {
          this.isBusy = false;
          return this.app.aureliaMain.doActionGlobal(action, payload, context || this);
        }
        this.app.notificationErrorCompact("Accção desconhecida: " + action);
      }
    }
  }
}
