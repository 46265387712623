import {BaseViewModel} from "./BaseViewModel";
import {ValidationRules} from "aurelia-validation";
import {PlanoExpedicao} from "./PlanoExpedicao";
import {VmWrapper} from "./VmWrapper";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class DuplicacaoPlanoExpedicaoVm extends BaseViewModel {
  public idPlanoExpedicaoOriginal: number        = 0;
  public idRegistoPlanoOriginario: number        = 0;
  public nvcArtigoTerminacaoSobreposicao: string = "";
  public nvcDescricaoArtigoSobreposicao: string  = "";
  public nvcRgSobreposicao: string               = "";
  public nvcObservacoes: string                  = "";
  public nvcTipoGravacao: string                 = "";
  public nvcAcabamento: string                   = "";
  public intQtyTotalEncomenda: number            = 0;

  //relações
  public _PlanoExpedicao: PlanoExpedicao = null;

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<DuplicacaoPlanoExpedicaoVm>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any): DuplicacaoPlanoExpedicaoVm {
    throw new Error("not implemented!");
  }

  public static multipleFromPOJSO(objs: any[]): DuplicacaoPlanoExpedicaoVm[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(DuplicacaoPlanoExpedicaoVm.fromPOJSO);
    return [];
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  public warpIt(cl?: number) {
    return new VmWrapper<DuplicacaoPlanoExpedicaoVm>({payload: this, confirmLevel: (+cl || 0)});
  }

  //endregion
}

// export let PlanoExpedicaoRules =
// todo: regra de validação para (um de) gravação / acabamento
ValidationRules
  .ensure((m: DuplicacaoPlanoExpedicaoVm) => m.intQtyTotalEncomenda).displayName("Total Encomenda").satisfies(value => (Number.parseInt("" + value) > 0)).withMessage(`\${$displayName} deve ser positivo`)
  .ensure((m: DuplicacaoPlanoExpedicaoVm) => m.nvcTipoGravacao).displayName("Tipo de gravação").required().withMessage(`\${$displayName} é obrigatório`)
  .on(DuplicacaoPlanoExpedicaoVm);
