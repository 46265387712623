import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from './BaseViewModel';
import {VmWrapper} from './VmWrapper';

export class ReclamacaoCaracterizacao extends BaseViewModel {
  public idCaracterizacao: number  = 0;
  public idReclamacao: number      = 0;
  public nvcTipo: string           = '';
  public nvcCaracterizacao: string = '';
  public nvcObservacao: string;
  public bitActivo: boolean        = false;
  public idUtilizadorI: number     = 0;
  public idUtilizadorA: number     = 0;
  public dtmDataI: string;
  public dtmDataA: string          = '';
  public timestamp: string;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ReclamacaoCaracterizacao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public copyState(obj: any, props: string[] = []): ReclamacaoCaracterizacao {
    super.copyState(obj, props);
    obj.dtmDataI && (this.dtmDataI = obj.dtmDataI.replace("T", " "));
    obj.dtmDataA && (this.dtmDataA = obj.dtmDataA.replace("T", " "));
    return this;
  }

  public static fromPOJSO(obj: any): ReclamacaoCaracterizacao {
    return new ReclamacaoCaracterizacao().copyState(obj);
  }

  public static multipleFromPOJSO(objs: any | any[]): ReclamacaoCaracterizacao[] {
    if (objs && Array.isArray(objs)) return objs.map(ReclamacaoCaracterizacao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state = this.getState();
    state     = Object.assign(state, super.getState());
    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ReclamacaoCaracterizacao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ReclamacaoCaracterizacao { return ReclamacaoCaracterizacao.fromPOJSO(this.stateToPOJSO());}

  //endregion estado

  public toString() {
    return `ReclamacaoCaracterizacao`;
  }

}

//region aurelia-validation (comentar o que não interessa)
ValidationRules
// .ensure((m: ReclamacaoCaracterizacao) => m.idCaracterizacao).displayName('idCaracterizacao').required()
// .ensure((m: ReclamacaoCaracterizacao) => m.idReclamacao).displayName('idReclamacao').required()
// .ensure((m: ReclamacaoCaracterizacao) => m.nvcTipo).displayName('nvcTipo').required()
  .ensure((m: ReclamacaoCaracterizacao) => m.nvcCaracterizacao).displayName('Tipo').required()
  // .ensure((m: ReclamacaoCaracterizacao) => m.nvcObservacao).displayName('nvcObservacao').required()
  // .ensure((m: ReclamacaoCaracterizacao) => m.bitActivo).displayName('bitActivo').required()
  // .ensure((m: ReclamacaoCaracterizacao) => m.idUtilizadorI).displayName('idUtilizadorI').required()
  // .ensure((m: ReclamacaoCaracterizacao) => m.idUtilizadorA).displayName('idUtilizadorA').required()
  //.ensure((m: ReclamacaoCaracterizacao) => m.dtmDataI).displayName('dtmDataI').required()
  // .ensure((m: ReclamacaoCaracterizacao) => m.dtmDataA).displayName('dtmDataA').required()
  //.ensure((m: ReclamacaoCaracterizacao) => m.timestamp).displayName('timestamp').required()
  .on(ReclamacaoCaracterizacao);
//endregion

//interna | externa
export const TipoReclamacaoCaracterizacao = {
  Defeito    : "defeito",
  Analise    : "analise",
  Comentario : "comentario",
};
