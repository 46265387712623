
import environment from "./environment";
import {ComposeDialog, ComposeDialogOptions} from "./dialogs/compose-dialog";
import {GuiaTransporte} from "./models/GuiaTransporte";
import {ActionInterface} from "./services/global-services";
import {PickerRemoteGrid} from "./it-features/remote-grid/picker-remote-grid";
import {DialogCloseResult} from "aurelia-dialog/src/dialog-result";
import {PlanoExpedicao} from "./models/PlanoExpedicao";
import {EstadoLinhaPlano} from "./models/EstadoLinhaPlano";
import {GuiaTransporteLinha} from "./models/GuiaTransporteLinha";
import {confirmaDeletionTyped} from "./services/api-envelopes";
import {RegistoPlano} from "./models/RegistoPlano";

/**
 * v1.0
 *
 * doActionGlobal
 * Quando as acções invocadas não são encontradas na route do componente "transbordam" para esta função.
 *
 * A acção `Q` ou  `QUEUE` processa uma lista de acções descritas pela `ActionInterface`
 *
 * Sugestão para nomear acções a fim de minimizar conflitos
 * TIPO-DE-OBJETO-ACCAO
 *
 * NOTAS:
 * Cuidado com os composes e os respectivos `invokers`, deverão ser na forma: `invoker: context || this,`
 *
 * @param {string} action
 * @param payload
 * @param context
 * @return {Promise<boolean>}
 */
export const doActionGlobal = function (action: string, payload: any, context: any) {
  if (environment.debug) console.log("[app]", "{doActionGlobal}", action, payload, this);
  try {
    switch (action) {
      //region Queue
      //inicia a execução de uma lista de acções (sem a necessidade de adição do método queueActions na route.)
      case "Q":
      case "QUEUE": {
        let actions = payload as ActionInterface[];
        return this.app.queueActionsGs(actions, context || this);
      }
      //endregion Queue

      //region Picker Remote Grid
      //abre um PickerRemoteGrid e atribui ou invoca um setter sobre a propriedade identificada no payload
      case "PICKER-RG":
      case "PICKER-REMOTE-GRID": {
        let pl = payload as PickerRgInlineOptions;
        if (pl.overrideActionsColumn === undefined) { pl.overrideActionsColumn = true;}
        //condicionais de execução
        if (!pl.codigoTabela) {
          throw new Error("Os PickerRemoteGrid têm de ter o 'codigoTabela' configurado");
        }
        if (!pl.modeloOverride) {
          throw new Error("Os PickerRemoteGrid têm de ter a 'modeloOverride' quando invocados centralmente (doActionGlobal).")
        }
        if (!pl.propriedadeModelo) {
          if (pl.selectionMode && pl.selectionMode == 'none') {
            if (environment.debug) console.log("[compose-dialog]", "Sem configuração de propriedade do modelo, sem tipo de selecao");
          } else {
            throw new Error("Os PickerRemoteGrid têm de ter a 'propriedadeModelo' configurada.");
          }
        }
        //abrir o picker RG
        return this.app.ds.open({viewModel: PickerRemoteGrid, model: pl})
          .whenClosed(r => {
            if (!r.wasCancelled) {
              if (environment.debug) console.log("[compose-dialog]", "Resultado Picker", r);
              if (typeof pl.modeloOverride[pl.propriedadeModelo] === "function") {
                //setter ou função que aceita uma row da tabela selecionada deve retornar Promise<[typeof modeloOverride]>
                //adicionalmente são passadas as referências ao global-services e ao contexto de invocação
                return pl.modeloOverride[pl.propriedadeModelo](r.output.rowRef, this.app, context);
              } else {
                pl.modeloOverride[pl.propriedadeModelo] = r.output.rowRef[pl.propriedadeRowRef || pl.propriedadeModelo];
              }
            }
            return pl.modeloOverride;
          })
          .catch(err => this.app.notificationErrorCompact(err));
      }

      //abre um PickerRemoteGrid e encadeia uma acção passada em payload
      case "PICKER-RG-ACTION":
      case "PICKER-REMOTE-GRID-ACTION": {
        let pl = payload as PickerRgInlineOptions;
        if (pl.overrideActionsColumn === undefined) { pl.overrideActionsColumn = true;}
        //condicionais de execução
        if (!pl.codigoTabela) {
          throw new Error("Os PickerRemoteGridAction têm de ter o 'codigoTabela' configurado");
        }
        if (!pl.modeloOverride) {
          if(environment.debug) console.warn("Os PickerRemoteGridAction devem ter a 'modeloOverride' quando invocados centralmente (doActionGlobal).")
        }
        if (!pl.thenCallAction) {
          throw new Error("Os PickerRemoteGridAction têm de ter a 'thenCallAction' quando invocados centralmente (doActionGlobal).")
        }
        //abrir o picker RG
        return this.app.ds.open({viewModel: PickerRemoteGrid, model: pl})
          .whenClosed(r => {
            if (!r.wasCancelled) {
              //return context.doAction(pl.thenCallAction, r.output.rowRef);
              //inicialmente o return era feito apenas com o rowRef, mas preve-se que o modelo deva ser passado conjuntamente para reduzir a ginástica de resolução na acção encadeada
              return context.doAction(pl.thenCallAction, {rowRef: r.output.rowRef, modelo: pl.modeloOverride});
            }
            return false;
          })
          .catch(err => this.app.notificationErrorCompact(err));
      }
      //endregion

      //region aplicação
      case "EDITA-PLANO-EXPEDICAO-COMPOSE": {
        let modelo          = payload as PlanoExpedicao;
        let composeSettings = {
          modelo : modelo,
          invoker: context,
          options: new ComposeDialogOptions({
            title            : modelo.idPlanoExpedicao <= 0 ? 'Nova linha de Encomenda' : 'Editar ' + modelo.toString(),
            withDefaultFooter: false,
            mainView         : '../routes/plano/ce/_plano-expedicao-form.html',
            postUri          : 'api/plano-expedicao/plano-expedicao',
            rootBindings     : {listaPlanos: context.listaPlanos, estados: EstadoLinhaPlano.estados()}
          }),
        };
        return this.app.ds.open({
            viewModel: ComposeDialog,
            model    : composeSettings
          })
          .whenClosed(r => {
            if (!r.wasCancelled) {
              this.app.notificationSuccess("Linha do plano gravada.");
              context.doAction("REFRESH-TABELA", null);
              //refrescar a tabela (tambem se podia invocar o doAction para o efeito)
              // if(context && context.rg &&  typeof context.rg.refreshPage == "function") {
              //   context.rg.refreshPage();
              // }
            }
          });
      }

      case "APAGAR-REGISTO-PLANO":{
        context.app.notificationWarning("APAGAR-REGISTO-PLANO");
        let rp = RegistoPlano.fromPOJSO(payload);
        return confirmaDeletionTyped(context, rp, 'api/plano-expedicao/registo-plano-prod')
          .then(r => {
            if (r) {
              this.app.notificationSuccess("A linha foi removida e a Guia de Transporte gravada");
              context.doAction("REFRESH");
            } else {
              this.app.notificationWarning("Não foi possível eliminar a linha");
            }
          });
      }

      //region Guias de transporte
      case "GUIA-TRANSPORTE-EDITAR": {
        if (environment.debug) console.log("[guias-transporte-lista]", "EDITAR-GUIA", "begin", payload);
        let modelo = payload as GuiaTransporte;
        return context.app.api.getProcessed('api/guia-transporte/guia-transporte', {id: modelo.idGuiaTransporte})
          .then(r => GuiaTransporte.fromPOJSO(r))
          .then(c => context.doAction("GUIA-TRANSPORTE-EDITAR-COMPOSE", c))
          .then(c => context.doAction("REFRESH", c));
      }

      case 'GUIA-TRANSPORTE-EDITAR-COMPOSE': {
        let modelo          = payload as GuiaTransporte;
        let composeSettings = {
          modelo         : modelo,
          containerModelo: null,
          invoker        : context,
          options        : new ComposeDialogOptions({
            title            : modelo.idGuiaTransporte <= 0 ? 'Nova Guia de Transporte' : 'Editar ' + modelo.toString(),
            withDefaultFooter: true,
            mainView         : '../routes/guias-transporte/ce/guia-transporte.html',
            postUri          : 'api/guia-transporte/guia-transporte',
            closeAfterSave   : false
          }),
        };
        return context.app.ds.open({
            viewModel: ComposeDialog,
            model    : composeSettings
          })
          .whenClosed((r: DialogCloseResult) => {
            if (!r.wasCancelled) {
              context.app.notificationSuccess("Guia de Transporte gravada");
              //this.doAction("REFRESH-TABELA", null);
            }
          });
      }

      //remoção de linhas de guias de tranporte
      case "GUIA-TRANSPORTE-REMOVE-LINHA": {
        //exemplo de destruct tipado
        let {gt, linha}: { gt: GuiaTransporte, linha: GuiaTransporteLinha } = payload;
        if (gt.idGuiaTransporte <= 0) {
          this.app.notificationSuccess("Linha removida com sucesso");
          gt.removerLinha(linha);
          return Promise.resolve(false);
        } else {
          return confirmaDeletionTyped(context, linha, 'api/guia-transporte/guia-transporte-linha')
            .then(r => {
              if (r) {
                this.app.notificationSuccess("A linha foi removida e a Guia de Transporte gravada");
                gt.removerLinha(linha);
              } else {
                this.app.notificationWarning("Não foi possível eliminar a linha");
              }
            })
        }
      }

      //adição de linhas por CDE:
      case 'GUIA-TRANSPORTE-ATRIBUI-LINHAS-CDE': {
        let {rowRef, modelo}:{rowRef:any, modelo:GuiaTransporte} = payload;
        if (environment.debug) console.log("[do-action]", "GUIA-TRANSPORTE-ATRIBUI-LINHAS-CDE", payload, context);
        // 1. pede todos os registos PROD livres (não associados a GT cuja linha do plano ainda está em aberto)
        return this.app.api.getProcessed('api/plano-expedicao/registos-plano-cde', {cde: rowRef.nvcCodigoEncomenda})
          .then(r => {
            //2. incorpora-os na forma de linhas de guias de transporte no modelo existente em `context.modelo`
            if(environment.debug) console.log("[do-action]","GUIA-TRANSPORTE-ATRIBUI-LINHAS-CDE", "resposta", r);
            let rps = RegistoPlano.multipleFromPOJSO(r);
            modelo.adicionaLinhasRpProd(rps);
          });
      }

      //adição de linhas por DATA:
      case 'GUIA-TRANSPORTE-ATRIBUI-LINHAS-DATA': {
        let {rowRef, modelo}:{rowRef:any, modelo:GuiaTransporte} = payload;
        if (environment.debug) console.log("[do-action]", "GUIA-TRANSPORTE-ATRIBUI-LINHAS-DATA", payload, context);
        // 1. pede todos os registos PROD livres (não associados a GT cuja linha do plano ainda está em aberto)
        return this.app.api.getProcessed('api/plano-expedicao/registos-plano-data', {data: rowRef.dtmMovimento})
          .then(r => {
            //2. incorpora-os na forma de linhas de guias de transporte no modelo existente em `context.modelo`
            if(environment.debug) console.log("[do-action]","GUIA-TRANSPORTE-ATRIBUI-LINHAS-DATA", "resposta", r);
            let rps = RegistoPlano.multipleFromPOJSO(r);
            modelo.adicionaLinhasRpProd(rps);
          });
      }
      //endregion Guias de transporte

      //endregion aplicação
      default: {
        if (environment.debug) this.app.notificationErrorCompact("Acção desconhecida: " + action);
        return Promise.resolve(false);
      }
    }
  } catch (err) {
    return Promise.resolve(this.app.notificationErrorCompact(err));
  }
};

/**
 * As opcções relevantes quando se invoca um picker-rg a partir de uma qq view
 */
export interface PickerRgInlineOptions {
  /**O código da tabela a invocar*/
  codigoTabela: string,

  /** sobreposição do modelo.
   * Quando invocado em compose-dialog permite usar estes picker em modelos descendentes (tipo linhas)
   * Quando invocado globalmente é obrigatório
   */
  modeloOverride?: any,

  /**
   * A propriedade do modelo para receber o valor do picker
   * Pode ser uma propriedade ou um método (setter passivo)
   */
  propriedadeModelo?: string,

  /**
   * Quando usado em conjunção com o anterior (em modo propriedade) permite atribuir:
   * modelo[propriedadeModelo] = rowRef[propriedadeRowRef]
   */
  propriedadeRowRef?: string,

  /** single | multi | none? */
  selectionMode?: string,

  /** filtro por defeito a aplicar */
  defaultFilter: any[],

  /** filtro inicial a aplicar */
  initialFilter: any[],

  /** usa a substituição da coluna acções por hidden? */
  overrideActionsColumn?: boolean

  /** acção a encadear (na variante RG-ACTION)
   *  > **esta propriedade não tem qualquer efeito numa chamada à acção de PICKER-RG**
   *
   *  quando o picker conclui, a acção é invocada em contexto, com o rowRef passado por parâmetro
   *
   */
  thenCallAction?: string;
}
