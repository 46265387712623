/**
 * Abstração de autenticação. Suportada por JWT.
 * É um serviço fraco (na medida que não há validação de assinatura do token). Tal não representa uma fragilidade visto que a API faz essa validação "per request"
 * 
 * v 2.00 (2019-05-18) versionamento
 * 
 */
import {LogManager, inject, autoinject, computedFrom} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';
import {HttpClient} from 'aurelia-fetch-client';
import environment from '../environment';
import * as jwtDecode from 'jwt-decode'

@autoinject()
export class AuthService {
  // protected logger: Logger;
  public isBusy: boolean = false;

  // o utilizador loggado
  identity: JwtToken;

  // o utilizador impersonalizado
  impersonation: JwtToken;

  //tokens
  protected access_token: string = "";
  //protected refresh_token: string = "";

  protected permissions: string[] = [];

  protected planos: AcessoPlano[] = [];

  //impersonalização
  impersonate_token: string = "";

  //region getters
  get user(): JwtToken {
    if (this.impersonation) return this.impersonation;
    return this.identity;
  }

  get userId(): number {
    if (this.user) return +this.user.id;
    return null;
  }

  get userName(): string {
    if (this.user) return this.user.sub;
    return null;
  }

  get perfil(): string {
    if (this.user) return this.user.perfil;
    return null;
  }

  /**
   * identifica o administrador.
   */
  get isAdmin(): boolean {
    if (this.user) return this.user.sub === "sistema";
    return false;
  }

  /**
   * Devolve o estado da validade do JwtToken adequado para o momento "agora".
   * @return {boolean}
   */

  @computedFrom("user", "user.exp")
  get authenticated(): boolean {
    if (this.user) return this.user.isValid;
    return false;
  }

  /**
   * predicado sobre a permissão auferida ao utilizador logado
   * @param {string} permissao
   * @return {boolean}
   */
  public can(permissao: string): boolean {
    if (permissao) {
      let b = this.permissions.includes(permissao);
      if(environment.debug) console.log("[auth-service]","Verificar ", permissao, "em", this.permissions, b);
      return b || this.isAdmin;
    }
    return false;
  }

  /**
   * predicado sobre a permissão auferida ao utilizador logado
   * @param {string[]} permissoes
   * @return {boolean}
   */
  public oneOf(permissoes: string[]): boolean {
    if (permissoes.length > 0) {
      for (let permissao of permissoes) {
        if (this.can(permissao)) return true;
      }
    }
    return false;
  }

  /**
   * obtém o token ativo associado ao serviço, i.e., se houver uma impersonalização ativa devolve esse token em vez do token de autorização genuíno.
   * @return {string|null}
   */
  public activeToken(): string {
    if (this.impersonate_token) return this.impersonate_token;
    if (this.access_token) return this.access_token;

    return null;
  }

  /**
   * Obtém um token válido. Se o token estiver para expirar pede um novo ao servidor.
   * @return {Promise<string>}
   */
  public promisedActiveToken(): Promise<string> {
    if (this.user) {
      // if(environment.debug) console.log("[auth-service]","Há uma identidade:", this.user, new Date(this.user.iat * 1000), new Date(this.user.exp * 1000));
      if (this.user.shouldRefresh) {
        return this.refresh()
          .then(as => as.activeToken())
          .catch(err => {
            console.warn("Não foi possível refrescar um token");
            return null
          });
      }
    }
    return Promise.resolve(this.activeToken());
  }

  //endregion

  //region acesso aos planos
  /**
   * predicado sobre a permissão auferida ao utilizador logado
   * @param {string} codigo
   * @return {boolean}
   */
  public canReadPlano(codigo: string): boolean {
    if (codigo) {
      //this.logger.debug("Verificar ", permissao, "em", this.permissions);
      if(environment.debug) console.log("[auth-service]","canReadPlano", codigo);
      return this.planos.findIndex(el => el.plano == codigo && el.bitRead === true) > -1
    }
    return false;
  }

  public canWritePlano(codigo: string): boolean {
    if (codigo) {
      //this.logger.debug("Verificar ", permissao, "em", this.permissions);
      if(environment.debug) console.log("[auth-service]","canReadPlano", codigo);
      return this.planos.findIndex(el => el.plano == codigo && el.bitWrite === true) > -1
    }
    return false;
  }

  public getPlanos():string[] {
    let acessoPlanos = this.planos.filter(el => el.bitRead || el.bitWrite);
    //if(environment.debug) console.log("[auth-service]","getPlanos", acessoPlanos);
    return acessoPlanos.map(el => el.plano);
  }

  //endregion acesso aos planos

  /**
   * CONSTRUCTOR
   */
  constructor() {
    // this.logger = LogManager.getLogger('AuthService');
    this.restore();
  }

  setAccessToken(token: string, permissoes: string[], planos: AcessoPlano[]): AuthService {
    //todo: programar um timer ou um canal para refrescar o token quando estiver para expirar
    if (this.impersonate_token) {
      this.impersonate_token = token;
      this.impersonation     = JwtToken.FromToken(this.impersonate_token);

    } else {
      this.access_token = token;
      this.identity     = JwtToken.FromToken(this.access_token);
      if (permissoes && Array.isArray(permissoes)) {
        this.permissions = permissoes;
      }
      if (planos && Array.isArray(planos)) {
        this.planos = planos;
      }
      this.store();
    }
    return this;
  }

  /**
   * Autentica um utilizador por username + password
   */
  login(body: any): Promise<AuthService> {
    this.isBusy    = true;
    let httpClient = new HttpClient().configure(c => c.useStandardConfiguration().withBaseUrl(environment.endpoint));

    return httpClient
      .fetch('api/jwt/login', {
        method : 'post',
        body   : JSON.stringify(body),
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.status >= 200 && response.status < 400) {
          return response.json();
        }
        let error                   = new Error('A resposta não teve o status code válido.');
        (<any>error).responseObject = response;
        throw error;
      })
      .then((data: any) => {
        this.isBusy = false;
        return this.setAccessToken(data.access_token, data.permissions || [], data.planos || []);
      })
      .catch(err => {
        this.isBusy = false;
        console.error("Não foi possível comunicar com o servidor para obter um token", err);
        throw err;
      });
  }

  /**
   * refresca o token automáticamente
   *
   * @return {Promise<AuthService>}
   */
  refresh(): Promise<AuthService> {
    // throw "not implemented";
    let httpClient = new HttpClient().configure(c => c.useStandardConfiguration().withBaseUrl(environment.endpoint));

    return httpClient.fetch('api/jwt/refresh', {
      method : "post",
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.activeToken()}
    }).then(response => {
      if (response.status >= 200 && response.status < 400) {return response.json();}
      throw response;
    }).then((data: any) => {return this.setAccessToken(data.access_token, data.permissions || [], data.planos || []);})
      .catch(err => {
        console.error("Não foi possível comunicar com o servidor ao refrescar um token");
        throw new Error("Não foi possível comunicar com o servidor ao refrescar um token");
      });
  }

  logout() {
    if(environment.debug) console.log("[auth-service]",`A sessão do utilizador ${this.userName} será terminada.`);
    if (this.impersonate_token) {
      this.impersonate_token = null;
      this.impersonation     = null;
    } else {
      this.permissions  = [];
      this.access_token = null;
      this.identity     = null;
      localStorage.removeItem("auth-service");
    }
    if(environment.debug) console.log("[auth-service]","logout ok");
  };

  /**
   * recupera uma password
   */
  recover(body: any): Promise<any> {
    this.isBusy    = true;
    let httpClient = new HttpClient().configure(c => c.useStandardConfiguration().withBaseUrl(environment.endpoint));

    return httpClient
      .fetch('api/auth/recover', {
        method : 'delete',
        body   : JSON.stringify(body),
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.status >= 200 && response.status < 400) {
          return response.json();
        }
        let error                   = new Error('A resposta não teve o status code válido.');
        (<any>error).responseObject = response;
        throw error;
      })
      .then((data: any) => {
        this.isBusy = false;
        return true;
      })
      .catch(err => {
        this.isBusy = false;
        console.error("Não foi possível comunicar com o servidor.", err);
        throw err;
      });
  }

  /**
   * Memoriza o token no localStorage
   */
  store() {
    // let serialization = JSON.stringify({access_token: this.access_token, refresh_token: this.refresh_token});
    let serialization = JSON.stringify({access_token: this.access_token, permissions: this.permissions, planos: this.planos});
    localStorage.setItem("auth-service", serialization);
    if(environment.debug) console.log("[auth-service]", "O estado do serviço foi persistido em localStorage");
  }

  /**
   * Restaura o token e identidade. Se o token estiver expirado limpa o registo
   */
  restore() {
    let serialization = localStorage.getItem("auth-service");
    if (serialization) {
      if(environment.debug) console.log("[auth-service]","Foi encontrada uma chave em localStorage");
      let obj = JSON.parse(serialization);
      this.setAccessToken(obj.access_token, obj.permissions || [], obj.planos || []);
      if (!this.authenticated) {
        localStorage.removeItem("auth-service");
        console.warn("O token expirou: o localStorage foi apagado.");
      }
    } else {
      console.warn("Não foi encontrada uma chave de token válida na localStorage");
    }
  }
}

/**
 * Estrutura para facilitar o acesso aos dados constantes num JWT token e opinião sobre a sua validade
 */
class JwtToken {
  public id: string;
  public perfil: string;
  public sub: string;
  public aud: string;
  public exp: number;
  public iat: number;
  public iss: string;
  public nbf: number;
  public jti: string;

  /**
   * o token encontra-se válido para o tempo do browser.
   * @return {boolean}
   */
  get isValid(): boolean {
    if (+this.exp > 0)
      return Math.floor(Date.now() / 1000) < this.exp;
    return false;
  }

  /**
   * getter que determina se o JwtToken se aproxima do prazo de expiração, com uma janela temporal de 5 minutos (300s)
   * @return {boolean}
   */
  get shouldRefresh(): boolean {
    //let t = 10 * 60;
    if (+this.exp > 0)
      return Math.floor(Date.now() / 1000) > this.exp - 300;
    return false;
  }

  public constructor(fields?: { id?: string; perfil?: string; sub?: string; aud?: string; exp?: number; iat?: number; iss?: string; nbf?: number; jti?: string; }) {
    if (fields) Object.assign(this, fields);
  }

  /**
   * factory de JwtToken a partir de um token válido.
   * Pode lançar um erro se o token estiver corrompido, mas aí pouco interessa.
   * @param token
   * @return {JwtToken}
   * @constructor
   */
  static FromToken(token: string): JwtToken {
    return new JwtToken(jwtDecode(token));
  }
}

interface AcessoPlano {
  plano: string;
  bitRead: boolean,
  bitWrite: boolean,
  birDefeito: boolean
}
