export let DateFilterTemplateGreaterEqual = generateFilter("greaterthanorequal");
export let DateFilterTemplateGreater      = generateFilter("greater");
export let DateFilterTemplate             = generateFilter("equal");

function generateFilter(type: string) {
  return {
    write: function (args) {
      args.element.ejDatePicker({
        locale: "pt-PT", dateFormat: "yyyy-MM-dd", width: "100%",
        value : args.element.val(),
        change: ej.proxy(args.column.filterBarTemplate.read, this, args)
      });
    },
    read : function (args) {
      if (args.element.val() == "clear") {
        this.clearFiltering(args.column.field);
        args.element.val("")
      }
      this.filterColumn(args.column.field, type, args.element.val(), "and", true)
    }
  }
}

export let identityNumericFilter = {
  // create: function (args) { return "<input>"},
  write: function (args) {
    // args.element.ejNumericTextbox({ width: "100%",decimalPlaces: 0, focusOut: ej.proxy(args.column.filterBarTemplate.read, this, args) });
    $(args.element).addClass('e-ejinputtext');
    // let db = debounce(ej.proxy(args.column.filterBarTemplate.read, this, args), 700, false);
    // $(args.element).off('change').on("change", ej.proxy(args.column.filterBarTemplate.read, this, args));
    $(args.element).off('keyup').on("keyup", debounce(ej.proxy(args.column.filterBarTemplate.read, this, args), 700, false));
  },
  read : function (args) {
    console.log(args);
    let val = args.element.val();
    if (val.startsWith('>=')) {
      val = val.substr(2).trim();
      // args.element.val( ">= " + val);
      this.filterColumn(args.column.field, ej.FilterOperators.greaterThanOrEqual, +val, "and", true)
    } else if (val.startsWith('>')) {
      val = val.substr(1).trim();
      this.filterColumn(args.column.field, ej.FilterOperators.greaterThan, +val, "and", true)
    } else if (val.startsWith('<=')) {
      val = val.substr(2).trim();
      this.filterColumn(args.column.field, ej.FilterOperators.lessThan, +val, "and", true)
    } else if (val.startsWith('<')) {
      val = val.substr(1).trim();
      this.filterColumn(args.column.field, ej.FilterOperators.lessThanOrEqual, +val, "and", true)
    } else
      this.filterColumn(args.column.field, "equal", args.element.val(), "and", true)
  },
};

export let IdentityStringFilter = {
  // create: function (args) { return "<input>"},
  write: function (args) {
    // console.log("IdentityStringFilter.write()", args);
    // args.element.ejNumericTextbox({ width: "100%",decimalPlaces: 0, focusOut: ej.proxy(args.column.filterBarTemplate.read, this, args) });
    // $(args.element).removeClass('e-filterUi_input');
    // $(args.element).removeClass('e-fltrTemp');
    $(args.element).addClass('e-ejinputtext');
    $(args.element).off('change').on("change", ej.proxy(args.column.filterBarTemplate.read, this, args));
    $(args.element).off('keyup').on("keyup", debounce(ej.proxy(args.column.filterBarTemplate.read, this, args), 700, false));
  },
  read : function (args) {
    // console.log("IdentityStringFilter.read()", args);
    // console.log(args);
    let val = args.element.val();
    // if(val && val.length > 0) {
    // }
    this.filterColumn(args.column.field, ej.FilterOperators.contains, "" + val, "and", true)
  },
};


function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}