import {autoinject, computedFrom} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";

@autoinject()
export class ConfirmacaoDialog {
  protected controller: DialogController;
  protected mensagem: string = "Evento a confirmar";
  protected mensagens: string[] = [];
  protected confirmBtn:HTMLButtonElement;

  constructor(controller: DialogController) {
    this.controller = controller;
    //console.log("lodash test", _s.camelCase("uma-string-que-vai passar a camel case"));
  }

  // canDeactivate() {
  //   $(".js-confirm-dialog").off("keypress")
  // }

  activate(msg: string|any) {

    //if(msg)
    if (typeof msg === 'string') {
      // this is a string
      this.mensagem = msg;
    } else {
      this.mensagem = null;
      this.mensagens = msg;
    }

    // $(this.confirmBtn).focus();
  }
}
