import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from './BaseViewModel';
import {MpfdModel, VmWrapper} from './VmWrapper';
import {PlanoExpedicao} from "./PlanoExpedicao";
import {ReclamacaoCaracterizacao, TipoReclamacaoCaracterizacao} from "./ReclamacaoCaracterizacao";
import {ReclamacaoFicheiro} from "./ReclamacaoFicheiro";
import {dateISOString} from "../utils/ItNumeric";
import {Artigo} from "./Artigo";

export class Reclamacao extends BaseViewModel {
  public idReclamacao: number                = 0;
  public nvcClienteArmazem: string;
  public idPlanoExpedicao: number            = 0;
  // public nvcCodigoInterno: string       = '';
  public dtmDataDocumento: string            = dateISOString();
  public nvcSerie: string                    = '';
  public intNumero: number                   = 0;
  public nvcResponsavel: string;
  public nvcClienteDestinatario: string      = '';
  public nvcComentarios: string;
  public nvcCabecalho: string;
  public intQuantidadeTotalEncomenda: number = 0;
  public intQuantidadeDefeituosa: number     = 0;
  public nvcArtigoSobreposicao: string;
  public nvcArtigoDescricaoSobreposicao: string;
  public nvcCodigoEncomendaSobreposicao: string;
  public nvcGravacaoSobreposicao: string;
  public nvcDefeitos: string;
  public nvcAnalise: string;
  public nvcTipo: string                     = TipoReclamacao.ExternaEmitida;
  public nvcEstado: string                   = EstadoReclamacao.Rascunho;
  public nvcEstadoResolucao: string          = EstadoResolucaoReclamacao.Iniciado;
  public bitFlushedXls: boolean              = false;

  //adenda para responsabilização do envio da FNC
  public nvcCriadoPor: string  = null;
  public nvcEnviadoPor: string = null;

  public caminhoBase:string = "";

  // audit
  // public idUtilizadorI: number           = 0;
  // public idUtilizadorA: number           = 0;
  public dtmDataI: string;
  public dtmDataA: string = '';
  // public timestamp: string;

  //extra
  public _totalLote: number = 0;

  public files: File[] = [];

  //relações
  public IdPlanoExpedicaoNavigation: PlanoExpedicao           = null;
  public ReclamacaoCaracterizacao: ReclamacaoCaracterizacao[] = [];
  public ReclamacaoFicheiro: ReclamacaoFicheiro[]             = [];

  @computedFrom("ReclamacaoCaracterizacao")
  public get CaracterizacaoDefeitos(): ReclamacaoCaracterizacao[] {
    return this.ReclamacaoCaracterizacao.filter(el => el.nvcTipo == TipoReclamacaoCaracterizacao.Defeito);
  }

  @computedFrom("ReclamacaoCaracterizacao")
  public get CaracterizacaoAnalises(): ReclamacaoCaracterizacao[] {
    return this.ReclamacaoCaracterizacao.filter(el => el.nvcTipo == TipoReclamacaoCaracterizacao.Analise);
  }

  @computedFrom("ReclamacaoCaracterizacao")
  public get CaracterizacaoComentarios(): ReclamacaoCaracterizacao[] {
    return this.ReclamacaoCaracterizacao.filter(el => el.nvcTipo == TipoReclamacaoCaracterizacao.Comentario);
  }

  /**
   * obtém todos os ficheiros já existentes em base de dados
   * @constructor
   */
  @computedFrom("ReclamacaoFicheiro")
  public get ReclamacaoFicheiroExistentes() {
    return this.ReclamacaoFicheiro.filter(el => el.idFicheiro > 0)
  }

  @computedFrom("_totalLote", "intQuantidadeDefeituosa", "intQuantidadeTotalEncomenda")
  public get percentil() {
    // if (!this._totalLote) {
    //   if (this.IdPlanoExpedicaoNavigation) {
    //     this._totalLote = +this.IdPlanoExpedicaoNavigation.intTotalCalculado;
    //   } else {
    //   }
    // }
    this._totalLote = this.intQuantidadeTotalEncomenda;
    if (this._totalLote == 0) this._totalLote = 1;
    return Math.round((+this.intQuantidadeDefeituosa / +this._totalLote) * 100);
  }

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Reclamacao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public copyState(obj: any, props: string[] = []): Reclamacao {
    super.copyState(obj, props);
    obj.dtmDataI && (this.dtmDataI = obj.dtmDataI.replace("T", " "));
    obj.dtmDataA && (this.dtmDataA = obj.dtmDataA.replace("T", " "));

    if (obj.reclamacaoCaracterizacao) {this.ReclamacaoCaracterizacao = ReclamacaoCaracterizacao.multipleFromPOJSO(obj.reclamacaoCaracterizacao)}
    if (obj.reclamacaoFicheiro) {this.ReclamacaoFicheiro = ReclamacaoFicheiro.multipleFromPOJSO(obj.reclamacaoFicheiro)}
    if (obj.idPlanoExpedicaoNavigation) {this.IdPlanoExpedicaoNavigation = PlanoExpedicao.fromPOJSO(obj.idPlanoExpedicaoNavigation)}
    return this;
  }

  public static fromPOJSO(obj: any): Reclamacao {
    return new Reclamacao().copyState(obj);
  }

  public static multipleFromPOJSO(objs: any | any[]): Reclamacao[] {
    if (objs && Array.isArray(objs)) return objs.map(Reclamacao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state = this.getState();
    state     = Object.assign(state, super.getState());
    if (this.ReclamacaoCaracterizacao) { state.reclamacaoCaracterizacao = this.ReclamacaoCaracterizacao.map(el => el.stateToPOJSO()); }
    if (this.ReclamacaoFicheiro) { state.reclamacaoFicheiro = this.ReclamacaoFicheiro.map(el => el.stateToPOJSO()); }
    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Reclamacao>({payload: this, confirmLevel: (+cl || 0)});
  }

  /**
   * Geração de payload para requests do tipo multipart/form-data
   */
  public mpfd() {
    //lógica adicional para arrumação dos ficheiros a enviar - devido à natureza repetitiva dos POSTs é necessária a limpeza ocasional do ReclamacaoFicheiro
    //1. reter os ficheiros formalizados na base de dados.
    this.ReclamacaoFicheiro = this.ReclamacaoFicheiro.filter(el => el.idFicheiro > 0);
    //2. adicionar quaisquer ficheiros que possam ter sido inseridos
    this.ReclamacaoFicheiro = [...this.ReclamacaoFicheiro, ...this.files.map(f => new ReclamacaoFicheiro({nvcPath: f.name, bitActivo: true}))];

    //criar o payload mpfd
    return new MpfdModel({model: this.wrapIt(), files: this.files});
  }

  public cloneInstance(): Reclamacao { return Reclamacao.fromPOJSO(this.stateToPOJSO());}

  //endregion estado

  //region gestão de caracterizações
  public renumeraIndicesCaracterizacoes() {
    this.ReclamacaoCaracterizacao.forEach((el, i) => el.__index = i);
  }

  public adicionaDefeito() {
    let carac                     = new ReclamacaoCaracterizacao({nvcTipo: TipoReclamacaoCaracterizacao.Defeito});
    this.ReclamacaoCaracterizacao = [...this.CaracterizacaoDefeitos, carac, ...this.CaracterizacaoAnalises, ...this.CaracterizacaoComentarios];
    this.renumeraIndicesCaracterizacoes()
  }

  public adicionaAnalise() {
    let carac                     = new ReclamacaoCaracterizacao({nvcTipo: TipoReclamacaoCaracterizacao.Analise});
    this.ReclamacaoCaracterizacao = [...this.CaracterizacaoDefeitos, ...this.CaracterizacaoAnalises, carac, ...this.CaracterizacaoComentarios];
    this.renumeraIndicesCaracterizacoes()
  }

  public adicionaComentario() {
    let carac                     = new ReclamacaoCaracterizacao({nvcTipo: TipoReclamacaoCaracterizacao.Comentario});
    this.ReclamacaoCaracterizacao = [...this.CaracterizacaoDefeitos, ...this.CaracterizacaoAnalises, ...this.CaracterizacaoComentarios, carac];
    this.renumeraIndicesCaracterizacoes()
  }

  public removeCaracterizacao(elemento: ReclamacaoCaracterizacao) {
    this.ReclamacaoCaracterizacao = this.ReclamacaoCaracterizacao.filter(el => el != elemento);
    this.renumeraIndicesCaracterizacoes()
  }

  //endregion gestão de caracterizações

  /**
   * Setter para assistir no preenchimento do artigo/descrição de uma qualquer etiqueta
   * @param rowRef
   */
  public setArtigoRg(rowRef: any) {
    //console.log(rowRef);
    let a                               = rowRef as Artigo;
    this.nvcArtigoSobreposicao          = a.nvcArtigo;
    this.nvcArtigoDescricaoSobreposicao = a.nvcDescricao;
  }

  public toString() { return `Reclamacao (${this.nvcTipo}) nº ${this.intNumero}/${this.nvcSerie}`; }
}

//region aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Reclamacao) => m.idReclamacao).displayName('idReclamacao').required()
  .ensure((m: Reclamacao) => m.nvcClienteArmazem).displayName('Cliente Armazem').required()
  //.ensure((m: Reclamacao) => m.idPlanoExpedicao).displayName('Plano Expedicao').required()
  //.ensure((m: Reclamacao) => m.nvcCodigoInterno).displayName('nvcCodigoInterno').required()
  .ensure((m: Reclamacao) => m.dtmDataDocumento).displayName('Data FNC').required()
  .ensure((m: Reclamacao) => m.nvcSerie).displayName('nvcSerie').required()
  .ensure((m: Reclamacao) => m.intNumero).displayName('intNumero').required()
  .ensure((m: Reclamacao) => m.intQuantidadeTotalEncomenda).displayName('Quantidade de Peças defeituosas').required()
  .ensure((m: Reclamacao) => m.intQuantidadeDefeituosa).displayName('Quantidade de Peças defeituosas').required()
  // .ensure((m: Reclamacao) => m.nvcResponsavel).displayName('nvcResponsavel').required()
  .ensure((m: Reclamacao) => m.nvcClienteDestinatario).displayName('Cliente/Destinatário').required()
  // .ensure((m: Reclamacao) => m.nvcComentarios).displayName('nvcComentarios').required()
  // .ensure((m: Reclamacao) => m.nvcCabecalho).displayName('nvcCabecalho').required()
  .ensure((m: Reclamacao) => m.nvcArtigoSobreposicao).displayName('Ref. Artigo').required()
  .ensure((m: Reclamacao) => m.nvcArtigoDescricaoSobreposicao).displayName('Designacao').required()
  //.ensure((m: Reclamacao) => m.nvcCodigoEncomendaSobreposicao).displayName('nvcCodigoEncomendaSobreposicao').required()
  // .ensure((m: Reclamacao) => m.nvcGravacaoSobreposicao).displayName('nvcGravacaoSobreposicao').required()
  // .ensure((m: Reclamacao) => m.nvcDefeitos).displayName('nvcDefeitos').required()
  // .ensure((m: Reclamacao) => m.nvcAnalise).displayName('nvcAnalise').required()
  .ensure((m: Reclamacao) => m.nvcTipo).displayName('Tipo').required()
  .ensure((m: Reclamacao) => m.nvcEstado).displayName('Estado').required()
  .ensure((m: Reclamacao) => m.nvcEstadoResolucao).displayName('Estado de Resolução').required()
  .on(Reclamacao);
//endregion

//interna | externa
export const TipoReclamacao    = {
  Interna        : "interna",
  ExternaEmitida : "externa.emitida",
  ExternaRecebida: "externa.recebida",
};
export const TipoReclamacaoObj = [
  {id: TipoReclamacao.Interna, text: "Interna"},
  {id: TipoReclamacao.ExternaEmitida, text: "Externa/Emitida"},
  {id: TipoReclamacao.ExternaRecebida, text: "Externa/Recebida"},
];

//rascunho | aberto | enviado | aceite | recusado | cancelado
export const EstadoReclamacao    = {
  Rascunho : "rascunho",
  Aberto   : "aberto",
  Enviado  : "enviado",
  Aceite   : "aceite",
  Recusado : "recusado",
  Cancelado: "cancelado",
};
export const EstadoReclamacaoObj = [
  {id: EstadoReclamacao.Rascunho, text: "Rascunho"},
  {id: EstadoReclamacao.Aberto, text: "Aberto"},
  {id: EstadoReclamacao.Enviado, text: "Enviado"},
  {id: EstadoReclamacao.Aceite, text: "Aceite"},
  {id: EstadoReclamacao.Recusado, text: "Recusado"},
  {id: EstadoReclamacao.Cancelado, text: "Cancelado"},
];

//iniciado | semsolucao | resolvivel | resolvido
export const EstadoResolucaoReclamacao    = {
  Iniciado  : "iniciado",
  SemSolucao: "semsolucao",
  Resolvivel: "resolvivel",
  Resolvido : "resolvido",
};
export const EstadoResolucaoReclamacaoObj = [
  {id: EstadoResolucaoReclamacao.Iniciado, text: "Iniciado/Por analizar"},
  {id: EstadoResolucaoReclamacao.SemSolucao, text: "Sem Solução Possível"},
  {id: EstadoResolucaoReclamacao.Resolvivel, text: "Resolvível/Com Solução"},
  {id: EstadoResolucaoReclamacao.Resolvido, text: "Resolvido"},
];
