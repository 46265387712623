import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";

import {GlobalServices} from "../services/global-services";
import {Utilizador} from "../models/Utilizador";

@autoinject()
export class UtilizadorPasswordDialog {
  //static inject = [DialogController, ValidationControllerFactory];
  protected validationController: ValidationController;
  protected controller: DialogController;
            app: GlobalServices;

  protected utilizador: Utilizador;
  protected confirma: boolean = true;
  protected isBusy:boolean = false;

  nvcOldPassword:string ;
  nvcPassword:string ;
  nvcPasswordConfirmation:string ;

  constructor(controller: DialogController, app: GlobalServices, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    this.app                  = app;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  activate(payload: { utilizador: Utilizador, confirma:boolean }) {
    console.log("UtilizadorPasswordDialog", payload);
    this.utilizador = payload.utilizador;

    if(payload.confirma != undefined)
      this.confirma = payload.confirma;
  }

  attached() {}

  cleanup() {
  }

  gravaUtilizador() {
    this.utilizador.nvcOldPassword = this.nvcOldPassword;
    this.utilizador.nvcPassword = this.nvcPassword;
    this.utilizador.nvcPasswordConfirmation = this.nvcPasswordConfirmation;
    return this.app.api.postProcessed("api/auth/utilizador-password", this.utilizador.stateToPOJSO())
      .then(r => {
        console.log("resposta gravaUtilizador", r);
        this.app.notificationSuccess("A password foi atualizada.");
        // this.cleanup();
        return true;
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }

  submit() {
    // console.log("Submit", planoExpedicao, this.planoExpedicao);
    this.isBusy = true;
    this.validationController.validate()
      .then(r => {
        if (r.valid) {
          this.gravaUtilizador()
            .then(_ =>{
            this.controller.ok({action: "REFRESH", payload: {}});
          });
        } else {
          this.isBusy = false;
          this.app.notificationErrorCompact("O formulário apresenta erros. Corrija-os e volte a submeter.")
        }
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }
}
ValidationRules.customRule(
  'matchesProperty',
  (value, obj, otherPropertyName) =>
  value === null
  || value === undefined
  || value === ''
  || obj[otherPropertyName] === null
  || obj[otherPropertyName] === undefined
  || obj[otherPropertyName] === ''
  || value === obj[otherPropertyName],
  '${$displayName} deve ser igual a ${$getDisplayName($config.otherPropertyName)}', otherPropertyName => ({otherPropertyName})
);

ValidationRules
  .ensure((m: UtilizadorPasswordDialog) => m.nvcOldPassword).displayName("palavra-chave (original)")
  .required()
  .ensure((m: UtilizadorPasswordDialog) => m.nvcPassword).displayName("palavra-chave (nova)")
  .required()
  .ensure((m: UtilizadorPasswordDialog) => m.nvcPasswordConfirmation).displayName("confirmação da palavra-chave")
  .required().satisfiesRule('matchesProperty', 'nvcPassword')
  .on(UtilizadorPasswordDialog);

