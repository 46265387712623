//import {setInterval} from "timers";
import {autoinject} from 'aurelia-framework';
import {Api} from "../../services/api";

@autoinject()
export class CustomComponent {
  private _api: Api;
          message = 'Dashboard';

  constructor(api: Api) {
    this._api = api;
  }

  // region Acções
  criaEpoca() {
    this._api.post("api/epoca/criar", 2017).then(console.log);
  }

  // endregion

  attached() {

    // try {
    //   $("#chartcontainer").ejChart("destroy");
    // } catch (err) {
    //   console.log("err", err);
    // }
    //
    // let chartData = [
    //   {mes: 'Jan', valor: 35 + 35},
    //   {mes: 'Fev', valor: 28 + 35},
    //   {mes: 'Mar', valor: 34 + 35},
    //   {mes: 'Abr', valor: 32 + 35},
    //   {mes: 'Mai', valor: 40 + 35},
    //   {mes: 'Jun', valor: 32 + 35},
    //   {mes: 'Jul', valor: 35 + 35},
    //   {mes: 'Ago', valor: 55 + 35},
    //   {mes: 'Set', valor: 38 + 35},
    //   {mes: 'Out', valor: 30 + 35},
    //   {mes: 'Nov', valor: 25 + 35},
    //   {mes: 'Dez', valor: 32 + 35}];
    //
    // //console.log("CustomComponent attached");
    // $("#chartcontainer").ejChart({
    //   series: [{
    //     dataSource: chartData,
    //     xName     : "mes",
    //     yName     : "valor",
    //     name      : "Despacho %",
    //     fill      : "#83cc76",
    //     enableAnimation                    : true,
    //
    //   }]
    // });
    //
    // let chartData2 = [
    //   {mes: 'Jan', valor: 28},
    //   {mes: 'Fev', valor: 35},
    //   {mes: 'Mar', valor: 55},
    //   {mes: 'Abr', valor: 35},
    //   {mes: 'Mai', valor: 30},
    //   {mes: 'Jun', valor: 32},
    //   {mes: 'Jul', valor: 25},
    //   {mes: 'Ago', valor: 40},
    //   {mes: 'Set', valor: 32},
    //   {mes: 'Out', valor: 34},
    //   {mes: 'Nov', valor: 38},
    //   {mes: 'Dez', valor: 32}];
    //
    // let chartData2a = [
    //   {mes: 'Jan', valor: 10},
    //   {mes: 'Fev', valor: 13},
    //   {mes: 'Mar', valor: 17},
    //   {mes: 'Abr', valor: 5},
    //   {mes: 'Mai', valor: 0},
    //   {mes: 'Jun', valor: 0},
    //   {mes: 'Jul', valor: 20},
    //   {mes: 'Ago', valor: 16},
    //   {mes: 'Set', valor: 30},
    //   {mes: 'Out', valor: 21},
    //   {mes: 'Nov', valor: 3},
    //   {mes: 'Dez', valor: 27}];
    //
    // $("#chartcontainer-2").ejChart({
    //   primaryYAxis: {
    //     //Customize the axis label format.
    //     labelFormat: '{value}k'
    //   },
    //   series      : [{
    //     dataSource: chartData2,
    //     xName     : "mes",
    //     yName     : "valor",
    //     name      : "Encomendas",
    //     fill      : "#83cc76", enableAnimation: true,
    //
    //   },
    //     {
    //       dataSource: chartData2a,
    //       xName     : "mes",
    //       yName     : "valor",
    //       name      : "LASCO",
    //       fill      : "#ffd764", enableAnimation: true,
    //
    //     }]
    // });
    //
    // let chartData3  = [
    //   {mes: '2017-01-02', valor: 35},
    //   {mes: '2017-01-05', valor: 55},
    //   {mes: '2017-01-09', valor: 35},
    //   {mes: '2017-01-12', valor: 300},
    //   {mes: '2017-01-16', valor: 320},
    //   {mes: '2017-01-19', valor: 250},
    //   {mes: '2017-01-23', valor: 400},
    //   {mes: '2017-01-26', valor: 320},
    //   {mes: '2017-01-30', valor: 340},
    //   {mes: '2017-02-02', valor: 380},
    //   {mes: '2017-02-06', valor: 320},
    //   {mes: '2017-02-09', valor: 280},
    //   {mes: '2017-02-13', valor: 350},
    //   {mes: '2017-02-16', valor: 550},
    //   {mes: '2017-02-20', valor: 350},
    //   {mes: '2017-02-23', valor: 30},
    //   {mes: '2017-02-27', valor: 32},
    //   {mes: '2017-03-02', valor: 25},
    //   {mes: '2017-03-06', valor: 40},
    //   {mes: '2017-03-09', valor: 32},
    //   {mes: '2017-03-13', valor: 34},
    //   {mes: '2017-03-16', valor: 38},
    //   {mes: '2017-03-20', valor: 32},
    //   {mes: '2017-03-23', valor: 28},
    // ];
    // let chartData3a = [
    //   {mes: '2017-01-02', valor: 135},
    //   {mes: '2017-01-05', valor: 155},
    //   {mes: '2017-01-09', valor: 135},
    //   {mes: '2017-01-12', valor: 200},
    //   {mes: '2017-01-16', valor: 220},
    //   {mes: '2017-01-19', valor: 250},
    //   {mes: '2017-01-23', valor: 200},
    //   {mes: '2017-01-26', valor: 220},
    //   {mes: '2017-01-30', valor: 240},
    //   {mes: '2017-02-02', valor: 280},
    //   {mes: '2017-02-06', valor: 220},
    //   {mes: '2017-02-09', valor: 280},
    //   {mes: '2017-02-13', valor: 350},
    //   {mes: '2017-02-16', valor: 535},
    //   {mes: '2017-02-20', valor: 30},
    //   {mes: '2017-02-23', valor: 80},
    //   {mes: '2017-02-27', valor: 132},
    //   {mes: '2017-03-02', valor: 157},
    //   {mes: '2017-03-06', valor: 145},
    //   {mes: '2017-03-09', valor: 189},
    //   {mes: '2017-03-13', valor: 205},
    //   {mes: '2017-03-16', valor: 207},
    //   {mes: '2017-03-20', valor: 232},
    //   {mes: '2017-03-23', valor: 228},
    // ];
    // $("#chartcontainer-3").ejChart(
    //   {
    //     primaryYAxis   : {
    //       //Customize the axis label format.
    //       labelFormat: '{value}'
    //     },
    //     series         : [{
    //       dataSource     : chartData3,
    //       xName          : "mes",
    //       yName          : "valor",
    //       name           : "espera",
    //       type           : 'area',
    //       fill           : "#cc3d5f",
    //       opacity        : 0.6,
    //       width          : 3,
    //       enableAnimation: true,
    //     }, {
    //       dataSource     : chartData3a,
    //       xName          : "mes",
    //       yName          : "valor",
    //       name           : "despachadas",
    //       type           : 'area',
    //       fill           : "#a6cc9b",
    //       opacity        : 0.6,
    //       width          : 3,
    //       enableAnimation: true,
    //     }]
    //     ,
    //     load           : "loadTheme",
    //     seriesRendering: "seriesRender",
    //
    //   }
    // );
    //
    // $("#chartcontainer-p").ejChart(
    //   {
    //     //Initializing Series
    //     series         : [
    //       {
    //         points                             : [
    //           {x: 'LASCO', y: 60, text: 'Lasco, 60%'},
    //           {x: 'JULEA', y: 8, text: 'Julea, 8%'},
    //           {x: 'SCAP', y: 5, text: 'Scap, 5%'},
    //           {x: 'FMI', y: 7, text: 'Fmi, 7%'},
    //           {x: 'GOULARD', y: 8, text: 'Goulard, 8%'},
    //           {x: 'RAOUL', y: 12, text: 'Raoul Guyot, 12%'}],
    //         marker                             : {
    //           dataLabel: {
    //             visible      : true,
    //             shape        : 'none',
    //             connectorLine: {type: 'bezier', color: 'black'},
    //             font         : {size: '14px'}
    //           }
    //         },
    //         border                             : {width: 2, color: 'white'},
    //         name                               : 'Clientes',
    //         type                               : 'pie',
    //         enableAnimation                    : true,
    //         labelPosition                      : 'outsideExtended',
    //         enableSmartLabels: true, startAngle: 145
    //       }
    //     ],
    //     load           : "loadTheme",
    //     seriesRendering: "seriesRender",
    //     //title:{text: 'Expenditures'},
    //     isResponsive   : true,
    //     size           : {height: "400"},
    //     legend         : {visible: false}
    //   })
  }
}


























