import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";
import {BaseViewModel, ViewModelImporter} from "../models/BaseViewModel";
import environment from "../environment";
import {ActionInterface, GlobalServices, GlobalServicesContainer} from "../services/global-services";
import {confirmaActionTyped, confirmaActionTypedMpfd} from "../services/api-envelopes";
import {PickerRemoteGrid} from "../it-features/remote-grid/picker-remote-grid";

/**
 * A versão deve ser incrementada por cada alteração à classe
 * versao: 2.01 (2019-05-17) introdução de propriedadeRowRef para facilitar os mappings dos `picker-rg`
 * versao: 2       coisas...
 */
@autoinject()
export class ComposeDialog {
  protected controller: DialogController;
  protected validationController: ValidationController;

  protected app: GlobalServices;
  protected invoker: GlobalServicesContainer;

  /** modelo sob edição. na view (html) parcial deve ser usado como referência a fazer bind*/
  public modelo: BaseViewModel;
  public modeloOriginal: BaseViewModel;

  /** referência a um objeto menos profundo na árvore do estado. Se estiver definido é usado na submissão POST.
   *  O objetivo é o de atuar sobre uma parte mas gravar uma raiz.
   *  Foi má opção NÃO USAR
   */
  protected containerModelo: BaseViewModel;

  // Algures no tempo existiu aqui uma referência para uma remoteGrid só do compose... não serve de nada pois a referência é criada em JS e usável.
  // protected composeRg:any;

  protected options: ComposeDialogOptions = new ComposeDialogOptions();
  public isBusy: boolean                  = true;

  constructor(controller: DialogController, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  //region Aurelia
  canActivate(p) {
    if (environment.debug) console.log("[compose-dialog]", "canActivate", p, this.controller);
    if (!p.modelo || !p.invoker) {
      console.error("Um dos parametros (modelo ou invoker) está em falta pelo que não se pode abir o popup.");
      return false;
    }
    this.invoker = p.invoker;
    this.app     = p.invoker.app;
    this.parseModelo(p.modelo);
    if (p.options) Object.assign(this.options, p.options);

    if (this.options && this.options.rootBindings) {
      let keys = Object.keys(this.options.rootBindings);

      for (let prop of keys) {
        this[prop] = this.options.rootBindings[prop];
      }
    }

    //ATENÇÃO: não usar a propriedade containerModelo - esta mantém-se por compatibilidade
    p.containerModelo && (this.containerModelo = p.containerModelo);
    //ATENÇÃO: não usar a propriedade containerModelo - esta mantém-se por compatibilidade

    this.isBusy = false;
    return true;
  }

  //apenas é verificado se a opção trackChanges estiver a true
  canDeactivate() {
    if (this.invoker && this.invoker.overrideDeactivation !== undefined) return this.invoker.overrideDeactivation;
    if (this.options.trackChanges && this.isChanged()) {
      return this.app.confirmaPopup(["Existem alterações por gravar.", "Deseja fechar o popup <b>SEM GRAVAR AS ALTERAÇÕES?</b>"])
        .then(r => {
          this.isBusy = false;
          if(r) {this.app.notificationWarning("Alterações descartadas");}
          return r;
        });
    }
    return true;
  }

  //endregion Aurelia

  //region actions

  /**
   * Verifica se há alterações no modelo
   */
  private isChanged(): boolean {
    if (typeof this.modelo.difference === "function") {
      let strings = this.modelo.difference(this.modeloOriginal);
      if (environment.debug) console.log("[compose-dialog]", "canDeactivate", strings, "modelo base", this.modelo, "modelo cópia", this.modeloOriginal);
      return (strings.length > 0);
    }
    return false;
  }

  private parseModelo(m: BaseViewModel) {
    this.modelo = m;
    if (m && typeof m.cloneInstance == "function") {
      this.modeloOriginal = m.cloneInstance();
    }
  }

  /**
   * Fecha o popup
   * Se a opção de fechar o popup (closeAfterSave = false) apenas tenta atualizar o estado do modelo com a resposta do servidor.
   */
  private Ok(r: any) {
    if (this.options.closeAfterSave) {
      this.options.trackChanges = false;
      this.controller.ok({
        containerModelo: this.containerModelo,
        modelo         : this.modelo,
        payload        : r,
      });
    } else {
      this.app.notificationShort("Ok");
      //se a função estática fromPOJSO estiver atribuída rehidrata o modelo
      // if (this.modelo && this.options.modelRef &&  typeof this.options.modelRef.fromPOJSO === "function") {
      //   let m = this.options.modelRef.fromPOJSO(r);
      //   this.parseModelo(m);
      // }
      if (this.modelo && this.modelo.getStaticType) {
        let m = this.modelo.getStaticType().fromPOJSO(r);
        this.parseModelo(m);
      }
    }
    //todo: caso else, fromPojso do modelo em edição?
  }

  // /**
  //  * processa uma lista de acções?
  //  */
  // public queueActions(actions:ActionInterface[]) { this.app.queueActionsGs(actions, this);}

  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[compose-dialog]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      // noinspection FallThroughInSwitchStatementJS
      switch (action) {

        //region estado
        case 'OK': {
          if (this.options.doValidation) {
            return this.doAction("VALIDAR")
          } else {
            return this.doAction("GRAVAR");
          }
        }

        case 'CANCEL': {
          this.controller.cancel();
          return Promise.resolve(false);
        }

        case 'VALIDAR': {
          //valida todos os controlos no ecrã
          return this.validationController.validate()
            .then(r => {
              if (r.valid) { return this.doAction("GRAVAR");} else return this.isBusy = this.app.notificationErrorCompact("O formulário apresenta erros");
            })
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err))
        }

        case 'GRAVAR': {
          // envia um POST para o endereco definido nas options
          // se o contentor estiver definido, e como as alterações provocam mutações, usa-se esse objeto em vez do modelo.
          if (environment.debug) console.log("[compose-dialog]", "GRAVAR", this.containerModelo, this.modelo);
          if (this.options.postUri) {

            //post comum
            if (this.options.postMethod == 'body') {
              return confirmaActionTyped(this, this.containerModelo || this.modelo, this.options.postUri)
                .then(r => {
                  if (environment.debug) console.log("[compose-dialog]", "GRAVAR(post) - resposta do servidor", r);

                  if (r) {
                    this.Ok(r);
                    return true;
                  }
                  return false;
                });
            }
            //post multipart/form-data
            if (this.options.postMethod == 'multipart') {
              return confirmaActionTypedMpfd(this, this.modelo, this.options.postUri, 1)
                .then(r => {
                  if (environment.debug) console.log("[compose-dialog]", "GRAVAR(multipart) - resposta do servidor", r);

                  if (r) {
                    this.Ok(r);
                    return true;
                  }
                  this.isBusy = false;
                  return false;
                });
            }
          } else {
            this.Ok(null);
            //this.controller.ok(this.containerModelo || this.modelo);
            return true;
          }
        }
        //endregion

        //region util
        //acções para abrir um picker remote grid
        // o codigoDaTabela tem de ser passado, ex:
        // ... click.delegate="doAction('PICKER-REMOTE-GRID', { codigoTabela: 'ClienteDestinatario', propriedadeModelo: 'nvcClienteDestinatario'})"
        // a propriedade modeloOverride permite alvejar dependentes do modelo em tratamento no compose corrente
        case "PICKER-RG":
        case "PICKER-REMOTE-GRID": {
          this.isBusy = false;
          let pl      = payload as { codigoTabela: string, propriedadeModelo?: string, propriedadeRowRef?: string, selectionMode?: string, defaultFilter: any[], initialFilter: any[], modeloOverride: any, overrideActionsColumn?: boolean };
          if (pl.overrideActionsColumn === undefined) { pl.overrideActionsColumn = true;}

          if (!pl.modeloOverride) {pl.modeloOverride = this.modelo;}
          //condicionais de execução
          if (!pl.codigoTabela) throw new Error("Os PickerRemoteGrid têm de ter o 'codigoTabela' configurado");
          if (!pl.propriedadeModelo) {
            if (pl.selectionMode && pl.selectionMode == 'none') {
              if (environment.debug) console.log("[compose-dialog]", "sem configuração de propriedade do modelo");
            } else {
              throw new Error("Os PickerRemoteGrid têm de ter a 'propriedadeModelo' configurada.");
            }
          }
          //if (!this.modelo[pl.propriedadeModelo]) throw new Error(`O '${this.modelo}' de ter a 'propriedadeModelo' ${pl.propriedadeModelo} configurada, para aceitar o input da RemoteGrid`);
          //abrir o picker RG
          return this.app.ds.open({viewModel: PickerRemoteGrid, model: pl})
            .whenClosed(r => {
              if (!r.wasCancelled) {
                if (environment.debug) console.log("[compose-dialog]", "Resultado Picker", r);
                if (typeof pl.modeloOverride[pl.propriedadeModelo] === "function") {
                  //setter ou função que aceita uma row da tabela selecionada
                  pl.modeloOverride[pl.propriedadeModelo](r.output.rowRef);
                } else {
                  pl.modeloOverride[pl.propriedadeModelo] = r.output.rowRef[pl.propriedadeRowRef || pl.propriedadeModelo];
                }
              }
              return Promise.resolve("false");
            })
            .catch(err => this.app.notificationErrorCompact(err));
        }
        //endregion

        //region import/Export

        case "EXPORT-SAFE": {
          this.isBusy = false;
          this.doAction("EXPORT", JSON.stringify(this.modelo.stateToPOJSOSafeId(), null, 2));
          break;
        }

        case "EXPORT-POJSO": {
          this.isBusy = false;
          this.doAction("EXPORT", JSON.stringify(this.modelo.stateToPOJSO(), null, 2));
          break;
        }

        case "EXPORT": {
          this.isBusy         = false;
          let zrg             = new ViewModelImporter({jsonString: payload, intent: "export"});
          let composeSettings = {
            modelo : zrg,
            invoker: this,
            options: new ComposeDialogOptions({
              title            : `Exportar ${this.modelo} para JSON`,
              withDefaultFooter: false,
              doValidation     : false,
              mainView         : './compose-dialog-import.html'
            }),
          };

          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : composeSettings
            })
            .whenClosed(r => {
              //
            });
        }

        case "IMPORT": {
          this.isBusy = false;
          if (!this.modelo.getStaticType()) {
            this.app.notificationWarning("O compose não tem um modelo corretamente definido para fazer importação.")
          }
          let vmi             = new ViewModelImporter();
          let composeSettings = {
            modelo : vmi,
            invoker: this,
            options: new ComposeDialogOptions({
              title            : `Importar JSON para ${this.modelo}`,
              withDefaultFooter: false,
              mainView         : './compose-dialog-import.html'
            }),
          };

          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : composeSettings
            })
            .whenClosed(r => {
              //
              if (!r.wasCancelled) {
                console.log("importer achou que", r);
                try {
                  let obj = JSON.parse(r.output.modelo.jsonString);
                  this.parseModelo(this.modelo.getStaticType().fromPOJSO(obj));
                } catch (e) {
                  console.error(e);
                  this.app.notificationWarning("Não foi possível importar os dados");
                  return null;
                }
              }
            });
        }
        //endregion import/Export

        default: {
          if (this.invoker && typeof this.invoker.doAction === "function") {
            if (environment.debug) console.log("[compose-dialog]", "calling doAction of invoker", action, payload);
            this.isBusy               = false;
            this.options.trackChanges = false;
            //quando a invocação de doAction transborda para o invoker é acrescentdo um 3º argumento que é o viewModel do ComposeDialog
            return this.invoker.doAction(action, payload, this);
          }
          console.error("{doAction} DESCONHECIDA [compose-dialog]", action, payload);
          this.app.notificationErrorCompact(["Acção DESCONHECIDA [compose-dialog]", action]);
          this.isBusy = false;
        }
      }
    } catch (err) {
      console.error(err);
      this.app.notificationErrorCompact(err);
    }
  }

  //endregion actions
}

/**
 * A versão deve ser incrementada por cada alteração à classe
 * versao: 2
 */
export class ComposeDialogOptions {
  /** deve ser executada a validação? */
  doValidation: boolean = true;
  trackChanges: boolean = true;

  /** selector de método de POST (body ou multipart) */
  postMethod: 'body' | 'multipart' = 'body';

  /** deve ser executada a validação*/
  mainView: string = "./compose-test.html";

  /** para onde deve ser feito o POST (se não estiver preenchido altera apenas o modelo) */
  postUri: string = "";

  /** Título do popup */
  title: string = "";

  /** Usa o footer defeito do popup? */
  withDefaultFooter: boolean = true;

  /** Deve fechar o popup? */
  closeAfterSave: boolean = true;
  //fromPOJSO: any          = null;
  //modelRef: any     = null;

  /** root bindings são objectos importados para a raiz do compose dialogs*/
  rootBindings: any = {};

  okLabel: string = "Gravar";
  okIcon: string  = "fa-save";

  public constructor(fields?: Partial<ComposeDialogOptions>,) {
    if (fields) Object.assign(this, fields);
  }
}
