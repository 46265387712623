// Auto-gerado com typewriter
import {VmWrapper} from "./VmWrapper";
//imports locais
import {BaseViewModel} from "./BaseViewModel";
import {RegistoEtiqueta} from "./RegistoEtiqueta";

//export classe
export class MoviemntoArmazemExpedicao extends BaseViewModel {

  public entradas: RegistoEtiqueta[] = [];
  public saidas: RegistoEtiqueta[] = [];

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<MoviemntoArmazemExpedicao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public copyState(obj: any, props: string[] = []): MoviemntoArmazemExpedicao {
    super.copyState(obj, props);
    return this;
  }

  public static fromPOJSO(obj: any): MoviemntoArmazemExpedicao {
    return new MoviemntoArmazemExpedicao().copyState(obj);
  }

  public static multipleFromPOJSO(objs: any | any[]): MoviemntoArmazemExpedicao[] {
    if (objs && Array.isArray(objs)) return objs.map(MoviemntoArmazemExpedicao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state = this.getState();
    //envia para o servidor o valor reservado?
    state.entradas = this.entradas.map(el => el.stateToPOJSO());
    state.saidas = this.saidas.map(el => el.stateToPOJSO());
    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<MoviemntoArmazemExpedicao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): MoviemntoArmazemExpedicao {
    console.log("cloneInstance", this.stateToPOJSO());
    return MoviemntoArmazemExpedicao.fromPOJSO(this.stateToPOJSO());
  }

  //endregion

  public toString() {
    return `MoviemntoArmazemExpedicao`;
  }
}
