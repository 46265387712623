import {autoinject} from "aurelia-framework";
import {AuthService} from "./auth-service";
import {Api} from "./api";
import {EventAggregator} from "aurelia-event-aggregator";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {DialogService} from "aurelia-dialog";
import {Router, Redirect} from 'aurelia-router';
import {ConfirmacaoDialog} from "../dialogs/confirmacao-dialog";
import {ItResponse, MpfdModel, VmWrapper} from "../models/VmWrapper";
import {ErroServidorDialog} from "../dialogs/erro-servidor-dialog";
import environment from "../environment";
import {ConfirmacaoOpcoesDialog} from "../dialogs/confirmacao-opcoes-dialog";
import {AlertDialog} from "../dialogs/alert-dialog";
import {camelCase} from "../utils/ItMultiPurpose";
import {App} from "../app";
import {MobileApp} from "../mobile-app";

/**
 * V 2.0 (2019-05-18) começo de versionamento
 */
@autoinject()
export class GlobalServices {
  public auth: AuthService;
  public api: Api;
  public ea: EventAggregator;

  public ds: DialogService;
  // public vcf: ValidationControllerFactory;
  public router: Router;

  //referência da aplicação aurelia (tem de ser injetada manualmente no construtor de app.ts, permite o uso de um doActionGlobal ao projeto)
  // isto é, básicamente, um fix FOLEIRO mas acredita-se útil
  public aureliaMain: App | MobileApp;

  //mantém um fragmento a aplicar para se realizar um redirect (após login)
  public redirectFragment: string = "";

  //uma flag para esfumaçar a UI (em desuso)
  public smoked: boolean = false;

  constructor(auth: AuthService, api: Api, ea: EventAggregator, ds: DialogService, router: Router) {
    this.auth   = auth;
    this.api    = api;
    this.ea     = ea;
    this.ds     = ds;
    this.router = router;
    // this.vcf    = vcf;
    // this.router = router;
  }

  //region wrappers
  /**
   * Notificação de sucesso
   * @param msg
   */
  public notificationSuccess(msg: string | string[]): boolean {
    this.ea.publish('notification:success', msg);
    return true;
  }

  /**
   * Notificação de sucesso (Curta)
   * @param msg
   */
  public notificationShort(msg: string | string[]): boolean {
    this.ea.publish('notification:short', msg);
    return true;
  }

  /**
   * Notificação de erro
   * @param msg
   */
  public notificationError(msg: string | string[]): boolean {
    if (environment.debug) console.log("notificationError", msg);
    this.ea.publish('notification:error', msg);
    return false;
  }

  /**
   * Notificação de warning
   * @param msg
   */
  public notificationWarning(msg: string | string[]): void {
    if (environment.debug) console.log("notificationWarning", msg);
    this.ea.publish('notification:warning', msg);
  }

  /**
   * Notificação de Info
   * @param msg
   */
  public notificationInfo(msg: string | string[]): void {
    if (environment.debug) console.log("notificationInfo", msg);
    this.ea.publish('notification:info', msg);
  }

  /**
   * Notificação de erro
   * @param e
   */
  public notificationErrorCompact(e: any): boolean {
    // console.debug("notificationErrorCompact", e);
    if (environment.debug) console.log("notificationErrorCompact", e);
    this.ea.publish('notification:error', e.message || e.error || e);
    return false;
  }

  //endregion

  //region dialogs de confirmação / erro
  public confirmaAction<T>(vmw: VmWrapper<T>, route: string) {
    // console.log("ConfirmAction", vmw, route);
    return this.api.postProcessed(route, vmw.stateToPOJSO())
      .then((obj: any) => {
        // console.info("ConfirmAction resp", vmw, route);
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent: string[] = ['<h5>Para realizar esta operação deve confirmar o seguinte:</h5>', ...obj.mensagens];
            // let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.confirmaAction<T>(vmw.nextLevel(), route);
                } else {
                  // throw new Error("A acção foi cancelada");
                  return false;
                }
              });
          } else if (obj.tipo === "erro-servidor") {
            // no caso do tipo "erro-servidor" as mensagens trazem uma estrutura
            // O dialog de erro não é obtrusivo
            this.ds.open({viewModel: ErroServidorDialog, model: obj});
            return {action: "VALIDATE", payload: obj};
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        //this.app.notificationSuccess("O processo foi criado com sucesso");
        return {action: "OK", payload: obj};
      });
  }

  /**
   * Uma versão simplificada do confirmaAction ara uso imediato (e sem consulta do servidor)
   * @param {string[]} msgs
   * @return {Promise<DialogCloseResult>}
   */
  public confirmaPopup(msgs: string[]) {
    // console.log("ConfirmAction", vmw, route);
    // let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${msgs.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
    let dialogContent: string[] = ['<h5>Para realizar esta operação deve confirmar o seguinte:</h5>', ...msgs];
    return this.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
      .whenClosed(resp => {
        return !resp.wasCancelled;
      });
  }

  //endregion

  //region respostas tipadas
  public confirmaActionTyped<T>(vmw: VmWrapper<T>, route: string, signal: boolean = true) {
    // console.log("ConfirmAction", vmw, route);
    this.smoked = true;
    return this.api.postProcessedT(route, vmw.stateToPOJSO(), signal)
      .then((obj: any) => {
        // console.info("ConfirmAction resp", vmw, route);
        this.smoked = false;
        if (obj.type) {
          let response = ItResponse.fromPOJSO(obj);

          if (response.type === "confirm") {
            //Apenas se presta atenção às mensagens passadas em _root.
            let {_root, rest}           = obj.messages;
            let dialogContent: string[] = ['<h5>Para realizar esta operação deve confirmar o seguinte:</h5>', ..._root];
            return this.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.confirmaActionTyped<T>(vmw.nextLevel(), route);
                } else {
                  // throw new Error("A acção foi cancelada");
                  return this.smoked = false;
                }
              });
          }
          if (response.type === "option-confirm") {
            if (environment.debug) console.log("[global-services]", "option-confirm", obj);
            return this.ds.open({viewModel: ConfirmacaoOpcoesDialog, model: {itr: response}})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM ou uma opção normal: aumenta o nível de confirmação e anexa a resposta
                  return this.confirmaActionTyped<T>(vmw.nextLevel().withOption(resp.output), route);
                } else {
                  return this.smoked = false;
                }
              });
          }
          if (response.type === "error") {
            // return {action: "ERROR", payload: obj.messages}
            this.smoked = false;
            return this.ds.open({viewModel: AlertDialog, model: {title: "Ocorreram erros no servidor.", errors: obj.messages}})
              .whenClosed(resp => ({action: "ERROR", payload: obj}));
          }
          if (response.type === "redirect") {
            if (environment.debug) console.log("[global-services]", "HASH", obj);
            if (response.hash != null) {
              return {action: "REDIRECT", payload: response};
            }
            return {action: "OK", payload: obj};
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        //this.app.notificationSuccess("O processo foi criado com sucesso");
        return {action: "OK", payload: obj};
      })
      .catch(err => {
        // console.info("ConfirmAction resp", vmw, route);
        this.smoked = false;
        throw err;
      })
      ;
  }

  /**
   * Condensa a análise de uma respota da webapi da família It*
   * Caso esta seja do tipo ERROR usa a referencia do ValidationController para disparar erros sobre as propriedades transmitidas no payload
   * @param r
   * @param {any[]} objs
   * @param {ValidationController} vc
   * @return {boolean}
   */
  public processConfirmation(r: any, objs?: any[], vc?: ValidationController) {
    let payload2 = r.payload;
    if (r.action == "OK") {
      return true;
    }
    if (r.action == "REDIRECT") {
      if (environment.debug) console.log("[global-services]", "REDIRECT", r);
      r && r.payload && this.redirect((r.payload as ItResponse).hash);
      return true;
    }
    if (r.action == "ERROR") {
      let msgs = ["Ocorreram erros no servidor."];
      if (payload2._root) msgs = [...msgs, ...payload2._root];
      this.notificationError(msgs);
      if (environment.debug) console.log("[global-services]", "Ocorreram erros de validação no servidor", payload2);
      if (vc) {
        for (let prop in payload2) {
          if (payload2.hasOwnProperty(prop)) {
            let v = camelCase(prop);
            for (let obj of objs) {
              if (obj.hasOwnProperty(v)) { vc.addError(payload2[prop], obj, v); }
            }
          }
        }
      }
      return false;
    }
  }

  public confirmaDeletionTyped<T>(vmw: VmWrapper<T>, route: string) {
    // console.log("ConfirmAction", vmw, route);
    this.smoked = true;
    return this.api.deleteProcessedT(route, {}, vmw.stateToPOJSO())
      .then((obj: any) => {
        // console.info("ConfirmAction resp", vmw, route);
        this.smoked = false;
        if (obj.type) {
          if (obj.type === "confirm") {
            //Apenas se presta atenção às mensagens passadas em _root.
            let {_root, rest}           = obj.messages;
            // let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${_root.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            let dialogContent: string[] = ['<h5>Para realizar esta operação deve confirmar o seguinte:</h5>', ..._root];
            return this.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                console.log("resp", resp);
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.confirmaDeletionTyped<T>(vmw.nextLevel(), route);
                } else {
                  // throw new Error("A acção foi cancelada");
                  return false;
                }
              });
          }
          //todo: lógica para option-confirm
          if (obj.type === "error") {
            return this.ds.open({viewModel: AlertDialog, model: {title: "Ocorreram erros no servidor.", errors: obj.messages}})
              .whenClosed(resp => ({action: "ERROR", payload: obj}));
          }
          console.error("confirmaDeletionTyped", "erro por exaustão");
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        //this.app.notificationSuccess("O processo foi criado com sucesso");
        return {action: "OK", payload: obj};
      })
      .catch(err => {
        // console.info("ConfirmAction resp", vmw, route);
        this.smoked = false;
        throw err;
      })
      ;
  }

  public report(mapa: string, pl: any, titulo: string, preview: boolean = true, baseUri = 'api/plano-expedicao/report/') {
    return this.api
      .post(baseUri + mapa, pl)
      .then(r => this.api.processBlobResponse(r))
      .then(blob => {
          if (preview)
            return this.api.processBlobPreview(blob, titulo);
          return this.api.processBlobDownload(blob, titulo);
        }
      )
      .catch(err => this.notificationErrorCompact(err))
  }

  /**
   * Versão do confirmaActionTyped com multipart/form-data
   * @param mpfd
   * @param route
   * @param uploadFromConfirmationLevel - limite de confirmação, a partir do qual, os ficheiros deverão ser enviados em formData
   * @param signal
   */
  public confirmaMpFdActionTyped<T>(mpfd: MpfdModel<T>, route: string, uploadFromConfirmationLevel: number = 1, signal: boolean = true) {
    // console.log("ConfirmAction", vmw, route);
    this.smoked        = true;
    // os (possíveis) ficheiros deverão ser incluídos?
    let includeAttachs = mpfd.shouldIncludeAttachs(uploadFromConfirmationLevel);
    // obter o FormData para realizar o POST
    let formData       = mpfd.getFormData(includeAttachs);

    //chamada com recursão, mediante o tipo de resposta do servidor
    return this.api.postMultipartProcessedT(route, formData, signal)
      .then((obj: any) => {
        // console.info("ConfirmAction resp", vmw, route);
        this.smoked = false;
        if (obj.type) {
          let response = ItResponse.fromPOJSO(obj);

          if (response.type === "confirm") {
            //Apenas se presta atenção às mensagens passadas em _root.
            let {_root, rest}           = obj.messages;
            let dialogContent: string[] = ['<h5>Para realizar esta operação deve confirmar o seguinte:</h5>', ..._root];
            return this.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.confirmaMpFdActionTyped(mpfd.nextLevel(), route, uploadFromConfirmationLevel, signal);
                } else {
                  // throw new Error("A acção foi cancelada");
                  return this.smoked = false;
                }
              });
          }
          // if (response.type === "option-confirm") {
          //   if (environment.debug) console.log("[global-services]", "option-confirm", obj);
          //   return this.ds.open({viewModel: ConfirmacaoOpcoesDialog, model: {itr: response}})
          //     .whenClosed(resp => {
          //       if (!resp.wasCancelled) {
          //         //O operador escolheu SIM ou uma opção normal: aumenta o nível de confirmação e anexa a resposta
          //         return this.confirmaActionTyped<T>(vmw.nextLevel().withOption(resp.output), route);
          //       } else {
          //         return this.smoked = false;
          //       }
          //     });
          // }
          if (response.type === "error") {
            // return {action: "ERROR", payload: obj.messages}
            this.smoked = false;
            return this.ds.open({viewModel: AlertDialog, model: {title: "Ocorreram erros no servidor.", errors: obj.messages}})
              .whenClosed(resp => ({action: "ERROR", payload: obj}));
          }
          if (response.type === "redirect") {
            if (environment.debug) console.log("[global-services]", "HASH", obj);
            if (response.hash != null) {
              return {action: "REDIRECT", payload: response};
            }
            return {action: "OK", payload: obj};
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        return {action: "OK", payload: obj};
      })
      .catch(err => {
        this.smoked = false;
        throw err;
      })
      ;
  }

  //endregion respostas tipadas

  //region conveniências
  //realização de um redirect com base num fragmento
  public redirect(fragment: string) {
    if (environment.debug) console.log("[global-services]", "refreshing...", fragment);

    if (fragment.startsWith("#") && !fragment.startsWith("#/")) {
      fragment = fragment.replace("#", "#/");
    }
    return this.router && this.router.navigate(fragment, {replace: true});
  }

  //faz um refresh mais bom para o aurelia-router
  public refresh() {
    this.ds && this.ds.closeAll();
    return this.redirect(window.location.hash);
  }

  //endregion conveniências

  //region queueAction

  /**
   * Sequencia a execução de uma lista de chamadas a `doAction`. Os mecanismos de transbordo da chamada (compose -> route -> app) devem estar implementados.
   *
   * Dependendo do tipo retornado nas chamadas, a lista poderá ser consumida sincronamente ou não. (ver flag `nowait`).
   *
   * A invocação deverá ocorrem no componente de maior especificidade, NÃO HÁ INVERSÃO DO CONTROLO.
   *
   * > Nota: as chamadas ao doAction são "autistas", i.e., uma não vê a seguinte, o valor retornado não é injetado na chamada seguinte.
   * > Isto quer dizer que quando se invoca uma lista, e.g.
   * > ```
   * > click.trigger="doAction('Q', [{action:'CRIA-REGISTO', payload: {linha: modelo.IdPlanoExpedicaoNavigation}, nowait: true}, {action: 'CANCEL'}])"
   * > click.trigger="queueActionsGs([{action:'CRIA-REGISTO', payload: {linha: modelo.IdPlanoExpedicaoNavigation}, nowait: true}, {action: 'CANCEL'}])"
   * > ```
   * > tem de existir acesso a todos os payloads.
   *
   * @param actions - lista de accoes
   * @param context - o componente que invoca, deve possuir um método doAction e poderá ter um ponto de entrada publico para o queueActionsGs
   *
   * ```typescript
   * public queueActions(actions:ActionInterface[]) { this.app.queueActionsGs(actions, this); }
   * ```
   */
  public queueActionsGs(actions: ActionInterface[], context) {
    if (environment.debug) console.log("[global-services QUEUE]", "BEGIN queueActions", actions);

    //condição de stop, a lista de acções foi esgotada
    if (!(actions && Array.isArray(actions) && actions.length > 0)) {
      if (environment.debug) console.log("[global-services QUEUE]", "END queueActions", "fim");
      return Promise.resolve(false);
    }

    //executa a (promessa) da 1a acção da lista, e consoante o tipo de resultado continua o processamento da mesma.
    let head = actions.shift();
    if (environment.debug) console.log("[global-services QUEUE]", "head", head, actions);

    let actionResult = null;
    //condição de skip
    if (context && typeof context.doAction === "function") {
      actionResult = context.doAction(head.action, head.payload);
    } else {
      if (environment.debug) {console.error("[global-services QUEUE]", "o context não contém um método doAction()", head);}
    }

    if (actionResult) {
      // quando há um resultado da chamada de doAction()
      // e é uma promise, espera-se pela sua conclusão
      // e chama-se a queueActions com o resto das acções
      if (typeof actionResult.then === "function") {
        if (environment.debug) console.log("[global-services QUEUE]", "TIPO PROMISE");
        if (!head.nowait) {
          return actionResult
            .then(_ => {
              //chamada recursiva em promise
              if (environment.debug) console.log("[global-services QUEUE]", "chamada recursiva em promise");

              //todo: considerar mutar o payload, mediante flag, com o resultado da actionResult
              return this.queueActionsGs(actions, context);
            });
        }
      }
    }
    //todas as acções que terminam em:
    // break;
    // retornam um scalar
    // foram marcadas como `nowait`
    // (cuidado, pois poderão ser criadas _race conditions_)
    return Promise.resolve(/*actionResult || */false)
      .then(_ => {
        //chamada recursiva em promise
        if (environment.debug) console.log("[global-services QUEUE]", "chamada recursiva em UNDEFINED/NULL/FALSE | SCALAR ou NOWAIT");
        return this.queueActionsGs(actions, context);
      });
    //
  }
  //endregion queueAction
}

export interface GlobalServicesContainer {
  app: GlobalServices;
  overrideDeactivation: boolean;
  doAction?;
}

/**
 * interface para enqueue de acções
 */
export interface ActionInterface {
  action: string;
  payload?: any;
  //o processamento não deve aguardar pela conclusão da promise?
  nowait?: boolean;

  //provavelmente as seguintes propriedades não serão usadas
  context?: any;
  event?: any;
}
