import {BaseViewModel} from "./BaseViewModel";
/**
 * Created by herna on 5/2/2017.
 */
export class Epoca extends BaseViewModel {
  public idEpoca: number      = 0;
  public nvcDescricao: string = "";
  public dtmInicio: Date      = null;
  public dtmFim: Date         = null;
  public bitDefeito: boolean  = false;
  public timestamp: string = "";

  public static fromPOJSO(obj: any): Epoca {
    let model = new Epoca();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): Epoca[] {
    // console.log("multipleFromPOJSO", objs);
    if(objs && Array.isArray(objs)) return objs.map(Epoca.fromPOJSO);
    return [];
  }

  //todo: stateToPOJSO
  //todo: check ao timestamp
}
