import {FrameworkConfiguration} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  // attributes
  config.globalResources([
    './attributes/foco',
    './attributes/autosel',
  ]);

  //custom-elements
  config.globalResources([
    //awesomplete
    './it-awesomplete/awesomplete',
    './it-awesomplete/awesomplete-cache',
    //awesomplete

    //choices
    './it-choices/multi-choices',
    './it-choices/remote-choices',
    './it-choices/remote-single-choices',
    './it-choices/single-choices',
    './it-choices/tags-choices',
    //choices

    //flatpicker
    './it-flatpickr/flat-pickr',
    //flatpicker

    //img-edit
    './it-img-edit/it-img',
    './it-img-edit/img-browser',
    //img-edit

    './it-numeric/numeric-input',

    //picker
    './it-picker/picker.html',
    './it-picker/flex-picker.html',
    //picker

    //it-percent
    './it-percent/it-percent.html',
    //it-percent

    //remote-grid
    './remote-grid/remote-grid',
    './remote-grid/distinct-rg-col-filter',
    './remote-grid/filter-proxy.html',
    //remote-grid
  ]);
}
