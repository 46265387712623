//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import {PickerRemoteGrid} from "../../it-features/remote-grid/picker-remote-grid";
import {Artigo} from "../../models/Artigo";
import {ArtigoDialog} from "./ce/artigo-dialog";
import {ArtigoComponent} from "./artigo-component";
import {ColConfigInterface} from "../../it-v-grid/interfaces";
import {ComposeDialog, ComposeDialogOptions} from "../../dialogs/compose-dialog";
import {TipoRegistoPlano} from "../../models/TipoRegistoPlano";
import {PlanoExpedicao} from "../../models/PlanoExpedicao";

@autoinject()
export class ArtigoListagem {
  private isBusy: boolean = false;
  private rg: RemoteGrid;

  /*
    colsOverride: ColConfigInterface[] = [
      // {colField: 'nvcTemplateAnexo', colHeaderClass: 'bg-danger', colProxyFilterType: "tags-choices", colProxy: true},
      // {colField: 'nvcTemplateAnexo', colHeaderClass: 'bg-danger', colRowTemplate: '<input class="avg-row-checkbox-100" v-selection="row" type="checkbox" checked.two-way="selected">', colType: "text"},
      {colField: 'nvcTemplateAnexo', colHeaderClass: 'bg-danger', colType: "selection", colHeaderName:"XPTO"},
      {colField: 'nvcCodigoPri', colHeaderClass: 'bg-success', colWidth: 30, colProxy: true, colProxyAlign: "right", colProxyFilterType: "distinct-rg-col-filter"},
      {colField: 'nvcArtigo', colHeaderClass: 'bg-warning', colWidth: 70, colProxy: true, colProxyFilterType: "distinct-rg-col-filter"},
      // {
      //   colField: 'nvcArtigo', colHeaderClass: 'bg-warning', colWidth: 70, colProxy: true, colProxyFilterType: "multi-choices", colProxyKeyVal: [
      //     {k: "Grupo 1", v: "Grupo 1"}, {k: "Grupo 2", v: "Grupo 2"}, {k: "Grupo 3", v: "Grupo 3"},
      //   ]
      // },
      {
        colField      : 'nvcDescricao', colProxy: true, colProxyFilterType: "remote-single-choices",
        colProxyKeyVal: [{k: "ap", v: 'api/cliente/list'}, {k: "key", v: "nvcClienteArmazem"}, {k: "val", v: "nvcClienteArmazem"}]
      },
      {colField: 'dtmDatains', colProxy: true, colProxyFilterType: "date-range"},
      {colField: 'idUtilizador2', colProxyFilterType: "number-strict"},
    ];
  */

  constructor(public app: GlobalServices, private parent: ArtigoComponent) { }

  canActivate() {
    if (!this.app.auth.can("App.ArtigosVer")) {
      this.app.notificationError("Não dispõe da permissao para visualizar a listagem de artigos");
      return false;
    }
    this.parent.activeSubRoute = "artigos";
    return true
  }

  /*
    //region TESTE
    aplicaFiltro() {
      let filtros: FilterObjectInterface[] = [
        {attribute: 'nvcCodigoPri', value: "teste", operator: '*'}
      ];
      this.rg.filter(filtros);
    }

    //endregion TESTE
  */

  // canDeactivate() {
  //   this.cleanup();
  //   return true;
  // }

  // cleanup() {
  //   try {
  //     if ($("#GridUtilizadores").hasClass("ej-grid"))
  //       $("#GridUtilizadores").ejGrid("destroy");
  //   } catch (err) {console.log("canDeactivate", err)}
  // }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[artigo-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          break;
        }
        case "REFRESH": {
          this.rg.refreshPage();
          this.isBusy = false;
          break;
        }
        // case "IMPORTAR-ARTIGO": {
        //   let informacao = `<div class="col-md-12"><h2>Importação de artigos do Primavera</h2><p>Na lista estão os artigos registados no Primavera que não têm equivalente local e cuja descrição é diferente de 'ANULADO'</p></div>`;
        //   return this.app.ds.open({viewModel: PickerRemoteGrid, model: {codigoTabela: "PriArtigo", informacao}, rejectOnCancel: false})
        //     .whenClosed(r => {
        //       if (!r.wasCancelled) {
        //         if (environment.debug) console.log("[configuracoes]", "Resultado Picker", r);
        //         let selecaoPriArtigo = (r.output as ActionFromRemoteGrid).rowRef;
        //         let artigo           = new Artigo({nvcArtigo: selecaoPriArtigo.artigo, nvcCodigoPri: selecaoPriArtigo.artigo, nvcDescricao: selecaoPriArtigo.descricao});
        //         this.doAction("EDITAR-ARTIGO", artigo)
        //           .then(r => {
        //             console.log("EDITAR-ARTIGO", r);
        //           });
        //       }
        //     })
        //     .catch(err => this.app.notificationErrorCompact(err))
        //     .then(r => (this.isBusy = false) || r);
        // }

        // case "EDITAR-ARTIGO": {
        //   if (environment.debug) console.log("[artigo-listagem]", "EDITAR-ARTIGO", "begin");
        //   return this.app.ds.open({viewModel: ArtigoDialog, model: {model: payload, app: this.app}, rejectOnCancel: false})
        //     .whenClosed(r => {
        //       if (!r.wasCancelled) {
        //         this.rg.refreshPage();
        //         return this.app.notificationShort("Artigo atualizado");
        //       }
        //       return false;
        //     })
        //     .catch(err => this.app.notificationErrorCompact(err))
        //     .then(r => (this.isBusy = false) || r);
        // }

        case "ARTIGO-GET": {
          let a = payload as Artigo;
          return this.app.api.getProcessed('api/artigo/get', {artigo: a.nvcArtigo})
            .then(r => Artigo.fromPOJSO(r))
            .then(art => this.doAction("ARTIGO-SIMULAR-GASTO", art))
            .catch(err => this.isBusy = this.app.notificationErrorCompact(err))
        }

        case "ARTIGO-SIMULAR-GASTO": {
          let artigo          = payload as Artigo;
          let pe              = new PlanoExpedicao({NvcArtigoTerminacaoNavigation: artigo, nvcCodigoEncomenda: "n/a"});
          let composeSettings = {
            modelo         : pe,
            containerModelo: null,
            invoker        : this,
            options        : new ComposeDialogOptions({
              title            : `Simular consumo ${pe}`,
              withDefaultFooter: false,
              trackChanges     : false,
              mainView         : '../routes/plano/ce/_registo-plano-listagem.html',
            }),
          };
          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : composeSettings
            })
            .whenClosed(r => {
              this.doAction("REFRESH", null);
            });
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[artigo-listagem]", "RG_CELL_CHANGE", payload);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // let artigo = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          // this.doAction("EDITAR-ARTIGO", artigo);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          if (environment.debug) console.log("[artigo-listagem]", "RG_ROW_CLICK", payload);
          // this.parse(payload.rowRef);
          let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          //let artigo           = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          let artigo           = Artigo.fromPOJSO(selecaoPriArtigo);
          this.doAction("EDITAR-ARTIGO", artigo);
          this.isBusy = false;
          break;
        }
        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[artigo-listagem]", "Acção DESCONHECIDA [artigo-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
