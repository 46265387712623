import {autoinject, bindable, bindingMode} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import {ImgEditDialog} from "./img-edit-dialog";

@autoinject()
export class ImgBrowser {
  @bindable({bindingMode: bindingMode.oneTime}) private debug: boolean = true;
  @bindable({bindingMode: bindingMode.oneTime}) private titulo: string = "Imagens";
  @bindable({bindingMode: bindingMode.twoWay}) private files: File[]   = [];
  @bindable({bindingMode: bindingMode.twoWay}) private existent: string[]   = [];

  // lista de url que um elemento <img> compreende
  private urls: string[] = [];

  //para selecção via índices
  private selectedIndexes: number[] = [];

  // input (escondido) para assistir à concatenação da lista de ficheiros,
  // escondido com "display: none" ou os eventos não funcionam
  private fileInput: HTMLInputElement;

  constructor(private app: GlobalServices) {}

  /**
   * observer da lista de ficheiros
   * @param newVal
   * @param oldVal
   */
  filesChanged(newVal: File[], oldVal: File[]) {
    if (this.debug) console.log("[img-browser]", "filesChanged", newVal, oldVal);
    // ...
    if (this.files) {
      this.urls = this.files.map(f => URL.createObjectURL(f));
      if (this.debug) {console.log("[img-browser]", "filesChanged", this.urls);}
    }
  }

  /**
   * Executa a selecção de um ficheiro
   * @param e
   * @param i
   */
  select(e: MouseEvent, i: number) {
    if (this.debug) console.log("[img-browser]", "select start", i, this.selectedIndexes);

    if (this.selectedIndexes.includes(i)) {
      if (e.ctrlKey) {
        this.selectedIndexes = this.selectedIndexes.filter(el => el != i);
      }
    } else {
      //se o CTRL está pressionado adiciona à seleção
      if (e.ctrlKey) {
        this.selectedIndexes = [...this.selectedIndexes, i];
      } else {
        this.selectedIndexes = [i];
      }
    }
    if (this.debug) console.log("[img-browser]", "select end", i, this.selectedIndexes);
  }

  /**
   * Força a abertura do selector de ficheiros associado ao <input type="file" multiple>
   */
  openFileDialog() {
    if (this.debug) console.log("[img-browser]", "openFileDialog", this);
    if (this.fileInput) {
      this.fileInput.click();
    }
  }

  /**
   * Reage ao change do input associado ao img-browser
   * @param $event
   */
  readFiles($event) {
    if (this.debug) console.log("[img-browser]", "readFiles", $event, $event.target);
    let target: HTMLInputElement = $event.target;

    if (target.files && target.files.length > 0) {
      //se pode acontecer a enumeração de listagem de ficheiros deve seleccionar-se um por defeito
      let newFiles = Array.from(target.files);
      this.files   = [...this.files, ...newFiles];

      //todo: garantir que apenas os novos ficheiros são carregados?
      // window.setTimeout(() => , 100);
      this.fileInput.files = null;
      this.fileInput.value = "";
    }
  }

  /**
   * edita um ficheiro partindo do seu índice
   * @param $index
   */
  editFile($index: number) {
    let f = this.files[$index];

    return this.app.ds.open({viewModel: ImgEditDialog, model: {file: f}})
      .whenClosed(r => {
        if (!r.wasCancelled) {
          //assumir o novo ficheiro
          this.files[$index] = r.output.file;
          //this.urls[$index]  = URL.createObjectURL(this.files[$index]);
          this.files = [...this.files];
        }
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }

  /**
   * Remove um ficheiro da lista, limpa a selecção
   * @param $event
   * @param $index
   */
  removeFile($event, $index) {
    $event.preventDefault();
    $event.stopPropagation();
    this.files           = this.files.filter((el, i) => i != $index);
    this.selectedIndexes = [];
  }

}
