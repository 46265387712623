import { HtmlCache } from '../interfaces';
import environment from "../../environment";

/**
 * This creates the markup for the rows for:
 * - pinned left and right
 * - main
 * - group
 *
 */
export class RowMarkup {
  private element: Element;
  private htmlCache: HtmlCache;
  private left: Element;
  private main: Element;
  private right: Element;
  private group: Element;

  private rowHeight: number;

  // private aggrLeft: Element;
  // private aggrMain: Element;
  // private aggrRight: Element;


  constructor(element: Element, htmlCache: HtmlCache, private bufferSize:number = 40) {
    this.element = element;
    this.htmlCache = htmlCache;
  }


  /**
   * Called when grid is created to set defaults, add event listners
   *
   */
  public init(rowHeight: number): void {
    this.rowHeight = rowHeight;
    this.updateInternalHtmlCache();
    this.generateRows();
  }



  /**
   * Generate the row markup
   * called when grid is created and not used again
   *
   */
  private generateRows(): void {

    let markupLeft = '';
    let markupMain = '';
    let markupRight = '';
    let markupGroup = '';

    let markupAggrLeft = '';
    let markupAggrMain = '';
    let markupAggrRight = '';


    // if(environment.debug) console.log("[VG rowmarkup]","generateRows", "BUFFERSIZE", this.bufferSize);
    for (let i = 0; i < this.bufferSize; i++) { // <- rows here will impact creation time
    // for (let i = 0; i < 60; i++) { // <- rows here will impact creation time

      let translateY = this.rowHeight * i;

      let avgRowMarkup = `<avg-row class="avg-row" style="height:${this.rowHeight}px; transform:translate3d(0px, ${translateY}px, 0px);" row="${i}"></avg-row>`;

      let avgRowMarkupGroup = `<avg-row class="avg-row-helper" style="height:${this.rowHeight}px; transform:translate3d(0px, ${translateY}px, 0px);" row="${i}"> </avg-row>`;
      // let avgRowMarkup = `<avg-row class="avg-row" style="height:${this.rowHeight}px; transform:translate3d(0px, ${translateY}px, 0px); z-index:5;" row="${i}"></avg-row>`;
      //
      // let avgRowMarkupGroup = `<avg-row class="avg-row-helper" style="height:${this.rowHeight}px; transform:translate3d(0px, ${translateY}px, 0px); z-index:5;" row="${i}"> </avg-row>`;


      markupLeft = markupLeft + avgRowMarkup;
      markupMain = markupMain + avgRowMarkup;
      markupRight = markupRight + avgRowMarkup;
      markupGroup = markupGroup + avgRowMarkupGroup;
    }


    //Render the empty rows to the different parts of the grid
    this.left.innerHTML = markupLeft;
    this.main.innerHTML = markupLeft;
    this.right.innerHTML = markupLeft;
    this.group.innerHTML = markupGroup;

    //todo: várias linhas de agregação? para isso precisa-se do numero de linhas (afinal a este ponto ainda não há bindings em efeito
    //todo: tratar a linha de aggregação como se de um header se tratasse
    // a )
    // {
    //   let avgRowMarkup = `<avg-row class="avg-row" style="height:${this.rowHeight}px;" row="0"></avg-row>`;
    //   markupAggrLeft = markupAggrLeft + avgRowMarkup;
    //   markupAggrMain = markupAggrMain + avgRowMarkup;
    //   markupAggrRight = markupAggrRight + avgRowMarkup;
    // }
    //
    // this.aggrLeft.innerHTML = markupAggrLeft;
    // this.aggrMain.innerHTML = markupAggrMain;
    // this.aggrRight.innerHTML = markupAggrRight;
  }



  /**
   * just adds the main html elements to class
   *
   */
  private updateInternalHtmlCache(): void {
    this.left = this.htmlCache.avg_content_left_scroll;
    this.main = this.htmlCache.avg_content_main_scroll;
    this.right = this.htmlCache.avg_content_right_scroll;
    this.group = this.htmlCache.avg_content_group_scroll;

    // //preciso mesmo disto?
    // this.aggrLeft = this.htmlCache.avg_aggregate_left_scroll;
    // this.aggrMain = this.htmlCache.avg_aggregate_main_scroll;
    // this.aggrRight = this.htmlCache.avg_aggregate_right_scroll;
  }
}
