// Auto-gerado com typewriter

import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

import {ArtigoFamilia} from './ArtigoFamilia';
import {Utilizador} from './Utilizador';

export class Familia extends BaseViewModel {
  //Propriedades (comentar as não importantes)
  public nvcFamilia: string       = null;
  public nvcDescricao: string     = null;
  public nvcObservacoes: string   = null;
  public nvcCodigoInterno: string = null;
  public bitActivo: boolean       = false;
  public bitFechado: boolean      = false;
  public bitVisivel: boolean      = false;
  public intOrdem: number         = null;
  public idUtilizadorI: number    = 0;
  public idUtilizadorA: number    = 0;
  public dtmDataI: string         = null;
  public _dtmDataI: Date          = new Date();
  public dtmDataA: string         = null;
  public _dtmDataA: Date          = new Date();
  public timestamp: number[]      = [];

  //relações
  public artigoFamilia: ArtigoFamilia[] = [];

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Familia>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): Familia {
    let model = new Familia();
    model.setState(obj);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): Familia[] {
    if (objs && Array.isArray(objs)) return objs.map(Familia.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Familia>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): Familia { return Familia.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `Familia`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Familia) => m.nvcFamilia).displayName("Família").required()
  .ensure((m: Familia) => m.nvcDescricao).displayName("Descrição").required()
  // .ensure((m: Familia) => m.nvcObservacoes).displayName("nvcObservacoes").required()
  .on(Familia);

