//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import environment from "../../environment";
import {ActionFromRemoteGrid, RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import {GuiaTransporte} from "../../models/GuiaTransporte";
import {SubrouteComponent} from "./subroute-component";
import {FilterObjectInterface} from "../../it-v-grid";

@autoinject()
export class GuiasTransporteLista {
  private isBusy: boolean = false;
  private rg: RemoteGrid;
  private initialFilter: FilterObjectInterface[] = [];

  constructor(public app: GlobalServices, private parent: SubrouteComponent) { }

  canActivate(p) {
    // esta verificação é desnecessária visto já ser feita no subroute-component
    // if (!this.app.auth.can("App.GuiasTransporteVer")) {
    //   this.app.notificationError("Não dispõe da permissao para visualizar a listagem de guias de transporte");
    //   return false;
    // }
    if(p && p.dia) {
      if(environment.debug) console.log("[guias-transporte-lista]","parametro de pré-filtro", p);
      this.initialFilter.push({attribute: "dtmDataExpedicao", value: p.dia, operator: "="})
    }
    this.parent.activeSubRoute = "guias-transporte-lista";
    return true
  }

  /**
   * Como há parametros de entrada na route é necessária esta configuração
   */
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload: any, context?: any) {
    if (environment.debug) console.log("[guias-transporte-lista]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          break;
        }

        case "REFRESH": {
          this.rg.refreshPage();
          return this.isBusy = false;
        }

        //region GUIAS-TRANSPORTE
        case 'NEW-GUIA-TRANSPORTE': {
          // let gt = new GuiaTransporte();
          // injecção de propriedades para teste, manter a linha acima
          let gt = new GuiaTransporte();
          if (payload) {
            gt.dtmDataExpedicao = payload;
            gt.dtmDataDocumento = payload;
          }
          return this.doAction("GUIA-TRANSPORTE-EDITAR-COMPOSE", gt);
        }
        //endregion

        case 'IMPRIME-GUIA-TRANSPORTE': {
          let pl = {id_guia_transporte: payload.idGuiaTransporte};
          return this.app.report("GuiaTransporte?tipo=pdf", pl, "Guia Transporte", true)
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.isBusy = false);
        }

        // case "GUIA-TRANSPORTE-EDITAR": {
        //   if (environment.debug) console.log("[guias-transporte-lista]", "EDITAR-GUIA", "begin", payload);
        //   let modelo = payload as GuiaTransporte;
        //   return this.app.api.getProcessed('api/guia-transporte/guia-transporte', {id: modelo.idGuiaTransporte})
        //     .then(r => GuiaTransporte.fromPOJSO(r))
        //     .then(c => this.doAction("GUIA-TRANSPORTE-EDITAR-COMPOSE", c))
        //     .then(x => {
        //       this.rg.refreshPage();
        //       //this.isBusy = false
        //     });
        // }
        //
        //region remote-grid related
        // case RemoteGridActions.RG_CELL_CHANGE: {
        //   if (environment.debug) console.log("[guias-transporte-lista]", "RG_CELL_CHANGE", payload);
        //   this.isBusy = false;
        //   break;
        // }
        // case RemoteGridActions.RG_ROW_DBL_CLICK: {
        //   if (environment.debug) console.log("[guias-transporte-lista]", "RG_ROW_CLICK", payload);
        //   let selectedGuia = (payload as ActionFromRemoteGrid).rowRef;
        //   let oGuia        = payload as GuiaTransporte;
        //   this.doAction("EDITAR-GUIA", oGuia);
        //   this.isBusy = false;
        //   break;
        // }
        // case RemoteGridActions.RG_ROW_CLICK: {
        //   this.isBusy = false;
        //   break;
        // }
        //endregion remote-grid related
        default: {
          if (environment.debug) console.log("[expedicao-tabela-virtual]", "Acção desconhecida [guias-transporte-lista]", action, payload);
          this.isBusy = false;
          if (!action.startsWith("RG_") && this.app.aureliaMain && typeof this.app.aureliaMain.doActionGlobal === "function") {
            return this.app.aureliaMain.doActionGlobal(action, payload, context || this);
          }
          break;
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
