// Auto-gerado com typewriter
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {RegistoPlano} from './RegistoPlano';
import {PlanoExpedicao} from './PlanoExpedicao';
import {ControloDiario} from "./ControloDiario";
import {RegistoControlo} from "./RegistoControlo";
import {RegistoEtiqueta} from "./RegistoEtiqueta";

//export classe
export class VRegistoPlanoPrgOriginal extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idRegistoPlano: number            = 0;
  public idPlanoExpedicao: number          = 0;
  public intDelta: number                  = 0;
  public dtmMovimento: string              = null;
  // public _dtmMovimento: Date                        = new Date();
  public intDeltaParcela: number           = 0;
  public intDeltaSaida: number             = 0;
  public intDeltaOriginal: number          = 0;
  public intQuantidadeControlada: number   = 0;
  public intQuantidadePorControlar: number = 0;
  public intQuantidadeGravada: number      = 0;
  public intQuantidadePorGravar: number    = 0;
  public intQuantidadeEtiquetada: number   = 0;
  public intQuantidadePorEtiquetar: number = 0;
  // public timestampA: number[]                       = [];
  public idPlanoExpedicaoNavigation: PlanoExpedicao;
  public idRegistoPlanoNavigation: RegistoPlano;
  public nvcArtigo: string;
  public nvcClienteArmazem: string;
  public nvcTipoGravacao: string;
  public nvcCodigoEncomenda: string;

  //condiçao dtmMovimento < hoje & intDelta > 0
  public bitFalta: boolean = false;

  //public intDisponivelParaControlar: number = 0;

  //relações
  public ControloDiario: ControloDiario = null;
  public idControloDiario:number;


  public RegistoControlo:RegistoControlo[] = [];
  public RegistoGravacao:RegistoControlo[] = [];
  public RegistoEtiqueta:RegistoEtiqueta[] = [];

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<VRegistoPlanoPrgOriginal>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): VRegistoPlanoPrgOriginal {
    let model = new VRegistoPlanoPrgOriginal();
    model.setState(obj);
    if(!model.intQuantidadePorControlar) model.intQuantidadePorControlar = model.intDeltaOriginal - model.intQuantidadeControlada;
    if(!model.intQuantidadePorGravar) model.intQuantidadePorGravar = model.intDeltaOriginal - model.intQuantidadeGravada;
    if(!model.intQuantidadePorEtiquetar) model.intQuantidadePorEtiquetar = model.intDeltaOriginal - model.intQuantidadeEtiquetada;

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): VRegistoPlanoPrgOriginal[] {
    if (objs && Array.isArray(objs)) return objs.map(VRegistoPlanoPrgOriginal.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<VRegistoPlanoPrgOriginal>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): VRegistoPlanoPrgOriginal { return VRegistoPlanoPrgOriginal.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `VRegistoPlanoPrgOriginal`;
  }
}
