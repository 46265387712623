//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";
import {RemoteGridActions} from "../../it-features/remote-grid/interfaces";
import {AdminRgComponent} from "./admin-rg-component";
import {confirmaActionTyped, confirmaActionTypedVmw, confirmaDeletionTyped} from "../../services/api-envelopes";
import {VmWrapper} from "../../models/VmWrapper";
import {ZzRemoteGrid} from "./models/ZzRemoteGrid";
import {ComposeDialog, ComposeDialogOptions} from "../../dialogs/compose-dialog";
import {ZzRemoteGridColumn} from "./models/ZzRemoteGridColumn";

@autoinject()
export class TabelasListagem {
  private isBusy: boolean = false;
  private rg: RemoteGrid;
  private edit: string    = null;

  /*
    colsOverride: ColConfigInterface[] = [
      // {colField: 'nvcTemplateAnexo', colHeaderClass: 'bg-danger', colProxyFilterType: "tags-choices", colProxy: true},
      // {colField: 'nvcTemplateAnexo', colHeaderClass: 'bg-danger', colRowTemplate: '<input class="avg-row-checkbox-100" v-selection="row" type="checkbox" checked.two-way="selected">', colType: "text"},
      {colField: 'nvcTemplateAnexo', colHeaderClass: 'bg-danger', colType: "selection", colHeaderName:"XPTO"},
      {colField: 'nvcCodigoPri', colHeaderClass: 'bg-success', colWidth: 30, colProxy: true, colProxyAlign: "right", colProxyFilterType: "distinct-rg-col-filter"},
      {colField: 'nvcArtigo', colHeaderClass: 'bg-warning', colWidth: 70, colProxy: true, colProxyFilterType: "distinct-rg-col-filter"},
      // {
      //   colField: 'nvcArtigo', colHeaderClass: 'bg-warning', colWidth: 70, colProxy: true, colProxyFilterType: "multi-choices", colProxyKeyVal: [
      //     {k: "Grupo 1", v: "Grupo 1"}, {k: "Grupo 2", v: "Grupo 2"}, {k: "Grupo 3", v: "Grupo 3"},
      //   ]
      // },
      {
        colField      : 'nvcDescricao', colProxy: true, colProxyFilterType: "remote-single-choices",
        colProxyKeyVal: [{k: "ap", v: 'api/cliente/list'}, {k: "key", v: "nvcClienteArmazem"}, {k: "val", v: "nvcClienteArmazem"}]
      },
      {colField: 'dtmDatains', colProxy: true, colProxyFilterType: "date-range"},
      {colField: 'idUtilizador2', colProxyFilterType: "number-strict"},
    ];
  */

  constructor(public app: GlobalServices, private parent: AdminRgComponent) { }

  canActivate(p) {
    if (p && p.edit) {
      this.edit = "" + p.edit;
    }
    // if (!this.app.auth.can("App.ArtigosVer")) {
    //   this.app.notificationError("Não dispõe da permissao para visualizar a listagem de artigos");
    //   return false;
    // }
    // this.parent.activeSubRoute = "artigos";
    return true;
  }

  // canDeactivate() {
  //   this.cleanup();
  //   return true;
  // }

  attached() {
    if (this.edit) {
      this.doAction("EDITAR", {tableName: this.edit});
    }
  }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  /**
   * doAction Loacl
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[tabelas-listagem]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          this.rg.refreshPage();
          this.isBusy = false;
          break;
        }
        case "IMPORTAR": {
          let vmw = new VmWrapper({payload: payload.tableName, confirmLevel: 0});

          if (environment.debug) console.log("[tabelas-listagem]", "vmw", vmw);
          return confirmaActionTypedVmw(this, vmw, 'api/it-remote-grid/zz/import-schema', false)
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.doAction("INIT"));
        }

        case "APAGAR": {
          let coluna = new ZzRemoteGrid({idZzRemoteGrid: payload.idZzRemoteGrid});

          return confirmaDeletionTyped(this, coluna, 'api/it-remote-grid/zz/remote-grid')
            .then(r => {
              if (r) {
                this.app.notificationSuccess("Ok");
                this.doAction("INIT");
              }
            })
        }

        case "EDITAR": {
          if (environment.debug) console.log("[tabelas-listagem]", "EDITAR", payload);
          return this.app.api.getProcessed('api/it-remote-grid/zz/remote-grid', {nome: payload.tableName})
            .then(r => ZzRemoteGrid.fromPOJSO(r))
            .then(z => this.doAction("EDITAR-TABELA", z));
        }

        case "EDITAR-TABELA": {
          if (environment.debug) console.log("[tabelas-listagem]", "EDITAR-TABELA", payload);
          let zrg             = payload as ZzRemoteGrid;
          let composeSettings = {
            modelo : zrg,
            invoker: this,
            options: new ComposeDialogOptions({
              title            : 'Editar a tabela ' + zrg.nome,
              withDefaultFooter: false,
              closeAfterSave   : false,
              mainView         : '../routes/admin-rg/ce/_rg-form.html',
              postUri          : 'api/it-remote-grid/zz/remote-grid'
            }),
          };

          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : composeSettings
            })
            .whenClosed(r => {
              //if (environment.debug) console.log("[expedicao-doc]", "ADICIONA-CONTROLO", r);
              if (!r.wasCancelled) {
                this.app.notificationSuccess("Ok");
                this.app.ds.closeAll();
                return this.doAction("INIT");
              } else {
                return this.isBusy = false;
              }
            });
        }

        case "CODIGO-TABELA": {
          this.isBusy         = false;
          let zrg             = payload as ZzRemoteGrid;
          let composeSettings = {
            modelo : zrg,
            invoker: this,
            options: new ComposeDialogOptions({
              title            : 'Ver código de r-g para ' + zrg.nome,
              withDefaultFooter: false,
              mainView         : '../routes/admin-rg/ce/_rg-code.html'
            }),
          };

          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : composeSettings
            })
            .whenClosed(r => {
              //
            });
        }

        case "ADICIONA-COLUNA-ACAO": {
          let tabela    = payload as ZzRemoteGrid;
          let coluna    = ZzRemoteGridColumn.fromPOJSO((tabela.ZzRemoteGridColumn[0]).stateToPOJSOSafeId());
          let nomeAccao = tabela.nome.toUpperCase();
          if (tabela.nome[0] === "v") {
            if (environment.debug) console.log("[tabelas-listagem]", "1st", tabela.nome[0]);
            nomeAccao = nomeAccao.charAt(0).toLowerCase() + tabela.nome.toUpperCase().slice(1);
          }
          coluna.ordinalPosition = 1;
          coluna.colRowTemplate  = `<button class="btn btn-xs btn-info" click.trigger="doAction('EDITAR-${nomeAccao}', rowRef)"><i class="fa fa-fw fa-edit"></i></button> <button class="btn btn-xs btn-danger" click.trigger="doAction('APAGAR-${nomeAccao}', rowRef)"><i class="fa fa-fw fa-trash"></i></button>`;
          coluna.colField        = 'accoes';
          coluna.colWidth        = 64;
          coluna.colHeaderName   = 'Ações';
          coluna.bundles         = 'Accoes';
          tabela.ZzRemoteGridColumn.push(coluna);
          this.app.notificationWarning("Alterações efetuadas, é necessário gravar");
          break;
        }

        //todo: passar para projeto base
        case "DUPLICAR-COLUNA": {
          if (environment.debug) console.log("[tabelas-listagem]", "ADICIONAR-COLUNA", payload);
          let tabela = payload.rg as ZzRemoteGrid;
          let coluna = ZzRemoteGridColumn.fromPOJSO((payload.col as ZzRemoteGridColumn).stateToPOJSOSafeId());
          tabela.ZzRemoteGridColumn.push(coluna);
          return this.doAction("EDITAR-COLUNA-COMPOSE", coluna)
        }

        case "EDITAR-COLUNA": {
          if (environment.debug) console.log("[tabelas-listagem]", "EDITAR-COLUNA", payload);
          let zrgc            = payload as ZzRemoteGridColumn;
          let composeSettings = {
            modelo : zrgc,
            invoker: this,
            options: new ComposeDialogOptions({
              title            : 'Editar a coluna ' + zrgc.colField,
              withDefaultFooter: false,
              mainView         : '../routes/admin-rg/ce/_rg-col-form.html',
              //postUri          : 'api/it-remote-grid/zz/remote-grid'
            }),
          };

          return this.app.ds.open({
              viewModel: ComposeDialog,
              model    : composeSettings
            })
            .whenClosed(r => {
              //if (environment.debug) console.log("[expedicao-doc]", "ADICIONA-CONTROLO", r);
              if (!r.wasCancelled) {
                this.app.notificationWarning("Alterações efetuadas, é necessário gravar");
              }
              this.isBusy = false;
            });
        }

        case "APAGAR-COLUNA": {
          let tabela = payload.rg as ZzRemoteGrid;
          let coluna = payload.col as ZzRemoteGridColumn;

          return confirmaDeletionTyped(this, coluna, 'api/it-remote-grid/zz/remote-grid-column')
            .then(r => {
              if (r) {
                this.app.notificationSuccess("Ok");
                tabela.ZzRemoteGridColumn = tabela.ZzRemoteGridColumn.filter(el => el != coluna);
              }
            })
        }

        //region remote-grid related
        case RemoteGridActions.RG_CELL_CHANGE: {
          if (environment.debug) console.log("[tabelas-listagem]", "RG_CELL_CHANGE", payload);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // let artigo = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          // this.doAction("EDITAR-ARTIGO", artigo);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_CLICK: {
          if (environment.debug) console.log("[tabelas-listagem]", "RG_ROW_CLICK", payload);
          // this.parse(payload.rowRef);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // //let artigo           = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          // let artigo           = Artigo.fromPOJSO(selecaoPriArtigo);
          // this.doAction("EDITAR-ARTIGO", artigo);
          this.isBusy = false;
          break;
        }
        case RemoteGridActions.RG_ROW_DBL_CLICK: {
          if (environment.debug) console.log("[tabelas-listagem]", "RG_ROW_DBL_CLICK", payload);
          // this.parse(payload.rowRef);
          // let selecaoPriArtigo = (payload as ActionFromRemoteGrid).rowRef;
          // //let artigo           = new Artigo({nvcArtigo: selecaoPriArtigo.nvcArtigo, nvcCodigoPri: selecaoPriArtigo.nvcCodigoPri, nvcDescricao: selecaoPriArtigo.nvcDescricao});
          // let artigo           = Artigo.fromPOJSO(selecaoPriArtigo);
          // this.doAction("EDITAR-ARTIGO", artigo);
          this.isBusy = false;
          break;
        }
        //endregion remote-grid related
        default: {
          if (environment.debug) console.error("[tabelas-listagem]", "Acção DESCONHECIDA [tabelas-listagem]", action);
          // if (environment.debug)  this.app.notificationErrorCompact("Acção desconhecida: " + action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }
}
