import {autoinject, bindable, bindingMode} from "aurelia-framework";
//monkey patch!!!
//https://github.com/flatpickr/flatpickr/issues/1102
import * as _flatpickr from 'flatpickr';

const flatpickr: any = (<any>_flatpickr).default || _flatpickr;

//more monkeys to come!
import * as _confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import {dateISOString, dateTimeISOString} from "../../utils/ItNumeric";

const confirmDatePlugin: any = (<any>_confirmDatePlugin).default || _confirmDatePlugin;

/**
 * Um CE com choices de base que se comporta como o select2 mas bem.
 */
@autoinject()
export class FlatPickr {
  @bindable debug: boolean                                                                = true;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string                       = "";
  //quando passado a true sobrepoe o config para usar um date time picker
  @bindable({defaultBindingMode: bindingMode.oneTime}) withTime: boolean | string         = false;
  //os botões extra devem ser desenhados?
  @bindable({defaultBindingMode: bindingMode.oneTime}) withExtraButtons: boolean | string = true;
  //@bindable modelValue: any[]       = [];
  @bindable config: _flatpickr.default.Options.Options                                    = {
    //locale: Portuguese
  };

  //o elemento HTML
  private inputElem: HTMLInputElement = null;

  //Instância do flatpickr
  private instance: _flatpickr.default.Instance;
  private buttonsMonth: HTMLButtonElement[] = [];

  /**
   * Handles click events on plugin's button.
   * Editado para propagar alteração ao custom-element
   */
  private onMonthClick = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();

    if (this.debug) console.log("[flat-pickr]", "onMonthClick", this, event);

    const target = event.target as HTMLButtonElement;
    if (target.tagName.toLowerCase() !== 'button') {
      return;
    }

    const index = parseInt(target.dataset.index, 10);

    let newDate = new Date();
    newDate.setDate(1);
    newDate.setMonth(index);
    newDate.setFullYear(this.instance.currentYear);

    if (this.instance) {
      this.instance.setDate(newDate);
      if (this.withTime) {
        this.value = dateTimeISOString(this.instance.latestSelectedDateObj);
      } else {
        this.value = dateISOString(this.instance.latestSelectedDateObj);
      }
    }
  };

  /**
   * Handle click para a data de HOJE
   * Editado para propagar alteração ao custom-element
   */
  private onTodayClick = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();

    if (this.debug) console.log("[flat-pickr]", "onTodayClick", this, event);

    const target = event.target as HTMLButtonElement;
    if (target.tagName.toLowerCase() !== 'button') {
      return;
    }

    let newDate = new Date();
    if (this.instance) {
      this.instance.setDate(newDate);
      if (this.withTime) {
        this.value = dateTimeISOString(this.instance.latestSelectedDateObj);
      } else {
        this.value = dateISOString(this.instance.latestSelectedDateObj);
      }
    }
  };

  // /**
  //  * Handles key down events on plugin's button.
  //  */
  // private onKeyDown(event: KeyboardEvent) {
  //   const target = event.target as HTMLButtonElement;
  //   if (event.key !== 'Tab' || target.tagName.toLowerCase() !== 'button') {
  //     return;
  //   }
  //
  //   if ((event.shiftKey && !target.previousSibling) || (!event.shiftKey && !target.nextSibling)) {
  //     event.preventDefault();
  //     fp.element.focus();
  //   }
  // }

  /**
   * binder, corre após instanciação e antes de attached
   * @param a
   * @param b
   */
  bind(a, b) {
    if (this.debug) console.log("[flat-pickr]", "bind", a, b);
    //this.config.disableMobile = true;
    if (this.withTime && this.withTime !== "false") {
      this.config.enableTime    = true;
      this.config.time_24hr     = true;
      this.config.enableSeconds = true;

      this.config.plugins = [new confirmDatePlugin({})]
    }

    if (this.withExtraButtons && this.withExtraButtons !== "false") {
      //adaptação em cima do joelho de shortcut-buttons-flatpickr
      //https://github.com/jcsmorais/shortcut-buttons-flatpickr
      this.config.onReady = (a, b, fp) => {
        let wrapperMonth: HTMLElement;
        if (this.debug) console.log("[flat-pickr]", "onReady", a, b, fp);

        //inicializar os botões de meses
        //todo: será positivo adicionar restrições à selecção mediante o intervalo de datas seleccionável?
        this.buttonsMonth = fp.l10n.months.shorthand.map((el, i) => {
          const btn = document.createElement("button");
          btn.classList.add('it-flatpickr-button');
          btn.textContent   = el;
          btn.dataset.index = String(i);
          btn.onclick       = this.onMonthClick;
          btn.style.flex    = "1";
          return btn;
        });

        /*
        //se algum dia se quiser substituir o mês por um dropdown
        let elems = fp.calendarContainer.getElementsByClassName("cur-month");
        console.log(elems);
        */

        if (fp && fp.calendarContainer) {
          let innerContainer = fp.calendarContainer.getElementsByClassName("flatpickr-innerContainer");
          if (this.debug) console.log("[flat-pickr]", "innerContainer", innerContainer);

          //cria wrapper para os botões extra
          wrapperMonth = document.createElement('div');
          wrapperMonth.classList.add('it-flatpickr-wrapper-month', 'light');

          //1a linha de botões
          const buttons1 = document.createElement('div');
          buttons1.classList.add('it-flatpickr-buttons');
          buttons1.style.display = "flex";
          this.buttonsMonth.filter((el, i) => (i < 6)).forEach(el => buttons1.appendChild(el));
          wrapperMonth.appendChild(buttons1);

          //2a linha de botões
          const buttons2 = document.createElement('div');
          buttons2.classList.add('it-flatpickr-buttons');
          buttons2.style.display = "flex";
          this.buttonsMonth.filter((el, i) => (i >= 6)).forEach(el => buttons2.appendChild(el));
          wrapperMonth.appendChild(buttons2);

          innerContainer.item(0).parentElement.insertBefore(wrapperMonth, innerContainer.item(0));

          //botão HOJE
          let wrapperToday: HTMLElement = document.createElement("div");
          wrapperToday.classList.add('it-flatpickr-wrapper-today');
          wrapperToday.style.display = "flex";

          const btnToday = document.createElement("button");
          btnToday.classList.add('it-flatpickr-button');
          btnToday.textContent = "Hoje";
          btnToday.onclick     = this.onTodayClick;
          btnToday.style.flex  = "1";
          wrapperToday.appendChild(btnToday);

          innerContainer.item(0).parentElement.insertBefore(wrapperToday, innerContainer.item(0).nextSibling);
          //innerContainer.item(0).parentElement.insert(wrapper, innerContainer.item(0));
        }
      };
    }
  }

  attached() {
    if (this.debug) {
      console.log("[flat-pickr]", "attached");
      window["fp"] = this.instance;
    }
    this.instance = flatpickr(this.inputElem, this.config);
  }

  //o value é um array de strings com as keys das opções selecionadas muda em duas ocasiões
  //1. inicialização do plugin
  //2. escolha de um elemento
  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("[flat-pickr]", "valueChanged", newValue, oldValue);
    if (newValue) {
      if (!this.instance) {
        if (this.debug) console.log("[flat-pickr]", "valueChanged", "inicialização do plugin");
      } else {
        if (this.debug) console.log("[flat-pickr]", "valueChanged", "escolha de um elemento");
        this.instance.setDate(newValue);
      }
    }
  }
}
