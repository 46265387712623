import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from './BaseViewModel';
import {VmWrapper} from './VmWrapper';

export class CacheTexto extends BaseViewModel {
    public idCacheTexto : number = 0 ;
    public nvcValor : string  = '' ;
    public nvcTipo : string  ;
    public nvcGrupo : string  ;
    public nvcDescricao : string  ;
    public intOrdem : number  ;
    public bitActivo : boolean  ;
    public bitVisivel : boolean  ;
    public nvcCa1 : string  ;
    public nvcCa2 : string  ;
    public nvcCa3 : string  ;
    public idUtilizadorI : number = 0 ;
    public idUtilizadorA : number = 0 ;
    public dtmDataI : string  ;
    public dtmDataA : string = '' ;
    public timestamp : string  ;


    //region estado
        /**
         * Construtor para inicializador à lá c#
         * @param fields
         */
        public constructor(fields?: Partial<CacheTexto>,) {
            super();
            if (fields) Object.assign(this, fields);
        }
    
        public static fromPOJSO(obj: any): CacheTexto {
            let model = new CacheTexto();
            model.setState(obj);
            return model;
        }
        
        public static multipleFromPOJSO(objs: any | any[]): CacheTexto[] {
            if (objs && Array.isArray(objs)) return objs.map(CacheTexto.fromPOJSO);
            return [];
        }
    
        public stateToPOJSO() {
            return this.getState();
        }
    
        public wrapIt(cl?: number) {
            return new VmWrapper<CacheTexto>({payload: this, confirmLevel: (+cl || 0)});
        }
    
        public cloneInstance(): CacheTexto { return CacheTexto.fromPOJSO(this.stateToPOJSO());}    
        
        public stateToPOJSOSafeId(): any {
            let state = this.getState();
            //no fundo apagam-se os ids (recursivamente)
            if(state.id) state.id = 0;
            return state;
        }
        
        //endregion estado
    
        public toString(){
            return `CacheTexto`;
        }
    
}
//region aurelia-validation (comentar o que não interessa)
ValidationRules
    .ensure((m: CacheTexto) => m.idCacheTexto).displayName('idCacheTexto').required()
    .ensure((m: CacheTexto) => m.nvcValor).displayName('nvcValor').required()
    .ensure((m: CacheTexto) => m.nvcTipo).displayName('nvcTipo').required()
    .ensure((m: CacheTexto) => m.nvcGrupo).displayName('nvcGrupo').required()
    .ensure((m: CacheTexto) => m.nvcDescricao).displayName('nvcDescricao').required()
    .ensure((m: CacheTexto) => m.intOrdem).displayName('intOrdem').required()
    .ensure((m: CacheTexto) => m.bitActivo).displayName('bitActivo').required()
    .ensure((m: CacheTexto) => m.bitVisivel).displayName('bitVisivel').required()
    .ensure((m: CacheTexto) => m.nvcCa1).displayName('nvcCa1').required()
    .ensure((m: CacheTexto) => m.nvcCa2).displayName('nvcCa2').required()
    .ensure((m: CacheTexto) => m.nvcCa3).displayName('nvcCa3').required()
    .ensure((m: CacheTexto) => m.idUtilizadorI).displayName('idUtilizadorI').required()
    .ensure((m: CacheTexto) => m.idUtilizadorA).displayName('idUtilizadorA').required()
    //.ensure((m: CacheTexto) => m.dtmDataI).displayName('dtmDataI').required()
    .ensure((m: CacheTexto) => m.dtmDataA).displayName('dtmDataA').required()
    //.ensure((m: CacheTexto) => m.timestamp).displayName('timestamp').required()
    .on(CacheTexto);
//endregion
