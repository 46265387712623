/**
 * Created by herna on 4/30/2017.
 */
import {inject, noView} from 'aurelia-framework';
import {EventAggregator, Subscription} from "aurelia-event-aggregator";
import * as Humane from 'humane-js';

@noView()
@inject(EventAggregator, Humane)
export class Notifications {
  protected subscriptions: Subscription[] = [];
  protected eventAggregator: EventAggregator;
  protected humane: Humane;

  constructor(ea: EventAggregator, h: Humane) {
    //console.log(ea, h);
    this.eventAggregator = ea;
    this.humane          = h;
  }

  attached() {
    this.subscriptions = [
      this.eventAggregator.subscribe('notification:success', msg => {
        // console.log("notification", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-success", clickToClose: true});
      }),
      this.eventAggregator.subscribe('notification:short', msg => {
        // console.log("notification", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-success", timeout: 1000, clickToClose: true});
      }),
      this.eventAggregator.subscribe('notification:error', msg => {
        // console.log("notification:error", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-error", timeout: 7000, clickToClose: true, waitForMove: true});
      }),
      this.eventAggregator.subscribe('notification:warning', msg => {
        // console.log("notification:error", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-warning", timeout: 7000, clickToClose: true, waitForMove: true});
      }),
      this.eventAggregator.subscribe('notification:info', msg => {
        // console.log("notification:error", msg, this);
        this.humane.log(`${msg}`, {addnCls: "humane-flatty-info", timeout: 7000, clickToClose: true, waitForMove: true});
      })
    ];
  }

  detached() {
    this.subscriptions.forEach(s => s.dispose());
    this.subscriptions = [];
  }
}

// export const success = "notification:success";