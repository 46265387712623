import {autoinject} from 'aurelia-framework';

@autoinject()
export class FocoCustomAttribute {

  /**
   * Atributo que requer o foco no elemento onde foi aplicado
   * e.g. <input value.bind="xpto" foco>
   * @param element
   */
  constructor(private element: Element) {
    //console.log("FocoCustomAttribute", this.element);
  }

  attached() {
    //console.log("FocoCustomAttribute", "attached", this.element);

    //se é de um <input> que se trata efetua o focus diretamente
    if (this.element.tagName.toLowerCase() === "input") {
      (this.element as any).focus();
    } else {
      //se não tenta fazer no primeiro <input> encontrado
      const inputs = this.element.getElementsByTagName("input");
      if (inputs.length > 0) {
        inputs.item(0).focus();
      }
    }
  }
}

