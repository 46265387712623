import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";

@autoinject()
export class ConfirmacaoPromptDialog {
  app: GlobalServices;
  public resposta: string;
  protected controller: DialogController;
  protected mensagem: string = "Evento a confirmar";
  protected tipo: string     = "resposta";
  private ok: string         = "Submeter";
  private cancel: string     = "Cancelar";
  private forma: string      = "textbox";

  private inputElem: HTMLInputElement;

  constructor(controller: DialogController, app: GlobalServices) {
    this.controller = controller;
    this.app        = app;
    //console.log("lodash test", _s.camelCase("uma-string-que-vai passar a camel case"));
  }

  canDeactivate() {
    //$(".js-confirm-dialog").off("keypress")
    // if (this.resposta == null || this.resposta.length == 0) {
    //   this.app.notificationError("É necessária a introdução da " + this.tipo);
    //   return false;
    // }
    return true;
  }

  activate(m: { msg: string, ok?: string, cancel?: string, tipo?: string, forma?: string }) {
    this.mensagem = m.msg;

    if (m.ok) this.ok = m.ok;
    if (m.cancel) this.cancel = m.cancel;

    if (m.tipo) this.tipo = m.tipo;
    if (m.forma) this.forma = m.forma;

    if (!["textbox", "input"].includes(this.forma)) this.forma = "textbox";

    $("#confirm-btn").focus();
  }

  attached(){
    this.inputElem && this.inputElem.focus();
    this.inputElem.onkeyup = (e) =>{
      if(e.key == "Enter") {
        this.confirma();
      }
    }
  }

  detached() {
    this.inputElem.onkeyup = null;
  }

  confirma() {
    if (this.resposta) {
      this.resposta = this.resposta.trim();
      if (this.resposta.length > 0) {
        this.controller.ok({resposta: this.resposta});
        return;
      }
    }
    this.app.notificationError("É necessária a introdução: " + this.tipo);
  }

  cancela() {
    this.controller.cancel();
  }
}
