// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {Utilizador} from './Utilizador';
import {Artigo} from './Artigo';

//export classe
export class EtiquetaAnexoArtigo extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idEtiquetaAnexoArtigo: number = 0;
  public nvcArtigo: string             = null;
  public nvcCodigoEncomenda: string    = null;
  public nvcDescricaoEtiqueta: string  = "";
  public nvcConteudoQr: string         = null;
  public intQuantidade: number         = null;
  public dtmEtiqueta: string;
  // public _dtmEtiqueta: Date                  = new Date();
  public bitActivo: boolean            = false;
  // public idUtilizadorI: number               = 0;
  // public idUtilizadorA: number               = 0;
  // public dtmDataI: string                    = null;
  // // public _dtmDataI: Date                     = new Date();
  // public dtmDataA: string                    = null;
  // // public _dtmDataA: Date                     = new Date();
  // public timestamp: number[]                 = [];
  // public idUtilizadorANavigation: Utilizador = null;
  // public idUtilizadorINavigation: Utilizador = null;
  public nvcArtigoNavigation: Artigo = null;

  //EXTRA
  // este campo serve de auxiliar para efeitos secundários (nomeadamente captação do HTML gerado pelo QUILL e sua manipulação)
  // private _descricao: string;
  //
  // @computedFrom("_descricao")
  // public get descricao(): string {
  //   // if (this.nvcDescricaoEtiqueta) {
  //   //   if(!this._descricao) this._descricao = this.nvcDescricaoEtiqueta;//.replace(/<br>/gi, "\n");
  //   // }
  //   //   return this.nvcDescricaoEtiqueta.replace(/<br>/gi, "\n");
  //   // else return "\n";
  //   return this._descricao;
  // }
  //
  // public set descricao(str) {
  //   this._descricao = str;
  //   this.setDescricao(str);
  // }

  @computedFrom("nvcConteudoQr")
  public get qrCode(): string {
    if (this.nvcConteudoQr)
      return `http://qr.iis.itech.pt/api/qr/g?data=${this.nvcConteudoQr}`;
    return '/img/j3lp.png';
  }

  public setArtigoRg(rowRef:Artigo) {
    this.nvcArtigo = rowRef.nvcArtigo;
  }

  // public setDescricao(str: string) {
  //   this.nvcDescricaoEtiqueta = "" + str.replace(/\n/g, "<br>").replace(/<p>/gi, "").replace(/<\/p>/gi, "<br>").replace(/<br><br>/gi, "<br>");
  //   this.nvcDescricaoEtiqueta = str;
  // }



  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<EtiquetaAnexoArtigo>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): EtiquetaAnexoArtigo {
    let model = new EtiquetaAnexoArtigo();
    model.setState(obj);
    if (obj.intQuantidade === null) model.intQuantidade = null;

    // if (obj.nvcDescricaoEtiqueta) {
    //   model.setDescricao(obj.nvcDescricaoEtiqueta);
    //   model.descricao = model.nvcDescricaoEtiqueta;
    // }
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): EtiquetaAnexoArtigo[] {
    if (objs && Array.isArray(objs)) return objs.map(EtiquetaAnexoArtigo.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let obj = this.getState();
    if (this.intQuantidade === null) obj.intQuantidade = null;
    return obj;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<EtiquetaAnexoArtigo>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): EtiquetaAnexoArtigo { return EtiquetaAnexoArtigo.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `EtiquetaAnexoArtigo`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: EtiquetaAnexoArtigo) => m.idEtiquetaAnexoArtigo).displayName("idEtiquetaAnexoArtigo").required()
  .ensure((m: EtiquetaAnexoArtigo) => m.nvcArtigo).displayName("nvcArtigo").required()
  .ensure((m: EtiquetaAnexoArtigo) => m.nvcCodigoEncomenda).displayName("nvcCodigoEncomenda").required()
  .ensure((m: EtiquetaAnexoArtigo) => m.nvcDescricaoEtiqueta).displayName("nvcDescricaoEtiqueta").required()
  // .ensure((m: EtiquetaAnexoArtigo) => m.nvcConteudoQr).displayName("nvcConteudoQr").required()
  // .ensure((m: EtiquetaAnexoArtigo) => m.intQuantidade).displayName("intQuantidade").required()
  // .ensure((m: EtiquetaAnexoArtigo) => m.dtmEtiqueta).displayName("dtmEtiqueta").required()
  // .ensure((m: EtiquetaAnexoArtigo) => m.bitActivo).displayName("bitActivo").required()
  .on(EtiquetaAnexoArtigo);
