// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {ControloPlanoExpedicao} from './ControloPlanoExpedicao';
import {Operador} from './Operador';
import {Utilizador} from './Utilizador';
import {OperadorDerivation} from "./BaseViewModelDerivations";
//export classe
export class DocumentoControlo extends OperadorDerivation {
    //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
    public idDocumentoControlo : number = 0;
    public intNumero : number = 0;
    public nvcEstado : string = null;
    public nvcDescricao : string = null;
    public nvcObservacoes : string = null;
    public nvcBuffer : string = null;
    public dtmDataInicio:string = null;
    public _dtmDataInicio:Date = new Date();
    public dtmDataFim:string = null;
    public _dtmDataFim:Date = new Date();
    public dtmDataExpedicao:string = null;
    public _dtmDataExpedicao:Date = new Date();
/*
    public idOperador : number = null;
    public idOperadorTemp : number = null;
    public nvcNomeOperadorTemp : string = null;
    public idUtilizadorI : number = 0;
    public idUtilizadorA : number = 0;
    public dtmDataI:string = null;
    public _dtmDataI:Date = new Date();
    public dtmDataA:string = null;
    public _dtmDataA:Date = new Date();
    public timestamp : number[] = [];
    public controloPlanoExpedicao : ControloPlanoExpedicao[] = [];
    public idOperadorNavigation : Operador = null;
    public idUtilizadorANavigation : Utilizador = null;
    public idUtilizadorINavigation : Utilizador = null;
*/
    //region estado
    /**
     * Construtor para inicializador à lá c#
     * @param fields
     */
    public constructor(fields?: Partial<DocumentoControlo>,) {
        super();
        if (fields) Object.assign(this, fields);
    }

    public static fromPOJSO(obj: any): DocumentoControlo {
        let model = new DocumentoControlo();
        model.setState(obj);
        return model;
    }
    
    public static multipleFromPOJSO(objs: any | any[]): DocumentoControlo[] {
        if (objs && Array.isArray(objs)) return objs.map(DocumentoControlo.fromPOJSO);
        return [];
    }

    public stateToPOJSO() {
        return this.getState();
    }

    public wrapIt(cl?: number) {
        return new VmWrapper<DocumentoControlo>({payload: this, confirmLevel: (+cl || 0)});
    }

    public cloneInstance(): DocumentoControlo { return DocumentoControlo.fromPOJSO(this.stateToPOJSO());}    
    //endregion

  public toString() {
    return `Controlo nº ${this.intNumero} (${this.nvcEstado}) - ${this.nvcDescricao}`;
  }
}
// aurelia-validation (comentar o que não interessa)
ValidationRules 
    // .ensure((m: DocumentoControlo) => m.idDocumentoControlo).displayName("idDocumentoControlo").required()
    .ensure((m: DocumentoControlo) => m.intNumero).displayName("intNumero").required()
    .ensure((m: DocumentoControlo) => m.nvcEstado).displayName("nvcEstado").required()
    .ensure((m: DocumentoControlo) => m.nvcDescricao).displayName("nvcDescricao").required()
    // .ensure((m: DocumentoControlo) => m.nvcObservacoes).displayName("nvcObservacoes").required()
    // .ensure((m: DocumentoControlo) => m.nvcBuffer).displayName("nvcBuffer").required()
    .ensure((m: DocumentoControlo) => m.dtmDataInicio).displayName("dtmDataInicio").required()
    .ensure((m: DocumentoControlo) => m.dtmDataFim).displayName("dtmDataFim").required()
    .ensure((m: DocumentoControlo) => m.dtmDataExpedicao).displayName("dtmDataExpedicao").required()
  .ensure((m: DocumentoControlo) => m.idOperador).displayName("Operador").required()
  .on(DocumentoControlo);
