//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import environment from "../environment";
import {GlobalServices} from "../services/global-services";
import {ConfirmacaoDialog} from "../dialogs/confirmacao-dialog";
import {DateFilterTemplateGreaterEqual, identityNumericFilter, IdentityStringFilter} from "../utils/EjHelper";

@autoinject()
export class ListagemLogs {
  public app: GlobalServices;

  registoVisualizacao: LogRecord;

  constructor(g: GlobalServices) { this.app = g;}

  canDeactivate() {
    this.cleanup();
    return true;
  }

  cleanup() {
    try {
      if ($("#GridLogs").hasClass("ej-grid"))
        $("#GridLogs").ejGrid("destroy");
    } catch (err) {console.log("canDeactivate", err)}
  }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate() {
    console.log("ListagemLogs activate!");
  }

  attached() {
    console.log("ListagemLogs Attached!");
    this.refresh();
  }

  refresh() {
    this.cleanup();
    this.app.auth.promisedActiveToken()
      .then(token => {
        let dataSource = ej.DataManager({
          //done: injetar a localização do serviço
          url        : environment.endpoint + "api/ejg/Log",
          crossDomain: true,
          //requestType : "post",
          adaptor    : new ej.WebMethodAdaptor(),
          headers    : [{Authorization: `Bearer ${token}`}]
        });

        $("#GridLogs").ejGrid({
          locale        : "pt-PT",
          dataSource    : dataSource,
          allowPaging   : true,
          pageSettings  : {pageSize: 20},
          allowSorting  : true,
          allowSearching: true,
          allowFiltering: true,
          // allowTextWrap   : true,
          // textWrapSettings: {wrapMode: "both"},
          cssClass      : "grid-picker",
          filterSettings: {
            showFilterBarStatus: true,
            // filterType         : "menu"
          },
          sortSettings  : {sortedColumns: [{field: "id_log", direction: "descending"}]},

          recordClick   : (args) => {
            console.log("Click", args);
            // this.showTabelaPermissoes(+args.data.id_utilizador);
            if (args.cellIndex === 0) {
              this.deleteLog(+args.data.id_log);
            } else
              this.loadLogRecord(args.data);
          },
          //@formatter:off
          columns       : [
{headerText: "Acções"      , width:  20                       , textAlign: "center"               , template: '<a><i class="fa fa-trash"></i></a>'} ,
{field: "id_log"       , headerText: "Código"             , width:  20                        , textAlign: "center"                             , type: "number"                            , filterBarTemplate: identityNumericFilter} ,
{field: "nvc_username" , headerText: "Nome de Utilizador" , width:  50                        , type:"string"                                   , filterBarTemplate: IdentityStringFilter } ,
{field: "nvc_evento"   , headerText: "Evento"             , width: 80                         , type:"string"                                   , filterBarTemplate: IdentityStringFilter   , tooltip: "{{:value }}"                    , clipMode: ej.Grid.ClipMode.EllipsisWithTooltip} ,
{field: "nvc_payload"  , headerText: "Email"              , width:  500                       , type:"string"                                   , filterBarTemplate: IdentityStringFilter   , tooltip: "{{:value }}"                    , clipMode: ej.Grid.ClipMode.EllipsisWithTooltip} ,
{field: "dtm_datains"  , headerText: "Data"               , headerTooltip:"Data do Movimento" , width:  70                                      , textAlign: "center"                       , format: "{0:yyyy-MM-dd HH:mm}"            , type: "date"                                    , filterBarTemplate: DateFilterTemplateGreaterEqual} ,
]
          //@formatter:on
        });
      });
  }

  deleteLog(id: number) {
    this.app.api
      .deleteVerb('api/auth/log/' + id, null, {})
      .then(r => this.app.api.processResponse(r))
      .then(_ => {
        this.registoVisualizacao = null;
        $("#GridLogs").ejGrid("refreshContent");
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }

  deleteLogRecord(id: number) {
    let dialogContent = `<h5>O registo de Log será apagado</h5>`;
    return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
      .whenClosed(resp => {
        if (!resp.wasCancelled) {
          this.deleteLog(id);
        } else {}
      });
  }

  loadLogRecord(data: LogRecord) {
    this.registoVisualizacao = data;
  }
}

export interface LogRecord {
  id_log: number,
  nvc_username: string,
  nvc_evento: string,
  nvc_payload: string,
  dtm_datains: string
}
