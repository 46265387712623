//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";
import {identityNumericFilter, IdentityStringFilter} from "../utils/EjHelper";
import {Router} from "aurelia-router";

@autoinject()
export class ClienteComponent {
  public app: GlobalServices;

  constructor(g: GlobalServices, protected router:Router) { this.app = g;}

  activate() {
    //this.listaEpocas();
  }

  canDeactivate() {
    try {$("#gridpagina").ejGrid("destroy");} catch (err) {console.log(err)}
    return true;
  }

  attached() {
    //console.log("activate!");
    this.app.auth.promisedActiveToken()
      .then(token => {
        let dataSource = ej.DataManager({
          //done: injetar a localização do serviço
          url        : environment.endpoint + "api/ejg/ClienteArmazem",
          crossDomain: true,
          //requestType : "post",
          adaptor    : new ej.WebMethodAdaptor(),
          headers    : [{Authorization: `Bearer ${token}`}]
        });

        $("#gridpagina").ejGrid({
          locale          : "pt-PT",
          dataSource      : dataSource,
          allowPaging     : true,
          pageSettings    : {pageSize: 25},
          allowSorting    : true,
          allowSearching  : true,
          allowFiltering  : true,
          cssClass        : "grid-picker",
          // filterSettings  : {
          //   showFilterBarStatus: true,
          //   filterType         : "menu"
          // },
          recordClick     : (args) => {
            console.log("Click", args);
            // let a = <Date> args.data.dtm_data_documento;
            this.router.navigate(`${document.location.origin}/#/cliente/${args.data.nvc_cliente_armazem}`);
          },
          //@formatter:off
            columns       : [
            {field: "nvc_cliente_armazem"  , headerText: "Código"               , width:  40 , textAlign: "center", type:"number"                            , filterBarTemplate: identityNumericFilter},
            {field: "nvc_nome"             , headerText: "Nome"                 , width:  100, type:"string"      , filterBarTemplate: IdentityStringFilter} ,
            {field: "nvc_tipo"             , headerText: "Tipo"                 , width:  60 , textAlign: "center", type:"string"                            , filterBarTemplate: IdentityStringFilter} ,
            {field: "nvc_cliente_primavera", headerText: "Ref. Primavera"       , width:  40 , textAlign: "center", type:"string"                            , filterBarTemplate: IdentityStringFilter} ,
            {field: "nvc_armazem_entrada"  , headerText: "Armazém Entrada"      , width:  60 , type:"string"      , filterBarTemplate: IdentityStringFilter} ,
            {field: "nvc_armazem_producao" , headerText: "Armazém Saída"        , width:  60 , type:"string"      , filterBarTemplate: IdentityStringFilter} ,
            {field: "nvc_armazem_lixo"     , headerText: "Armazém Residual"     , width:  60 , type:"string"      , filterBarTemplate: IdentityStringFilter  , }                                        ,
            {field: "nvc_armazem_expedicao", headerText: "Armazém Expedição"    , width:  60 , type:"string"      , filterBarTemplate: IdentityStringFilter  , visible: false  }                        ,
            {field: "total_morada"         , headerText: "Nº Moradas Associadas", width:  20 , type:"number"      , filterBarTemplate: identityNumericFilter},
            ]
            //@formatter:on
        });
      });
  }
}
