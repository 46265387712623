/**
 * Created by hernani on 2017-06-20.
 */
import {datePt, strDatePt} from "../../utils/ItNumeric";

export class DatePtValueConverter {
  toView(value) {
    if(!value) return "";
    if(typeof value === "string") return strDatePt(value);
    return datePt(value);
  }

  fromView(value) {
    // let exp = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
    //     result = exp.exec(hex);
    //
    // let euroStringToFloat2 = euroStringToFloat(value);
    // console.error(value, euroStringToFloat2);
    return value;
  }
}