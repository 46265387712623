// import {ColConfigInterface, DataSource, EntityInterface, GridConnector, Selection, SortObjectInterface} from "aurelia-v-grid";
import environment from "../../environment";
import {ColConfigInterface, DataSource, EntityInterface, GridConnector, Selection} from "../../it-v-grid/interfaces";

export class Vgrid {
  message: string;
  private ds: DataSource;
  private gridConnector: GridConnector;
  private collection: EntityInterface[];
  private columns: ColConfigInterface[];

  constructor() {

    // //dummy data
    this.collection = [
      {name: 'Vegar', number: 5425.25, country: 'Norway', hired: true},
      {name: 'awqe', number: 55.25, country: 'Portugal', hired: true},
      {name: 'Lars', number: 545.45, country: 'Sweden', hired: false}
    ];

    // create datasource (you could just inject this from somewhere else)
    this.ds = new DataSource(new Selection('multiple'));

    //create grid connector
    this.gridConnector = new GridConnector(this.ds);

    this.columns = [
      {
        colHeaderName: "Nome",
        colField     : "name",
        colWidth     : 200,
        colLabelMenu : "sort:name",
        colSort      : "field:name"
      },
      {
        colHeaderName : "País",
        colField      : "country",
        colRowTemplate: '<button click.trigger="testar(rowRef)" inner-text.bind="rowRef.country" if.bind="rowRef.name"></button>',
        colWidth      : 100,
        colHidden     : false,
        colSort       : "field:country",
        colFilter     : "field:country;operator:*"
      },
    ];

    // this.gridConnector.setColConfig(this.columns);
    // set data to the datasource, you dont need to set data now, you can do it later...
    this.ds.setArray(this.collection);
  }

  singleClick(ev) {
    if (environment.debug) console.log("[vgrid]", "singleClick", ev);
  }

  dblClick(ev) {
    if (environment.debug) console.log("[vgrid]", "dblClick", ev);
  }

  setColumns() {
    // this.columns = [
    //   {
    //     colHeaderName: "Nome",
    //     colField     : "name",
    //     colWidth     : 200,
    //     colLabelMenu : "sort:name",
    //     colSort      : "field:name"
    //   },
    //   {
    //     colHeaderName : "País",
    //     colField      : "country",
    //     colRowTemplate: '<button click.trigger="testar(rowRef)" inner-text.one-time="rowRef.country" if.bind="rowRef.name"></button>',
    //     colWidth      : 100,
    //     colHidden     : false,
    //   },
    //   // {
    //   //   colHeaderName : "País 2",
    //   //   colField      : "country",
    //   //   colRowTemplate: '<button click.trigger="testar(rowRef)" inner-text.bind="rowRef.country"></button>',
    //   //   colWidth      : 100
    //   // },
    // ];

    this.columns[1].colHidden = true;

    this.gridConnector.setColConfig(this.columns);
  }

  loadData() {
    this.ds.setArray(this.collection)
  }

  testar(obj?) {
    console.log(obj);
    alert("teee" + obj.country);
  }
}

// class CDataSource extends DataSource {
//   constructor(selection, options?) {
//     super(selection, options);
//   }
//
//   orderBy(attribute: string | SortObjectInterface, addToCurrentSort?: boolean): void {
//     if (environment.debug) console.log("[vgrid]", "CDataSource.orderby", attribute, addToCurrentSort);
//     //super.orderBy(attribute, addToCurrentSort);
//     //return;
//   }
// }