import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {BootstrapFormRenderer} from "../services/bootstrap-form-renderer";
import {GlobalServices} from "../services/global-services";
import {DuplicacaoPlanoExpedicaoVm} from "../models/DuplicacaoPlanoExpedicaoVm";
import {PickerArtigo} from "./picker-artigo";
import {ConfirmacaoDialog} from "./confirmacao-dialog";
import {VmWrapper} from "../models/VmWrapper";
import {tiposGravacao} from "../utils/ItMultiPurpose";
import environment from "../environment";

@autoinject()
export class DuplicaLinhaPlanoPrgDialog {
  protected validationController: ValidationController;
  protected controller: DialogController;
            app: GlobalServices;
  protected duplicacaoVm: DuplicacaoPlanoExpedicaoVm;
  private isBusy: boolean = false;

  constructor(controller: DialogController, app: GlobalServices, vcf: ValidationControllerFactory) {
    console.log("DuplicaLinhaPlanoDialog constructor");
    this.controller           = controller;
    this.app                  = app;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  activate(payload: DuplicaLinhaPlanoPrgPayload) {
    if(environment.debug) console.log("[duplica-linha-plano-prg-dialog]","activate", payload);
    this.duplicacaoVm = payload.duplicacaoVm;
  }

  detached() {
  }

  attached() {
    $('#nvcTipoGravacao').ejAutocomplete({
      locale          : "pt-PT",
      width          : "100%",
      watermarkText  : "Gravação - A, O, P, S",
      emptyResultText: "Outros",
      showPopupButton: true,
      dataSource     : tiposGravacao,
      focusIn        : function (argument: any) {
        console.log(argument);
        $("#nvcTipoGravacao").ejAutocomplete("open");
      },
      change         : (v) => {
        // console.log("change", v, this.duplicacaoVm.nvcTipoGravacao);
        try {
          if (v.value != this.duplicacaoVm.nvcTipoGravacao) {
            this.duplicacaoVm.nvcTipoGravacao = v.value;
          }
          // else
          //   this.duplicacaoVm.nvcTipoGravacao = "";
        } catch (err) {
          this.duplicacaoVm.nvcTipoGravacao = "";
        }
        // console.log("change end", v, this.duplicacaoVm.nvcTipoGravacao, this.duplicacaoVm.stateToPOJSO());
        this.validationController.validate({object: this.duplicacaoVm, propertyName: 'nvcTipoGravacao'});
      }
    });
  }

  cleanup() {    $("#nvcTipoGravacao").ejAutocomplete('destroy');}

  submit() {
    if(environment.debug) console.log("[duplica-linha-plano-prg-dialog]", "Submit", this.duplicacaoVm);
    this.validationController.validate({object: this.duplicacaoVm, propertyName: 'nvcTipoGravacao'})
      .then(r => {
        console.log("Validation Results", r);
        if (r.valid) {
          this.isBusy = true;
          this.gravaDuplicacao(this.duplicacaoVm.warpIt());
        }
      })
  }

  gravaDuplicacao(vmw: VmWrapper<DuplicacaoPlanoExpedicaoVm>) {
    return this.app.api
      .postProcessed("api/plano-expedicao/duplica-linha-rp", vmw.stateToPOJSO())
      // .then(r => this.app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return this.gravaDuplicacao(vmw.nextLevel());
                } else {
                  this.isBusy = false;
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        //console.log("returning", GuiaTransporte.fromPOJSO(obj));
        this.cleanup();
        //todo: melhorar o caso positivo
        return this.controller.ok({action: "REFRESH-TABELA", payload: null});
      })
      .catch(err => {
        console.error(err);
        this.isBusy = false;
        this.app.notificationErrorCompact(err)
      });
  }
}

export interface DuplicaLinhaPlanoPrgPayload {duplicacaoVm: DuplicacaoPlanoExpedicaoVm}