// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {ControloDiario} from './ControloDiario';
import {Operador} from './Operador';
import {Utilizador} from './Utilizador';

//export classe
export class RegistoEntradaControlo extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idRegistoEntradaControlo: number                = 0;
  public idControloDiario: number                        = 0;
  public intQuantidade: number                           = 0;
  public idControloDiarioSaida: number                   = null;
  public intBoas: number                                 = 0;
  public intK: number                                    = 0;
  public intF: number                                    = 0;
  public intApart: number                                = 0;
  public nvcTipo: string                                 = null;
  public idOperador: number                              = null;
  public idOperadorTemp: number                          = null;
  public nvcNomeOperadorTemp: string                     = null;
  public idUtilizadorI: number                           = 0;
  public idUtilizadorA: number                           = 0;
  public dtmDataI: string                                = null;
  public _dtmDataI: Date                                 = new Date();
  public dtmDataA: string                                = null;
  public _dtmDataA: Date                                 = new Date();
  public timestamp: number[]                             = [];
  public idControloDiarioNavigation: ControloDiario      = null;
  public idControloDiarioSaidaNavigation: ControloDiario = null;
  public idOperadorNavigation: Operador                  = null;
  public idUtilizadorANavigation: Utilizador             = null;
  public idUtilizadorINavigation: Utilizador             = null;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<RegistoEntradaControlo>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): RegistoEntradaControlo {
    let model = new RegistoEntradaControlo();
    model.setState(obj);

    if(obj.idControloDiarioNavigation) model.idControloDiarioNavigation = ControloDiario.fromPOJSO(obj.idControloDiarioNavigation);
    if(obj.idControloDiarioSaidaNavigation) model.idControloDiarioSaidaNavigation = ControloDiario.fromPOJSO(obj.idControloDiarioSaidaNavigation);

    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): RegistoEntradaControlo[] {
    if (objs && Array.isArray(objs)) return objs.map(RegistoEntradaControlo.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<RegistoEntradaControlo>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): RegistoEntradaControlo { return RegistoEntradaControlo.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `RegistoEntradaControlo`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: RegistoEntradaControlo) => m.idRegistoEntradaControlo).displayName("idRegistoEntradaControlo").required()
  .ensure((m: RegistoEntradaControlo) => m.idControloDiario).displayName("idControloDiario").required()
  .ensure((m: RegistoEntradaControlo) => m.intQuantidade).displayName("intQuantidade").required()
  .ensure((m: RegistoEntradaControlo) => m.idControloDiarioSaida).displayName("idControloDiarioSaida").required()
  .ensure((m: RegistoEntradaControlo) => m.intBoas).displayName("Ok").required()
  .ensure((m: RegistoEntradaControlo) => m.intK).displayName("K").required()
  .ensure((m: RegistoEntradaControlo) => m.intF).displayName("F").required()
  .ensure((m: RegistoEntradaControlo) => m.intApart).displayName("Apart").required()
  .ensure((m: RegistoEntradaControlo) => m.idOperador).displayName("Operador").required()
  .ensure((m: RegistoEntradaControlo) => m.idOperadorTemp).displayName("OperadorTemp").required()
  // .ensure((m: RegistoEntradaControlo) => m.nvcNomeOperadorTemp).displayName("nvcNomeOperadorTemp").required()
  // .ensure((m: RegistoEntradaControlo) => m.idUtilizadorI).displayName("idUtilizadorI").required()
  // .ensure((m: RegistoEntradaControlo) => m.idUtilizadorA).displayName("idUtilizadorA").required()
  // .ensure((m: RegistoEntradaControlo) => m.dtmDataI).displayName("dtmDataI").required()
  // .ensure((m: RegistoEntradaControlo) => m.dtmDataA).displayName("dtmDataA").required()
  // .ensure((m: RegistoEntradaControlo) => m.timestamp).displayName("timestamp").required()
  // .ensure((m: RegistoEntradaControlo) => m.idControloDiarioNavigation).displayName("idControloDiarioNavigation").required()
  // .ensure((m: RegistoEntradaControlo) => m.idControloDiarioSaidaNavigation).displayName("idControloDiarioSaidaNavigation").required()
  // .ensure((m: RegistoEntradaControlo) => m.idOperadorNavigation).displayName("idOperadorNavigation").required()
  // .ensure((m: RegistoEntradaControlo) => m.idUtilizadorANavigation).displayName("idUtilizadorANavigation").required()
  // .ensure((m: RegistoEntradaControlo) => m.idUtilizadorINavigation).displayName("idUtilizadorINavigation").required()
  .on(RegistoEntradaControlo);
