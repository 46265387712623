import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from './BaseViewModel';
import {VmWrapper} from './VmWrapper';

export class ErpGceStkReal extends BaseViewModel {
    public strCodArtigo : string  = '' ;
    public fltStockQtd : number = 0 ;
    public fltStockReservado : number = 0 ;


    //region estado
        /**
         * Construtor para inicializador à lá c#
         * @param fields
         */
        public constructor(fields?: Partial<ErpGceStkReal>,) {
            super();
            if (fields) Object.assign(this, fields);
        }
    
        public static fromPOJSO(obj: any): ErpGceStkReal {
            let model = new ErpGceStkReal();
            model.setState(obj);
            return model;
        }
        
        public static multipleFromPOJSO(objs: any | any[]): ErpGceStkReal[] {
            if (objs && Array.isArray(objs)) return objs.map(ErpGceStkReal.fromPOJSO);
            return [];
        }
    
        public stateToPOJSO() {
            return this.getState();
        }
    
        public wrapIt(cl?: number) {
            return new VmWrapper<ErpGceStkReal>({payload: this, confirmLevel: (+cl || 0)});
        }
    
        public cloneInstance(): ErpGceStkReal { return ErpGceStkReal.fromPOJSO(this.stateToPOJSO());}    
        
        public stateToPOJSOSafeId(): any {
            let state = this.getState();
            //no fundo apagam-se os ids (recursivamente)
            if(state.id) state.id = 0;
            return state;
        }
        
        //endregion estado
    
        public toString(){
            return `ErpGceStkReal`;
        }
    
}
//region aurelia-validation (comentar o que não interessa)
ValidationRules
    .ensure((m: ErpGceStkReal) => m.strCodArtigo).displayName('strCodArtigo').required()
    .ensure((m: ErpGceStkReal) => m.fltStockQtd).displayName('fltStockQtd').required()
    .ensure((m: ErpGceStkReal) => m.fltStockReservado).displayName('fltStockReservado').required()
    .on(ErpGceStkReal);
//endregion
