import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from './BaseViewModel';
import { VmWrapper } from './VmWrapper';
import { ClienteDestinatario } from './ClienteDestinatario';

export class Cliente extends BaseViewModel {
  public nvcCliente: string        = '';
  public nvcClienteErp: string     = '';
  public nvcClienteArmazem: string = '';
  public nvcNome: string           = '';
  public nvcArmazemEntrada: string;
  public nvcArmazemProducao: string;
  public nvcArmazemExpedicao: string;
  public nvcArmazemLixo: string;
  public idUtilizadorI: number     = 0;
  public idUtilizadorA: number     = 0;
  public dtmDataI: string;
  public dtmDataA: string          = '';
  public timestamp: string;

  public ClienteDestinatario: ClienteDestinatario[] = [];
  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Cliente>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): Cliente {
    let model = new Cliente();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): Cliente[] {
    if (objs && Array.isArray(objs)) return objs.map(Cliente.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<Cliente>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): Cliente { return Cliente.fromPOJSO(this.stateToPOJSO());}

  public stateToPOJSOSafeId(): any {
    let state = this.getState();
    //no fundo apagam-se os ids (recursivamente)
    if (state.id) state.id = 0;
    return state;
  }

  //endregion estado

  public toString() {
    return `Cliente ${this.nvcCliente} - ${this.nvcNome}`;
  }

}

//region aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: Cliente) => m.nvcCliente).displayName('nvcCliente').required()
  // .ensure((m: Cliente) => m.nvcClienteErp).displayName('nvcClienteErp').required()
  .ensure((m: Cliente) => m.nvcClienteArmazem).displayName('nvcClienteArmazem').required()
  .ensure((m: Cliente) => m.nvcNome).displayName('nvcNome').required()
  // .ensure((m: Cliente) => m.nvcArmazemEntrada).displayName('nvcArmazemEntrada').required()
  // .ensure((m: Cliente) => m.nvcArmazemProducao).displayName('nvcArmazemProducao').required()
  // .ensure((m: Cliente) => m.nvcArmazemExpedicao).displayName('nvcArmazemExpedicao').required()
  // .ensure((m: Cliente) => m.nvcArmazemLixo).displayName('nvcArmazemLixo').required()
  // .ensure((m: Cliente) => m.idUtilizadorI).displayName('idUtilizadorI').required()
  // .ensure((m: Cliente) => m.idUtilizadorA).displayName('idUtilizadorA').required()
  //.ensure((m: Cliente) => m.dtmDataI).displayName('dtmDataI').required()
  // .ensure((m: Cliente) => m.dtmDataA).displayName('dtmDataA').required()
  //.ensure((m: Cliente) => m.timestamp).displayName('timestamp').required()
  .on(Cliente);
//endregion
