import {autoinject, bindable, bindingMode, inlineView} from "aurelia-framework";
import * as AwesompleteJs from "awesomplete";
import {removeDiacritics} from "../../utils/ItMultiPurpose";
import {GlobalServices} from "../../services/global-services";

@inlineView('<template><require from="./awesomplete.theme.css"></require><input show.bind="isReady" class="form-control" ref="inputEl" autosel placeholder.bind="placeholder"></template>')
export class AwesompleteCache {
  @bindable({defaultBindingMode: bindingMode.oneTime}) debug: boolean      = false;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string        = "";
  @bindable({defaultBindingMode: bindingMode.oneTime}) placeholder: string = "Introduza um caracter para sugestões";

  //opcções por defeito
  @bindable({defaultBindingMode: bindingMode.toView}) options: AwesompleteCacheOptions = null;

  //localizadores para chamadas à API
  @bindable({defaultBindingMode: bindingMode.oneTime}) baseApiPath: string = "api/cache-texto";

  // primeiro indicador de segmentação
  @bindable({defaultBindingMode: bindingMode.fromView}) tipo: string = "";

  // segundo indicador de segmentação
  @bindable({defaultBindingMode: bindingMode.fromView}) grupo: string = "";

  //internas
  private inputEl: HTMLInputElement = null;
  private awesompleteInstance: AwesompleteJs;
  private isReady: boolean          = false;

  constructor(private app: GlobalServices) {
    //nada, por enquanto
  }

  bind(a, b) {
    //todo: neste ponto já são conhecidos os indicadores de segmentação.
    console.log("[awesomplete-cache]", "bind", a, b, this);
    //é seguro fazer a chamada À api para a lista de sugestões do tipo
  }

  attached() {
    if (!this.grupo) {
      this.app.api.getProcessed(this.baseApiPath + "/sugestoes-tipo", {tipo: this.tipo})
        .catch(err => {
          console.error("[awesomplete-cache] Não foi possível obter dados do servidor");
          return [];
        })
        .then(s => {
          if (this.debug) console.log("[awesomplete-cache]", "Resposta do servidor, após tratamento de erro", s);
          this.isReady = true;
          this.initAwesomplete(s);
          //return s;
        })
    } else {
      this.app.api.getProcessed(this.baseApiPath + "/sugestoes-tipo-grupo", {tipo: this.tipo, grupo: this.grupo})
        .catch(err => {
          console.error("[awesomplete-cache] Não foi possível obter dados do servidor");
          return [];
        })
        .then(s => {
          if (this.debug) console.log("[awesomplete-cache]", "Resposta do servidor, após tratamento de erro", s);
          this.isReady = true;
          this.initAwesomplete(s);
          //return s;
        })

    }
    //.then(s => this.initAwesomplete(s))

  }

  detached() {
    this.destroyAwesomplete();
  }

  private initAwesomplete(listaSugestoes: string[] = []) {
    if (!this.options && this.debug) { console.log("[awesomplete-cache]", "Não foi passado um objeto de opcções ao CE awesomplete-cache, e é suposto")}

    //fusão de opções defeito + binded
    let base = new AwesompleteCacheOptions();

    this.options = base.merger(this.options);

    this.options.list = [...listaSugestoes];

    //inicialização do 1o valor
    if (this.value) {
      this.inputEl.value = this.value;
    }

    //instanciação do plugin
    this.awesompleteInstance = new AwesompleteJs(this.inputEl, this.options);
    //window["aw"]             = this.awesompleteInstance;

    // registo de eventos
    // this.inputEl.addEventListener("awesomplete-select", this.handleSelect);
    this.inputEl.addEventListener("awesomplete-selectcomplete", this.handleSelectComplete);
    // this.inputEl.addEventListener("awesomplete-close", this.handleSelectClose);
    this.inputEl.addEventListener("input", this.handleChange);

    //registo dos eventHandlers (curried?)
    if (this.debug) console.log("[awesomplete-cache]", "plugin instanciado", this.awesompleteInstance);
  }

  private destroyAwesomplete() {
    //neat & tidy cleanup
    this.inputEl && this.inputEl.removeEventListener("input", this.handleChange);
    this.inputEl && this.inputEl.removeEventListener("awesomplete-selectcomplete", this.handleSelectComplete);
    this.awesompleteInstance && (typeof this.awesompleteInstance.destroy == "function" && this.awesompleteInstance.destroy());
  }

  //region enventHandlers

  public handleSelect = (a) => {
    console.info(a);
  };

  /**
   * rotina de tratamento de uma selecção.
   * No fundo representa o fluxo plugin -> aurelia
   * @param e
   */
  public handleSelectComplete = (e) => {
    console.info(e);
    if (!e.text) {console.error("[awesomplete-cache]", "O evento não transporta a propriedade necessária (e.text)", e);} else {
      let sel: { label: string, value: string } = e.text;
      //todo: proteger contra nulls e afins?
      if (this.value != sel.value) {this.value = sel.value}
    }
  };

  /**
   * rotina de tratamento de uma selecção.
   * No fundo representa o fluxo plugin -> aurelia
   * @param e
   */
  public handleChange = (e) => {
    console.info(e);
    if (this.inputEl.value != this.value) {this.value = this.inputEl.value}
  };

  //endregion enventHandlers

  //region observers

  valueChanged(newVal, oldVal) {
    if (this.debug) console.log("[awesomplete-cache]", "valueChanged", newVal, oldVal);
    if (this.inputEl) {
      if (newVal != this.inputEl.value) {
        //só isto???
        this.inputEl.value = newVal;
      }
    }
  }

  optionsChanged(newVal, oldVal) {
    if (this.debug) console.log("[awesomplete-cache]", "optionsChanged", newVal, oldVal);
  }

  //endregion observers
}

/**
 * Objeto de opções para instanciação do plugin
 * https://leaverou.github.io/awesomplete/#api
 */
export class AwesompleteCacheOptions {
  //lista de opções que o plugin permite escolher
  list: string[] = [];

  //num. mínimo de caracteres para triggar o popup
  minChars: number = 1;

  //número máximo de itens exibidos sob uma qq procura
  maxItems: number = 10;

  //selecção automática do primeiro elemento?
  autoFirst: boolean = true;

  regExpEscape = (s) => {
    return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  filter = (text, input) => {
    console.log("filter");
    let t = removeDiacritics(text);
    let i = removeDiacritics(input);
    return RegExp(this.regExpEscape(i.trim()), "i").test(t);
  };

  //todo: o highlight sem diacríticas é mais difícil

  // a função item tem de devolver um nodo
  // item = (text, input) => {
  //   var html = input.trim() === "" ? text : text.replace(RegExp(this.regExpEscape(input.trim()), "gi"), "<mark>$&</mark>");
  //   return html;
  // };

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<AwesompleteCacheOptions>,) {
    if (fields) Object.assign(this, fields);
  }

  public merger(other: AwesompleteCacheOptions): AwesompleteCacheOptions {
    if (other) {
      if (other.list) {
        this.list = other.list;//.map(el => ({value: el, label:removeDiacritics(el)}));
      }
      if (other.minChars) {
        this.minChars = other.minChars;
      }
      if (other.maxItems) {
        this.maxItems = other.maxItems;
      }
      if (other.autoFirst) {
        this.autoFirst = other.autoFirst;
      }
    }
    return this;
  }
}
