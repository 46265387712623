//Aurelia Imports
import {autoinject} from "aurelia-framework";
//App Imports
import {Logger} from "aurelia-logging";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {dateISOString} from "../../utils/ItNumeric";
import {DateFilterTemplate, identityNumericFilter, IdentityStringFilter} from "../../utils/EjHelper";
import {VmWrapper} from "../../models/VmWrapper";
import {ConfirmacaoDialog} from "../../dialogs/confirmacao-dialog";
import {Router} from "aurelia-router";

@autoinject()
export class FolhasExpedicao {
  private logger: Logger;
  private app: GlobalServices;

  private id: number;
  private linhas: any;
  private ejGrid: HTMLDivElement = null;


  // protected planoExpedicao: PlanoExpedicaoV;

  // get total() {
  //   return this.linhas.reduce((acc, el) => (acc + (el.qtd) ? +el.qtd : 0), 0);
  // }
  //
  // get valor() {
  //   return this.linhas.reduce((acc, el) => (acc + (el.valor) ? +el.valor : 0), 0);
  // }

  constructor(g: GlobalServices, protected router:Router) {
    this.app = g;
  }

  activate() {
    // this.id = +p.id;
    // console.log(p, this.id);
  }

  canDeactivate() {
    try {$(this.ejGrid).ejGrid("destroy");} catch (err) {console.log(err)}
    return true;
  }

  private apagaFolhaExpedicao(vmw: VmWrapper<number>) {
    return this.app.api.deleteProcessed(`api/armazem/folha-expedicao`, null, vmw.stateToPOJSO())
      .then((obj: any) => {
        console.log("Resposta do servidor", obj);
        if (obj.tipo === "confirm") {
          let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
          return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
            .whenClosed(resp => {
              if (!resp.wasCancelled) {
                //O operador escolheu SIM: aumenta o nível de confirmação
                return this.apagaFolhaExpedicao(vmw.nextLevel());
              } else {
              }
              $(this.ejGrid).ejGrid("refreshContent");
            });
        } else {
          $(this.ejGrid).ejGrid("refreshContent");
        }
      })
      .catch(err => this.app.notificationErrorCompact(err))
  }



  attached() {
    //console.log("activate!");
    this.app.auth.promisedActiveToken()
      .then(token => {
        let dataSource = ej.DataManager({
          //done: injetar a localização do serviço
          url        : environment.endpoint + "api/ejg/FolhaExpedicao",
          crossDomain: true,
          //requestType : "post",
          adaptor    : new ej.WebMethodAdaptor(),
          headers    : [{Authorization: `Bearer ${token}`}]
        });

        $(this.ejGrid).ejGrid({
          locale          : "pt-PT",
          dataSource      : dataSource,
          allowPaging     : true,
          pageSettings    : {pageSize: 20},
          allowSorting    : true,
          allowSearching  : true,
          allowFiltering  : true,
          cssClass        : "grid-picker",
          filterSettings  : {
            showFilterBarStatus: true,
            // filterType         : "menu"
          },
          sortSettings  : {sortedColumns: [{field: "dtm_data_expedicao", direction: "descending"}]},
          recordClick     : (args) => {
            // console.log("Click", args);
            if (args.cellIndex === 0) {
              // this.deleteLog(+args.data.id_log);
              // console.log(args);
              this.apagaFolhaExpedicao(new VmWrapper({payload: +args.data.id_folha_expedicao, confirmLevel:0}));
            } else{
              let a = <Date> args.data.dtm_data_expedicao;
              this.router.navigate(`#/folha-expedicao/${dateISOString(a)}`);

              // this.loadLogRecord(args.data);
            }
          },
          //@formatter:off
            columns       : [
{headerText: "Acções"            , width:  20                   , textAlign: "center" , template: '<a><i class="fa fa-trash"></i></a>'} ,
{field: "dtm_data_expedicao" , headerText: "Data"           , width:  40          , type: "date"                                    , textAlign: "center"                     , format: "{0:yyyy-MM-dd}"                   , filterBarTemplate: DateFilterTemplate} ,
{field: "nvc_observacoes"    , headerText: "Observações"    , width:  350         , type:"string"                                   , filterBarTemplate: IdentityStringFilter , }                                          ,
{field: "totalEnviar"            , headerText: "Total a enviar" , width:  60          , type:"number"                                   , filterBarTemplate: identityNumericFilter}                                       ,
{field: "totalControlo"          , headerText: "Controlo"       , width:  60          , type:"number"                                   , filterBarTemplate: identityNumericFilter}                                       ,
{field: "nvc_estado"         , headerText: "Estado"         , width:  40          , type: "string"                                  , textAlign: "center"                     , filterBarTemplate: IdentityStringFilter  } ,
]
            //@formatter:on
        });
      });
  }

}
