import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from './BaseViewModel';
import {VmWrapper} from './VmWrapper';

export class ConfiguracaoCv extends BaseViewModel {
    public nvcChave : string  = '' ;
    public nvcValor : string  = '' ;
    public nvcDescricao : string  = '' ;
    public nvcValoresAdmitidos : string  ;
    public intNivel : number  ;
    public idUtilizador : number = 0 ;
    public idUtilizadorultact : number  ;
    public dtmDatains : string = '' ;
    public dtmDataultact : string  ;
    public timestamp : string  ;


    //region estado
        /**
         * Construtor para inicializador à lá c#
         * @param fields
         */
        public constructor(fields?: Partial<ConfiguracaoCv>,) {
            super();
            if (fields) Object.assign(this, fields);
        }
    
        public static fromPOJSO(obj: any): ConfiguracaoCv {
            let model = new ConfiguracaoCv();
            model.setState(obj);
            return model;
        }
        
        public static multipleFromPOJSO(objs: any | any[]): ConfiguracaoCv[] {
            if (objs && Array.isArray(objs)) return objs.map(ConfiguracaoCv.fromPOJSO);
            return [];
        }
    
        public stateToPOJSO() {
            return this.getState();
        }
    
        public wrapIt(cl?: number) {
            return new VmWrapper<ConfiguracaoCv>({payload: this, confirmLevel: (+cl || 0)});
        }
    
        public cloneInstance(): ConfiguracaoCv { return ConfiguracaoCv.fromPOJSO(this.stateToPOJSO());}    
        
        public stateToPOJSOSafeId(): any {
            let state = this.getState();
            //no fundo apagam-se os ids (recursivamente)
            if(state.id) state.id = 0;
            return state;
        }
        
        //endregion estado
    
        public toString(){
            return `ConfiguracaoCv`;
        }
    
}
//region aurelia-validation (comentar o que não interessa)
ValidationRules
    .ensure((m: ConfiguracaoCv) => m.nvcChave).displayName('nvcChave').required()
    .ensure((m: ConfiguracaoCv) => m.nvcValor).displayName('nvcValor').required()
    .ensure((m: ConfiguracaoCv) => m.nvcDescricao).displayName('nvcDescricao').required()
    .ensure((m: ConfiguracaoCv) => m.nvcValoresAdmitidos).displayName('nvcValoresAdmitidos').required()
    .ensure((m: ConfiguracaoCv) => m.intNivel).displayName('intNivel').required()
    .ensure((m: ConfiguracaoCv) => m.idUtilizador).displayName('idUtilizador').required()
    .ensure((m: ConfiguracaoCv) => m.idUtilizadorultact).displayName('idUtilizadorultact').required()
    .ensure((m: ConfiguracaoCv) => m.dtmDatains).displayName('dtmDatains').required()
    .ensure((m: ConfiguracaoCv) => m.dtmDataultact).displayName('dtmDataultact').required()
    //.ensure((m: ConfiguracaoCv) => m.timestamp).displayName('timestamp').required()
    .on(ConfiguracaoCv);
//endregion
