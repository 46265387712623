//import {setInterval} from "timers";
import {autoinject, computedFrom} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import {PlanoExpedicao, RegistosAgrupados} from "../../models/PlanoExpedicao";
import {CalendarioExpedicaoEpoca} from "../../models/CalendarioExpedicaoEpoca";
import {RegistoPlano} from "../../models/RegistoPlano";
// http://stackoverflow.com/questions/39884413/aurelia-refresh-view-with-a-map-value
import {TipoRegistoPlano, tiposRegisto} from "../../models/TipoRegistoPlano";
import {PlanoColumn, PlanoDefinition} from "../../models/PlanoColumn";
import {activationStrategy} from "aurelia-router";
import {ConfirmacaoDialog} from "../../dialogs/confirmacao-dialog";
import {VmWrapper} from "../../models/VmWrapper";
import {ConfigTabelaVirtual} from "../../dialogs/config-tabela-virtual";
import {addUTCDays, dateISOString} from "../../utils/ItNumeric";
import {DuplicaLinhaPlanoDialog, DuplicaLinhaPlanoPayload} from "../../dialogs/duplica-linha-plano-dialog";
import {PickerData} from "../../dialogs/picker-data";
import environment from "../../environment";
import {range, removeDiacriticsLower} from "../../utils/ItMultiPurpose";
import {ComposeDialog, ComposeDialogOptions} from "../../dialogs/compose-dialog";
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {EstadoLinhaPlano} from "../../models/EstadoLinhaPlano";
import {confirmaDeletionTyped} from "../../services/api-envelopes";
import {GuiaTransporte} from "models/GuiaTransporte";

@autoinject()
export class ExpedicaoTabelaVirtual {
  // private logger: Logger;
  public isBusy: boolean                                = false;
  public isFetching: boolean                            = false;
  public hasDialog: boolean                             = false;
  public title: string                                  = '---';
  public tipoPlano: "armazem" | "expedicao" | "calculo" = "expedicao";
  private ca: string[];
  public app: GlobalServices;

  public calendario: CalendarioExpedicaoEpoca[]     = [];
  public periodoVisivel: CalendarioExpedicaoEpoca[] = [];

  public indexOfCalendario: number = 0;
  public linhas: PlanoExpedicao[]  = [];

  // campos para albergar os somatórios
  public totalEncomendado: number = 0;
  public totalEnviado: number     = 0;
  public totalPorFazer: number    = 0;

  public selectionCount                   = 0;
  public cellSelectionCount               = 0;
  private selectionCountTipoPlano: number = 0;
  private _previousSelectedIndex          = -1;

  public selEncomendado: number = 0;
  public selEnviado: number     = 0;
  public selPorFazer: number    = 0;

  private margin = 15;

  private _scrollLeft: number = 0;
  private _scrollTop: number  = 0;
  // private _referenceTop: number = 0;

  private _totalLinhasEpoca: number = 0;

  private skip: number = 0;
  private take: number = 120; // 4x page

  // a página deve ser par?
  private pageElements: number   = 26;
  private offsetVertical: number = 12;
  private pageNumber: number     = 0;

  // bufferMultiplier -> simboliza o multiplicador aplicado para desenho de linhas intermédias, que não triggam a paginação virtual.
  private bufferMultiplier: number = 1;
  private rowHeight: number        = 28;

  public mapaDef: PlanoDefinition;
  private podeEditar: boolean = false;

  private Math = Math;

  private mapa: HTMLTableElement;
  public activeSelection: MultiSelecao = new MultiSelecao;
  public resumo: ResumoRegistosPlano   = new ResumoRegistosPlano;
  private podeCriarLinhas: boolean     = false;

  //largura, em pixels, da primeira coluna (ações)
  private actionWidth: number = 54;

  //adicionado E-T
  private listaPlanos: ClienteArmazem[] = [];

  //teste
  // private bs: BindingSignaler = null;

  //region getters & setters
  @computedFrom('_scrollLeft')
  get scrollLeft(): number {
    return this._scrollLeft;
  }

  set scrollLeft(value: number) {
    this._scrollLeft = value;
  }

  @computedFrom('_scrollTop')
  get scrollTop(): number {
    return this._scrollTop;
  }

  set scrollTop(value: number) {
    if (environment.debug) console.log("set scrollTop", value, "internal", this._scrollTop, "next", (this.linhas.length - this.pageElements) * this.rowHeight, "total de linhas", this.linhas.length, "total linhas epoca", this._totalLinhasEpoca, "skip", this.skip, "buffer", (this.bufferMultiplier + 1) * this.pageElements);
    if (!this.isFetching) {
      // if (value >= this.pageElements * this.bufferMultiplier * this.rowHeight) {
      if (this.linhas.length > this.pageElements) {
        if (value >= (this.linhas.length - this.pageElements/* - 1*/) * this.rowHeight) {
          if (environment.debug) console.log("[expedicao-tabela-virtual]", "aumenta a página");
          if (this._totalLinhasEpoca - this.skip > (this.bufferMultiplier + 1) * this.pageElements) {

            // memorizar o id do plano que irá servir de ponto de "nova página"
            let referenceIndex = -1;
            let maxlen         = this.linhas.length;
            if (maxlen > this.pageElements) referenceIndex = maxlen - this.pageElements;
            let idAt = 0;
            if (this.linhas[referenceIndex])
              idAt = this.linhas[referenceIndex].idPlanoExpedicao;

            this.getLinhas(this.pageNumber + 1)
              .then(_ => {
                let skipTop = 3;
                let newIdAt = this.linhas.findIndex(el => el.idPlanoExpedicao == idAt);
                if (newIdAt > -1) skipTop = newIdAt + 1;
                //usar o id memorizado para manter o topo constante com a navegação anterior.
                if (environment.debug) console.log("[expedicao-tabela-virtual]", "skipTop", skipTop);
                this._scrollTop = this.rowHeight * skipTop;
                this.isFetching = false;
              });
          }
        } else if (value <= 0) {
          if (this.pageNumber > 0) {
            if (environment.debug) console.log("[expedicao-tabela-virtual]", "reduz a página");
            let idAt = this.linhas[0].idPlanoExpedicao;

            this.getLinhas(this.pageNumber - 1)
              .then(_ => {
                let skipTop = 0;

                let newIdAt = this.linhas.findIndex(el => el.idPlanoExpedicao == idAt);
                if (newIdAt > -1) skipTop = newIdAt;
                if (environment.debug) console.log("[expedicao-tabela-virtual]", "skipTop", skipTop);
                this._scrollTop = /*this.pageElements **/ this.rowHeight * skipTop;
                this.isFetching = false;
              })
          }
        }
        this._scrollTop = value;
      } else {
        if (environment.debug) console.log("Não é uma página inteira", this.linhas.length, this.pageElements, this.pageNumber, this._totalLinhasEpoca);
        if (this.linhas.length > 0) {
          this._scrollTop = this.rowHeight;
        }
      }
    }
  }

  @computedFrom("mapaDef")
  public get visibleWidth20() {
    let w = this.mapaDef.columns.filter(e => e.visible).reduce((acc, e) => acc + e.width, 0);
    return w + this.actionWidth + 21;//+ Math.round(21 / window.devicePixelRatio);
  }

  @computedFrom("mapaDef")
  public get visibleWidth4() {
    let w = this.mapaDef.columns.filter(e => e.visible).reduce((acc, e) => acc + e.width, 0);
    return w + this.actionWidth + 5;// + Math.round(5 / window.devicePixelRatio);
  }

  public cumulativeWidth(index) {
    let w = this.mapaDef.columns.reduce((acc, e, i) => {
      if (e.visible && i < index) return acc + e.width;
      return acc;
    }, 0);
    return w + this.margin + this.actionWidth;
  }

  //rotina de tratamento de um click de seleção
  public select(event: MouseEvent, linha: PlanoExpedicao) {
    event.preventDefault();

    if (event.ctrlKey) {
      linha._selected = !linha._selected;
      if (linha._selected) {
        this.selectionCount++;
        this._previousSelectedIndex = linha.__index;
      } else {
        this.selectionCount--;
        this._previousSelectedIndex = -1;
      }
    } else {
      if (event.shiftKey) {
        if (this._previousSelectedIndex >= 0) {
          if (linha.__index < this._previousSelectedIndex) {
            let sel = this.linhas.filter(el => el.__index >= linha.__index && el.__index <= this._previousSelectedIndex);
            sel.forEach(el => el._selected = !el._selected);
            this.selectionCount = sel.filter(el => el._selected).length;
          } else if (linha.__index > this._previousSelectedIndex) {
            let sel = this.linhas.filter(el => el.__index <= linha.__index && el.__index >= this._previousSelectedIndex);
            sel.forEach(el => el._selected = !el._selected);
            this.selectionCount = sel.filter(el => el._selected).length;
          }
          this._previousSelectedIndex = -1;
        } else {
          this._previousSelectedIndex = linha.__index;
        }
      } else {
        this.linhas.forEach(el => { if (el.__index != linha.__index) el._selected = false });
        linha._selected = !linha._selected;
        if (linha._selected) {
          this.selectionCount         = 1;
          this._previousSelectedIndex = linha.__index;
        } else {
          this.selectionCount         = 0;
          this._previousSelectedIndex = -1;
        }
      }
    }
    this.calculaTotaisSelecao();
  }

  /**
   * Agregações sobre a seleção atual
   */
  private calculaTotaisSelecao() {
    this.selEncomendado          = 0;
    this.selEnviado              = 0;
    this.selPorFazer             = 0;
    this.selectionCount          = 0;
    this.selectionCountTipoPlano = 0;

    this.linhas.filter(el => el._selected).forEach(el => {
      this.selEncomendado += +el._qtyTotal;
      this.selEnviado += +el._qtyEmFalta;
      this.selPorFazer += (this.tipoPlano === "armazem") ? +el._qtyPorLancar : +el._qtyPorProgramar;
      this.selectionCount++;
      if (el.nvcTipo == 'plano')
        this.selectionCountTipoPlano++;
    });

    this.cellSelectionCount = document.getElementsByClassName("SEL").length;
  }

  // endregion

  /**
   * Construtor
   * @param g
   * @param bs
   */
  constructor(g: GlobalServices/*, bs: BindingSignaler*/) {
    this.isBusy = true;
    this.app    = g;
    // this.bs     = bs;

  }

  //region aurelia lifecycle
  public defaultPlanoDefinition() {
    let mapaDef = new PlanoDefinition({tipoPlano: this.tipoPlano, epoca: 1, _container: this});
    mapaDef
    //@formatter:off
      .AddColumn(new PlanoColumn({ background: '#dcedc1', align: 'center', width: 60, name: 'CC', title: 'Plano', hint: 'Plano', bind: "nvcClienteArmazem", value: this.ca, visible: false }))
      .AddColumn(new PlanoColumn({ background: '#fff78e'  , align: 'center' , width: 180 , name: 'Cliente'   , title: 'Cliente'   , hint: 'Cliente'                        , bind: "nvcClienteDescricao"}))
      .AddColumn(new PlanoColumn({ background: '#a8e6cf'  , align: 'center' , width: 110 , name: 'CodArtigo' , title: 'Ref'       , hint: 'Referência do Artigo'           , bind: "nvcArtigoTerminacao"}))
      .AddColumn(new PlanoColumn({ background: '#dcedc1'  , align: 'left'   , width: 180 , name: 'Descricao' , title: 'Artigo'    , hint: 'Descrição do Artigo'            , bind: "nvcDescricaoArtigo" }))
      .AddColumn(new PlanoColumn({ background: '#e1ebf5'  , align: 'center' , width: 70  , name: 'Encomenda' , title: 'Cod. Enc.' , hint: 'Código da Encomenda do Cliente' , bind: "nvcCodigoEncomenda" }))
      //.AddColumn(new PlanoColumn({background: '#acd5f5' , align: 'center' , width: 60  , name: 'Gravacao'  , title: "Grav."     , hint: 'Tipo Gravação'                  , bind: "nvcTipoGravacao"}))
      .AddColumn(new PlanoColumn({ background: '#ffaaa5'  , align: 'center' , width: 80  , name: 'DtmPedido' , title: 'Dt Pedido' , hint: 'Data de Pedido'                 , bind: "dtmPedido" }))
      .AddColumn(new PlanoColumn({ background: '#ffd3b6'  , align: 'center' , width: 80  , name: 'DtmRecep'  , title: 'Dt Recep'  , hint: 'Data da Recepção'               , bind: "dtmRececao" }))
      .AddColumn(new PlanoColumn({ background: '#ffd3b6'  , align: 'center' , width: 80  , name: 'RG'        , title: 'Obs'       , hint: 'Observações'                    , bind: "nvcRg" }))
      .AddColumn(new PlanoColumn({ background: '#e1ebf5'  , align: 'right'  , width: 60  , name: 'Qtd'       , title: 'Qtd E'     , hint: 'Quantidade Encomendada'         , bind: "intQtyTotalEncomenda" }))
      ;
    //@formatter:on
    return mapaDef;
  }

  canActivate(param: any, routeConfig: any) {
    if (environment.debug) console.log("[ExpedicaoTabelaVirtual]", "canActivate", this, "param", param, "routeConfig", routeConfig);
    let haveAccess = this.app.auth.can("App.VerPlano");
    let planos     = this.app.auth.getPlanos();
    if (planos.length == 0) {
      this.app.notificationErrorCompact("O utilizador não dispõe de autorização para visualização de nenhum plano.");
      return false;
    }

    if (haveAccess) {
      if (param.ca) {
        this.ca = param.ca.split("+");
      } else {
        this.ca = (localStorage.clienteArmazem ? JSON.parse(localStorage.clienteArmazem) : this.app.auth.getPlanos());
      }
      if (environment.debug) console.log("[ExpedicaoTabelaVirtual]", "canActivate", "parsed argument", this.ca);

      //filtro sobre a os ca de acordo com aqueles que o utilizador contém autorização de leitura
      this.ca = this.ca.filter(ca => planos.includes(ca));
      localStorage.setItem("clienteArmazem", JSON.stringify(this.ca));
    } else {
      this.app.notificationWarning("Não dispõe das permissões necessárias para visualizar qualquer plano");
    }
    this.podeCriarLinhas = this.app.auth.can("Plano.CriaLinhas");
    this.podeEditar      = this.app.auth.can('App.EditaEventosPlano');
    //negar acesso
    if (!haveAccess) return false;

    //carregar depenências
    return ClienteArmazem.memoizeMultiple(this.app.api, (param && param.rebuild && param.rebuid == "all"))
      .then(planos => this.listaPlanos = planos)
      .then(_ => true);
    // return haveAccess;
  }

  activate(param: any, routeConfig: any) {
    this.pageElements = (((window.innerHeight - 280) / this.rowHeight) | 0);
    if (this.pageElements < 0) this.pageElements = 28;
    if (this.pageElements > 56) this.pageElements = 56;
    // if (environment.debug) this.pageElements = 20;
    if (environment.debug) console.log("[ExpedicaoTabelaVirtual]", "activate()", param, routeConfig, window.innerHeight, this.pageElements);

    // this.podeEditar = false;
    // this.ca           = param.ca || (localStorage.clienteArmazem ? localStorage.clienteArmazem : "LA");
    // localStorage.setItem("clienteArmazem", this.ca);

    if (routeConfig.name == "armazem") {
      this.title     = "Armazém";
      this.tipoPlano = "armazem";
    } else if (routeConfig.name == "expedicao") {
      this.title     = "Expedição";
      this.tipoPlano = "expedicao";
    } else {
      this.title     = "Expedição (Cálculo)";
      this.tipoPlano = "calculo";
    }

    //restore from localstorage.
    this.mapaDef = PlanoDefinition.fromLocalStorage(this);
    if (this.mapaDef == null) {
      this.mapaDef = this.defaultPlanoDefinition();
    }
    //voltinha dos tristes para localização do plano
    this.mapaDef.clienteArmazem   = this.ca;
    this.mapaDef.columns[0].value = this.ca;

    this.offsetVertical = (window.innerWidth - this.mapaDef.visibleWidth()) / 100;
    if (environment.debug) console.log("ocupado", this.mapaDef.visibleWidth(), "total", window.innerWidth, "offset", this.offsetVertical);
  }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  created() {
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "created", this.mapaDef);
  }

  makeDefaultPeriod() {
    const offset = this.actionWidth;
    if (!this.mapaDef.dataMin) {
      //valor por defeito
      if (environment.debug) console.log("[expedicao-tabela-virtual]", "makeDefaultPeriod", "set dataMin");
      this.mapaDef.dataMin = <any>dateISOString(addUTCDays(new Date(), -offset));
    }

    if (!this.mapaDef.dataMax) {
      //valor por defeito

      if (environment.debug) console.log("[expedicao-tabela-virtual]", "makeDefaultPeriod", "set dataMax");
      this.mapaDef.dataMax = <any>dateISOString(addUTCDays(this.mapaDef.dataMin, 2 * offset));
    }
  }

  attached() {
    this.definePeriodoVisivel();
    // this.isFetching = true;
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "attached", this.mapaDef);

    //todo: localizador temporal vertical
    // this.getLinhas().then(_ =>
    //   this.doAction("REFRESH-TABELA", null)
    // );

    this.getLinhas(0).then(_ => {
      this.setJssStyles();
      this.resetToTop();
      this.localizaDiaHoje();
    })
    // .then(_ =>
    //   this.doAction("REFRESH-TABELA", null)
    // );
  }

  localizaDiaHoje() {
    if (environment.debug) console.log("localizaDiaHoje", this.scrollLeft, this.indexOfCalendario - this.offsetVertical, this.indexOfCalendario, this.offsetVertical);
    this.scrollLeft = (this.indexOfCalendario - this.offsetVertical) < 0 ? 0 : (this.indexOfCalendario - this.offsetVertical) * 46;
  }

  resetToTop() {
    this.pageNumber = 0;
    this.scrollTop  = 0;
    this.isBusy     = false;
    this.isFetching = false;
  }

  definePeriodoVisivel() {
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "definePeriodoVisivel");
    this.makeDefaultPeriod();
    let minimo          = new Date(this.mapaDef.dataMin);
    this.periodoVisivel = [];
    let counter         = 0;
    let maximo          = addUTCDays(new Date(this.mapaDef.dataMax), 1);
    let count           = 0;
    while (minimo <= maximo || count < 150) {
      let diaIsoString = dateISOString(minimo);

      // console.log("getDay", minimo.getDay());
      if ([1, 2, 3, 4, 5].includes(minimo.getUTCDay())) {
        this.periodoVisivel.push(CalendarioExpedicaoEpoca.fromPOJSO({dtmData: diaIsoString, _dtmData: minimo, nvcTipo: "ordinario"}, counter++))
      } else {
        if (this.mapaDef.mostraTodosOsDias) {
          this.periodoVisivel.push(CalendarioExpedicaoEpoca.fromPOJSO({dtmData: diaIsoString, _dtmData: minimo, nvcTipo: "virtual"}, counter++))
        }
      }
      //console.log(minimo, maximo, addUTCDays(minimo, 1) <= maximo, addUTCDays(minimo, 1), count);
      minimo = addUTCDays(minimo, 1);
      count  = count + 1;
    }
    console.log("saida", minimo, maximo);
  }

  /**
   *
   * @param pagina
   */
  getLinhas(pagina: number = 0) {
    if (pagina < 0) pagina = 0;
    let take = (this.bufferMultiplier + 1) * this.pageElements;
    // let take = this.pageElements;
    let skip = this.bufferMultiplier * this.pageElements * pagina;

    if (environment.debug) console.log("[ExpedicaoTabelaVirtual]", "getLinhas", `página ${pagina}, take: ${take}, skip: ${skip}, pageElements: ${this.pageElements}`);

    if (pagina > 1 && this._totalLinhasEpoca > 0) {
      // console.log("getLinhas", this._totalLinhasEpoca, skip, this._totalLinhasEpoca - skip, this.bufferMultiplier, this.pageElements);
      if (this._totalLinhasEpoca - skip <= (this.bufferMultiplier + 1) * this.pageElements) {
        take = this._totalLinhasEpoca - skip + 10;
        if (take < 0) return this.getLinhas(0);
      }
    }
    this.isFetching   = true;
    let searchPayload = this.mapaDef.getSearchPayload();
    let vskip         = Math.round(skip);
    if (vskip < 0) vskip = 0;
    let vtake = Math.round(take) + 2;
    return this.app.api.postProcessed(`api/plano-expedicao/linhas?take=${vtake}&skip=${vskip}&pag=${pagina}`, searchPayload)
      // .then(r => r.json())
      .then((res: any) => {
        this._totalLinhasEpoca = res.count || 0;
        this.take              = take;
        this.skip              = skip;
        this.pageNumber        = pagina;
        // ordena as linhas root consoante a lista de inteiros enviada pelo servidor (tem de ser antes do conjunto se achatar).
        if (res.order) {
          let orederedData = res.order.map(el => res.data.find(pe => pe.idPlanoExpedicao == el));
          return PlanoExpedicao.multipleFromPOJSO(orederedData, this.mapaDef.apenasAtivos);
        }
        // O segundo parâmetro elimina as linhas fechadas se estas não estiverem a ser visualizadas.
        return PlanoExpedicao.multipleFromPOJSO(res.data, this.mapaDef.apenasAtivos)
      })
      .then(flat => {
        // local filter -> é necessária uma limpeza "extra" para alguns filtros para que o frontend reflita o documento a gerar via Reporting
        let filtrosAplicados = this.mapaDef.columns.filter(t => (!!t.search) && ['CodArtigo', 'Descricao', 'Gravacao'].includes(t.name));
        // console.log("FILTROS_APLICADOS", filtrosAplicados);
        //let med                       = flat.filter(el => filtrosAplicados.reduce((acc, fel) => acc && ((fel.value.length == 1) ? (el[fel.bind] && el[fel.bind].toLowerCase().includes(fel.value[0].toLowerCase())) : fel.value.includes(el[fel.bind])), true));
        let med                       = flat.filter(el => filtrosAplicados.reduce((acc, fel) => acc && ((fel.value.length == 1) ? (el[fel.bind] && removeDiacriticsLower(el[fel.bind]).includes(removeDiacriticsLower(fel.value[0]))) : fel.value.includes(el[fel.bind])), true));
        //isolar os filtros numéricos aplicados
        let filtrosNumericosAplicados = this.mapaDef.columns.filter(t => (!!t.search) && ['QtyDespachadas', 'QtyPorLancar', 'QtyPorProgramar'].includes(t.name));
        // console.log("FILTROS_Numericos_APLICADOS", filtrosNumericosAplicados);
        //filtrar os resultados de acordo com o subconjunto de filtros numéricos
        med = med.filter(el => filtrosNumericosAplicados.reduce((acc, fel) => acc && ((fel.value.length == 1) ? (el[fel.bind] && el[fel.bind] > +fel.value[0].replace(/\D/g, '')) : fel.value == el[fel.bind]), true));
        return med;
      })
      .then(z => {
        //substitui as linhas
        this.linhas = z;
        this.definePeriodoVisivel();
        this.isBusy = false;
        this.clearSelection(false);
        return true;
      }).then(z => {
        this.drawEventosPlano();
        return true;
      }).then(z => {
        this.registerJQEvents();
        return true;
      })
      .catch(e => {
        this.app.notificationErrorCompact(e);
        return false
      });
  }

  calculaTotais() {
    this.totalEncomendado = 0;
    this.totalEnviado     = 0;
    this.totalPorFazer    = 0;
    this.linhas.forEach(el => {
      this.totalEncomendado += el._qtyTotal;
      this.totalEnviado += el._qtyEmFalta;
      this.totalPorFazer += (this.tipoPlano === "armazem") ? el._qtyPorLancar : el._qtyPorProgramar;
    });
  }

  setJssStyles() {
    jss.remove();
    jss.set(`.tabela-jss-left th:nth-child(1), .tabela-jss-left td:nth-child(1)`, {
      'max-width' : this.actionWidth + "px",
      'min-width' : this.actionWidth + 'px',
      'text-align': 'center',
      'background': 'lightblue',
    });

    // https://github.com/Box9/jss
    this.mapaDef.columns.forEach((e, i) => {
      // console.log(e, i);
      if (e.visible) {
        jss.set(`.tabela-jss-left th:nth-child(${i + 2})`, {
          'max-width' : e.width + "px",
          'min-width' : e.width + "px",
          'text-align': 'center',
          'background': e.background,
        });
        jss.set(`.tabela-jss-left td:nth-child(${i + 2})`, {
          'max-width' : e.width + "px",
          'min-width' : e.width + "px",
          'text-align': e.align || 'center',
          'background': e.background,
          //'cursor'    : 'pointer'
        });
        jss.set(`.tabela-jss-left td:nth-child(${i + 2}):focus`, {
          'width'     : e.width * 5 + "px",
          'z-index'   : '50',
          'background': 'white'
        });
      } else {
        jss.set(`.tabela-jss-left th:nth-child(${i + 2})`, {
          'display': 'none',
        });
        jss.set(`.tabela-jss-left td:nth-child(${i + 2})`, {
          'display': 'none',
        });
      }
    });
    jss.set(`.tabela-jss-left th:last-child, .tabela-jss-left td:last-child`, {
      // 'background': 'red',
      'border-right': '1px solid black'
    });

    if (Array.isArray(this.periodoVisivel) && this.periodoVisivel[0]) {
      let now                = new Date();
      // console.log(this.periodoVisivel);
      this.indexOfCalendario = this.periodoVisivel.findIndex(e => new Date(e.dtmData) >= now);
      if (this.indexOfCalendario < 0) this.indexOfCalendario = this.periodoVisivel.length - 1;
      //aqui já se conhece o calendário.
      jss.set(`.tabela-jss-right  td.ordinario, .tabela-jss-right  th.ordinario`, {
        'background': "#e9e9e9"
      });
      jss.set(`.tabela-jss-right  td:nth-child(-n + ${this.indexOfCalendario}), .tabela-jss-right  th:nth-child(-n + ${this.indexOfCalendario})`, {
        'background': "#f5f1e6"
      });
      jss.set(`.tabela-jss-right  td:nth-child(-n + ${this.indexOfCalendario}).ordinario, .tabela-jss-right  th:nth-child(-n + ${this.indexOfCalendario}).ordinario`, {
        'background': "#e9e0c7"
      });
      jss.set(`.tabela-jss-right  td:nth-child(${this.indexOfCalendario}), .tabela-jss-right  th:nth-child(${this.indexOfCalendario})`, {
        'background': "#e6d1c1"
      });
      jss.set(`.tabela-jss-right  td:nth-child(${this.indexOfCalendario}).ordinario, .tabela-jss-right  th:nth-child(${this.indexOfCalendario}).ordinario`, {
        'background': "#ffbe95"
      })
    }
  }

  /**
   * Regista eventos tipo "live" com jquery
   * @return {boolean}
   */
  registerJQEvents() {
    if (["expedicao", "armazem"].includes(this.tipoPlano)) {
      if (environment.debug) console.log("[ExpedicaoTabelaVirtual]", "Registar Eventos Normais", this.tipoPlano);
      return this.normalJqEvents();
    } else {
      if (environment.debug) console.log("[ExpedicaoTabelaVirtual]", "Registar Eventos de Cálculo / Seleção por célula", this.tipoPlano);
      return this.selectionJqEvents();
    }
  }

  private selectionJqEvents() {
    // replicar um evento manualmente.
    // pode ser necessário para que a grelha "ouça" os clicks quando existem células.
    //http://jsfiddle.net/Cr9Kt/1/
    if (!this.podeEditar) {
      // this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      return;
    }
    window["prgChange"] = (e: any, idRegisto: number) => {
      if (environment.debug) console.log("[expedicao-tabela-virtual]", "Na view de cálculo não é possivel a edição da quantidade programada.");
    };

    $(this.mapa).off("click", "td").on("click", "td", (e) => {
      e.preventDefault();
      let cell      = e.currentTarget;
      let cellIndex = (cell as HTMLTableCellElement).cellIndex;
      let row       = cell.parentNode;
      let rowIndex  = (row as HTMLTableRowElement).rowIndex;
      if (environment.debug) console.log("[expedicao-tabela-virtual]", "Seleção", "cellIndex", cellIndex, "rowIndex", rowIndex, "event", e);
      // this.doAction("SELECTION-START", {x: cellIndex, y: rowIndex});
      this.selectionStart(cellIndex, rowIndex, <MouseEvent>e.originalEvent);
    });

    return false;
  }

  selectionStart(x: number, y: number, e: MouseEvent) {
    if (e.shiftKey) { this.activeSelection.add(x, y); }

    if (e.ctrlKey) {
      this.activeSelection.fase = 0;
      this.activeSelection.add(x, y);
    }

    if (!e.shiftKey && !e.ctrlKey) {
      this.clearSelection(true);
      this.activeSelection.add(x, y);
    }

    let registosSelecionados = new Set();

    this.activeSelection.forEach((xe, ye) => {
      let row = this.mapa.rows[ye];
      xe.forEach(el => {
        row.cells[el].classList.add("SEL");
        // // console.log(row.cells[el], row.cells[el].textContent);
        // if(row.cells[el]){
        //
        // }
      });

      let linhaPlano       = this.linhas[ye];
      linhaPlano._selected = true;
      let registos         = linhaPlano.getRegistosPorIndices(xe);
      registos.forEach(el => registosSelecionados.add(el));
    });
    this.resumo.setState(registosSelecionados);
    this.calculaTotaisSelecao();
  }

  clearSelection(updateView) {
    if (updateView) {
      this.activeSelection.forEach((xe, ye) => {
        let row = this.mapa.rows[ye];
        xe.forEach(el => row.cells[el].classList.remove("SEL"));
      });
    }
    this.linhas.forEach(el => el._selected = false);
    this.selectionCount = this.cellSelectionCount = 0;
    this.resumo.setState(new Set);
    this.activeSelection.clearAll();
  }

  //aqui está um excelente exemplo de como as coisa não deviam ter sido feitas.
  private normalJqEvents() {
    // replicar um evento manualmente.
    // pode ser necessário para que a grelha "ouça" os clicks quando existem células.
    //http://jsfiddle.net/Cr9Kt/1/
    if (!this.podeEditar) {
      // this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      return;
    }
    window["prgChange"] = (e: any, idRegisto: number) => {
      let inputElem = e.currentTarget;
      let cell      = inputElem.parentNode.parentNode;
      let cellIndex = (cell as HTMLTableCellElement).cellIndex;
      let row       = cell.parentNode;
      let rowIndex  = (row as HTMLTableRowElement).rowIndex;
      // console.log("prgChange", e, idRegisto, "element", inputElem, inputElem.value, "row index:", rowIndex, "cell index:", cellIndex);
      try {
        this.doAction("EDITA-REGISTO-DIRETO", {rowIndex, cellIndex, registo: idRegisto, intDelta: (+inputElem.value || 0)});
      } catch (err) {
        console.error("err", err);
      }
    };

    $(this.mapa).off("click", "td").on("click", "td", (e) => {
        e.preventDefault();
        let cell      = e.currentTarget;
        let cellIndex = (cell as HTMLTableCellElement).cellIndex;
        let row       = cell.parentNode;
        let rowIndex  = (row as HTMLTableRowElement).rowIndex;
        // console.log("row index:", rowIndex, "cell index:", cellIndex, "target", e.target);
        //
        //this.selectionStart(cellIndex, rowIndex);
        if (!cell.innerHTML) {
          //click numa célula vazia (passam-se os índices y e x)
          this.doAction("CELULA-VAZIA", {rowIndex, cellIndex});
        } else {
          //click numa célula com eventos: 1 ou mais
          let children = cell.childNodes;
          if (children.length == 1) { // exatamente 1
            let lastChild = children[0] as HTMLDivElement;
            if (lastChild.classList.contains("FAL")) {
              //this.doAction("EDITA-REGISTO-FAL", {rowIndex, cellIndex});
            } else if (lastChild.classList.contains("PRG")) {
              // console.log("PRG clicked!");
              //this.doAction("EDITA-REGISTO-FAL", {rowIndex, cellIndex});
            } else if (lastChild.classList.contains("SAI")) {
              this.doAction("LISTAGEM-REGISTOS-LINHA", {rowIndex, cellIndex});
            } else {
              //console.log(lastChild);
              let idRegisto = lastChild.dataset.idRegisto;
              this.doAction("EDITA-REGISTO", {rowIndex, cellIndex, registo: idRegisto});
            }
          } else { // mais do que 1
            this.doAction("LISTAGEM-REGISTOS-LINHA", {rowIndex, cellIndex});
          }
        }
      })
      .off("dblclick", "td > div.PRG").on("dblclick", "td > div.PRG", (e) => {
      // console.log("[dblclick] ", e);
      if ($(e.target).hasClass('PRG')) {
        e.preventDefault();
        let cell      = e.currentTarget.parentNode;
        let cellIndex = (cell as HTMLTableCellElement).cellIndex;
        let row       = cell.parentNode;
        let rowIndex  = (row as HTMLTableRowElement).rowIndex;
        console.log("[dblclick] row index:", rowIndex, "cell index:", cellIndex, "target", e.target);
        this.doAction("LISTAGEM-REGISTOS-LINHA", {rowIndex, cellIndex});
      }
    });

    // Eventos de Drag
    $(document).off("dragstart").on("dragstart", ".PRG, .FAL, .LCM", (event) => {
      //let de: DragEvent = event.originalEvent as DragEvent;
      let dt        = (event.originalEvent as DragEvent).dataTransfer;
      let node      = event.target;
      let cell      = node.parentNode;
      let row       = cell.parentNode as HTMLTableRowElement;
      let rowIndex  = (row as any).rowIndex;
      let cellIndex = (cell as any).cellIndex;

      (node as HTMLDivElement).style.opacity = "0.4";
      dt.setData('text/html', node.outerHTML);
      let idRegisto = (node as HTMLElement).dataset.idRegisto;

      //marca a(s) rows que servirão de alvo para drop
      row.classList.add("drag-target");
      //registar o evento de listener para as células
      $(row).on("dragenter dragover dragleave drop", "td", (innerEvent) => {
        if (innerEvent.preventDefault) innerEvent.preventDefault();

        let cellElement = (innerEvent.target as HTMLTableCellElement);
        if (innerEvent.type === 'dragenter') { cellElement.classList.add("cell-hover"); }
        if (innerEvent.type === 'dragleave') { cellElement.classList.remove("cell-hover"); }
        if (innerEvent.type === 'drop') {
          innerEvent.stopPropagation();

          let newCellIndex = cellElement.cellIndex;
          if (newCellIndex === undefined || newCellIndex === null) newCellIndex = (cellElement.parentNode as HTMLTableCellElement).cellIndex;
          if (newCellIndex === undefined || newCellIndex === null) newCellIndex = cellIndex;

          // console.log("drop na grelha:", `origem: ${rowIndex} x ${cellIndex}`, `destino: ${rowIndex} x ${newCellIndex}`, cellElement);
          if (cellIndex != newCellIndex) {
            // let de: DragEvent = innerEvent.originalEvent as DragEvent;
            // let dt            = de.dataTransfer;
            //reconstrói o conteúdo
            let transf  = (innerEvent.originalEvent as DragEvent).dataTransfer.getData("text/html");
            let $transf = $(transf);

            if ($transf.hasClass("PRG") || $transf.hasClass("LCM"))
              this.doAction("TRANSFERE-REGISTO", {idRegisto, rowIndex, cellIndex: cellIndex, newCellIndex: newCellIndex});
            if ($transf.hasClass("FAL"))
              this.doAction("EDITA-REGISTO-FAL-TIPO2", {rowIndex, cellIndex: cellIndex, newCellIndex: newCellIndex});
            (node as HTMLDivElement).style.opacity = "1";
          } else {
            this.app.notificationError("deve arrastar a célula para outra coluna.");
            (node as HTMLDivElement).style.opacity = "1";
          }
          row.classList.remove("drag-target");
          cellElement.classList.remove("cell-hover");
          //desvincular os listeners para os eventos de drag
          $(row).off("dragenter dragover dragleave drop", "td");
          // return true;
        }
      });
    });

    // //de-registar o evento de listener para as células
    // // to do: e repor efeitos visuais com classes
    // $(document).off("dragend", ".movimento").on("dragend", ".movimento", function (event) {
    //   console.log("dragend");
    //   (event.target as any).style.opacity = 1;
    //   // (event.target as HTMLDivElement).style.color = "red";
    //   $(event.target.parentNode.parentNode).css("background", "white");
    //   $(event.target.parentNode.parentNode).off("dragenter dragover dragleave drop", "td");
    //   event.preventDefault();
    //   event.stopPropagation();
    // });

    return false;
  }

  /**
   * desenha as células representativas de eventos
   */
  drawEventosPlano() {
    this.drawTipo2();
  }

  drawTipo2() {
    this.totalEncomendado  = 0;
    this.totalEnviado      = 0;
    this.totalPorFazer     = 0;
    let inverseLookup: any = {};
    let rowInnerHtml       = "";

    //construir lookup inverso
    for (let dia of this.periodoVisivel) {
      inverseLookup[dia.dtmData] = dia.__index;
      if (dia.nvcTipo == "ordinario")
        rowInnerHtml += "<td class='ordinario'></td>";
      else
        rowInnerHtml += "<td></td>";
    }
    rowInnerHtml += "<td></td>";

    //limpar o conteúdo da parte direita
    this.mapa.innerHTML = "";

    try {
      this.linhas.forEach((linha, i) => {

        let row       = this.mapa.insertRow(i);
        row.innerHTML = "" + rowInnerHtml;

        let rowCells = row.querySelectorAll("td");
        // console.log(rowCells);

        let accProduzidas  = 0;
        let accProgramadas = 0;
        let accSaida       = 0;

        this.totalEncomendado += +linha._qtyTotal;
        this.totalEnviado += +linha._qtyEmFalta;
        // antes da inclusão dos campos totalizadores
        // this.totalPorFazer += (this.tipoPlano === "armazem") ? +linha._qtyPorLancar : +linha._qtyPorProgramar;
        this.totalPorFazer += (this.tipoPlano === "armazem") ? +linha.intPorLancar : +linha.intPorProgramar;

        let reordenaRegistosParaVisualizacao: RegistosAgrupados[] = linha.reordenaRegistosParaVisualizacaoEt(this.periodoVisivel, inverseLookup);

        for (let p of reordenaRegistosParaVisualizacao) {
          // console.log(p);
          accProduzidas  = p.prod.reduce((acc, el) => acc + el.intDelta, accProduzidas);
          accProgramadas = p.prg.reduce((acc, el) => acc + el.intDelta, accProgramadas);
          accSaida       = p.sai.reduce((acc, el) => acc + el.intDelta, accSaida);
          //não há geração de html para os RegistosAgrupados de índice < 1
          if (p.index < 0) {
            continue;
          }

          // As programações devem ser mostradas?
          if (this.mapaDef.registoPrg && p.prg.length > 0) {
            let registoPrg              = RegistoPlano.FactoryRepresentante(p.prg);
            rowCells[p.index].innerHTML = registoPrg.htmlPill();
            accProduzidas += registoPrg.intDelta;
            // // rowCells[p.index].innerHTML = p.prg.filter(el => el.GuiaTransporteLinhaIdRpParcelaNavigation == null).reduce((acc, el) => acc + el.htmlPill(), rowCells[p.index].innerHTML);
            // let prg = p.prg.filter(el => el.GuiaTransporteLinhaIdRpParcelaNavigation == null).slice(-1)[0];
            // if (prg) {
            //   if (prg.intDelta > 0) {
            //     if (this.podeEditar) {
            //     } else {
            //       rowCells[p.index].innerHTML = prg.htmlPill2ColorVisualizacao(accProduzidas - accProgramadas + prg.intDelta, tiposRegistoCorProgress.LCM, this.tipoPlano);
            //     }
            //   } else {
            //     //rowCells[p.index].innerHTML = prg.usedHtmlPill();
            //   }
            //   continue;
            // }
          }

          // As produções devem ser mostradas?
          if (this.mapaDef.registoProd && p.prod.length > 0) {
            let registoProd             = RegistoPlano.FactoryRepresentante(p.prod);
            rowCells[p.index].innerHTML = registoProd.htmlPill();
            accProduzidas += registoProd.intDelta;
          }

          // As saídas devem ser mostradas?
          if (this.mapaDef.registoSaiFal && p.sai.length > 0) {
            let registoSai              = RegistoPlano.FactoryRepresentante(p.sai);
            rowCells[p.index].innerHTML = registoSai.htmlPill();
            accProduzidas += registoSai.intDelta;
          }
        }
      })
    } catch (err) {
      console.log("Error drawTipo2()", err);
    }
  }

  clearFilters() {
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "clearFilters", this.mapaDef);
    this.mapaDef.columns.forEach(el => el.open = false);
    for (let i = 1; i < this.mapaDef.columns.length; i++) {
      this.mapaDef.columns[i].value = [];
    }
    this.mapaDef.familia = null;
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "clearFilters", this.mapaDef);
    this.mapaDef.doSearch();
  }

  /**
   * Atividades de limpeza
   */

  detached() {
    this.linhas         = null;
    // this.items      = null;
    this.calendario     = null;
    this.periodoVisivel = null;
    //limpa os estilos virtuais.
    jss.remove();
  }

  //endregion

  //region Memória
  useMemory(slot: string) {
    if (environment.debug) console.log("[ExpedicaoTabelaVirtual]", "TROCA DE MEMÓRIA");
    if (this.mapaDef.restoreMemory(slot))
      this.mapaDef.doSearch();
    this.mapaDef.memoriaAtual = slot;
  }

  setMemory() {
    let memoriaAtual = this.mapaDef.memoriaAtual;
    if (memoriaAtual) {
      this.mapaDef.saveMemory(memoriaAtual);
    }
    this.mapaDef.memoriaAtual = "";
    this.mapaDef.memoriaAtual = memoriaAtual;
    //this.useMemory(memoriaAtual);
  }

  resetMemory() {
    if (this.mapaDef.memoriaAtual) {
      this.mapaDef.memoriaAtual = "";
    }
    this.clearFilters();
  }

  clearMemory() {
    if (this.mapaDef.memoriaAtual) {
      console.log("clearing....", this.mapaDef.memoriaAtual);
      this.mapaDef.clearMemory(this.mapaDef.memoriaAtual);
      this.mapaDef.memoriaAtual = "";
    } else {
      console.log("clearing all...");
      this.mapaDef.memoriaAtual = "clear";
      this.mapaDef.clearMemory();
      this.mapaDef.memoriaAtual = "";
    }
    this.clearFilters();
  }

  //endregion

  //region Acções Diretas
  /**
   * Action creator
   * @param rowIndex
   * @return {undefined}
   */
  listaRegistosLinha(rowIndex: number = -1) {
    if (this.isFetching) return;
    return this.doAction("LISTAGEM-REGISTOS-LINHA", {rowIndex: rowIndex, cellIndex: -1})
  }

  private normalizaNumerico(str: string) {
    const re = /([=><]{0,2})\s*(\d+)/;
    let res  = re.exec(str);
    let op   = res[1];
    let val  = res[2];
    if (!op || (op === "")) op = "=";
    if (!val || (val === "")) val = "0";
    console.log("normalizaNumerico", str, res);
    return op.trim() + ' ' + (val.trim());
  }

  /**
   * Geração do payload para obtenção do XLS do plano
   * @param tipo
   */
  public transfere(tipo: string = "xls") {
    if (tipo != "xls") tipo = "pdf";
    let payload: any = {id_epoca: 1, nvc_cliente_armazem: this.mapaDef.clienteArmazem.join(",")};

    // atribuíção dos parametros adicionais à geração do Report
    if (this.mapaDef.dataMin) { payload.dtm_data_ini = this.mapaDef.dataMin; }
    if (this.mapaDef.dataMax) { payload.dtm_data_fim = this.mapaDef.dataMax; }
    if (this.mapaDef.mostraTodosOsDias) { payload.ver_todos_dias = 1 }
    if (this.mapaDef.apenasAtivos) { payload.nvc_estado = "aberto" }
    if (this.mapaDef.verVirtuais) { payload.nvc_tipo = "erp,plano" } else { payload.nvc_tipo = "erp" }

    if (this.mapaDef.columns[1] && this.mapaDef.columns[1].value.length > 0) {
      let nvcArtigoTerminacao = this.mapaDef.columns[1].search;
      if (nvcArtigoTerminacao) payload.nvc_artigo_terminacao = nvcArtigoTerminacao;
    }
    if (this.mapaDef.columns[2] && this.mapaDef.columns[2].value.length > 0) {
      let nvcDescricaoArtigo = this.mapaDef.columns[2].search;
      if (nvcDescricaoArtigo) payload.nvc_descricao_artigo = nvcDescricaoArtigo;
    }
    if (this.mapaDef.columns[3] && this.mapaDef.columns[3].value.length > 0) {
      let nvcTipoGravacao = this.mapaDef.columns[3].search;
      if (nvcTipoGravacao)
        payload.nvc_tipo_gravacao = nvcTipoGravacao;
    }
    if (this.mapaDef.columns[4] && this.mapaDef.columns[4].value.length > 0) {
      let dtmPedidoMin = this.mapaDef.columns[4].primeiro;
      if (dtmPedidoMin) payload.dtm_pedido_min = dtmPedidoMin;
      let dtmPedidoMax = this.mapaDef.columns[4].segundo;
      if (dtmPedidoMax) payload.dtm_pedido_max = dtmPedidoMax;
    }
    if (this.mapaDef.columns[5] && this.mapaDef.columns[5].value.length > 0) {
      let DtmRececaoMin = this.mapaDef.columns[5].primeiro;
      if (DtmRececaoMin) payload.dtm_rececao_min = DtmRececaoMin;
      let DtmRececaoMax = this.mapaDef.columns[5].segundo;
      if (DtmRececaoMax) payload.dtm_rececao_max = DtmRececaoMax;
    }
    if (this.mapaDef.columns[6] && this.mapaDef.columns[6].value.length > 0) {
      if (this.mapaDef.columns[6].search)
        payload.nvc_codigo_encomenda = this.mapaDef.columns[6].search;
    }
    if (this.mapaDef.columns[7] && this.mapaDef.columns[7].value.length > 0) {
      if (this.mapaDef.columns[7].search) payload.nvc_rg = this.mapaDef.columns[7].search;
    }

    if (this.mapaDef.columns[8] && this.mapaDef.columns[8].value.length > 0) {
      let intTotalCalculado = this.mapaDef.columns[8].primeiro;
      if (intTotalCalculado) payload.int_total_calculado = this.normalizaNumerico(intTotalCalculado);
    }

    if (this.mapaDef.columns[9] && this.mapaDef.columns[9].value.length > 0) {
      let intPorSair = this.mapaDef.columns[9].primeiro;
      if (intPorSair) payload.int_por_sair = this.normalizaNumerico(intPorSair);
    }

    if (this.mapaDef.columns[10] && this.mapaDef.columns[10].value.length > 0) {
      let intPorProgramar = this.mapaDef.columns[10].primeiro;
      if (intPorProgramar) payload.int_por_programar = this.normalizaNumerico(intPorProgramar);
    }

    // inclusão dos parametros que determinam a ordenação do report.
    payload.SortByLevel0    = 'nvc_cliente_armazem';
    payload.SortOrderLevel0 = 'ASC';
    payload.SortByLevel1    = 'dtm_pedido';
    payload.SortOrderLevel1 = 'ASC';
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "this.mapaDef.order", this.mapaDef.order);
    if (this.mapaDef.order && this.mapaDef.order.length > 0) {
      let lv0                 = this.mapaDef.order[0];
      payload.SortByLevel0    = this.mapaDef.reportOrderTranslation[lv0.name];
      payload.SortOrderLevel0 = "" + lv0.direction;
      let lv1                 = this.mapaDef.order[1];
      if (lv1) {
        payload.SortByLevel1    = this.mapaDef.reportOrderTranslation[lv1.name];
        payload.SortOrderLevel1 = "" + lv1.direction;
      }
    }
    if (payload.SortByLevel0 == payload.SortByLevel1) {
      console.error("O sort iria gerar dois níveis iguais");
      payload.SortByLevel1 = (payload.SortByLevel0 == 'nvc_cliente_armazem') ? 'dtm_pedido' : 'nvc_cliente_armazem';
    }

    payload.registos_vazios = 1;
    this.isFetching         = true;
    if (this._totalLinhasEpoca > 10) this.app.notificationWarning("A geração do ficheiro é uma operação pesada que pode demorar alguns minutos a concluir. Por favor seja paciente.");

    if (this.mapaDef.familia) {
      payload.nvc_familia = this.mapaDef.familia;
    }

    this.app.api
      .post("api/plano-expedicao/report/MapaExpedicao?tipo=" + tipo /*+ "&definicao=1"*/, payload)
      .then(r => this.app.api.processBlobResponse(r))
      .then(blob => {
        let URL         = window.URL || (window as any).webkitURL;
        let downloadUrl = URL.createObjectURL(blob);
        let a           = document.createElement("a");
        // safari doesn't support theese yet
        a.href          = downloadUrl;
        a.download      = `plano-${this.mapaDef.clienteArmazem}-${dateISOString(new Date())}.${tipo}`;
        document.body.appendChild(a);
        a.click();
        this.app.notificationSuccess("Ficheiro transferido para a pasta dos downloads com o nome: <strong>" + a.download + "</strong>");
        setTimeout(function () {
          URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);
        }, 200); // cleanup
        this.isFetching = false;
      })
      .catch(e => {
        this.app.notificationErrorCompact(e);
        this.isFetching = false;
      });
  }

  public popupConfigTabela() {
    this.app.ds
      .open({viewModel: ConfigTabelaVirtual, model: this.mapaDef.deepClone(), lock: true, keyboard: false, overlayDismiss: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          if (response.output && response.output.action) {
            this.doAction(response.output.action, response.output.payload);
          }
        }
      }).catch(err => this.app.notificationErrorCompact(err));
  }

  public gravaLinhaPlano(linha: PlanoExpedicao) {
    if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
      this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
      return;
    }
    this.isFetching = true;
    this.app.api
      .postProcessed("api/plano-expedicao/add-linha", linha.stateToPOJSO())
      .then(r => {
        if (environment.debug) console.log("[expedicao-tabela-virtual]", "gravaLinhaPlano", r);
        linha.nvcArtigoTerminacao = r.nvcArtigoTerminacao;
        linha.nvcDescricaoArtigo  = r.nvcDescricaoArtigo;
        linha.intTotalCalculado   = r.intTotalCalculado;
        linha.intPorProgramar     = r.intPorProgramar;
        linha.intPorLancar        = r.intPorLancar;
        linha.intPorSair          = r.intPorSair;
        this.app.notificationSuccess("Linha do plano gravada no servidor");
        this.isFetching = false;
        // this.doAction("REFRESH-TABELA", null);
      }, (err) => console.log("ERR", err))
      .catch(err => {
        this.app.notificationErrorCompact(err);
        this.doAction("REFRESH-TABELA", null)
      });
  }

  public duplicaLinhaPlano(p, i) {
    if (!this.app.auth.canWritePlano(p.nvcClienteArmazem)) {
      this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${p.nvcClienteArmazem}`);
      return;
    }

    this.app.ds
      .open({viewModel: DuplicaLinhaPlanoDialog, model: <DuplicaLinhaPlanoPayload>{duplicacaoVm: p.duplicateMe(), rowIndex: i, cellIndex: -1}, lock: true})
      .whenClosed(resp => {
        if (!resp.wasCancelled) { this.doAction(resp.output.action, resp.output.payload); }
      }).catch(err => this.app.notificationErrorCompact(err));
  }

  public removeDuplicadoLinhaPlano(vmw: VmWrapper<PlanoExpedicao>) {
    return this.app.api
      .deleteProcessed('api/plano-expedicao/remove-duplicado', null, vmw.stateToPOJSO())
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => { return acc + '<li>' + el + '</li>' }, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  return this.removeDuplicadoLinhaPlano(vmw.nextLevel());
                } else {
                  this.isFetching = false;
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        } else {
          if (environment.debug) console.log("[expedicao-tabela-virtual]", "returning", obj);
          return {action: "REFRESH-TABELA", payload: null};
        }
      })
  }

  public reajustaDuplicadoLinhaPlano(vmw: VmWrapper<PlanoExpedicao>) {
    return this.app.api
      .postProcessed('api/plano-expedicao/ajusta-duplicado', vmw.stateToPOJSO("stateToPOJSOSimple"))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => { return acc + '<li>' + el + '</li>' }, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  return this.reajustaDuplicadoLinhaPlano(vmw.nextLevel());
                } else {
                  this.isFetching = false;
                  this.doAction("REFRESH-TABELA", null);
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        } else {
          if (environment.debug) console.log("[expedicao-tabela-virtual]", "returning", obj);
          return {action: "REFRESH-TABELA", payload: null};
        }
      })
  }

  private editaFal(vmw: VmWrapper<RegistoPlano>) {
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "editaFal", vmw);
    this.isFetching = true;
    this.app.api
      .postProcessed('api/plano-expedicao/transforma-falta', vmw.stateToPOJSO())
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => { return acc + '<li>' + el + '</li>' }, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  return this.editaFal(vmw.nextLevel());
                } else {
                  this.isFetching = false;
                }
              });
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        this.app.notificationSuccess("Sucesso");
        let registos = RegistoPlano.multipleFromPOJSO(obj);
        this.doAction("UPSERT-REGISTO-LINHA", {models: registos, rowIndex: this.linhas.findIndex(el => el.idPlanoExpedicao == vmw.payload.idPlanoExpedicao)});
      })
      .catch(err => {
        this.app.notificationErrorCompact(err);
        this.doAction("REFRESH-TABELA", null);
      })
  }

  public fecharLinhas(vmw: VmWrapper<number[]>) {
    // this.isBusy = true;
    return this.app.api
      .postProcessed("api/plano-expedicao/fecha-linhas", vmw.stateToPOJSO())
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => { return acc + '<li>' + el + '</li>' }, '')}</ul>`;
            return this.app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  return this.fecharLinhas(vmw.nextLevel());
                } else {
                  //nada
                }
              });
          } else if (obj.tipo === 'warning') {
            this.app.notificationWarning(["A(s) linha(s) foram fechada(s) mas aconteceram erros ao movimentar os stocks:", ...obj.mensagens]);
            this.doAction("REFRESH-TABELA", null);
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, refresque a página e tente executar os passos novamente");
        }
        this.app.notificationSuccess("A(s) linha(s) foram fechada(s)");
        this.doAction("REFRESH-TABELA", null);
      })
      .catch(err => {
        this.app.notificationErrorCompact(err);
        this.doAction("REFRESH-TABELA", null);
      })
      ;
  }

  //endregion

  /**
   * Dispatcher de ações para esta view
   * @param action
   * @param payload
   * @param context
   */
  public doAction(action: string, payload: any, context?: any) {
    if (environment.debug) console.log("[expedicao-tabela-virtual]", "Acção [expedicao-tabela-virtual]", action, payload, context);
    switch (action) {
      //region visualização
      case 'PAGINA-PRIMEIRA': {
        if (!this.isFetching) {
          this.getLinhas(0)
            .then(_ => { this.isFetching = false; })
            .then(_ => this.resetToTop())
          ;
        }
        break;
      }
      case 'PAGINA-SEGUINTE': {
        if (!this.isFetching) {
          if (this.linhas.length > this.pageElements) {
            //console.log("aumenta a página", this.linhas.length * this.bufferMultiplier * this.rowHeight, "scrollTop", "total linhas epoca", this._totalLinhasEpoca, "skip", this.skip, "buffer", (this.bufferMultiplier + 1) * this.pageElements);
            if (this._totalLinhasEpoca - this.skip > (this.bufferMultiplier + 1) * this.pageElements) {
              this.getLinhas(this.pageNumber + 1)
                .then(_ => {
                  // this._scrollTop = this.rowHeight;
                  this.isFetching = false;
                });
            }
          }
        }
        break;
      }

      case 'PAGINA-ANTERIOR': {
        if (!this.isFetching) {
          if (this.pageNumber > 0) {
            console.log("reduz a página", this.linhas.length * (this.bufferMultiplier) * this.rowHeight);
            this.getLinhas(this.pageNumber - 1)
              .then(_ => {
                // this._scrollTop = (this.linhas.length - this.pageElements) * this.rowHeight - 1;
                this.isFetching = false;
              })
          }
        }
        break;
      }

      case "REFRESH-EVENTOS": {
        this.drawEventosPlano();
        this.isFetching = false;
        break;
      }

      //acções sobre a tabela
      case "REFRESH-TABELA": {
        this.isFetching = true;
        let memory      = this.scrollTop;
        //jss.remove();
        this.getLinhas(this.pageNumber)
          .then(_ => {
            this.setJssStyles();
            this.localizaDiaHoje();
            // this.registerJQEvents();
            this.scrollTop = memory;
          })
          .then(_ => this.isFetching = false)
          .catch(e => this.app.notificationErrorCompact(e));
        break;
      }

      case "VISIBILIDADE-REGISTOS": {
        if (payload == "Prg") this.mapaDef.registoPrg = !this.mapaDef.registoPrg;
        if (payload == "Lcm") this.mapaDef.registoProd = !this.mapaDef.registoProd;
        if (payload == "SaiFal") this.mapaDef.registoSaiFal = !this.mapaDef.registoSaiFal;
        if (payload == "Fal") this.mapaDef.registoFal = !this.mapaDef.registoFal;
        this.doAction("REFRESH-TABELA", null);
        break;
      }

      case 'DEFINE-DATA-PLANO': {
        let data = this.mapaDef["data" + payload];
        if (!!data) {
          this.app.ds
            .open({viewModel: PickerData, model: {dataInicial: data}, centerHorizontalOnly: true, rejectOnCancel: false})
            .whenClosed(response => {
              if (!response.wasCancelled && response.output && response.output.action) {
                console.log("DEFINE-DATA-PLANO", response);
                this.mapaDef["data" + payload] = response.output.payload;
                this.doAction("UPDATE-DEFINICAO-TABELA", this.mapaDef);
              }
            });
        }
        break;
      }

      case 'MOSTRA-TODOS-DIAS': {
        this.mapaDef.mostraTodosOsDias = !this.mapaDef.mostraTodosOsDias;
        this.doAction("UPDATE-DEFINICAO-TABELA", this.mapaDef);
        break;
      }
      case 'RESET-DATAS-PLANO': {
        this.mapaDef.dataMin = null;
        this.mapaDef.dataMax = null;
        this.makeDefaultPeriod();
        this.doAction("UPDATE-DEFINICAO-TABELA", this.mapaDef);
        break;
      }

      case "UPDATE-DEFINICAO-TABELA": {
        this.mapaDef = payload;
        this.mapaDef.toLocalStorage();
        this.doAction("REFRESH-TABELA", null);
        break;
      }

      case "RESET-DEFINICAO-TABELA": {
        this.mapaDef = this.defaultPlanoDefinition();
        this.mapaDef.toLocalStorage();
        this.doAction("REFRESH-TABELA", null);
        break;
      }
      //endregion

      //region edição linhas (e-t)
      case "NOVO-PLANO-EXPEDICAO": {
        let linha = new PlanoExpedicao({nvcClienteArmazem: 'GRL'});
        return this.doAction("EDITA-PLANO-EXPEDICAO-COMPOSE", linha);
      }

      case "EDITA-PLANO-EXPEDICAO": {
        let linha = payload as PlanoExpedicao;
        return this.app.api.getProcessed('api/plano-expedicao/plano-expedicao', {id: linha.idPlanoExpedicao})
          .then(r => PlanoExpedicao.fromPOJSO(r))
          .then(c => this.doAction("EDITA-PLANO-EXPEDICAO-COMPOSE", c))
      }

      /*
            case "EDITA-PLANO-EXPEDICAO-COMPOSE": {
              let modelo = payload as PlanoExpedicao;
              let composeSettings = {
                modelo: modelo,
                containerModelo: null,
                invoker: this,
                options: new ComposeDialogOptions({
                  title: modelo.idPlanoExpedicao <= 0 ? 'Nova linha de Encomenda' : 'Editar ' + modelo.toString(),
                  withDefaultFooter: false,
                  mainView: '../routes/plano/ce/_plano-expedicao-form.html',
                  postUri: 'api/plano-expedicao/plano-expedicao',
                  rootBindings: { listaPlanos: this.listaPlanos, estados: EstadoLinhaPlano.estados() }
                }),
              };
              return this.app.ds.open({
                viewModel: ComposeDialog,
                model: composeSettings
              })
                .whenClosed(r => {
                  if (!r.wasCancelled) {
                    this.app.notificationSuccess("Linha do plano gravada.");
                    this.doAction("REFRESH-TABELA", null);
                    //refrescar a tabela (tambem se podia invocar o doAction para o efeito)
                    // if(context && context.rg &&  typeof context.rg.refreshPage == "function") {
                    //   context.rg.refreshPage();
                    // }
                  }
                });

            }
      */
      //endregion

      // //region edição de linhas
      // //apaga uma linha de tipo "plano"
      // case "REMOVE-LINHA": {
      //   if (!this.podeEditar) return this.app.notificationErrorCompact("Não dispõe da permissão para efetuar essa operação");
      //   let linha: PlanoExpedicao = payload.linha || this.linhas[payload.rowIndex];
      //   if (!linha) {
      //     return this.app.notificationErrorCompact("A seleção não é válida. Não foi possível determinar a linha a eliminar.");
      //   }
      //
      //   this.isFetching = true;
      //   return this.app.confirmaDeletionTyped(linha.wrapIt(), 'api/plano-expedicao/remove-linha')
      //     .then(r => {
      //       if (this.app.processConfirmation(r)) {
      //         return r.payload || r;
      //       }
      //       return false;
      //     })
      //     .catch(err => this.app.notificationErrorCompact(err))
      //     .then(r => (this.isFetching = false) || r)
      //     .then(_ => this.doAction('REFRESH-TABELA', null));
      // }
      //
      // case "FUNDE-LINHAS": {
      //   if (!this.podeEditar) return this.app.notificationErrorCompact("Não dispõe da permissão para efetuar essa operação");
      //
      //   let selecao = this.linhas.filter(el => el._selected);
      //   if (selecao.length != 2) return this.app.notificationErrorCompact("A seleção não é válida.");
      //
      //   let linhaErp = selecao.find(l => l.nvcTipo == tipoLinhaPlanoExpedicao.erp);
      //   if (linhaErp == null) return this.app.notificationErrorCompact("A seleção não é válida. Não foi possível determinar a linha destino");
      //
      //   let linhaPlano = selecao.find(l => l.nvcTipo == tipoLinhaPlanoExpedicao.plano);
      //   if (linhaPlano == null) return this.app.notificationErrorCompact("A seleção não é válida. Não foi possível determinar a linha origem");
      //
      //   let fusao = new FusaoPlanoExpedicaoVm({idLinhaErp: linhaErp.idPlanoExpedicao, idLinhaPlano: linhaPlano.idPlanoExpedicao});
      //
      //   if (environment.debug) console.log("[expedicao-tabela-virtual]", "FUNDE-LINHAS", linhaErp, fusao);
      //   return this.app.confirmaActionTyped(fusao.wrapIt(), 'api/plano-expedicao/funde-linhas')
      //     .then(r => {
      //       if (this.app.processConfirmation(r)) {
      //         this.doAction("REMOVE-LINHA", {linha: linhaPlano});
      //         // this.doAction('REFRESH-TABELA', null);
      //         return r.payload || r;
      //       }
      //       return false;
      //     })
      //     .catch(err => this.app.notificationErrorCompact(err))
      //     .then(r => (this.isFetching = false) || r)
      //   // .then(_ => this.doAction('REFRESH-TABELA', null));
      // }
      //
      // //isto é usado?
      // case 'MUDA-DATA': {
      //   let linha = payload as PlanoExpedicao;
      //   this.app.ds
      //     .open({viewModel: PickerData, model: {dataInicial: linha.dtmPedido}, centerHorizontalOnly: true, rejectOnCancel: false})
      //     .whenClosed(response => {
      //       if (!response.wasCancelled) {
      //         if (response.output && response.output.action) {
      //           linha.dtmPedido = response.output.payload;
      //           this.doAction("EDITA-LINHA-DIRETO", linha);
      //           return;
      //         }
      //       }
      //       console.log("dialog closed!");
      //     });
      //
      //   break;
      // }
      //
      // case 'ATUALIZA-LINHA': {
      //   // if (!this.podeEditar) {
      //   //   this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      //   //   return;
      //   // }
      //   this.app.notificationSuccess("Linha de encomenda alterada com sucesso");
      //   let linha = this.linhas[payload.rowIndex];
      //   linha.setState(payload.model);
      //   //this.linhas = [...this.linhas];
      //   this.doAction("REFRESH-TABELA", null);
      //   break;
      // }
      //
      // //popup de registos da linha
      // case "LISTAGEM-REGISTOS-LINHA": {
      //   let linha       = this.linhas[payload.rowIndex];
      //   this.isFetching = true;
      //
      //   //LEGACY
      //   this.app.ds
      //     .open({viewModel: ListaRegistoPlanoDialog, model: {linha: linha, rowIndex: payload.rowIndex, cellIndex: payload.cellIndex}, lock: false})
      //     .whenClosed(resp => {
      //       this.isFetching = false;
      //       if (!resp.wasCancelled) {
      //         this.doAction(resp.output.action, resp.output.payload);
      //       }
      //     });
      //   break;
      //
      //   // v1.2
      //   // Afinal
      //   // return this.app.api.getProcessed('api/plano-expedicao/linha-completa', {id: linha.idPlanoExpedicao})
      //   //   .then(r => {
      //   //     console.log(r);
      //   //     return r;
      //   //   })
      //   //   .then(r => PlanoExpedicao.fromPOJSO(r))
      //   //   .then(pe => {
      //   //     if(environment.debug) console.log("[expedicao-tabela-virtual]","openDialog", pe, payload);
      //   //     this.app.ds.open({viewModel: ListaRegistoPlanoDialog, model: {linha: pe, rowIndex: payload.rowIndex, cellIndex: payload.cellIndex}, lock: false})
      //   //       .whenClosed(resp => {
      //   //         if (!resp.wasCancelled) {
      //   //           this.doAction(resp.output.action, resp.output.payload);
      //   //         }
      //   //       })
      //   //   });
      // }
      //
      // case "DUPLICA-LINHA-PRG": {
      //   if (environment.debug) console.log("[expedicao-tabela-virtual]", "DUPLICA-LINHA-PRG", payload);
      //   if (!this.podeEditar) {
      //     this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      //     return;
      //   }
      //   let id = +payload.registoPlano.idRegistoPlano;
      //   if (id <= 0) {
      //     this.app.notificationError("Não foi possível determinar qual o registo de base para a duplicação.");
      //     return;
      //   }
      //   let pl = new DuplicacaoPlanoExpedicaoVm({idRegistoPlanoOriginario: id, intQtyTotalEncomenda: payload.registoPlano.intDelta});
      //   this.app.ds
      //     .open({viewModel: DuplicaLinhaPlanoPrgDialog, model: <DuplicaLinhaPlanoPrgPayload>{duplicacaoVm: pl}, lock: true})
      //     .whenClosed(resp => {
      //       if (!resp.wasCancelled) { this.doAction(resp.output.action, resp.output.payload);}
      //     }).catch(err => this.app.notificationErrorCompact(err));
      //   break;
      // }
      //
      // case "REMOVE-LINHA-DUPLICADA": {
      //   if (!this.podeEditar) {
      //     this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      //     return;
      //   }
      //   let linha: PlanoExpedicao = payload.linha || this.linhas[payload.rowIndex];
      //   if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
      //     this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
      //     return;
      //   }
      //
      //   this.isFetching = true;
      //   this.removeDuplicadoLinhaPlano(linha.wrapIt())
      //     .then(r => {
      //       if (r && r.action) {
      //         this.app.notificationSuccess("A linha duplicada foi removida");
      //         this.doAction(r.action, r.payload);
      //       }
      //     })
      //     .catch(err => {
      //       this.app.notificationErrorCompact(err);
      //       this.isFetching = false;
      //     });
      //   break;
      // }
      //
      // case "REDEFINE-LINHA-DUPLICADA": {
      //   if (!this.podeEditar) {
      //     this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      //     return;
      //   }
      //   let linha: PlanoExpedicao = payload.linha || this.linhas[payload.rowIndex];
      //   if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
      //     this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
      //     return;
      //   }
      //
      //   this.isFetching = true;
      //   this.reajustaDuplicadoLinhaPlano(linha.wrapIt())
      //     .then(r => {
      //       if (r && r.action) {
      //         this.app.notificationSuccess("A quantidade da linha original duplicada foi reajustada");
      //         this.doAction(r.action, r.payload);
      //       }
      //     })
      //     .catch(err => {
      //       this.app.notificationErrorCompact(err);
      //       this.isFetching = false;
      //     });
      //   break;
      // }
      //
      case "FECHA-LINHAS": {
        if (!this.podeEditar) {
          this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
          return;
        }
        if (this.selectionCount > 0) {
          // this.isFetching        = true;
          let linhasSelecionadas = this.linhas.filter(el => el._selected);
          let len                = linhasSelecionadas.length;
          //filtra linhas selecionadas para aquelas que o utilizador
          linhasSelecionadas     = linhasSelecionadas.filter(el => this.app.auth.canWritePlano(el.nvcClienteArmazem));
          if (linhasSelecionadas.length == 0) {
            this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar os fechos das linhas selecionadas`);
            return;
          }

          if (linhasSelecionadas.length < len) {
            this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar os todos fechos das linhas selecionadas. (algumas linhas não serão fechadas)`);
          }

          let indexes = linhasSelecionadas.map(el => el.idPlanoExpedicao);
          console.log(indexes);
          this.fecharLinhas(new VmWrapper<number[]>({payload: indexes, confirmLevel: 0}));
        }
        break;
      }
      // //endregion
      //
      //region eventos/registos
      //tratamento de um click numa célula vazia
      case "CELULA-VAZIA": {
        //if (environment.debug) console.log("[expedicao-tabela-virtual]", "CELULA-VAZIA");
        let linha      = this.linhas[payload.rowIndex];
        let calendario = this.periodoVisivel[payload.cellIndex];

        // console.log("CELULA-VAZIA", payload, linha, calendario);
        if (!this.podeEditar) {
          this.doAction("LISTAGEM-REGISTOS-LINHA", linha);
          break;
        }
        if (linha.getTotalPorProgramar() > 0) {
          //reduzir a dependências dos indices
          this.doAction("CRIA-REGISTO", {linha: linha, calendario: calendario});
        } else {
          this.doAction("LISTAGEM-REGISTOS-LINHA", linha);
        }
        // if (this.title == "Expedição") {
        // } else {
        //   if (!this.podeEditar) {
        //     this.doAction("LISTAGEM-REGISTOS-LINHA", payload);
        //     break;
        //   }
        //   // if (linha.getTotalPorLancar() > 0) {
        //   //   this.doAction("CRIA-REGISTO", payload);
        //   // } else {
        //   //   this.doAction("LISTAGEM-REGISTOS-LINHA", payload);
        //   // }
        // }
        break;
      }

      // Efetua uma transferência de dia para um certo registo.
      // case "TRANSFERE-REGISTO": {
      //   if (!this.podeEditar) {
      //     this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      //     return;
      //   }
      //   this.isFetching = true;
      //   let linha       = this.linhas[payload.rowIndex];
      //
      //   if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
      //     this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
      //     this.isFetching = false;
      //     return;
      //   }
      //
      //   let model  = linha.getRegisto(payload.idRegisto).cloneInstance();
      //   let newDay = this.periodoVisivel[payload.newCellIndex];
      //
      //   model.dtmMovimento  = newDay.dtmData;
      //   model._dtmMovimento = newDay._dtmData;
      //
      //   RegistoPlano.saveWithConfirm(this.app, model.wrapIt())
      //     .then(registosLinha => { this.doAction('UPSERT-REGISTO-LINHA', {linha: linha, rowIndex: payload.rowIndex, models: registosLinha});})
      //     .catch(e => {
      //       this.app.notificationErrorCompact(e);
      //       this.doAction("REFRESH-EVENTOS", {});
      //     });
      //
      //   break;
      // }

      // mostra popup para criação de novo registo do plano
      case "CRIA-REGISTO": {
        //LEGACY payload {rowIndex, cellIndex}
        //E-T payload {linha, calendario}

        // if (!this.podeEditar) {
        //   this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
        //   return;
        // }

        let linha = payload.linha || this.linhas[payload.rowIndex];
        if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
          this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
          this.isFetching = false;
          return;
        }

        let cellIndex = payload.cellIndex;
        if (cellIndex === -1) {
          //determinar a data máxima dos eventos associados à linha e encontrar um índice adequado no calendário de expedição.
          //linha.getMaxDate()
          cellIndex = 0;
        }
        let calendario = payload.calendario || this.periodoVisivel[cellIndex];

        let model = new RegistoPlano();
        if (this.title == "Expedição") {
          model.nvcTipo  = tiposRegisto.PRG;
          model.intDelta = linha.getTotalPorProgramar();
        } else {
          model.nvcTipo = tiposRegisto.PROD;
          // model.intDelta = linha.getTotalPorLancar();
        }
        model.dtmMovimento               = (calendario && calendario.dtmData) || dateISOString(new Date());
        model.idPlanoExpedicao           = linha.idPlanoExpedicao;
        model.IdPlanoExpedicaoNavigation = linha;
        return this.doAction("EDITA-REGISTO-COMPOSE", model, context);
        //
        // break;
      }

      // mostra popup para edição de registo do plano existente
      case "EDITA-REGISTO": {
        if (!this.podeEditar) {
          this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
          return;
        }
        let linha = payload.linha || this.linhas[payload.rowIndex];
        //procura o registo na linha, por id
        let model = linha.getRegisto(payload.registo);
        if (!model) {
          this.doAction("LISTAGEM-REGISTOS-LINHA", linha);
        } else {
          this.doAction("EDITA-REGISTO-COMPOSE", model, context);
        }
        break;
      }

      case "EDITA-REGISTO-COMPOSE": {
        // if (environment.debug) console.log("[expedicao-tabela-virtual]", "EDITA-REGISTO-COMPOSE", payload);
        // if (environment.debug) console.log("[expedicao-tabela-virtual]", "EDITA-REGISTO-COMPOSE", payload.wrapIt());
        let modelo          = payload as RegistoPlano;
        let composeSettings = {
          modelo         : modelo,
          containerModelo: null,
          invoker        : this,
          options        : new ComposeDialogOptions({
            title            : modelo.idRegistoPlano <= 0 ? 'Novo registo do plano' : 'Editar ' + modelo.toString(),
            withDefaultFooter: false,
            mainView         : '../routes/plano/ce/_registo-plano-form.html',
            postUri          : 'api/plano-expedicao/registo-plano',
            rootBindings     : {
              tipos   : TipoRegistoPlano.tipos().filter(el => el.bitManual),
              tiposMap: TipoRegistoPlano.tipos().reduce((acc, t) => {
                acc[t.nvcTipo] = t.nvcDescricao;
                return acc;
              }, {})
            }
          }),
        };
        return this.app.ds.open({
            viewModel: ComposeDialog,
            model    : composeSettings
          })
          .whenClosed(r => {
            if (!r.wasCancelled) {
              this.app.notificationSuccess("Registo do plano gravado.");
              if (context) {
                if (environment.debug) console.log("[expedicao-tabela-virtual]", "context", context);
                //determinar se o contexto contém um modelo de tipo PlanoExpedicao
                if (context.modelo && context.modelo.getStaticType() == PlanoExpedicao) {
                  this.app.ds.closeAll();
                  this.doAction("LISTAGEM-REGISTOS-LINHA", context.modelo);
                }
              }
              this.doAction("REFRESH-TABELA", null);

              return Promise.resolve(false);
            }
          });
      }

      case "REMOVE-REGISTO": {
        if (!this.podeEditar) {
          this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
          return;
        }
        this.isFetching = true;
        if (environment.debug) console.log("[expedicao-tabela-virtual]", "payload", payload);

        // todo: inserir a funcionalidade de permissão por plano
        // let linha = payload.linha || this.linhas[payload.rowIndex];
        // if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
        //   this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
        //   return;
        // }

        let modelo = payload as RegistoPlano;
        return confirmaDeletionTyped(this, modelo, 'api/plano-expedicao/registo-plano')
          .then(r => {
            if (environment.debug) console.log("[expedicao-tabela-virtual]", "REMOVE-REGISTO", "Resposta do servidor", r);
            if (r) {
              this.app.ds.closeAll();
              if (context.modelo && context.modelo.getStaticType() == PlanoExpedicao) {
                this.doAction("LISTAGEM-REGISTOS-LINHA", context.modelo);
              }
              this.doAction("REFRESH-TABELA", null);
            }
          })
      }

      case "LISTAGEM-REGISTOS-LINHA": {
        let modelo = payload as PlanoExpedicao;

        return this.app.api.getProcessed('api/plano-expedicao/plano-expedicao', {id: modelo.idPlanoExpedicao})
          .then(r => PlanoExpedicao.fromPOJSO(r))
          .then(pe => {
            let composeSettings = {
              modelo         : pe,
              containerModelo: null,
              invoker        : this,
              options        : new ComposeDialogOptions({
                title            : `Ver registos para ${pe}`,
                withDefaultFooter: false,
                mainView         : '../routes/plano/ce/_registo-plano-listagem.html',
                // postUri          : 'api/plano-expedicao/registo-plano',
                rootBindings     : {tipos: TipoRegistoPlano.tipos().filter(el => el.bitManual)}
              }),
            };
            return this.app.ds.open({
                viewModel: ComposeDialog,
                model    : composeSettings
              })
              .whenClosed(r => {
                this.doAction("REFRESH-TABELA", null);
              });
          })
      }

      // case "EDITA-REGISTO-FAL": {
      //   if (!this.podeEditar) {
      //     this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      //     return;
      //   }
      //   let linha: PlanoExpedicao = payload.linha || this.linhas[payload.rowIndex];
      //   if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
      //     this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
      //     return;
      //   }
      //
      //   let data      = this.periodoVisivel[payload.cellIndex];
      //   let pseudoFal = new RegistoPlano({idPlanoExpedicao: linha.idPlanoExpedicao, dtmMovimento: data.dtmData, nvcTipo: tiposRegisto.FAL});
      //   console.log("Editar FAL", linha, data, pseudoFal);
      //   this.editaFal(pseudoFal.warpIt());
      //   break;
      // }
      //
      // case "EDITA-REGISTO-FAL-TIPO2": {
      //   if (!this.podeEditar) {
      //     this.app.notificationWarning("Não dispõe da permissão para efetuar essa operação");
      //     return;
      //   }
      //   let linha: PlanoExpedicao = payload.linha || this.linhas[payload.rowIndex];
      //   if (!this.app.auth.canWritePlano(linha.nvcClienteArmazem)) {
      //     this.app.notificationWarning(`Não dispõe da permissão de escrita para efetuar alterações no plano ${linha.nvcClienteArmazem}`);
      //     return;
      //   }
      //
      //   let data      = this.periodoVisivel[payload.cellIndex];
      //   let novaData  = this.periodoVisivel[payload.newCellIndex];
      //   let pseudoFal = new RegistoPlano({idPlanoExpedicao: linha.idPlanoExpedicao, dtmMovimento: novaData.dtmData, dtmDatains: data.dtmData, nvcTipo: tiposRegisto.FAL});
      //   console.log("Editar FAL", linha, data, pseudoFal);
      //   this.editaFal(pseudoFal.warpIt());
      //   break;
      // }
      //endregion

      //region GUIAS-TRANSPORTE
      case 'NEW-GUIA-TRANSPORTE': {
        // let gt = new GuiaTransporte();
        // injecção de propriedades para teste, manter a linha acima
        let gt = new GuiaTransporte();
        if (payload) {
          gt.dtmDataExpedicao = payload;
          gt.dtmDataDocumento = payload;
        }
        return this.doAction("GUIA-TRANSPORTE-EDITAR-COMPOSE", gt);
      }

      //endregion

      default: {
        if (environment.debug) console.log("[expedicao-tabela-virtual]", "Acção desconhecida [expedicao-tabela-virtual]", action, payload);
        console.log("[expedicao-tabela-virtual]", "Acção desconhecida [expedicao-tabela-virtual]", action, payload);
        if (this.app.aureliaMain && typeof this.app.aureliaMain.doActionGlobal === "function") {
          this.isBusy = false;
          return this.app.aureliaMain.doActionGlobal(action, payload, context || this);
        }
        this.app.notificationErrorCompact("Accção desconhecida: " + action);
      }
    }
  }
}

//region Classes adicionais Seleção
export class Selecao {
  x: number  = 0;
  y: number  = 0;
  xf: number = 0;
  yf: number = 0;

  constructor(fields: Partial<Selecao>) { Object.assign(this, fields); }

  //define o segundo ponto
  setEnd(x, y) {
    if (this.x <= x)
      this.xf = x;
    else
      this.x = x;

    if (this.y <= y)
      this.yf = y;
    else
      this.y = y;
  }

  static FromPoint(x, y) {
    return new Selecao({x: x, y: y, xf: x, yf: y});
  }

  deltaX() { return this.xf - this.x };

  deltaY() { return this.yf - this.y };

  rangeX() { return range(this.x, this.xf + 1) };

  rangeY() { return range(this.y, this.yf + 1) };

  length() { return (this.deltaX() + 1) * (this.deltaY() + 1) }
}

export class MultiSelecao {
  //inteiro de controlo para inserção de seleções faseadamente
  public fase: number         = 0;
  private selecoes: Selecao[] = [];

  @computedFrom("fase")
  get selectedCells() {
    return this.selecoes.reduce((acc, el) => acc + el.length(), 0);
  }

  public add(x, y) {
    let len = this.selecoes.length;
    if (this.fase <= 0) {
      this.selecoes.push(Selecao.FromPoint(x, y));
      this.fase = 1;
    } else {
      //a chamada a esta função quando o estado interno é outro que 0 ou 1 força a inserção de um novo registo
      if (this.fase !== 1) {
        this.fase = 0;
        return this.add(x, y);
      }
      this.fase = 0;
      this.selecoes[len - 1].setEnd(x, y);
    }
    return this;
  }

  public clearAll() {
    this.fase     = -1;
    this.selecoes = [];
    return this;
  }

  public forEach(callback: (x: number[], y: number) => void) {
    this.selecoes.forEach(el => {
      for (let yy = el.y; yy <= el.yf; yy++) {
        let xx = el.rangeX();
        callback(xx, yy);
        // apply(callback, this, [xx, yy]);
        // for (let xx = el.x; xx <= el.xf; xx++) {}
      }
    })
  }

}

export class ResumoRegistosPlano {
  lcm: RegistoPlano[] = [];
  prg: RegistoPlano[] = [];
  fal: RegistoPlano[] = [];
  sai: RegistoPlano[] = [];
  visivel: number     = 0;

  @computedFrom('lcm')
  get nLcm() {
    return this.lcm.length;
  }

  @computedFrom('lcm')
  get tLcm() {
    return this.lcm.reduce((acc, el) => (acc + el.intDelta), 0);
  }

  @computedFrom('prg')
  get nPrg() {
    return this.prg.length;
  }

  @computedFrom('prg')
  get tPrg() {
    return this.prg.reduce((acc, el) => (acc + el.intDelta), 0);
  }

  @computedFrom('fal')
  get nFal() {
    return this.fal.length;
  }

  @computedFrom('fal')
  get tFal() {
    return this.fal.reduce((acc, el) => (acc + el.intDelta), 0);
  }

  @computedFrom('sai')
  get nSai() {
    return this.sai.length;
  }

  @computedFrom('sai')
  get tSai() {
    return this.sai.reduce((acc, el) => (acc + el.intDelta), 0);
  }

  constructor(s?: Set<RegistosAgrupados>) { if (s) this.setState(s); }

  public setState(s: Set<RegistosAgrupados>) {
    this.lcm = [];
    this.prg = [];
    this.fal = [];
    this.sai = [];

    this.visivel = Array.prototype.map.call(document.getElementsByClassName("SEL"), (el) => +el.textContent).reduce((acc, el) => acc + el, 0);
    // s.forEach(el => {
    //   this.lcm = [...this.lcm, ...el.lcm];
    //   this.prg = [...this.prg, ...el.prg];
    //   if (el.fal) this.fal.push(el.fal);
    //   if (el.sai) this.sai.push(el.sai);
    // });
  }
}

//endregion
