import {BaseViewModel} from "./BaseViewModel";
import {ValidationRules} from "aurelia-validation";
import {Api} from "../services/api";
import {ClienteDestinatario} from "./ClienteDestinatario";
import {VmWrapper} from "./VmWrapper";
import environment from "../environment";

/**
 * Created by herna on 5/2/2017.
 */
export class ClienteArmazem extends BaseViewModel {
  public nvcClienteArmazem: string = "";
  public nvcNome: string           = "";

  public nvcClientePrimavera: string = "";
  public nvcArmazemEntrada: string   = "";
  public nvcArmazemProducao: string  = "";
  public nvcArmazemExpedicao: string = "";
  public nvcArmazemLixo: string      = "";
  public nvcTipo: string             = "plano";
  public timestamp: string           = "";

  //Relações
  public ClienteDestinatario: ClienteDestinatario[] = [];

  public static fromPOJSO(obj: any, index: number = 0): ClienteArmazem {
    let model = new ClienteArmazem();
    model.setState(obj);

    if (obj.clienteDestinatario) {
      model.ClienteDestinatario = ClienteDestinatario.multipleFromPOJSO(obj.clienteDestinatario);
    }
    model.__index = index;
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): ClienteArmazem[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(ClienteArmazem.fromPOJSO);
    return [];
  }

  /**
   * Apaga a o sessionStorage dos planos
   */
  public static clearMemoization() {
    sessionStorage.removeItem(this.getClassName());
    console.debug("Memória dos planos apagada");
  }

  /**
   * Memoizing dos planos existentes
   * @param {Api} api
   * @param {boolean} rebuild
   * @returns {Promise<Operador[]>}
   */
  public static memoizeMultiple(api: Api, rebuild = false) {
    if (environment.debug) console.log("[ClienteArmazem]", "memoizeMultiple");
    if (sessionStorage.getItem(this.getClassName()) === null || rebuild) {
      return api.getProcessed("api/cliente-armazem/list")
        .then(r => {
          sessionStorage.setItem(this.getClassName(), JSON.stringify(r));
          return this.multipleFromPOJSO(<any>r)
        });
    }
    return Promise.resolve(this.multipleFromPOJSO(JSON.parse(sessionStorage.getItem(this.getClassName()))));
  }


  public stateToPOJSO(): any {
    let ret = this.getState(false);
    return ret;
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<GuiaTransporte>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<ClienteArmazem>({payload: this, confirmLevel: (+cl || 0)});
  }

  static getClassName() { return 'ClienteArmazem'; }

  /**
   *
   * @param ca
   * @param api
   */
  public static getFromLocalStorage(ca: string, api: Api) {
    return ClienteArmazem.memoizeMultiple(api)
      .then(cas => cas.find(el => el.nvcClienteArmazem == ca));
  }

  public static tipos() {
    return [
      {nvcTipo: 'plano', nvcDescricao: 'Plano', nvcTitulo: 'Um cliente associado a um plano de expedição e a diferentes armazéns no ERP Primavera'},
      //{nvcTipo: 'Expedido', nvcDescricao: 'Expedido'},
      {nvcTipo: 'virtual', nvcDescricao: 'Virtual', nvcTitulo: 'Um cliente sem associação a um plano de expedição. O seu propósito é o de produção de guias de transporte emitidas no seu nome.'},
      {nvcTipo: 'escondido', nvcDescricao: 'Não visível', nvcTitulo: 'Um cliente "Não visível" não se apresenta na aplicação.'},
    ]
  }
}

ValidationRules
  .ensure((m: ClienteArmazem) => m.nvcClienteArmazem).displayName("Código Cliente").required()
  .ensure((m: ClienteArmazem) => m.nvcNome).required()
  .on(ClienteArmazem);
