import {bindable, autoinject} from 'aurelia-framework';
import environment from "../../environment";
import {GlobalServices} from "../../services/global-services";

export class MySelect2Tags {
  @bindable value;
  @bindable placeholder           = "Escolha uma opção";
  @bindable debug: boolean        = false;
  @bindable options: any[]        = [];
  @bindable withEmpty             = true; //with-empty="false"
  @bindable splitterChars: string = ",";
  @bindable ajaxObj: any;
  public domElement: HTMLSelectElement;
  private select2: any;

  valueChanged(newValue, oldValue) {
    if (this.debug) console.log("[MySelect2Tags]", "valueChanged", this, newValue, oldValue);
    if (newValue && newValue != oldValue && this.value != newValue) {
      this.value = newValue;
    }
    if (this.select2 && this.select2.val() != newValue && newValue) {
      //Setting select2
      if (this.debug) console.log("[MySelect2Tags]", "Setting select2", this.select2, this.select2.val(), newValue, ("" + newValue).split(this.splitterChars));
      // this.select2.val(("" + newValue).split(this.splitterChars)).trigger("change");
      this.select2.val(newValue).trigger("change");

      let data = ("" + newValue).split(this.splitterChars);

      data.forEach(el => {
        console.log("Select2Option", el);
        if(!this.select2.find("option[value='" + el + "']").length) {
          let newOption = new Option(el, el, true, true);
          // Append it to the select
          this.select2.append(newOption).trigger('change');

        }
      });
      this.select2.val(data).trigger("change");
    }
  }

  // constructor(app: GlobalServices) {
  //   this.app           = app;
  // }

  attached() {
    if (this.debug) console.log("[MySelect2Tags]", "attached", this);
    this.select2 = $(this.domElement).select2({
      ajax           : this.ajaxObj,
      language       : "pt",
      width          : "100%",
      debug          : this.debug,
      data           : this.options,
      placeholder    : this.placeholder,
      allowClear     : true,
      tags           : true,
      tokenSeparators: [this.splitterChars],
      // createTag  : (params) => {
      //   if (this.debug) console.log("[MySelect2Tags]", "criar Tag", params);
      //   return {
      //     id    : params.term,
      //     text  : params.term + " (Criar nova...)",
      //     newTag: true
      //   };
      // },
      insertTag      : function (data, tag) {
        // Inserir a tag no final da lista de resultados

        if (this.debug) console.log("[MySelect2Tags]", "insertTag", data, tag);
        data.push(tag);
      }
      //placeholder: this.placeholder
    }).off('select2:select').on('select2:select', (e) => {
      // let data = e.params.data;
      // if (this.debug) console.log("[MySelect2Tags]", "select2:select", e, data);
      if (this.debug) console.log("[MySelect2Tags]", this.select2.select2("data"));
      let data   = this.select2.select2("data");
      this.value = data.map(el => el.id).filter(el => (el && !!(el.trim()))).join(this.splitterChars);
    }).off('select2:unselecting').on('select2:unselecting', (event) => {
      if (this.debug) console.log("[MySelect2Tags]", "select2:unselecting", event);
      let data   = event.params.args.data;
      let values = this.value.split(this.splitterChars);
      if (this.debug) console.log("[MySelect2Tags]", "select2:unselecting removing", data, "from", values);

      this.value = values.filter(el => el != data.id).join(this.splitterChars);
      return true;
    });

    // //inicialização do valor
    // if (this.value || this.value === false) {
    //   this.select2.val("" + this.value).trigger("change");
    // }

    if (this.debug) console.log("[MySelect2Tags]", "attached", this.select2);
  }
}
