/**
 * Created by herna on 4/30/2017.
 */
import { ValidationRenderer, RenderInstruction, ValidateResult} from 'aurelia-validation';

export class BootstrapFormRenderer {
  render(instruction: RenderInstruction) {
    for (let {result, elements} of instruction.unrender) {
      for (let element of elements) {
        this.remove(element, result);
      }
    }

    for (let {result, elements} of instruction.render) {
      for (let element of elements) {
        this.add(element, result);
      }
    }
  }

  public add(element: Element, result: ValidateResult) {
    if (result.valid) {
      return;
    }

    let formGroup = element.closest('.form-group');
    if (!formGroup) {
      formGroup = element.closest('div');
      if (!formGroup) {
        return;
      }
    }
    // add the has-error class to the enclosing form-group div
    formGroup.classList.add('has-error');

    // add help-block
    const message     = document.createElement('span');
    message.className = 'help-block validation-message invalid-feedback';
    message.innerHTML = result.message;
    message.id        = `validation-message-${result.id}`;
    formGroup.appendChild(message);
  }

  remove(element: Element, result: ValidateResult) {
    if (result.valid) {
      return;
    }

    let formGroup = element.closest('.form-group');
    if (!formGroup) {
      formGroup = element.closest('div');
      if (!formGroup) {
        return;
      }
    }

    // remove help-block
    const message = formGroup.querySelector(`#validation-message-${result.id}`);
    if (message) {
      formGroup.removeChild(message);

      // remove the has-error class from the enclosing form-group div
      if (formGroup.querySelectorAll('.help-block.validation-message').length === 0) {
        formGroup.classList.remove('has-error');
      }
    }
  }
}
