import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";

@autoinject()
export class PickerClienteVirtual {
  protected controller: DialogController;
  private app: GlobalServices;
  private ca: string = null;
  private Grid:HTMLDivElement;

  constructor(gs: GlobalServices, controller: DialogController) {
    this.app        = gs;
    this.controller = controller;
  }

  activate(args) {
    //obter a lista de clientes.
    console.log("PickerClienteVirtual activate", args);
    this.ca = args;
  }

  attached() {
    //console.log("activate!");
    this.app.auth.promisedActiveToken()
      .then(token => {

        let filteredColumns = [];
        //if (this.ca) { filteredColumns.push({field: "nvc_cliente_armazem", operator: "equal", value: this.ca, matchcase: false})}

        let g = $(this.Grid).ejGrid({
          locale        : "pt-PT",
          dataSource    : ej.DataManager({
            //done: injetar a localização do serviço
            url        : environment.endpoint + "api/ejg/ClienteArmazemVirtual",
            crossDomain: true,
            //requestType : "post",
            adaptor    : new ej.WebMethodAdaptor(),
            headers    : [{Authorization: `Bearer ${token}`}]
          }),
          allowPaging   : true,
          pageSettings  : {pageSize: 15},
          allowSorting  : true,
          allowSearching: true,
          allowFiltering: true,
          filterSettings: {
            filteredColumns: filteredColumns,
          },

          recordClick: (args) => {
            //console.log("Click", args);
            //$(this.Grid).ejGrid("destroy");
            this.pickLinha(args);
          },
          columns    : [
            //@formatter:off
            {field: "nvc_cliente_armazem"     , headerText:"Referência" , width:80  , type: 'string'} ,
            {field: "nvc_nome"                , headerText:"Cliente"    , width:80  , type: 'string'} ,
            {field: "nvc_alias"               , headerText:"Etiqueta"   , width:80  , type: 'string'} ,
            {field: "nvc_morada"              , headerText:"Morada"     , width:400 , type: 'string'} ,
            //@formatter:on
          ]
        });
      });
  }

  resetSearch() {
    $(this.Grid).ejGrid("clearFiltering");
  }

  pickLinha(args: any) {
    if (args.data.nvc_morada) {
      this.controller.ok({action: 'FILL-MORADA', payload: args.data.nvc_morada});
    } else
      this.controller.ok({action: 'FILL-MORADA', payload: args.data.nvc_nome});
  }
}
