//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {activationStrategy} from "aurelia-router";
import environment from "../environment";
import {GlobalServices} from "../services/global-services";
import {UtilizadorDialog} from "../dialogs/utilizador-dialog";
import {Utilizador} from "../models/Utilizador";
import {UtilizadorPasswordDialog} from "../dialogs/utilizador-password-dialog";
import {jsRedirect} from "../utils/ItMultiPurpose";

@autoinject()
export class PerfilUtilizador {
  public app: GlobalServices;
  private utilizador: Utilizador = null;
  private isBusy: boolean           = false;
  private isAdmin: boolean          = false;
  // private childRouter: Router;

  constructor(g: GlobalServices) {
    this.app = g;
    console.log("l a m e", this.app.auth.userName);
    if (this.app.auth.userName == "sistema") {
      console.log("Admin super power user!!!!");
      this.isAdmin = true;
    }
  }

  canDeactivate() {
    this.cleanup();
    return true;
  }

  cleanup() {}

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate() {
    console.log("Configs activate!");
  }

  attached() {
    this.isBusy = true;
    console.log("Configs Attached!");
    this.getUtilizador();
    // this.refresh();
  }

  getUtilizador() {
    this.app.api
      .getProcessed(`api/auth/utilizador`)
      .then(r => {
        this.isBusy     = false;
        this.utilizador = Utilizador.fromPOJSO(r);
        console.log(r, this.utilizador);
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }

  alteraPalavraChave() {
    this.app.ds
      .open({viewModel: UtilizadorPasswordDialog, model: {utilizador: this.utilizador}, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {
          this.app.notificationShort("Palavra chave alterada");
        } else {

        }
      });
  }

  injetaPermissoes(){
    this.app.api.getProcessed('api/auth/injeta-permissoes')
      .then(r => {
        this.app.notificationSuccess("A lista de permissões por defeito foi atualizada.");
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }

  /*injetaTriggers(){
    this.app.api.getProcessed('api/auth/injeta-triggers')
      .then(r => {
        this.app.notificationSuccess("Os triggers foram injetados.");
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }*/

  healthCheck(){
    this.app.api.getProcessed('api/auth/health-check')
      .then(r => {
        this.app.notificationSuccess("A sp foi executada.");
      })
      .catch(err => this.app.notificationErrorCompact(err));
  }

  forceClean(){
    window.sessionStorage.clear();
    window.localStorage.clear();
    this.app.notificationShort("ok");
    jsRedirect("/");
    //this.app.router.navigateToRoute("/", {})
  }

  forceRefresh(){
    this.app.auth.refresh()
      .then(r => {
        if(environment.debug) console.log("[perfil-utilizador]","response from refresh", r);
        this.app.notificationSuccess("Sucesso");
        return true;
      }).catch(err => this.app.notificationErrorCompact(err))
    //this.app.router.navigateToRoute("/", {})
  }
}
