import {BaseViewModel} from "./BaseViewModel";
/**
 * Created by herna on 5/2/2017.
 * DomainModel (virtual) que classifica com estado cada linha do plano de expedição
 */
export class TipoGuiaTransporte extends BaseViewModel {
  public nvcTipo: string    = "";
  public nvcDescricao: string = "";

  //relações:

  public static fromPOJSO(obj: any): TipoGuiaTransporte {
    let model = new TipoGuiaTransporte();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): TipoGuiaTransporte[] {
    if (objs && Array.isArray(objs)) return objs.map(TipoGuiaTransporte.fromPOJSO);
    return [];
  }

  public static tipos() {
    return [
      {nvcTipo: 'erp', nvcDescricao: 'Normal - Guia de Transporte com integração no ERP.'},
      //{nvcTipo: 'Expedido', nvcDescricao: 'Expedido'},
      {nvcTipo: 'arm', nvcDescricao: 'Armazém - Guia de Transporte sem integração no ERP. Apenas para movimentação de stocks.'},
    ]
  }
}

export const tiposGuiaTransporte = {
  erp  : "erp",
  arm : "arm",
};
