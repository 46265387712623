import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {GlobalServices} from "../../../services/global-services";
import {Artigo} from "../../../models/Artigo";
import {BootstrapFormRenderer} from "../../../services/bootstrap-form-renderer";
import {confirmaActionTyped} from "../../../services/api-envelopes";

@autoinject()
export class ArtigoDialog {
  //static inject = [DialogController, ValidationControllerFactory];
  protected validationController: ValidationController;
  protected controller: DialogController;
  protected app: GlobalServices;

  protected artigo: Artigo;
  protected artigoOriginal: Artigo;

  protected isBusy: boolean = false;

  constructor(controller: DialogController, vcf: ValidationControllerFactory) {
    this.controller           = controller;
    //inicializar o validador
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  canActivate(p: any) {
    if (!p.model || !p.app) {
      console.error("Um dos parametros (model, app) está em falta pelo que não se pode abir o popup de criação/alteração de artigo.");
      return false;
    }
    this.app = p.app;
    this.parseArtigo((p.model as Artigo).stateToPOJSO());

    return true;
  }

  //attached() {}

  parseArtigo(obj: any) {
    this.artigo         = Artigo.fromPOJSO(obj);
    this.artigoOriginal = Artigo.fromPOJSO(obj);
    return true;
  }

  gravaArtigo() {
    return confirmaActionTyped(this, this.artigo, "api/artigo/cria-artigo")
      .then(r => r && this.controller.ok(this.artigo));

    // return this.app.confirmaActionTyped(this.artigo.wrapIt(), "api/artigo/cria-artigo")
    //   .then(r => {
    //     if (this.app.processConfirmation(r)) {
    //       // this.app.notificationSuccess(`Dados de ${this.artigo} atualizados`);
    //       this.controller.ok(this.artigo);
    //     }
    //     // this.isBusy = false;
    //   })
    //   .catch(err => this.app.notificationErrorCompact(err))
    //   .then(_ => this.isBusy = false);
  }

  dialogSubmit() {
    return this.validationController.validate()
      .then(r => {
        if (r.valid) {
          this.isBusy = true;
          this.gravaArtigo()
        } else {
          this.app.notificationErrorCompact("Verifique os erros.");
          console.error(r.results);
        }
      })
      .catch(e => this.app.notificationErrorCompact(e));
  }
}
