import {BaseViewModel} from "./BaseViewModel";
import {GuiaTransporte} from "./GuiaTransporte";
import {RegistoPlano} from "./RegistoPlano";
import {VmWrapper} from "./VmWrapper";
import {GlobalServices} from "../services/global-services";
import {ConfirmacaoDialog} from "../dialogs/confirmacao-dialog";
import {RegistoEtiqueta} from "./RegistoEtiqueta";
import environment from "../environment";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class GuiaTransporteLinha extends BaseViewModel {
  public idGuiaTransporteLinha: number           = 0;
  public idGuiaTransporte: number                = 0;
  public idGuiaTransporteLinhaOrigem: number     = 0;
  public idRpOrigem: number                      = 0;
  public idRpDestino: number                     = 0;
  public intNumero: number                       = 0;
  public nvcArtigoTerminacao: string             = "";
  public nvcDescricaoArtigo: string              = "";
  public nvcArtigoTerminacaoSobreposicao: string = "";
  public nvcDescricaoArtigoSobreposicao: string  = "";
  public nvcCodigoEncomenda: string              = "";
  public nvcGravacao: string                     = "";
  public nvcObservacoesRtf: string               = "";
  public nvcObservacoes: string                  = "";
  public intQuantidade: number                   = 0;
  public intQuantidadeConfirmada: number         = 0;
  //public intQuantidadeTotal: number              = 0;
  public fltPrecoUnitario: number                = null;
  public nvcMoeda: string                        = "";
  public decDescontoLinha: number                = 0;
  //public timestamp: string                       = "";

  //relações
  public IdGuiaTransporteNavigation: GuiaTransporte                          = null;
  public IdRpOrigemNavigation: RegistoPlano                                  = null;
  public IdRpDestinoNavigation: RegistoPlano                                 = null;
  //esta é apenas a relação inversa (que é a que interessa manter e vigiar)
  public InverseIdGuiaTransporteLinhaOrigemNavigation: GuiaTransporteLinha[] = [];
  public RegistoEtiqueta: RegistoEtiqueta[]                                  = [];
  //public GuiaTransporteLinhaRegistoPlanoDestino: RegistoPlano[] = [];

  // artificiais
  /**
   * este getter é DirtyChecked. ver https://github.com/aurelia/binding/issues/149
   *
   * @return {number}
   */
  //public get quantidade() {
  //  //if(Array.isArray(Inverse))
  //  return this.intQuantidade + this.InverseIdGuiaTransporteLinhaOrigemNavigation.reduce((acc, el) => acc + el.intQuantidade, 0);
  //}

  //public set quantidade(val: number) {
  //  let acumulado           = this.InverseIdGuiaTransporteLinhaOrigemNavigation.reduce((acc, el) => acc + el.intQuantidade, 0);
  //  this.intQuantidade      = +val - acumulado;
  //  this.intQuantidadeTotal = +val;
  //}

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<GuiaTransporteLinha>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): GuiaTransporteLinha {
    // if(environment.debug) console.log("[guiatransportelinha]","fromPOJSO", obj);
    let model = new GuiaTransporteLinha();
    model.setState(obj);
    //relações
    if (obj.idGuiaTransporteNavigation) {
      model.IdGuiaTransporteNavigation = GuiaTransporte.fromPOJSO(obj.idGuiaTransporteNavigation);
    }
    if (obj.idRpOrigemNavigation) {
      model.IdRpOrigemNavigation = RegistoPlano.fromPOJSO(obj.idRpOrigemNavigation);
      model.idRpOrigem           = obj.idRpOrigemNavigation.idRpOrigem || model.idRpOrigem;
    }
    if (obj.idRpDestinoNavigation) {
      model.IdRpDestinoNavigation = RegistoPlano.fromPOJSO(obj.idRpDestinoNavigation);
      model.idRpDestino           = obj.idRpDestinoNavigation.idRpDestino || model.idRpDestino;
    }
    //o objeto inverso não é enviado pelo servidor? nunca foi?
    if (obj.inverseIdGuiaTransporteLinhaOrigemNavigation && Array.isArray(obj.inverseIdGuiaTransporteLinhaOrigemNavigation) && obj.inverseIdGuiaTransporteLinhaOrigemNavigation.length > 0) {
      model.InverseIdGuiaTransporteLinhaOrigemNavigation = GuiaTransporteLinha.multipleFromPOJSO(obj.inverseIdGuiaTransporteLinhaOrigemNavigation);
    }
    if (obj.registoEtiqueta) {model.RegistoEtiqueta = RegistoEtiqueta.multipleFromPOJSO(obj.registoEtiqueta);}
    model.__index = index;
    // console.log("GuiaTransporteLinha fromPOJSO", model);
    return model;
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    if (this.IdRpOrigemNavigation) {
      ret.idRpOrigemNavigation = this.IdRpOrigemNavigation.stateToPOJSO();
    }
    if (this.IdRpDestinoNavigation) {
      ret.idRpDestinoNavigation = this.IdRpDestinoNavigation.stateToPOJSO();
    }
    if (this.InverseIdGuiaTransporteLinhaOrigemNavigation && Array.isArray(this.InverseIdGuiaTransporteLinhaOrigemNavigation) && this.InverseIdGuiaTransporteLinhaOrigemNavigation.length > 0) {
      ret.inverseIdGuiaTransporteLinhaOrigemNavigation = this.InverseIdGuiaTransporteLinhaOrigemNavigation.map(e => e.stateToPOJSO());
    }
    if (this.RegistoEtiqueta) {
      ret.registoEtiqueta = this.RegistoEtiqueta.map(e => e.stateToPOJSO());
    }
    return ret;
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<GuiaTransporteLinha>}
   */
  public wrapIt(cl?: number) { return new VmWrapper<GuiaTransporteLinha>({payload: this, confirmLevel: (+cl || 0)});}

  public static multipleFromPOJSO(objs: any[]): GuiaTransporteLinha[] {
    //console.log("multipleFromPOJSO", objs);
    //if(environment.debug) console.log("[guiatransportelinha]","multipleFromPOJSO");
    if (objs && Array.isArray(objs) && objs.length > 0) return objs.map(GuiaTransporteLinha.fromPOJSO);
    return [];
  }

  public cloneInstance(): GuiaTransporteLinha {
    return GuiaTransporteLinha.fromPOJSO(this.stateToPOJSO());
  }

  /**
   * Gravação envolvida com confirmações de nível.
   * @param app
   * @param vmw
   * @return {Promise<TResult2|TResult1>}
   */
  public static saveWithConfirm(app: GlobalServices, vmw: VmWrapper<GuiaTransporteLinha>) {
    return app.api
      .post("api/plano-expedicao/add-guia-transporte", vmw.stateToPOJSO())
      .then(r => app.api.processResponse(r))
      .then((obj: any) => {
        if (obj.tipo) {
          if (obj.tipo === "confirm") {
            let dialogContent = `<h5>Para realizar esta operação deve confirmar o seguinte:</h5><ul>${obj.mensagens.reduce((acc, el) => {return acc + '<li>' + el + '</li>'}, '')}</ul>`;
            return app.ds.open({viewModel: ConfirmacaoDialog, model: dialogContent})
              .whenClosed(resp => {
                if (!resp.wasCancelled) {
                  //O operador escolheu SIM: aumenta o nível de confirmação
                  return GuiaTransporteLinha.saveWithConfirm(app, vmw.nextLevel());
                } else {
                  throw new Error("A ação foi cancelada.");
                }
              });
          }
          if (obj.tipo === "select") {
            return {action: "FILL-GAPS", payload: GuiaTransporte.fromPOJSO(obj.modelo)}
            // return {action: "FILL-GAPS", payload: vmw.payload}
          }
          throw new Error("A resposta do servidor não é de um tipo conhecido.\nPor favor, tente executar os passos novamente");
        }
        return {action: "UPDATE-GUIA-EDICAO", payload: GuiaTransporte.fromPOJSO(obj)};
      })
      //.then(GuiaTransporte.fromPOJSO)
      ;
  }

  //endregion

  public ToString(): string {
    return `
Linha de Guia de Transporte:
Origem  (plano)  : # ${this.IdRpOrigemNavigation.idPlanoExpedicao}
Origem  (registo): # ${this.idRpOrigem}  ${this.IdRpOrigemNavigation ? '=[' + this.IdRpOrigemNavigation.intDelta + ']' : ''}
Destino (registo): # ${this.idRpDestino} ${this.IdRpDestinoNavigation ? '=[' + this.IdRpDestinoNavigation.intDelta + ']' : ''}
    `;
  }
}
