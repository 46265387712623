import {computedFrom} from 'aurelia-framework';
import {ValidationRules} from 'aurelia-validation';
import {BaseViewModel} from './BaseViewModel';
import {VmWrapper} from './VmWrapper';

export class ReclamacaoFicheiro extends BaseViewModel {
  public idFicheiro: number       = 0;
  public idReclamacao: number     = 0;
  // public nvcCodigoInterno: string = '';
  public nvcPath: string          = '';
  public bitActivo: boolean       = false;
  // public idUtilizadorI: number    = 0;
  // public idUtilizadorA: number    = 0;
  // public dtmDataI: string;
  // public dtmDataA: string         = '';
  // public timestamp: string;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ReclamacaoFicheiro>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  copyState(obj: any, props: string[] = []): ReclamacaoFicheiro {
    super.copyState(obj, props);
    // obj.dtmDataI && (this.dtmDataI = obj.dtmDataI.replace("T", " "));
    // obj.dtmDataA && (this.dtmDataA = obj.dtmDataA.replace("T", " "));
    return this;
  }

  public static fromPOJSO(obj: any): ReclamacaoFicheiro {
    return new ReclamacaoFicheiro().copyState(obj);
  }

  public static multipleFromPOJSO(objs: any | any[]): ReclamacaoFicheiro[] {
    if (objs && Array.isArray(objs)) return objs.map(ReclamacaoFicheiro.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state = this.getState();
    state     = Object.assign(state, super.getState());
    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ReclamacaoFicheiro>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ReclamacaoFicheiro { return ReclamacaoFicheiro.fromPOJSO(this.stateToPOJSO());}

  //endregion estado

  public toString() {
    return `ReclamacaoFicheiro`;
  }

}

//region aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: ReclamacaoFicheiro) => m.idFicheiro).displayName('idFicheiro').required()
  .ensure((m: ReclamacaoFicheiro) => m.idReclamacao).displayName('idReclamacao').required()
  // .ensure((m: ReclamacaoFicheiro) => m.nvcCodigoInterno).displayName('nvcCodigoInterno').required()
  .ensure((m: ReclamacaoFicheiro) => m.nvcPath).displayName('nvcPath').required()
  .ensure((m: ReclamacaoFicheiro) => m.bitActivo).displayName('bitActivo').required()
  // .ensure((m: ReclamacaoFicheiro) => m.idUtilizadorI).displayName('idUtilizadorI').required()
  // .ensure((m: ReclamacaoFicheiro) => m.idUtilizadorA).displayName('idUtilizadorA').required()
  // .ensure((m: ReclamacaoFicheiro) => m.dtmDataI).displayName('dtmDataI').required()
  // .ensure((m: ReclamacaoFicheiro) => m.dtmDataA).displayName('dtmDataA').required()
  //.ensure((m: ReclamacaoFicheiro) => m.timestamp).displayName('timestamp').required()
  .on(ReclamacaoFicheiro);
//endregion
