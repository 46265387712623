// import {ColConfigInterface, DataSource, EntityInterface, GridConnector, Selection, SortObjectInterface} from "aurelia-v-grid";
import environment from "../../environment";
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {GlobalServices} from "../../services/global-services";
import {autoinject} from "aurelia-framework";
//import flatpickr from "flatpickr";
// import * as flatpickr from 'flatpickr';

//monkey patch!!!
//https://github.com/flatpickr/flatpickr/issues/1102
import * as _flatpickr from 'flatpickr';
const flatpickr: any = (<any>_flatpickr).default || _flatpickr;
// let fp = require("flatpickr");
// import "flatpickr";
// import flatpickr = require("flatpickr");
//
//import * from "choices.js/assets/styles/css";
@autoinject()
export class FlatpickrTest {
  message: string;
  public iref: HTMLInputElement;
  public dateStr:string = "2018-07-10";

  constructor(public app: GlobalServices) {}

  attached() {
    if(environment.debug) console.log("[flatpickr-test]","attached");
//    window["fp"] = flatpickr;
//     fp(this.iref, {})
    flatpickr(this.iref, {});
  }

  log(n) {
    console.log("some log", n);
  }

  doAction(action, payload){
    console.log(action, payload, this);
  }
}

// class CDataSource extends DataSource {
//   constructor(selection, options?) {
//     super(selection, options);
//   }
//
//   orderBy(attribute: string | SortObjectInterface, addToCurrentSort?: boolean): void {
//     if (environment.debug) console.log("[vgrid]", "CDataSource.orderby", attribute, addToCurrentSort);
//     //super.orderBy(attribute, addToCurrentSort);
//     //return;
//   }
// }
