import {autoinject} from "aurelia-framework";
import {GlobalServices} from "../../services/global-services";
import environment from "../../environment";
import {App} from "../../app";
import {dateISOString, dia} from "../../utils/ItNumeric";
import {ColConfigInterface, DataSource, GridConnector, Selection} from "../../it-v-grid/interfaces";
import {RegistoEtiqueta} from "../../models/RegistoEtiqueta";
import * as moment from 'moment';
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {activationStrategy} from "aurelia-router";
import {ControloPlanoExpedicao} from "../../models/ControloPlanoExpedicao";
import {VRegistoPlanoPrgOriginal} from "../../models/VRegistoPlanoPrgOriginal";
import {RegistoControlo} from "../../models/RegistoControlo";
import {ListaRegistoPlanoDialog} from "../../dialogs/lista-registo-plano-dialog";
import {ComposeDialog, ComposeDialogOptions} from "../../dialogs/compose-dialog";
import {ControloDiario} from "../../models/ControloDiario";

@autoinject()
export class ExpedicaoRelacional {
  public app: GlobalServices;

  //itsy bitsy spiders
  public isBusy: boolean = false;

  private codBarras: HTMLInputElement;

  private buffer: string = "";

  //V-Grid
  private ds: DataSource;
  private gridConnector: GridConnector;
  private columns: ColConfigInterface[] = [];

  //

  /**
   * A permissa é a seguinte:
   *
   * um fragmento de calendário com linhas do plano
   *
   * por cada dia poderá ver-se:
   *
   *
   * Qtd Programada (com respetivo diário, se houver. esta informação pode aparecer apenas em popup/tooltip)
   * Controlado no dia
   * Gravado no dia
   * Etiquetado no dia
   *
   * @type {any[]}
   */
  private linhas: ControloPlanoExpedicao[] = [];

  private diaIni: string;
  private diaFim: string;

  private momentIni: moment.Moment = moment();
  private momentFim: moment.Moment = moment();
  private numeroDias: number       = 1;
  private rangeDias: dia[]         = [];

  //cliente armazém
  private planosUtilizador: string[] = [];
  private planos: ClienteArmazem[]   = [];
  private listaClientes: ClienteArmazem[];

  constructor(app: GlobalServices, public rootApp: App) {
    this.app           = app;
    this.ds            = new DataSource(new Selection("single"), {key: 'idRegistoPlano'});
    this.gridConnector = new GridConnector(this.ds);
    window["curr"]     = this;
  }

  //region Aurelia
  canActivate(p) {
    if (environment.debug) console.log("[expedicao-relacional]", "canActivate");
    let oneOf = this.app.auth.oneOf(["Controlo.ExpedicaoVer", "App.FolhaExpedicaoVer"]);

    if (!oneOf) return this.app.notificationErrorCompact("O utilizador não dispõe de permissões para aceder a esta página.");

    this.planosUtilizador = this.app.auth.getPlanos();//.join(",");//this.parent.activeSubRoute = "expedicao";
    if (environment.debug) console.log("[expedicao-relacional]", "canActivate", this.planosUtilizador);

    if (p && p.diaIni) { this.diaIni = p.diaIni;} else { this.diaIni = dateISOString(new Date()); }
    if (p && p.diaFim) { this.diaFim = p.diaFim;} else { this.diaFim = dateISOString(new Date()); }

    this.momentIni = moment(new Date(this.diaIni));
    this.momentFim = moment(new Date(this.diaFim));
    // if(environment.debug) console.log("[expedicao-relacional]","canActivate", this.diaIni, this.momentIni, this.diaFim, this.momentFim);

    this.numeroDias = this.momentFim.diff(this.momentIni, 'day') + 1;
    if (this.numeroDias < 0) {
      // this.isReady = true;
      this.app.notificationWarning(`O período está mal definido, a data do início (${this.diaIni}) é superior à final (${this.diaFim})`);
      // this.parent.childRouter.navigateToRoute("controlo", {diaIni: this.diaFim, diaFim: this.diaIni, tipo: this.tipo}, {replace: true});
      return false;

      // let c = this.diaIni;
      // this.diaIni = this.diaFim;
      // this.diaFim = c;
      // this.momentIni = moment(new Date(this.diaIni));
      // this.momentFim = moment(new Date(this.diaFim));
      //return false;
    }
    this.rangeDias = this.rangePeriodo();
    if (this.rangeDias.length == 0) {
      this.app.notificationWarning("O período não contém dias úteis.");
    }

    return ClienteArmazem.memoizeMultiple(this.app.api)
      .then(r => this.planos = r)
      .then(_ => true)
      .catch(err => this.app.notificationErrorCompact(err));
  }

  activate(p) {
    if (environment.debug) console.log("[expedicao-relacional]", "activate", p);
    this.configuraColunas();
    //reduz a seleção dos planos
    this.listaClientes = this.planos.filter(el => this.planosUtilizador.includes(el.nvcClienteArmazem));
    if (environment.debug) console.log("[expedicao-relacional]", "activate end", this.columns, this.listaClientes);
  }

  attached() {
    if (environment.debug) console.log("[expedicao-relacional]", "attached");

    /*
        this.linhas = [];
        this.ds.setArray(this.linhas);
        this.ds.orderBy("nvcClienteArmazem");
        this.ds.orderBy("dtmMovimento", true);
        this.ds.orderBy("idRegistoPlano", true);
        this.registerListeners();
    */
    this.doAction("INIT");
  }

  unbind() {
    if (environment.debug) console.log("[expedicao-relacional]", "unbind");
    document.onkeyup = null;
    //fechar todos os popups
    this.app.ds.closeAll();
  }

  //noinspection JSMethodCanBeStatic
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  /**
   * Pede uma lista de linhas de programações e as etiquetas associadas
   * @param {string[] | number[]} rps
   * @return {Promise<ControloPlanoExpedicao[]>}
   */
  getLinhas(rps: string[] | number[] = []) {
    let payload: any = {};
    payload.diaIni   = this.diaIni;
    payload.diaFim   = this.diaFim;
    payload.planos   = this.planosUtilizador;
    if (rps && rps.length > 0) payload.rps = rps;

    return this.app.api.getProcessed('api/expedicao/folha-relacionada', payload)
      .then(r => {
        if (environment.debug) console.log("[expedicao-relacional]", "getLinhas");
        let periodo = this.rangeDias;
        let linhas: ControloPlanoExpedicao[];// = ControloPlanoExpedicao.multipleFromPOJSO(r.linhas);

        //todo: proteger a inexistência de keys

        if (Array.isArray(r.linhas)) {
          linhas = r.linhas.map(l => {
            let linha: ControloPlanoExpedicao = ControloPlanoExpedicao.fromPOJSO(l);

            //inferencia das faltas
            let faltas = r.prgs.filter(p => p.idPlanoExpedicao == linha.idPlanoExpedicao && (p.dtmMovimento < this.diaIni));
            if (faltas.length > 0) {
              //if(environment.debug) console.log("[expedicao-relacional]","FALTAS", faltas);
              let relacional       = new Relacional();
              relacional.dia       = "falta";
              relacional.diaSemana = "falta";
              relacional.prgs      = VRegistoPlanoPrgOriginal.multipleFromPOJSO(faltas);
              // relacional.intTotalPrg = relacional.getTotalPrg();
              relacional.conteudo  = true;
              relacional.normaliza();
              linha.PrgDias.falta = relacional;
              linha.intFalta      = relacional.intTotalFalta;
            }

            //distribuição dos eventos do periodo visível
            periodo.forEach(d => {
              //ATENÇÃO: aqui pode haver múltipla cardinalidade
              let relacional       = new Relacional();
              relacional.dia       = d.dia;
              relacional.diaSemana = d.diaSemana;
              // há programações no dia?
              let prgRaw: any[]    = r.prgs.filter(p => p.idPlanoExpedicao == linha.idPlanoExpedicao && p.dtmMovimento.startsWith(d.dia));

              if (prgRaw.length > 0) {
                relacional.prgs     = VRegistoPlanoPrgOriginal.multipleFromPOJSO(prgRaw);
                // relacional.intTotalPrg = relacional.getTotalPrg();
                relacional.conteudo = true;
              }

              // há controlos no dia?
              let rcRaw = r.rc.filter(p => p.idPlanoExpedicao == linha.idPlanoExpedicao && p.dtmData.startsWith(d.dia) && p.nvcTipo == 'controlo');
              if (rcRaw.length > 0) {
                relacional.rcs      = RegistoControlo.multipleFromPOJSO(rcRaw);
                relacional.conteudo = true;
              }

              // há gravações no dia?
              let rgRaw = r.rc.filter(p => p.idPlanoExpedicao == linha.idPlanoExpedicao && p.dtmData.startsWith(d.dia) && p.nvcTipo == 'gravacao');
              if (rgRaw.length > 0) {
                relacional.rgs      = RegistoControlo.multipleFromPOJSO(rgRaw);
                relacional.conteudo = true;
              }

              // há etiquetas no dia?
              let reRaw = r.re.filter(p => p.idPlanoExpedicao == linha.idPlanoExpedicao && p.dtmData.startsWith(d.dia));
              if (reRaw.length > 0) {
                relacional.res      = RegistoEtiqueta.multipleFromPOJSO(reRaw);
                relacional.conteudo = true;
              }

              //há conteúdo no dia?
              if (relacional.conteudo) {
                relacional.normaliza();
                //acummula os totais
                linha.PrgDias[d.dia] = relacional;
                linha.intPrgOriginal += relacional.intTotalPrg;
                linha.intSai += relacional.intTotalSai;
                linha.totalControlado += relacional.intControlado;
                linha.totalGravado += relacional.intGravado;
                linha.totalEtiquetado += relacional.intEtiquetado;
                //console.log("diario", linha);
              }
            });

            return linha;
          })
        }

        return linhas;
      })
  }

  /**
   * Atualiza algumas linhas com novasLinhas
   * Usado para publicar dados na grid
   *
   * @param {ControloPlanoExpedicao[]} novasLinhas
   * @return {Promise<boolean>}
   */
  mergeLinhas(novasLinhas: ControloPlanoExpedicao[]) {
    if (!novasLinhas || novasLinhas.length == 0) {
      console.warn("não há novas linhas", novasLinhas);
      return Promise.resolve(true);
    }
    novasLinhas.forEach(nl => {
      let existente = this.linhas.findIndex(l => l.idPlanoExpedicao == nl.idPlanoExpedicao);
      if (existente >= 0) {
        //atualizar a referência
        this.linhas[existente] = nl;
      } else {
        //if (environment.debug) console.warn("[nova-expedicao]", `A linha de código ${nl.idPlanoExpedicao} não foi encontrada, será inserida uma nova.`);
        this.linhas.push(nl);
      }
    });
    this.ds && this.ds.refresh(this.linhas);
    return Promise.resolve(true);
  }

  //endregion

  //region diversos

  //cria um dia[] com todos os dias entre diaIni e diaFim, inclusivo
  private rangePeriodo(): dia[] {
    let ret: dia[] = [];
    for (let i = 0; i < this.numeroDias; i++) {
      let moment2 = this.momentIni.clone().add(i, "days");
      if (moment2.isoWeekday() >= 1 && moment2.isoWeekday() <= 5) {
        ret.push({dia: moment2.format("YYYY-MM-DD"), diaSemana: moment2.format("ddd")});
      }
    }
    if (environment.debug) console.log("[expedicao-relacional]", "rangePeriodo", ret);
    return ret;
  }

  //colunas com afixadas à esquerda
  private lefties: ColConfigInterface[] = [
    {
      colHeaderName : "#", colType: "static", colWidth: 22, colField: "idPlanoExpedicao", colSort: "field:idPlanoExpedicao", colFilter: "field:idPlanoExpedicao; operator:=",
      colRowTemplate:
      //'<button class="btn btn-xs btn-info" click.delegate=\'doAction("REFRESH-LINHAS", [rowRef.idPlanoExpedicao])\' title="Refrescar a linha ${rowRef.idPlanoExpedicao}"><i class="fa fa-refresh"></i></button>' +
        ' <button class="btn btn-xs btn-info" click.delegate=\'doAction("DETALHES-LINHA-PLANO", rowRef.idPlanoExpedicao)\' title="Ver os eventos associados à linha do plano"><i class="fa fa-list"></i></button>',
      // colCss        : 'text-align:center; line-height: 40px; background: ${rowRef._backgroundColor}',
      colPinLeft    : true,
    },
    {colHeaderName: "Tipo", colType: "text", colWidth: 20, colField: "nvcTipoShort", colPinLeft: true, colSort: "field:nvcTipo", colFilter: "field:nvcTipoShort; operator:=", colResizeable: "false"},
    {colHeaderName: "Cliente", colType: "text", colWidth: 48, colField: "nvcClienteArmazem", colPinLeft: true, colSort: "field:nvcClienteArmazem", colFilter: "field:nvcClienteArmazem; operator:*", colCss: 'background: ${rowRef._backgroundColor}',},
    {colHeaderName: "Código Produto", colType: "text", colWidth: 100, colField: "nvcArtigo", colPinLeft: true, colSort: "field:nvcArtigo", colFilter: "field:nvcArtigo; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "Designação Produto", colType: "text", colWidth: 300, colField: "nvcArtigoDescricao", colPinLeft: true, colSort: "field:nvcArtigoDescricao", colFilter: "field:nvcArtigoDescricao; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "CDE", colType: "text", colWidth: 80, colField: "nvcCodigoEncomenda", colPinLeft: true, colSort: "field:nvcCodigoEncomenda", colFilter: "field:nvcCodigoEncomenda; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {colHeaderName: "Gravação", colType: "text", colWidth: 80, colField: "nvcGravacao", colPinLeft: true, colSort: "field:nvcGravacao", colFilter: "field:nvcGravacao; operator:*", colCss: 'background: ${rowRef._backgroundColor}'},
    {
      colHeaderName : "Faltas", colType: "text", colWidth: 104, colField: "intFalta", colPinLeft: true, colSort: "field:intFalta",
      //colHeaderTemplate: `<div class="text-center" v-sort="field:intFalta">Faltas</div>`,
      colRowTemplate: '<falta-relacional-cell if.bind="rowRef.PrgDias.falta" modelo.bind="rowRef.PrgDias.falta" do-action.call="doAction(action, payload)"></falta-relacional-cell>',
    },
  ];

  //colunas afixadas à direita
  private righties: ColConfigInterface[] = [
    {colHeaderName: "Programado", colType: "static", colWidth: 80, colField: "intPrgOriginal", colPinRight: true, colSort: "field:intPrgOriginal", colFilter: "field:intPrgOriginal; operator:*",},
    {colHeaderName: "Saida", colType: "static", colWidth: 80, colField: "intSai", colPinRight: true, colSort: "field:intSai", colFilter: "field:intSai; operator:*",},
    {colHeaderName: "Controlado", colType: "static", colWidth: 80, colField: "totalControlado", colPinRight: true, colSort: "field:totalControlado", colFilter: "field:totalControlado; operator:*",},
    {colHeaderName: "Gravado", colType: "static", colWidth: 80, colField: "totalGravado", colPinRight: true, colSort: "field:totalGravado", colFilter: "field:totalGravado; operator:*",},
    {colHeaderName: "Etiquetado", colType: "static", colWidth: 80, colField: "totalEtiquetado", colPinRight: true, colSort: "field:totalEtiquetado", colFilter: "field:totalEtiquetado; operator:*",},
  ];

  //injeta a definição das colunas de acordo com os parâmetros de navegação ao viewModel
  public configuraColunas(): void {
    this.columns = [...this.lefties, ...this.colunasDinamicas(), ...this.righties];
    //this.columns = [...this.lefties, ...this.colunasDinamicas()];
    //if (environment.debug) console.log("[expedicao-relacional]", "configuraColunas", this.columns);
  }

  //gerador das colunas (parte central, variável consoante o periodo visualizado)
  private colunasDinamicas(): ColConfigInterface[] {
    //if (environment.debug) console.log("[expedicao-relacional]", "colunasDinamicas", this.tipo);

    let r = this.rangeDias;
    return r.map(el => ({
      colWidth         : 104,
      colField         : "na",
      colHeaderTemplate: `<div class="text-center ${el.diaSemana.toLocaleLowerCase()}" v-sort="field:DiarioSort.${el.dia};asc:false;">${el.diaSemana}<br>${el.dia}</div>`,
      colSort          : `field:PrgDias['${el.dia}']`,
      colRowTemplate   :
        `<div class="${el.diaSemana.toLocaleLowerCase()}"><relacional-diario-cell class="${el.diaSemana.toLocaleLowerCase()}" if.bind="rowRef.PrgDias['${el.dia}']" modelo.bind="rowRef.PrgDias['${el.dia}']" tipo.bind="rowRef.nvcTipo" do-action.call="doAction(action, payload)"></relacional-diario-cell></div>`
    }))
      ;
  }

  //endregion

  //region acções
  /**
   * doAction Componente
   * @param {string} action
   * @param payload
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[expedicao-relacional]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "INIT": {
          this.linhas = [];
          this.ds.setArray([]);
          // this.ds.orderBy("nvcClienteArmazem");
          // this.ds.orderBy({attribute: "intFalta", asc: false}, true);
          // this.ds.orderBy("dtmMovimento", true);
          // this.ds.orderBy("idRegistoPlano", true);
          // this.registerListeners();
          this.doAction("REFRESH-LINHAS");
          break;
        }

        case "REFRESH-LINHAS": {
          this.app.ds.closeAll();
          this.app.smoked = true;
          return this.getLinhas(payload)
            .then(novasLinhas => this.mergeLinhas(novasLinhas))
            .then(_ => {
              this.ds.orderBy("nvcClienteArmazem", false);
              this.ds.orderBy({attribute: "intFalta", asc: false}, true);
            })
            .catch(err => this.app.notificationErrorCompact(err))
            .then(_ => this.app.smoked = this.isBusy = false);
        }

        case "PERIODO": {
          if (environment.debug) console.log("[expedicao-relacional]", "PERIODO", "NAVIGATING....");
          return this.app.router.navigateToRoute("expedicao2", {diaIni: this.diaIni, diaFim: this.diaFim}, {replace: true});
        }

        case "DETALHES-LINHA-PLANO": {
          this.app.ds
            .open({viewModel: ListaRegistoPlanoDialog, model: {linha: {idPlanoExpedicao: payload}, rowIndex: -1, cellIndex: -1, withActions: false}, lock: false})
            .whenClosed(resp => {this.app.smoked = this.isBusy = false;});
          break;
        }


        case "INFORMACAO-CONTROLO-ID" : {
          this.app.ds.closeAll();
          let diario = new ControloDiario({idControloDiario: payload});

          return this.app.api.getProcessed("api/controlo/diario", {id: diario.idControloDiario})
            .then(r => {
              diario = ControloDiario.fromPOJSO(r);
              if (environment.debug) console.log("[expedicao-relacional]", "Diário recebido:", r, diario);
              return true;
            })
            .then(_ => {
              let mainView = /*diario.nvcTipo == "controlo" ? '../routes/expedicao/ce/controlo-diario-info-dialog.html' : */'../routes/expedicao/ce/controlo-diario-info-gravacao-dialog.html';
              this.isBusy  = false;
              return this.app.ds.open({
                  viewModel: ComposeDialog,
                  model    : {
                    modelo : diario,
                    invoker: this,
                    options: new ComposeDialogOptions({
                      title            : `Detalhes do controlo de ${diario.dtmData} - # ${diario.idControloDiario}`,
                      withDefaultFooter: false,
                      okLabel          : null,
                      trackChanges     : false,
                      mainView         : mainView,
                    }),
                  }
                })
                .whenClosed(r => {
                  return r.wasCancelled
                })
                .catch(err => this.app.notificationErrorCompact(err))
                .then(_ => this.isBusy = false);
            });
        }

        case 'VER-DIARIO': {
          if (environment.debug) console.log("[expedicao-relacional]", "VER-DIARIO", payload);
          let pl = payload as Relacional;
          if (!pl || !pl.prgs[0] || !pl.prgs[0].idControloDiario) {
            return this.isBusy = this.app.notificationErrorCompact("Não há diários de controlo associados à programação.");
          }
          let id     = pl.prgs[0].idControloDiario;
          return this.doAction("INFORMACAO-CONTROLO-ID", id);
        }

        case "VER-ETIQUETA":{
          return this.app.ds.open({
            viewModel: ComposeDialog,
            model    : {
              modelo : payload,
              invoker: this,
              options: new ComposeDialogOptions({
                withDefaultFooter: false,
                trackChanges     : false,
                title            : "Ver detalhes de etiqueta nº " + payload.idRegistoEtiqueta + " #CD" + payload.idControloDiario,
                okLabel          : "",
                mainView         : './lista-registo-plano-dialog-detalhes-etiqueta.html',
                //postUri          : 'api/controlo/etiqueta'
              }),
            }
          });
        }

        //endregion detalhes de etiquetas
        default: {
          if (environment.debug) console.log("[expedicao-relacional]", "Acção DESCONHECIDA [artigo-listagem]", action);
          return Promise.resolve(this.isBusy = false);
        }
      }
    } catch (err) {
      return Promise.resolve(this.isBusy = this.app.notificationErrorCompact(err));
    }
  }

  //endregion acções
}

/**
 * Utilitário para concentrar informação relacional entre PRG/CONTROL/GRAV/ETIQ
 * Em princípio o seu uso limita-se a este viewModel daí a sua declaração estar conjunta
 */
export class Relacional {
  public dia: string;
  public diaSemana: string;
  public conteudo: boolean                = false;
  public prgs: VRegistoPlanoPrgOriginal[] = [];

  //formalização de valores usados no mapa
  public intTotalPrg: number   = 0;
  public intTotalFalta: number = 0;
  public intTotalSai: number   = 0;
  public intControlado: number = 0;
  public intGravado: number    = 0;
  public intEtiquetado: number = 0;

  //formalização dos valores controlados no dia
  public intControladoDia: number = 0;
  public intGravadoDia: number    = 0;
  public intEtiquetadoDia: number = 0;

  //controlo
  public rcs: RegistoControlo[] = [];

  //gravação
  public rgs: RegistoControlo[] = [];

  //etiquetas
  public res: RegistoEtiqueta[] = [];

  normaliza() {
    // this.intTotalPrg   = this.getTotalPrg();
    // this.intTotalSai   = this.getTotalSai();
    // this.intTotalFalta = this.getTotalFalta();
    this.prgs.forEach(p => {
      this.intTotalPrg += p.intDeltaOriginal;
      this.intTotalSai += p.intDeltaSaida;
      this.intTotalFalta += p.intDelta;
      this.intControlado += p.intQuantidadeControlada;
      this.intGravado += p.intQuantidadeGravada;
      this.intEtiquetado += p.intQuantidadeEtiquetada;
    });
    this.intControladoDia = this.getTotalRc();
    this.intGravadoDia    = this.getTotalRg();
    this.intEtiquetadoDia = this.getTotalRe();
  }

  /**
   * getters passivos
   * A fim de reduzir o numero de observadores
   */
  public getTotalPrg() {
    return this.prgs.reduce((acc, el) => acc + el.intDeltaOriginal, 0);
  }

  public getTotalSai() {
    return this.prgs.reduce((acc, el) => acc + el.intDeltaSaida, 0);
  }

  public getTotalFalta() {
    return this.prgs.reduce((acc, el) => acc + el.intDelta, 0);
  }

  public getTotalRc() {
    return this.rcs.reduce((acc, el) => acc + el.intTotal, 0);
  }

  public getTotalRg() {
    return this.rgs.reduce((acc, el) => acc + el.intTotal, 0);
  }

  public getTotalRe() {
    return this.res.reduce((acc, el) => acc + (el.intApart + el.intBoas + el.intF + el.intK), 0);
  }

  public prgClass(): string {
    if (this.intTotalSai >= this.intTotalPrg) return "fechado";
    return "aberto";
  }
}
