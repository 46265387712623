//import {setInterval} from "timers";
import {autoinject} from "aurelia-framework";
import {activationStrategy} from 'aurelia-router';
import {ValidationController, ValidationControllerFactory} from "aurelia-validation";
import {GlobalServices} from "../../services/global-services";
import {ClienteArmazem} from "../../models/ClienteArmazem";
import {BootstrapFormRenderer} from "../../services/bootstrap-form-renderer";
import environment from "../../environment";
import {RemoteGrid} from "../../it-features/remote-grid/remote-grid";

@autoinject()
export class Remote {
  public app: GlobalServices;
  public isBusy: boolean      = false;
  private validationController: ValidationController;

  public rg:RemoteGrid;

  //region Aurelia
  constructor(g: GlobalServices, vcf: ValidationControllerFactory) {
    this.app                  = g;
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  canActivate(p, rc) {
    if (environment.debug) console.log("[remote]", "Cliente Armazém Activate!", p, rc);
    this.isBusy = false;
  }

  public doAction(action: string, payload?: any) {
    if (environment.debug) console.log("[remote]", "{doAction}", action, payload);
    try {
      this.isBusy = true;
      switch (action) {
        case "RESET": {

          this.rg && this.rg.resetFiltering();
          this.isBusy = false;
          break;
        }
        default: {
          this.app.notificationErrorCompact(["Acção DESCONHECIDA [remote]", action]);
          this.isBusy = false;
        }
      }
    } catch (err) {
      this.app.notificationErrorCompact(err);
    }
  }
}
