import {BaseViewModel} from "./BaseViewModel";
import {weekOf} from "../utils/ItNumeric";
/**
 * Created by herna on 5/2/2017.
 */
export class CalendarioExpedicaoEpoca extends BaseViewModel {
  public idCalendarioExpedicaoEpoca: number;
  public idEpoca: number;
  public dtmData: string = "";
  public _dtmData: Date = null;
  public nvcTipo: string;
  public timestamp: string;

  public constructor(fields?: Partial<CalendarioExpedicaoEpoca>) {
    super();
    Object.assign(this, fields);
  }


  public static fromPOJSO(obj: Partial<CalendarioExpedicaoEpoca>, index:number = 0): CalendarioExpedicaoEpoca {
    let model = new CalendarioExpedicaoEpoca();
    model.setState(obj);
    if(model.dtmData) model._dtmData = new Date(model.dtmData);
    //serve para fazer inverse lookup no array de calendário.
    model.__index = index;
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): CalendarioExpedicaoEpoca[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map((val, ind) => CalendarioExpedicaoEpoca.fromPOJSO(val, ind));
    return [];
  }

  public semana(){
    return weekOf(this._dtmData);
  }

  //todo: stateToPOJSO
  //todo: check ao timestamp
}
