// Auto-gerado com typewriter
import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";
import {RegistoEtiqueta} from "./RegistoEtiqueta";
//imports locais

//export classe
export class RegistoPlanoPrgOriginal extends BaseViewModel {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idRegistoPlano: number          = 0;
  public idPlanoExpedicao: number        = 0;
  public intDelta: number                = 0;
  public dtmMovimento: string            = null;
  // public _dtmMovimento: Date                         = new Date();
  public intDeltaParcela: number         = 0;
  public intDeltaSaida: number           = 0;
  public intDeltaOriginal: number        = 0;
  public intQuantidadeControlada: number = 0;
  public intQuantidadeGravada: number    = 0;
  public intQuantidadeEtiquetada: number = 0;
  public nvcClienteArmazem: string       = null;
  public nvcArtigo: string               = null;
  // public timestampA: number[]                        = [];
  // public idPlanoExpedicaoNavigation: PlanoExpedicao  = null;
  // public idRegistoPlanoNavigation: RegistoPlano      = null;
  // public nvcArtigoNavigation: Artigo                 = null;
  // public nvcClienteArmazemNavigation: ClienteArmazem = null;

  //region EXTRA
  public bitFalta: boolean          = false;
  public nvcGravacao: string        = null;
  public nvcCodigoEncomenda: string = null;
  public nvcDescricaoArtigo: string = null;


  public RegistoEtiqueta: RegistoEtiqueta[] = [];

  public _backgroundColor:string = "#FFFFFF";

  //endregion EXTRA

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<RegistoPlanoPrgOriginal>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): RegistoPlanoPrgOriginal {
    let model = new RegistoPlanoPrgOriginal();
    model.setState(obj);

    if(model.bitFalta) model._backgroundColor = "#ffd764";
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): RegistoPlanoPrgOriginal[] {
    if (objs && Array.isArray(objs)) return objs.map(RegistoPlanoPrgOriginal.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<RegistoPlanoPrgOriginal>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): RegistoPlanoPrgOriginal { return RegistoPlanoPrgOriginal.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public toString() {
    return `RegistoPlanoPrgOriginal`;
  }
}

// aurelia-validation (comentar o que não interessa)
// ValidationRules
//   .ensure((m: RegistoPlanoPrgOriginal) => m.idRegistoPlano).displayName("idRegistoPlano").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.idPlanoExpedicao).displayName("idPlanoExpedicao").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.intDelta).displayName("intDelta").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.dtmMovimento).displayName("dtmMovimento").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.intDeltaParcela).displayName("intDeltaParcela").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.intDeltaSaida).displayName("intDeltaSaida").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.intDeltaOriginal).displayName("intDeltaOriginal").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.intQuantidadeControlada).displayName("intQuantidadeControlada").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.intQuantidadeGravada).displayName("intQuantidadeGravada").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.intQuantidadeEtiquetada).displayName("intQuantidadeEtiquetada").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.nvcClienteArmazem).displayName("nvcClienteArmazem").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.nvcArtigo).displayName("nvcArtigo").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.timestampA).displayName("timestampA").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.idPlanoExpedicaoNavigation).displayName("idPlanoExpedicaoNavigation").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.idRegistoPlanoNavigation).displayName("idRegistoPlanoNavigation").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.nvcArtigoNavigation).displayName("nvcArtigoNavigation").required()
//   .ensure((m: RegistoPlanoPrgOriginal) => m.nvcClienteArmazemNavigation).displayName("nvcClienteArmazemNavigation").required()
//   .on(RegistoPlanoPrgOriginal);
