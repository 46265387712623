// import {ColConfigInterface, DataSource, EntityInterface, GridConnector, Selection, SortObjectInterface} from "aurelia-v-grid";
import {GlobalServices} from "../../services/global-services";
import {autoinject} from "aurelia-framework";
import * as AwesompleteJs from "awesomplete";
import {validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {BootstrapFormRenderer} from "../../services/bootstrap-form-renderer";
import environment from "../../environment";

//import * from "choices.js/assets/styles/css";
@autoinject()
export class AwesompleteVm {
  message: string;
  private el1: HTMLInputElement;
  public classico: string         = "valor inicial";
  public varCustomElement: string = "valor inicial 1";
  public varCustomElementCache: string = "valor inicial 1 (cache)";
  public varCustomElementCacheGrupo: string = "valor inicial 1 (grupo)";
  private validationController: ValidationController;

  constructor(public app: GlobalServices, vcf: ValidationControllerFactory) {
    this.validationController = vcf.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.validationController.validateTrigger = validateTrigger.changeOrBlur;
  }

  //esta route apenas ativa em debug
  canActivate(p) {
    return environment.debug;
  }

  attached() {
    //this.el1
    new AwesompleteJs(this.el1, {
      list: ["Ada", "Java", "JavaScript", "Brainfuck", "LOLCODE", "Node.js", "Ruby on Rails"]
    });
  }

  valida() {
    this.validationController.validate()
      .then(r => {
        console.log("Validação:", r)
      })
  }
}

ValidationRules
  .ensure((m: AwesompleteVm) => m.classico).required()
  .ensure((m: AwesompleteVm) => m.varCustomElement).required()
  .ensure((m: AwesompleteVm) => m.varCustomElementCache).required()
  .on(AwesompleteVm);
