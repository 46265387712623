/**
 * Mapa de routes-mobile para entry-point em mobile
 */
import {RouteConfig} from "aurelia-router";

export const mobileRoutes: RouteConfig[] = [
//@formatter:off
{route: ''           , href: ''           , redirect: 'inicio'}   ,
{route: 'inicio'     , href: 'inicio'     , name: 'mobile_inicio' , moduleId: 'routes-mobile/index'                      , nav: true   , title: 'Mobile'                 , auth: false} ,
{route: 'producao'   , href: 'producao'   , name: 'producao'      , moduleId: 'routes-mobile/expedicao/registo-producao' , nav: true   , title: "Registo de Produção"    , auth: true}  ,
{route: 'utilizador' , href: 'utilizador' , name: 'utilizador'    , moduleId: 'routes-mobile/utilizador'                 , nav: false  , title: "Detalhes do utilizador" , auth: true}   ,
{route: 'login'      , href: 'login'      , name: 'login'         , moduleId: 'routes-mobile/login'                      , nav: false} ,
//@formatter:on
];
