// Auto-gerado com typewriter
import {computedFrom} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";
import {VmWrapper} from "./VmWrapper";
//imports locais
import {OperadorDerivation} from "./BaseViewModelDerivations";
import {ControloDiario, TipoControlo} from "./ControloDiario";

//export classe
export class RegistoControlo extends OperadorDerivation {
  //Propriedades (comentar as não importantes) $  Properties[$Declaratio n]
  public idRegistoControlo: number                  = 0;
  public idControloDiario: number                   = 0;
  public intQuantidade: number                      = 0;
  public dtmData: string                            = null;
  // public _dtmData: Date                             = new Date();
  public bitValido: boolean                         = true;
  public bitSinal: boolean                          = false;
  public intFactor: number                          = 1;
  public intBoas: number                            = 0;
  public intK: number                               = 0;
  public intF: number                               = 0;
  public intApart: number                           = 0;
  public bitGravado: boolean                        = false;
  public nvcObservacoes: string;
  public nvcTipo: string                            = TipoControlo.Controlo;
  public dtmDataI: string;
  public dtmDataA: string;
  public idControloDiarioNavigation: ControloDiario = null;
  // public idOperadorNavigation : Operador = null;
  // public idUtilizadorANavigation : Utilizador = null;
  // public idUtilizadorINavigation : Utilizador = null;

  public intLimite: number = 0;

  @computedFrom("intBoas", "intK", "intF", "intApart")
  public get intTotal() {
    this.intQuantidade = +this.intBoas + +this.intF + +this.intK + +this.intApart;
    return +this.intQuantidade;
  }

  //region estado

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<RegistoControlo>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public copyState(obj: any, props: string[] = []): RegistoControlo {
    super.copyState(obj, props);
    obj.dtmDataI && (this.dtmDataI = obj.dtmDataI.replace("T", " "));
    obj.dtmDataA && (this.dtmDataA = obj.dtmDataA.replace("T", " "));
    return this;
  }

  public static fromPOJSO(obj: any): RegistoControlo {
    return new RegistoControlo().copyState(obj);
  }

  public static multipleFromPOJSO(objs: any | any[]): RegistoControlo[] {
    if (objs && Array.isArray(objs)) return objs.map(RegistoControlo.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    let state = this.getState();
    state     = Object.assign(state, super.getState());
    return state;
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<RegistoControlo>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): RegistoControlo { return RegistoControlo.fromPOJSO(this.stateToPOJSO());}

  //endregion

  public inicializaParaEdicao() {
    //o limite é o total programado  - ( o (controlado /gravado) + esta parte )
    if (this.nvcTipo == TipoControlo.Controlo) {
      this.intLimite = this.idControloDiarioNavigation.intTotalPrgOriginal - this.idControloDiarioNavigation.intTotalControladas + this.intQuantidade;
    } else {
      this.intLimite = this.idControloDiarioNavigation.intTotalPrgOriginal - this.idControloDiarioNavigation.intTotalGravadas + this.intQuantidade;
    }
  }

  public toString() {
    return `RegistoControlo`;
  }
}

// aurelia-validation (comentar o que não interessa)
ValidationRules
  .ensure((m: RegistoControlo) => m.intQuantidade).displayName("Quantidade").required()
  .ensure((m: RegistoControlo) => m.dtmData).displayName("Data").required()
  .ensure((m: RegistoControlo) => m.intFactor).displayName("Factor").required()
  .ensure((m: RegistoControlo) => m.intBoas).displayName("Ok").required()
  .ensure((m: RegistoControlo) => m.intK).displayName("K").required()
  .ensure((m: RegistoControlo) => m.intF).displayName("F").required()
  .ensure((m: RegistoControlo) => m.intApart).displayName("Apart").required()
  .ensure((m: RegistoControlo) => m.idOperador).displayName("Operador").required().when(m => !m.idOperadorTemp)
  //.ensure((m: RegistoControlo) => m.idOperadorTemp).displayName("Operador (t)").required().when(m => !m.idOperadorTemp)
  .on(RegistoControlo);
