/**
 * Created by herna on 5/9/2017.
 */
import {activationStrategy, RouteConfig} from "aurelia-router";

//---------------------------------------------------------------- APP
export const mainRoutes: RouteConfig[] = [
//@formatter:off
{route: ['' , 'inicio']                                       , name: 'inicio'                                         , moduleId: 'routes/dashboard/index'  , nav: true                                  , title: 'Início'                  , auth: true} ,
{route: 'plano'                /* , href: 'plano/expedicao'*/ , name: 'plano'                                          , moduleId: 'routes/plano-component'  , nav: true                                  , title: 'Plano'                   , auth: true} ,
{route: 'guias-transporte'        , name: 'guias-transporte'  , moduleId: 'routes/guias-transporte/subroute-component' , nav: true                           , title: 'Guias de Transporte'               , auth: true}                      ,
{route: 'artigo'                  , name: 'artigo'            , moduleId: 'routes/artigo/artigo-component'             , nav: true                           , title: 'Artigos'                           , auth: true}                      ,
{route: 'cliente'                 , name: 'cliente'           , moduleId: 'routes/cliente/subroute-component'          , nav: true                           , title: 'Cliente'                           , auth: true}                      ,
{route: 'admin-rg'                , name: 'admin_rg'          , moduleId: 'routes/admin-rg/admin-rg-component'         , nav:true                            , auth:true                                  , title: 'Admin(RG)'             } ,
{route: 'perfil-utilizador'       , name: 'perfilUtilizador'  , moduleId: 'routes/perfil-utilizador'                   , nav: false                          , title: 'Perfil de utilizador'              , auth: true}                      ,
{route: 'configs'                 , name: 'configuracoes'     , moduleId: 'routes/configuracoes'                       , nav: false                          , title: 'Configuracoes'                     , auth: true}                      ,
{route: 'logs'                    , name: 'logs'              , moduleId: 'routes/listagem-logs'                       , nav: false                          , title: 'Listagem de registos da aplicação' , auth: true}                      ,
{route: 'login'                   , name: 'login'             , moduleId: 'routes/login'                               , nav: false}                         ,
//developping-test
// {route: 'vg'                        , name: 'vg'                        , moduleId: 'routes/testes/remote'                , nav: false                                 , title: 'Teste de Remote grid'                    , auth: true}                   ,
//@formatter:on
];

//---------------------------------------------------------------- PLANO EXPEDIÇÃO
export const planoSubRoutes: RouteConfig[] = [
//@formatter:off
{route: ''                          , redirect: 'expedicao'             , nav: false                                        , title: 'Default'                                  , auth: true}                 ,
{route: 'expedicao'                 , name: 'expedicao'                                 , moduleId: 'routes/plano/expedicao-tabela-virtual' , title: "Expedição"          , nav: true   , auth: true/* , settings:{idEpoca: 1}*/} ,
{route: 'armazem'                   , name: 'armazem'                                   , moduleId: 'routes/plano/expedicao-tabela-virtual' , title: "Armazém"            , nav: true   , auth: true}  ,
//@formatter:on
];

//---------------------------------------------------------------- GUIAS TRANSPORTE
export const guiasTransporteSubRoutes: RouteConfig[] = [
  //@formatter:off
  { route: '', redirect: 'listagem', nav: false, title: 'Default', auth: true },
  { route: 'listagem', name: 'guias-transporte-lista', moduleId: 'routes/guias-transporte/guias-transporte-lista', title: "Listagem de Guias de Transporte", nav: false, auth: true },
  //@formatter:on
];


//---------------------------------------------------------------- ARTIGOS
export const artigoSubRoutes: RouteConfig[] = [
//@formatter:off
{route: ''        , redirect: 'artigos', nav: false                                , title: 'Default'             , auth: true},
{route: 'artigos' , name: 'artigos'    , moduleId: 'routes/artigo/artigo-listagem' , title: "Listagem de artigos" , nav: false , auth: true},
// {route: 'familias', name: 'familias'   , moduleId: 'routes/artigo/familia-listagem', title: "Listagem de familias", nav: false , auth: true},
//@formatter:on
];

//---------------------------------------------------------------- CLIENTES
export const clienteSubRoutes: RouteConfig[] = [
//@formatter:off
{route: ''            , redirect: 'clientes' , nav: false                                  , title: 'Default'              , auth: true} ,
{route: 'clientes'    , name: 'clientes'     , moduleId: 'routes/cliente/cliente-listagem' , title: "Listagem de clientes" , nav: false  , auth: true} ,
// {route: 'familias' , name: 'familias'     , moduleId: 'routes/artigo/familia-listagem'  , title: "Listagem de familias" , nav: false  , auth: true} ,
//@formatter:on
];

// region Funcionalidade administrativa para configuração de tabelas semi-dinâmicas
export const adminRgRoutes: RouteConfig[] = [
//@formatter:off
{route: ''               , redirect: 'tabelas-listar' , nav: false                                   , title: 'Default' , auth: true} ,
{route: 'tabelas-listar' , name: 'tabelas_listar'     , moduleId: 'routes/admin-rg/tabelas-listagem' , nav: true        , auth: true  , title: 'Listagem Tabelas' , settings:{icon: "fa fa-list" , titulo: "Listagem de tabelas/views da base de dados."}} ,
//@formatter:on  ,
];
// endregion Funcionalidade administrativa para configuração de tabelas semi-dinâmicas

