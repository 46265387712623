import {BaseViewModel} from './BaseViewModel';
import {VmWrapper} from './VmWrapper';
import {Artigo} from "./Artigo";

export class ErpGceArtigosComposicao extends BaseViewModel {
  public strCodArtigo: string            = '';
  public strCodArtigoComp: string        = '';
  public strCodArmazem: string;
  public intTpCalculo: number            = 0;
  public strFormula: string;
  public id: number;
  // public updateStamp: string             = '';
  public intNumero: number               = 0;
  public bitNaoPedePorDefeito: boolean;
  public fltPercCusto: number            = 0;
  public intMenuMaxLaunches: number      = 0;
  public bitMenuCanExceedLimit: boolean  = false;
  public intMenuLaunchesRequired: number = 0;

  public StrCodArtigoCompNavigation: Artigo = null;
  public ArtigoCompNavigation: Artigo       = null;

  public quantidadeEncomenda  = 0;
  public quantidadeNecessaria = 0;

  //region estado
  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<ErpGceArtigosComposicao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  public static fromPOJSO(obj: any): ErpGceArtigosComposicao {
    let model = new ErpGceArtigosComposicao();
    model.setState(obj);
    // model.StrCodArtigoCompNavigation = Artigo.fromPOJSO(obj.strCodArtigoCompNavigation);
    model.ArtigoCompNavigation = Artigo.fromPOJSO(obj.strCodArtigoCompNavigation);
    return model;
  }

  public static multipleFromPOJSO(objs: any | any[]): ErpGceArtigosComposicao[] {
    if (objs && Array.isArray(objs)) return objs.map(ErpGceArtigosComposicao.fromPOJSO);
    return [];
  }

  public stateToPOJSO() {
    return this.getState();
  }

  public wrapIt(cl?: number) {
    return new VmWrapper<ErpGceArtigosComposicao>({payload: this, confirmLevel: (+cl || 0)});
  }

  public cloneInstance(): ErpGceArtigosComposicao { return ErpGceArtigosComposicao.fromPOJSO(this.stateToPOJSO());}

  public stateToPOJSOSafeId(): any {
    let state = this.getState();
    //no fundo apagam-se os ids (recursivamente)
    if (state.id) state.id = 0;
    return state;
  }

  //endregion estado

  public toString() {
    return `ErpGceArtigosComposicao`;
  }

  setQuantidadeEncomendada(qty: number) {
    this.quantidadeEncomenda  = qty;
    this.quantidadeNecessaria = +qty * +this.strFormula;
    this.quantidadeNecessaria = +this.quantidadeNecessaria.toFixed(4);
  }
}
