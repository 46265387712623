import {BaseViewModel} from "./BaseViewModel";
import {VmWrapper} from "./VmWrapper";

/**
 * Created by herna on 17/7/2017.
 * DomainModel
 */
export class Permissao extends BaseViewModel {
  nvcPermissao: string  = "";
  nvcDescricao: boolean = false;
  timestamp: string     = "";

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<Permissao>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): Permissao {
    let model = new Permissao();
    model.setState(obj);
    model.__index = index;

    return model;
  }

  public static multipleFromPOJSO(objs: any): Permissao[] {
    if (objs) {
      if (Array.isArray(objs)) return objs.map((el, i) => Permissao.fromPOJSO(el, i));
      return [Permissao.fromPOJSO(objs, 0)];
    }
    return [];
  }

  public stateToPOJSO(): any {
    return this.getState(false);
  }

  public cloneInstance(): Permissao {
    console.log("clone");
    return Permissao.fromPOJSO(this.stateToPOJSO());
  }

  /**
   * McDonalds Wraps
   * @param cl
   * @return {VmWrapper<Permissao>}
   */
  public wrapIt(cl?: number) {
    return new VmWrapper<Permissao>({payload: this, confirmLevel: (+cl || 0)});
  }

  //endregion
}
