import {BaseViewModel} from "./BaseViewModel";
/**
 * Created by herna on 5/2/2017.
 * DomainModel (virtual) que classifica com estado cada linha do plano de expedição
 */
export class EstadoLinhaPlano extends BaseViewModel {
  public nvcEstado: string    = "";
  public nvcDescricao: string = "";

  public static fromPOJSO(obj: any): EstadoLinhaPlano {
    let model = new EstadoLinhaPlano();
    model.setState(obj);
    return model;
  }

  public static multipleFromPOJSO(objs: any[]): EstadoLinhaPlano[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(EstadoLinhaPlano.fromPOJSO);
    return [];
  }

  public static estados() {
    return [
      {nvcEstado: 'aberto', nvcDescricao: 'Há peças a produzir/expedir'},
      //{nvcEstado: 'Expedido', nvcDescricao: 'Expedido'},
      {nvcEstado: 'fechado', nvcDescricao: 'Todas as peças foram produzidas, ou transferidas para outra linha do plano.'},
    ]
  }
}

export const estadosLinhaPlano = {
  aberto  : "aberto",
  expedido: "expedido",
  fechado : "fechado",
};
