import {autoinject, bindable, bindingMode, inlineView} from "aurelia-framework";
import * as AwesompleteJs from "awesomplete";
import {removeDiacritics} from "../../utils/ItMultiPurpose";

@inlineView('<template><require from="./awesomplete.theme.css"></require><input class="form-control" ref="inputEl" autosel placeholder.bind="placeholder"></template>')
export class Awesomplete {
  @bindable({defaultBindingMode: bindingMode.oneTime}) debug: boolean = false;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string   = "";
  @bindable({defaultBindingMode: bindingMode.oneTime}) placeholder: string = "Introduza um caracter";

  //opcções por defeito
  @bindable({defaultBindingMode: bindingMode.toView}) options: AwesompleteOptions = null;

  //internas
  private inputEl: HTMLInputElement = null;
  private awesompleteInstance: AwesompleteJs;

  constructor() {
    //nada, por enquanto
  }

  attached() {
    //verificar existencia de bindings das opções
    if (!this.options) { console.warn("[awesomplete]", "Não foi passado um objeto de opcções ao CE")}

    //fusão de opções defeito + binded
    let base     = new AwesompleteOptions();
    this.options = base.merger(this.options);

    //avisos para develop
    if (!this.options.list || this.options.list.length <= 0) {
      console.warn("[awesomplete]", "A lista de opções não foi facultada ou é vazia")
    }

    //inicialização do 1o valor
    if(this.value) {
      this.inputEl.value = this.value;
    }

    // AwesompleteJs._ITEM = function (text, input, item_id) {
    //   console.log("asdsd");
    // };
      // var html = input.trim() === "" ? text : text.replace(RegExp($.regExpEscape(input.trim()), "gi"), "<mark>$&</mark>");
      // return $.create("li", {
      //   innerHTML: html,
      //   "aria-selected": "false",
      //   "id": "awesomplete_list_" + this.count + "_item_" + item_id
      // });

    //instanciação do plugin
    this.awesompleteInstance = new AwesompleteJs(this.inputEl, this.options);
    window["aw"]             = this.awesompleteInstance;

    // registo de eventos
    // this.inputEl.addEventListener("awesomplete-select", this.handleSelect);
    this.inputEl.addEventListener("awesomplete-selectcomplete", this.handleSelectComplete);
    // this.inputEl.addEventListener("awesomplete-close", this.handleSelectClose);
    this.inputEl.addEventListener("input", this.handleChange);

    //registo dos eventHandlers (curried?)
    if (this.debug) console.log("[awesomplete]", "plugin instanciado", this.awesompleteInstance);
  }

  detached() {
    //neat & tidy cleanup
    this.inputEl && this.inputEl.removeEventListener("input", this.handleChange);
    this.inputEl && this.inputEl.removeEventListener("awesomplete-selectcomplete", this.handleSelectComplete);
    this.awesompleteInstance && (typeof this.awesompleteInstance.destroy == "function" && this.awesompleteInstance.destroy());
  }

  //region enventHandlers

  public handleSelect = (a) => {
    console.info(a);
  };

  /**
   * rotina de tratamento de uma selecção.
   * No fundo representa o fluxo plugin -> aurelia
   * @param e
   */
  public handleSelectComplete = (e) => {
    console.info(e);
    if (!e.text) {console.error("[awesomplete]", "O evento não transporta a propriedade necessária (e.text)", e);} else {
      let sel: { label: string, value: string } = e.text;
      //todo: proteger contra nulls e afins?
      if (this.value != sel.value) {this.value = sel.value}
    }
  };

  /**
   * rotina de tratamento de uma selecção.
   * No fundo representa o fluxo plugin -> aurelia
   * @param e
   */
  public handleChange = (e) => {
    console.info(e);
    if (this.inputEl.value != this.value) {this.value = this.inputEl.value}
  };

  //endregion enventHandlers

  //region observers

  valueChanged(newVal, oldVal) {
    if (this.debug) console.log("[awesomplete]", "valueChanged", newVal, oldVal);
    if (this.inputEl) {
      if (newVal != this.inputEl.value) {
        //só isto???
        this.inputEl.value = newVal;
      }
    }
  }

  optionsChanged(newVal, oldVal) {
    if (this.debug) console.log("[awesomplete]", "optionsChanged", newVal, oldVal);
  }

  //endregion observers
}

/**
 * Objeto de opções para instanciação do plugin
 * https://leaverou.github.io/awesomplete/#api
 */
export class AwesompleteOptions {
  //lista de opções que o plugin permite escolher
  list: string[] = [];

  //num. mínimo de caracteres para triggar o popup
  minChars: number = 1;

  //número máximo de itens exibidos sob uma qq procura
  maxItems: number = 10;

  //selecção automática do primeiro elemento?
  autoFirst: boolean = true;

  regExpEscape = (s) => {
    return s.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  filter = (text, input)  => {
    console.log("filter");
    let t = removeDiacritics(text);
    let i = removeDiacritics(input);
    return RegExp(this.regExpEscape(i.trim()), "i").test(t);
  };

  //todo: o highlight sem diacríticas é mais difícil

  // a função item tem de devolver um nodo
  // item = (text, input) => {
  //   var html = input.trim() === "" ? text : text.replace(RegExp(this.regExpEscape(input.trim()), "gi"), "<mark>$&</mark>");
  //   return html;
  // };

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<AwesompleteOptions>,) {
    if (fields) Object.assign(this, fields);
  }

  public merger(other: AwesompleteOptions): AwesompleteOptions {
    if (other) {
      if (other.list) {
        this.list = other.list;//.map(el => ({value: el, label:removeDiacritics(el)}));
      }
      if (other.minChars) {
        this.minChars = other.minChars;
      }
      if (other.maxItems) {
        this.maxItems = other.maxItems;
      }
      if (other.autoFirst) {
        this.autoFirst = other.autoFirst;
      }
    }
    return this;
  }
}
