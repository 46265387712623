import {BaseViewModel} from "./BaseViewModel";
import {FolhaExpedicao} from "./FolhaExpedicao";
import {PlanoExpedicao} from "./PlanoExpedicao";

/**
 * Created by herna on 5/2/2017.
 * DomainModel
 */
export class FolhaExpedicaoLinha extends BaseViewModel {

  public idFolhaExpedicaoLinha: number     = 0;
  public idFolhaExpedicao: number          = 0;
  public idPlanoExpedicao: number          = 0;
  public intQuantidadeEnviar: number       = 0;
  public intQuantidadeExpedida: number     = 0;
  public intQuantidadeDespachada: number   = 0;
  public intControlo: number               = 0;
  public intOrdem: number                  = 0;
  public nvcPal: string                    = "";
  public nvcRal: string                    = "";
  public nvcQuantidadesControladas: string = "";
  public nvcObservacoes: string            = "";
  public timestamp: string = "";

  //relações
  public IdFolhaExpedicaoNavigation: FolhaExpedicao = null;
  public IdPlanoExpedicaoNavigation: PlanoExpedicao = null;

  /**
   * Construtor para inicializador à lá c#
   * @param fields
   */
  public constructor(fields?: Partial<FolhaExpedicaoLinha>,) {
    super();
    if (fields) Object.assign(this, fields);
  }

  //region estado
  public static fromPOJSO(obj: any, index: number = 0): FolhaExpedicaoLinha {
    let model = new FolhaExpedicaoLinha();
    model.setState(obj);
    if (obj.idPlanoExpedicaoNavigation) {
      model.IdPlanoExpedicaoNavigation = PlanoExpedicao.fromPOJSO(obj.idPlanoExpedicaoNavigation);
    }
    model.__index = index;
    return model;
  }

  public stateToPOJSO(): any {
    let ret = this.getState(false);
    // if (this.RegistoPlanoIdPlanoExpedicaoNavigation && Array.isArray(this.RegistoPlanoIdPlanoExpedicaoNavigation) && this.RegistoPlanoIdPlanoExpedicaoNavigation.length > 0) {
    //   ret.RegistoPlanoIdPlanoExpedicaoNavigation = this.RegistoPlanoIdPlanoExpedicaoNavigation.map(e => e.stateToPOJSO());
    // }
    return ret;
  }

  public static multipleFromPOJSO(objs: any[]): FolhaExpedicaoLinha[] {
    //console.log("multipleFromPOJSO", objs);
    if (objs && Array.isArray(objs)) return objs.map(FolhaExpedicaoLinha.fromPOJSO);
    return [];
  }

  public cloneInstance(): FolhaExpedicao {
    return FolhaExpedicao.fromPOJSO(this.stateToPOJSO());
  }

  //endregion
}
