import {autoinject, bindable} from "aurelia-framework";
import {GlobalServices} from "../services/global-services";
import environment from "../environment";
import {MobileApp} from "../mobile-app";
import {RemoteGrid} from "../it-features/remote-grid/remote-grid";
import {FilterObjectInterface} from "../it-v-grid";

@autoinject()
export class Index {
  message: string;
  navigation: any;
  breadcrumbs: any;
  private rg: RemoteGrid;
  public isBusy:boolean = true;
  // private vHeaderHeight: number = 50;

  // public vRowHeight: number     = 50;
  // private vFooterHeight         = 25;

  private defaultFilter: FilterObjectInterface[] = [];

  constructor(private app: GlobalServices, protected mobileApp: MobileApp) {
    this.message = 'Hello world';
    this.defaultFilter = [
      {attribute: 'nvcTipo', value: 'PROD', operator: '='},
      {attribute: 'idUtilizador', value: this.app.auth.userId, operator: '='},
      //{attribute: 'guiaTransporte.idGuiaTransporte', value: '0', operator: '='}
    ]
  }

  attached() {
    this.isBusy = false;
  }

  /**
   * doAction Global
   * @param {string} action
   * @param payload
   * @param context
   * @return {Promise<boolean>}
   */
  public doAction(action: string, payload: any, context?: any) {
    if (environment.debug) console.log("[MOBILE-index]", "{doAction}", action, payload);
    this.isBusy = true;
    switch (action) {
      case "REFRESH": {
        this.rg.refreshPage();
        this.isBusy = false;
        break;
      }
      default: {
        if (environment.debug) console.log("[expedicao-tabela-virtual]", "Acção desconhecida [guias-transporte-lista]", action, payload);
        this.isBusy = false;
        if (!action.startsWith("RG_") && this.app.aureliaMain && typeof this.app.aureliaMain.doActionGlobal === "function") {
          return this.app.aureliaMain.doActionGlobal(action, payload, context || this);
        }
        break;
      }
    }
  }
}
