//exemplo de imports de packages npm
// import moment = require("moment");
// import * as marked from 'marked';
import {autoinject} from "aurelia-framework";
import {LoginFormModel} from "../models/login-form-model";
import {Subscription} from "aurelia-event-aggregator";
import {GlobalServices} from "../services/global-services";
import {ValidationControllerFactory} from "aurelia-validation";
import {RecuperaPassDialog} from "../dialogs/recupera-pass-dialog";
import {Router} from "aurelia-router";

@autoinject()
export class Login {
  protected subscription: Subscription;
  public isBusy: boolean           = false;
  public viewModel: LoginFormModel = null;
  public versao: string = "versão";
  app: GlobalServices;

  constructor(app: GlobalServices, vcf: ValidationControllerFactory, protected router: Router) {
    this.isBusy    = true;
    this.app       = app;
    this.viewModel = new LoginFormModel(vcf);
    this.isBusy    = false;
    this.versao = window.localStorage.getItem("versaoApp");
  }

  attached() {
    this.subscription = this.app.ea.subscribe('auth:tryLogin', person => this.tryLogin(person));
    if (this.app.auth.authenticated)
      this.router.navigateToRoute("inicio");
  }

  detached() {
    this.subscription.dispose();
    this.subscription = null;
  }

  //noinspection JSMethodCanBeStatic
  tryLogin(payload: LoginFormModel) {
    //alert(`${person.firstName} ${person.lastName} selected`);
    // console.log("tryLogin", payload);

    this.app.auth.login({username: payload.userName, password: payload.password})
      .then(response => {
        // console.log("Auth ok", response, this.app.auth);
        this.app.notificationSuccess("Bem-vindo");
        this.router.navigateToRoute("inicio");
        //new Redirect('/');
      })
      .catch(r => {
        console.log(r);
        this.app.notificationError("Não foi possível estabelecer a ligação ao servidor");
        //this.app.ea.publish('notification:error', "As credenciais são inválidas");
        this.isBusy = false;
      });
  }

  submit() {
    this.isBusy = true;
    this.viewModel.controller.validate().then(vr => {
      // console.log(vr);
      if (vr.valid) {
        //this.eventAggregator.publish("auth:tryLogin", this.viewModel);
        this.tryLogin(this.viewModel);
      } else {
        this.app.notificationError("O formulário apresenta erros");
        this.isBusy = false;
      }
    }).catch(err => {
      console.log(err);
      this.isBusy = false;
    });
  }

  recuperar(){
    this.app.ds
      .open({viewModel: RecuperaPassDialog, centerHorizontalOnly: true, rejectOnCancel: false})
      .whenClosed(response => {
        if (!response.wasCancelled) {

        } else {
        }
      });
  }
}
